import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";

import Avatar from "react-avatar";
import { FireIcon, SparklesIcon } from "@heroicons/react/24/outline";

import styles from "../styles.module.css";

export function SubCard({
  title,
  content,
  dataDisposal,
  badge = null,
  username = "Aryane Campos",
  userProfile = "https://s3-alpha-sig.figma.com/img/2e01/b2ad/c895fca7e299aceb539bf37f9a72f316?Expires=1693785600&Signature=UHlyk5iI8wiGcq0uhtUOwekzWJe8kIyOaRWm7mB70h4NdplF-FOCqmN3K-9Z4WaAum2fPdQQwQWPZ7Xk4wRcQ~nft45RdPrYo9g53Xr63LWK~kwBCOtRtBcKjo9pX483w52DWC2AGcyNpqDqkPLz3BcjEjPpiCTMk~rM3RyuKcJDDn78AdWh8ldkGCOU5jh1LXb7dSSVGnN~lI9q0m9b5IoeDZPYRCL9IJQqS4eph5NMU9zeD-ORRmsR69-3Duno7mCDNCBwC~dE0~00m3fELFFlKku2f1frDsND9I--eXHAFqDi3chjf1Xl9QkpmGZcU~HRnqzS0-ySZ0jyERjZaQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
}) {
  const getCardType = () => {
    let cardType = "0";
    if (
      dataDisposal === "entrada" ||
      dataDisposal === "scheduled" ||
      dataDisposal === "sold"
    ) {
      cardType = "1";
    } else if (
      dataDisposal === "prospection" ||
      dataDisposal === "connection" ||
      dataDisposal === "qualified"
    ) {
      cardType = "2";
    }
    return cardType;
  };

  const cardType = getCardType();
  return (
    <div
      className={`${styles.subcardContainer} ${
        dataDisposal === "entrada" ||
        dataDisposal === "scheduled" ||
        dataDisposal === "qualified"
          ? styles.subcardRed
          : styles.subcardBlue
      }`}
    >
      <div className={styles.subcardTitleContainer}>
        <h4>{title}</h4>
        <EllipsisVerticalIcon width={20} className={styles.subcardMenuSelect} />
      </div>
      {dataDisposal === "entrada"|| dataDisposal==="scheduled" || dataDisposal === "sold" ? (
        <>
          <p className={styles.subcardContent}>{content}</p>
          <div className={styles.subcardBadgeContainer}>
            <Avatar
              className={styles.subcardAvatar}
              size="20"
              src={userProfile}
            />
            <p className={styles.subcardBadgeName}>{username}</p>
          </div>
        </>
      ) :dataDisposal === "prospection"|| dataDisposal==="connection" || dataDisposal === "qualified" ? (
        <>
          <div className={styles.subcardBadgeContainer}>
            <Avatar
              className={styles.subcardAvatar}
              size="20"
              src="https://s3-alpha-sig.figma.com/img/2e01/b2ad/c895fca7e299aceb539bf37f9a72f316?Expires=1693785600&Signature=UHlyk5iI8wiGcq0uhtUOwekzWJe8kIyOaRWm7mB70h4NdplF-FOCqmN3K-9Z4WaAum2fPdQQwQWPZ7Xk4wRcQ~nft45RdPrYo9g53Xr63LWK~kwBCOtRtBcKjo9pX483w52DWC2AGcyNpqDqkPLz3BcjEjPpiCTMk~rM3RyuKcJDDn78AdWh8ldkGCOU5jh1LXb7dSSVGnN~lI9q0m9b5IoeDZPYRCL9IJQqS4eph5NMU9zeD-ORRmsR69-3Duno7mCDNCBwC~dE0~00m3fELFFlKku2f1frDsND9I--eXHAFqDi3chjf1Xl9QkpmGZcU~HRnqzS0-ySZ0jyERjZaQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
            />
            <p className={styles.subcardBadgeName}>Aryane Campos</p>
          </div>
          {dataDisposal === "prospection" || dataDisposal === "qualified" ? (
            <div
              className={`${styles.subcardStatusBadgeContainer} ${styles.subcardStatusBadgeCMQ}`}
            >
              <FireIcon
                width={20}
                className={styles.subcardStatusBadgeIcon}
                color="#c80000"
              />
              <p
                className={`${styles.subcardStatusBadgeTitle} ${styles.subcardStatusBadgeMQ}`}
              >
                Muito Quente
              </p>
            </div>
          ) : dataDisposal === "connection" ? (
            <div
              className={`${styles.subcardStatusBadgeContainer} ${styles.subcardStatusBadgeCMorno}`}
            >
              <SparklesIcon
                width={20}
                className={styles.subcardStatusBadgeIcon}
                color="#006A00"
              />
              <p
                className={`${styles.subcardStatusBadgeTitle} ${styles.subcardStatusBadgeMorno}`}
              >
                Morno
              </p>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
