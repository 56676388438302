import { useLocation } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react';

import { ModalExit } from '../../../../../../components/ModalExit';

import styles from '../../styles.module.css';
import { CardRelatorio } from '../components/cardRelatorio';

export function Gerados({ ocefx }: any) {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [downloadingIndex, setDownloadingIndex] = useState(-1);
    const [isModalSair, setIsModalSair] = useState(false);
    const [namePdfSelect, setNamePdfSelect] = useState('')
    const [deletePdfStatus, setDeletePdfStatus] = useState('');

    const location = useLocation();
    const queryString = location.search.substring(1);
    const queryParams = new URLSearchParams(queryString);

    const idParams = queryParams.get("id");
    const seqAtendParams = queryParams.get("atd");

    const handleOpenModalExit = (namePdf: string) => {
        setNamePdfSelect(namePdf)
        setIsModalSair(true);
    };

    const handleCloseModalExit = () => {
        setIsModalSair(false);
    };

    const openPagePdf = (dataPdf: string) => {
        window.open(ocefx.estado._servCefx + `/laudos/${idParams}/${seqAtendParams}/${dataPdf}/`, '_blank');
    }

    const getDeletedPdfStatus = (status: string) => {
        setDeletePdfStatus(status)
    }

    useEffect(() => {
        if (idParams && seqAtendParams) {
            ocefx.PDFsGerados(idParams, seqAtendParams, setLoading, setFiles);
        }
    }, []);

    useEffect(() => {
        if (deletePdfStatus === 'success') {
            handleCloseModalExit()
            ocefx.PDFsGerados(idParams, seqAtendParams, setLoading, setFiles);
        }
    }, [deletePdfStatus]);

    return (
        <div>
            {
                !loading && files.length > 0 &&
                <p className={styles.textPdfsGerados}>
                    Total de {files.length} PDF{files.length > 1 ? 's' : ''} gerado{files.length > 1 ? 's' : ''}.
                </p>
            }

            <div className={styles.wrapper}>
                <div className={styles.container_content}>
                    {
                        loading ?
                            <div className={styles.loading_container}>
                                <div className={styles.loading_spinne}></div>
                                <p>Carregando...</p>
                            </div>
                            : files.length == 0 ?
                                <p
                                    className={styles.noResult}
                                    style={
                                        {
                                            width: '100%',
                                            textAlign: 'center',
                                            marginTop: '20px',
                                            fontWeight: '600',
                                            fontSize: '14px',
                                        }}>
                                    Nenhum PDF foi gerado até o momento.
                                </p>
                                :
                                files.map((image, imageIndex) => (
                                    <div
                                        key={imageIndex}
                                        className={`${styles.wrapperImage}`}
                                        onClick={() => openPagePdf(image)}
                                        title='Abrir PDF'
                                    >
                                        <div
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleOpenModalExit(image);
                                            }}
                                            title='Excluir PDF'
                                            className={styles.iconClose}
                                        >
                                            <XMarkIcon
                                                width={26}
                                                color='white'
                                            />
                                        </div>

                                        <div className={styles.containerImage_active}></div>

                                        <div className={`${styles.containerImage}`}>
                                            {downloadingIndex !== imageIndex && (
                                                <img
                                                    src={`${ocefx.estado._servCefx}/api/web/arquivos/getImagecmp/${idParams}/${seqAtendParams}/${image.replace('.pdf', '_m.jpg')}`}
                                                    alt="PDF gerado"
                                                    onError={(e: any) => {
                                                        e.target.onerror = null;
                                                        e.target.src =
                                                            'https://cyberbullying.org/wp-content/uploads/2019/02/pdf-download-300x300.png';
                                                    }}
                                                    className={styles.imgPdf}
                                                />
                                            )}
                                        </div>

                                        <div className={styles.containerContentImage}>
                                            <strong>{image}</strong>
                                            <p></p>
                                        </div>
                                    </div>
                                ))
                    }
                </div>
            </div>
            {isModalSair && (
                <ModalExit
                    ocefx={ocefx}
                    isOpen={isModalSair}
                    onRequestClose={handleCloseModalExit}
                    modoExcluir
                    namePdfSelect={namePdfSelect}
                    getDeletedPdfStatus={getDeletedPdfStatus}
                />
            )}
        </div>
    )
}