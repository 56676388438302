import React, { useState } from 'react';
import Sheet from 'react-modal-sheet';
import styles from './styles.module.css';
import { Avatar, Button } from '@cdt-ui/react';
import { ChevronDownIcon, GlobeAmericasIcon, PencilSquareIcon, PaperAirplaneIcon, LinkIcon } from '@heroicons/react/24/outline';
import { Input } from '../../../../components/Form/Input';
import { TextArea } from '../../../../components/Form/TextArea';
import { Formik, Form } from 'formik';
import { color } from 'framer-motion';
import Select from 'react-select';

interface ModalSheetShareProps {
    isOpen: boolean;
    onRequestClose: () => void;
    PatientNameAndId: any;
    PatientImageSource: any;
}

export const ModalSheetShare = ({isOpen, onRequestClose, PatientNameAndId, PatientImageSource} : ModalSheetShareProps) => {
    const shareOptions = [
        {value: 'editar perfil', label: 'Editar o perfil'},
        {value: 'somente visualizar', label: 'Somente visualizar'},
        {value: 'visulizar e baixar', label: 'Visualzar e baixar'}
    ]
    
    const [selected, setSelected] = useState([]);

    return (
        <Sheet isOpen={isOpen} onClose={onRequestClose} detent="content-height">
            <Sheet.Container style={{borderRadius: '30px 30px 0px 0px', overflowY: 'scroll'}}>
                <Sheet.Header />
                    <Sheet.Content>
                        <div className={styles.container} >
                            <div className={styles.textButtons}>
                                <div className={styles.textButtonHeader}>
                                    <h2>Compartilhar perfil público</h2>
                                    <small>O destinatário poderá visualizar os dados e as imagens, sem login.</small>
                                </div>
                                <div className={styles.textButton}>
                                    <h3>Paciente</h3>
                                    <div className={styles.profile}>
                                    <img
                                        src={`${PatientImageSource}`} alt="Foto do perfil do paciente"
                                        className={styles.imagePerfilPaciente}
                                    />
                                        <div>
                                            <strong>
                                                {
                                                    PatientNameAndId && PatientNameAndId.Paciente ?
                                                        PatientNameAndId.Paciente
                                                        :
                                                        `Nome paciente`
                                                }
                                            </strong>
                                            <span>
                                                {
                                                    PatientNameAndId && PatientNameAndId.Paciente ?
                                                        `#${PatientNameAndId.Id}`
                                                        :
                                                        `Id paciente`
                                                }
                                            </span>
                                        </div>
                                </div>
                                </div>
                                <Formik
                                    initialValues={{
                                        typeMessage: '',
                                    }}
                                    onSubmit={async (values) => {
                                    }} 
                                >
                                    <Form>
                                        <div className={styles.input_group}>
                                            <div className={styles.input_wrapper}>
                                                <Input
                                                    name="emailToShare"
                                                    type='text'
                                                    title='Para'
                                                    labelStyleAbsolute
                                                    placeholder='exemplo@exemplo.com'
                                                />
                                            </div>

                                            <div className={styles.input_wrapper}>
                                                <Input
                                                    name="dentist"
                                                    type='text'
                                                    title='Dentista'
                                                    labelStyleAbsolute
                                                    placeholder='Thiago Martins'
                                                />
                                            </div>

                                            <div className={styles.input_wrapper}>
                                                <TextArea
                                                    name="messageToShare"
                                                    title='Para'
                                                    labelStyleAbsolute
                                                    placeholder='Lorem ipsum dolor sit amet'
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.textButton}>
                                            <h3>Selecione o tipo de compartilhamento</h3>
                                            <div className={styles.shareCard}>
                                                <div className={styles.shareCardInfo}>
                                                    <div className={styles.circle}>
                                                        <GlobeAmericasIcon color='#919DAF' width={32} height={32} />
                                                    </div>
                                                    <div className={styles.shareCardText}>
                                                        <h4>Compartilhar perfil</h4>
                                                        <p>A pessoa que visualizará o perfil do paciente poderá editar todas as informações</p>
                                                    </div>
                                                    <ChevronDownIcon color='#57667A' width={32} height={32} />
                                                </div>
                                                <Select
                                                    className={`${styles.select}`}
                                                    options={shareOptions}
                                                    value={selected}
                                                    onChange={setSelected}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                        ...theme.colors,
                                                        primary: "#DCDCDC",
                                                        },
                                                    })}
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        minHeight: "50px",
                                                        maxHeight: "auto",
                                                        backgroundColor: "#fff" ,
                                                        border: state.isFocused ? '1px solid #007E69' : '1px solid #afafaf',
                                                        borderRadius: "12px",
                                                        paddingLeft: "7px",
                                                        outline: "none",
                                                        }),
                                                        option: (baseStyles) => ({
                                                            ...baseStyles,
                                                            color: "black"
                                                        }),
                                                }}
                                                    defaultValue={shareOptions[0]}
                                                    /* valueRenderer={customValueRenderer} */
                                                />
                                                </div>
                                        </div>

                                        <div className={styles.container_buttons}>
                                            <Button
                                                type="submit"
                                                variant="greenDark"
                                                size="full"
                                                >
                                                <PaperAirplaneIcon />
                                                Enviar
                                            </Button>
                                            <Button
                                                type="submit"
                                                variant="tertiary"
                                                size="full"
                                                >
                                                <LinkIcon />
                                                Copiar Link
                                            </Button>
                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </Sheet.Content>
                    <footer></footer>
            </Sheet.Container>
            <Sheet.Backdrop />
        </Sheet>
    )
}