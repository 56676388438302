import { ReactNode, useEffect, useState } from "react";
import { ActiveLink } from "./ActiveLink";

import styles from "./styles.module.css";

import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { HeartIcon } from "@heroicons/react/24/outline";

import * as Icon from "@heroicons/react/24/outline";
import {
  BrowserRouter,
  redirect,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { replace } from "formik";
import { children } from "dom7";

type Props = {
  title: string;
  href: string;
};

interface NavLinkProps {
  item: any;
  collapse: boolean;
  listId: number;
  isActive: boolean;
  onNavLinkClick: (listId: number) => void;
  handleCollapse: () => void;
}
export function NavLink({
  item,
  collapse,
  listId,
  isActive,
  onNavLinkClick,
  handleCollapse,
}: NavLinkProps) {
  const IconeComponente = Icon[item.icon as keyof typeof Icon];
  const [open, setOpen] = useState(false);
  const [showLinkCollapse, setShowLinkCollapse] = useState(false);
  const navigate = useNavigate();

  const asPath = window.location.pathname;

  const menuOpened = (): void => {
    if (item?.children) {
      item.children.map((child: any) => {
        if (child.href == asPath) {
          setOpen(true);
        }
      });
    }
  };

  function handleOpenMenuLink() {
    setOpen(!open);
  }

  useEffect(() => {
    menuOpened();
  }, []);

  if (collapse == false) {
    return (
      <>
        <li className={styles.li}>
          {item.children!.map((child: any, index: number) => {
            return (
              <ActiveLink key={index} href={child.href}>
                <>
                  <div
                    className={
                      child.isActive
                        ? `${styles.containerTitle_open}`
                        : `${styles.containerTitle}`
                    }
                    onClick={() => {
                      onNavLinkClick(listId);
                    }}
                  >
                    <div
                      className={
                        open
                          ? `${styles.containerIcon_open}`
                          : `${styles.containerIcon}`
                      }
                    >
                      {!IconeComponente ? (
                        <Icon.HeartIcon width={20} height={20} />
                      ) : (
                        <IconeComponente width={20} height={20} />
                      )}

                      <span>
                        {" "}
                        <div className={styles.border}></div>
                        <span>{child.title}</span>
                      </span>
                    </div>
                  </div>
                </>
              </ActiveLink>
            );
          })}
        </li>
      </>
    );
  } else {
    return (
      <li
        className={
          isActive || showLinkCollapse
            ? `${styles.containerIconCollapse_showLinkCollapse}`
            : `${styles.containerIconCollapse}`
        }
        onMouseEnter={() => setShowLinkCollapse(true)}
        onMouseLeave={() => setShowLinkCollapse(false)}
        onClick={() => {
          item.children.forEach((child: any) => {
            navigate(`${child.href}`);
          });
          onNavLinkClick(listId);
          handleCollapse(); // This will close the sidebar
        }}
      >
        {!IconeComponente ? (
          <Icon.HeartIcon width={20} height={20} />
        ) : (
          <IconeComponente width={20} height={20} />
        )}
        <div
          className={
            showLinkCollapse
              ? `${styles.containerLinkCollapse}`
              : `${styles.containerLinkCollapse_showLinkCollapse}`
          }
        >
          {item.children!.map((child: any, index: number) => (
            <ActiveLink key={index} href={child.href}>
              <span>{child.title}</span>
            </ActiveLink>
          ))}
        </div>
      </li>
    );
  }
}
