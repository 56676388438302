import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  patients: [],
  clinicPagination: {},
};


export const PatientListSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    setpatients: (state, action: PayloadAction<any[]>) => {
      state.patients = action.payload;
    },
    addPatients: (state, action: PayloadAction<any[]>) => {
      const newPatients = action.payload.filter(newPatient =>
        !state.patients.some(existingPatient => existingPatient.id === newPatient.id)
      );
      state.patients = [...state.patients, ...newPatients];
    },
    clearPatients: (state) => {
      state.patients = [];
      state.clinicPagination = {};
    },
    setClinicPage: (state, action: PayloadAction<{ idClient: string, currentPage: number, totalPages: number, toConcludeCefx?: any }>) => {
      const { idClient, currentPage, totalPages, toConcludeCefx } = action.payload;
      state.clinicPagination[idClient] = { currentPage, totalPages, ...(toConcludeCefx !== undefined && { toConcludeCefx }) };
    },

  },
});

export const { setpatients, clearPatients, addPatients, setClinicPage } = PatientListSlice.actions;

export const selectPatientsList = (state: any) => state.patients || {};

export default PatientListSlice.reducer;
