import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { Button } from "@cdt-ui/react";
import { ModalTableOfFactors } from "../../Modals/ModalTableOfFactors";
import { ModalReport } from "../../Modals/ModalReport";
import { saveAs } from "file-saver";
import { XMarkIcon } from "@heroicons/react/24/outline";

import styles from "./styles.module.css";

export function HeaderViewImageComponent({
  image3dParams,
  handleModoCefX,
  selecionarImagem,
  imageParams,
  name,
}: any) {
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width <= 880;

  const previouspage = () => {
    window.history.back()
  }
  const handleDownloadFile = (imageParams, image3dParams) => {
    setLoading(true);
    //  console.log(imageParams)
    if (imageParams.includes(".jpg")) {
      const fileName = `${name}.jpg`;

      fetch(imageParams)
        .then((response) => response.blob())
        .then((blob) => {
          saveAs(blob, fileName);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Erro ao baixar arquivo:", error);
          setLoading(false);
        });
    } else if (imageParams.includes(".zip")) {
      const fileName = `${name}.zip`;

      fetch(imageParams)
        .then((response) => response.blob())
        .then((blob) => {
          saveAs(blob, fileName);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Erro ao baixar arquivo:", error);
          setLoading(false);
        });
    } else if (image3dParams.includes(".ply")) {
      const fileName = `imagem.ply`;

      fetch(image3dParams)
        .then((response) => response.blob())
        .then((blob) => {
          saveAs(blob, fileName);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Erro ao baixar arquivo:", error);
          setLoading(false);
        });
    } else if (image3dParams.includes(".dfx")) {
      const fileName = `imagem.dfx`;

      fetch(image3dParams)
        .then((response) => response.blob())
        .then((blob) => {
          saveAs(blob, fileName);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Erro ao baixar arquivo:", error);
          setLoading(false);
        });
    } else {
      const fileName = `imagem.stl`;

      fetch(image3dParams)
        .then((response) => response.blob())
        .then((blob) => {

          saveAs(blob, fileName);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Erro ao baixar arquivo:", error);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    handleModoCefX(checked);
  }, [checked]);
  return (
    <header className={styles.wrapper}>
      <div className={styles.containerLogo}>
        <Link to="/dashboard/lista-pacientes">
          <img src="/logo_serodonto.svg" />
        </Link>
      </div>

      {isMobile ?
        <div>
          <div
            onClick={previouspage}
            className={styles.patientStatus}
          >

            <>
              <XMarkIcon />
            </>

          </div></div> : <div className={styles.container_onOffAndButton}>
          <div className={styles.containerButton_download}>
            <Button
              variant="greenDark"
              size="full"
              type="button"
              className={styles.buttonDownload}
              onClick={() => handleDownloadFile(imageParams, image3dParams)}
              disabled={loading}
            >
              <div>
                {
                  loading === false && <ArrowDownTrayIcon />
                }
              </div>
              {
                loading === false ? "Baixar" : "Baixando..."
              }
            </Button>
          </div>
        </div>}
    </header>
  );
}
