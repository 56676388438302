import { ErrorInfo, useEffect, useState, useRef } from "react";
import { Input } from "../../components/Form/Input";
import { Formik, Form, FormikValues } from "formik";
import { Button } from "@cdt-ui/react";
import * as yup from "yup";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";

import styles from "./styles.module.css";
import Api from "../../helpers/Api";
import { selectUser } from "../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { InputMask } from "../../components/Form/InputMask";
import { ModalErrorOrSucessMessage } from "../../components/ModalErrorOrSucessMessage";
import axios from "axios";
import { Loading } from "../../components/Loading";
import { FloppyDisk, X } from "@phosphor-icons/react";

import React from "react";

export function EditarPerfil() {
  const formRef = useRef<any>();

  const [isModalRecoverPasswordOpen, setModalRecoverPasswordOpen] =
    useState(false);
  const [isModalErroOrSucessOpen, setIsModalErroOrSucessOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<any>([]);

  const { user } = useSelector(selectUser);
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    nomePessoal: yup.string().required("Campo obrigatório"),
    dataNascimento: yup.string().required("Campo obrigatório"),
    logon: yup.string().required("Campo obrigatório"),
    senha: yup
      .string()
      .min(8, ({ min }) => `A senha deve ter ${min} caracteres`)
      .max(80, ({ max }) => `A senha deve ter ${max} caracteres`)
      .required("Campo obrigatório"),
    cep: yup.string().required("Campo obrigatório"),
    estado: yup.string().required("Campo obrigatório"),
    cidade: yup.string().required("Campo obrigatório"),
    endereco: yup.string().required("Campo obrigatório"),
    numeroEndereco: yup.string().required("Campo obrigatório"),
    complemento: yup.string().required("Campo obrigatório"),
    foneSms: yup.string().required("Campo obrigatório"),
    emailAtendimento: yup.string().required("Campo obrigatório"),
  });

  const handleOpenModalRecoverPassword = () => {
    setModalRecoverPasswordOpen(true);
  };

  const handleCloseModalErrorOrSucess = () => {
    setIsModalErroOrSucessOpen(false);
  };

  const getUserDetails = async () => {
    setLoading(true);
    const id = user[0]?.idUser;

    if (!id) {
      console.error('ID não é verdadeiro ou está indefinido.');
      return;
    }

    try {
      const result = await Api.GetuserById(id);

      if (result.isSuccess) {
        setUserDetails(result);
      } else {
        console.error('A requisição não foi bem-sucedida:', result.errorMessage);
      }
    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values: FormikValues) => {
    const { dataNascimento, ...value } = values
    const id = user[0]?.idUser;
    const valorDataDeNascimento = dataNascimento;
    const partesDataDeNascimento = valorDataDeNascimento.split('/');
    const formattedDateOfBirth = `${partesDataDeNascimento[2]}-${partesDataDeNascimento[1]}-${partesDataDeNascimento[0]}`;

    const payload = {
      pais: userDetails.data.pais,
      cpf: userDetails.data.cpf,
      tipoRegistroProfissional: userDetails.data.tipoRegistroProfissional,
      registroProfissional: userDetails.data.registroProfissional,
      dataNascimento: formattedDateOfBirth,
      ...value
    }

    await Api.EditUser({ id, payload })
      .then((result) => {
        setIsModalErroOrSucessOpen(true);
      })
      .catch((error: ErrorInfo) => {
        if (axios.isAxiosError(error)) {
          toast("Ocorreu um erro!", {
            position: "top-right",
            style: {
              background: "#c80000",
              color: "#fff",
            },
          });
        }
        console.log(error);
      });
  };

  function onBlurCep(ev, setFieldValue) {
    const { value } = ev.target;

    const cep = value?.replace(/[^0-9]/g, '');

    if (cep?.length !== 8) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        if (typeof setFieldValue === 'function') {
          setFieldValue('logradouro', data?.logradouro);
          setFieldValue('bairro', data?.bairro);
          setFieldValue('cidade', data?.localidade);
          setFieldValue('uf', data?.uf);
        }
      });
  }

  useEffect(() => {
    getUserDetails();
  }, []);

  let formatBirsthData = userDetails?.data?.dataNascimento?.split(`-`);
  let newBirsthData =
    formatBirsthData &&
    formatBirsthData[2]?.split("T")[0] +
    "/" +
    formatBirsthData[1] +
    "/" +
    formatBirsthData[0];


  useEffect(() => {
    formRef?.current?.setFieldValue('nomePessoal', userDetails?.data?.nomePessoal);
    formRef?.current?.setFieldValue('dataNascimento', newBirsthData);
    formRef?.current?.setFieldValue('logon', userDetails?.data?.logon,);
    formRef?.current?.setFieldValue('senha', user?.senha);
    formRef?.current?.setFieldValue('cep', userDetails?.data?.cep);
    formRef?.current?.setFieldValue('estado', userDetails?.data?.estado);
    formRef?.current?.setFieldValue('cidade', userDetails?.data?.cidade);
    formRef?.current?.setFieldValue('endereco', userDetails?.data?.endereco);
    formRef?.current?.setFieldValue('numeroEndereco', userDetails?.data?.numeroEndereco);
    formRef?.current?.setFieldValue('complemento', userDetails?.data?.complemento);
    formRef?.current?.setFieldValue('foneSms', userDetails?.data?.foneSms);
    formRef?.current?.setFieldValue('emailAtendimento', userDetails?.data?.emailAtendimento);
  }, [userDetails, newBirsthData]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container_form}>
        <div className={styles.userHeader}>
          <div className={styles.containerAvatar}>
            <Avatar src="" size="60" className={styles.userProfileImage} />
          </div>
          <div className={styles.userText}>
            {userDetails.isSuccess === true ? (
              <>
                <h3>{`${userDetails?.data?.nomePessoal}`}</h3>
                <p>
                  <span>E-mail: </span> {userDetails?.data?.emailAtendimento}{" "}
                  <br />
                  <span>Tel: </span>
                  {userDetails?.data?.foneSms}
                </p>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <Formik
          initialValues={{
            nomePessoal: userDetails.data?.nomePessoal || "",
            dataNascimento: newBirsthData || "",
            logon: userDetails.data?.logon || "",
            senha: "",
            cep: userDetails.data?.cep || "",
            estado: userDetails.data?.estado || "",
            cidade: userDetails.data?.cidade || "",
            endereco: userDetails.data?.endereco || "",
            numeroEndereco: userDetails.data?.numeroEndereco || "",
            complemento: userDetails.data?.complemento || "",
            foneSms: userDetails.data?.foneSms || "",
            emailAtendimento: userDetails.data?.emailAtendimento || "",
          }}
          innerRef={formRef}
          onSubmit={(values) => handleSubmit(values)}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          {({
            errors,
            isSubmitting,
            values,
            setFieldValue,
            handleChange,
          }) => (
            <Form className={styles.form}>
              <p
                style={{
                  color: "#7B889C",
                  fontWeight: "700",
                }}
              >
                DADOS PESSOAIS
              </p>

              <div className={styles.containerInput}>
                <Input
                  name="nomePessoal"
                  type="text"
                  title="Nome Profissional"
                  placeholder="Como será chamado pela clínica (Ex: Dr. Anna...)"
                  onChange={(e) =>
                    setFieldValue("nomePessoal", e.target.value)
                  }
                  error={
                    typeof errors.nomePessoal === "string"
                      ? errors.nomePessoal
                      : ""
                  }
                />

                <InputMask
                  type="text"
                  title="Data de Nascimento"
                  name="dataNascimento"
                  mask="00/00/0000"
                  value={values.dataNascimento}
                  onChange={handleChange}
                  placeholder="00/00/0000"
                  labelStyleAbsolute
                  error={
                    typeof errors.dataNascimento === "string"
                      ? errors.dataNascimento
                      : ""
                  }
                />
              </div>

              <div
                style={{
                  borderWidth: 0.5,
                  borderStyle: "inset",
                  opacity: 0.2,
                }}
              />

              <p
                style={{
                  fontSize: "14px",
                  color: "#7B889C",
                  fontWeight: "700",
                }}
              >
                DADOS DE CADASTRO
              </p>

              <div className={styles.containerUserSenha}>
                <Input
                  labelStyleAbsolute
                  name="logon"
                  type="text"
                  title="Usuário"
                  placeholder="@exemplodenome"
                /* error={errors.logon || userExists && 'Usuário já existente.'} */
                />
                <Input
                  name="senha"
                  type="password"
                  title="Senha"
                  placeholder="Digitar senha"
                  error={errors.senha}
                />
              </div>
              {/* <div
                      className={styles.container_recoverPassword}
                      onClick={handleOpenModalRecoverPassword}
                    >
                      <span>Alterar senha?</span>
                    </div> */}

              <div
                style={{
                  borderWidth: 0.5,
                  borderStyle: "inset",
                  opacity: 0.2,
                }}
              />

              <p
                style={{
                  color: "#7B889C",
                  fontWeight: "700",
                }}
              >
                ENDEREÇO
              </p>

              <div className={styles.containerInputs}>
                <InputMask
                  type="text"
                  title="CEP"
                  onBlur={onBlurCep}
                  name="cep"
                  value={values.cep}
                  mask="00000-000"
                  onChange={handleChange}
                  placeholder="00000-000"
                  labelStyleAbsolute
                  error={typeof errors.cep === "string" ? errors.cep : ""}
                />

                <div className={styles.wrapperInput}>
                  <Input
                    name="estado"
                    type="text"
                    title="Estado (UF)"
                    placeholder="Selecionar"
                    error={
                      typeof errors.estado === "string" ? errors.estado : ""
                    }
                  />

                  <Input
                    name="cidade"
                    type="text"
                    onBlur={onBlurCep}
                    title="Cidade"
                    placeholder="Selecionar"
                    error={
                      typeof errors.cidade === "string" ? errors.cidade : ""
                    }
                  />
                </div>
              </div>

              <Input
                name="endereco"
                type="text"
                onBlur={onBlurCep}
                title="Endereço"
                placeholder="Ex: Rua José de Freitas"
                error={
                  typeof errors.endereco === "string" ? errors.endereco : ""
                }
              />

              <div
                className={styles.containerInputs}
                style={{ marginTop: "26px", marginBottom: "0" }}
              >
                <Input
                  name="numeroEndereco"
                  type="number"
                  onBlur={onBlurCep}
                  title="Número"
                  placeholder="00"
                  error={
                    typeof errors.numeroEndereco === "string"
                      ? errors.numeroEndereco
                      : ""
                  }
                />

                <Input
                  name="complemento"
                  type="text"
                  title="Complemento"
                  placeholder="Ex: Na esquina do mercado"
                  error={
                    typeof errors.complemento === "string"
                      ? errors.complemento
                      : ""
                  }
                />
              </div>

              <div
                style={{
                  borderWidth: 0.5,
                  borderStyle: "inset",
                  opacity: 0.2,
                }}
              />

              <p
                style={{
                  fontSize: 14,
                  color: "#7B889C",
                  fontWeight: "700",
                }}
              >
                CONTATO
              </p>

              <div className={styles.containerInputs}>
                <InputMask
                  type="text"
                  title="Celular"
                  onBlur={onBlurCep}
                  name="foneSms"
                  value={values.foneSms}
                  onChange={handleChange}
                  mask="(00) 00000-0000"
                  placeholder="(00) 00000-0000"
                  labelStyleAbsolute
                  error={
                    typeof errors.foneSms === "string" ? errors.foneSms : ""
                  }
                />

                <Input
                  name="emailAtendimento"
                  type="email"
                  title="E-mail"
                  placeholder="exemplo@exemplo.com"
                  value={values.emailAtendimento}
                  error={
                    typeof errors.emailAtendimento === "string"
                      ? errors.emailAtendimento
                      : ""
                  }
                />
              </div>

              <div className={styles.container_buttons}>
                <div className={styles.buttonCancelar}>
                  <Button
                    type="button"
                    size="full"
                    variant="tertiary"
                    onClick={() =>
                      user !== false
                        ? navigate("/dashboard")
                        : navigate("/")
                    }
                  >
                    <X />
                    <span>Cancelar</span>
                  </Button>
                </div>

                <div className={styles.buttonContinuar}>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    size="full"
                    variant="greenDark"
                  >
                    {isSubmitting ? (
                      <div className={styles.container_buttons_loading}>
                        <div className={styles.loading_spinne}></div>
                      </div>
                    ) : (
                      <>
                        <FloppyDisk />
                        <span>Salvar</span>
                      </>
                    )}
                  </Button>
                </div>
              </div>

            </Form>
          )}
        </Formik>
      </div>

      {isModalErroOrSucessOpen && (
        <ModalErrorOrSucessMessage
          type="success"
          isOpen={isModalErroOrSucessOpen}
          handleClose={handleCloseModalErrorOrSucess}
          onRequestClose={handleCloseModalErrorOrSucess}
          handleToNavigate={handleCloseModalErrorOrSucess}
        />
      )}
    </div>
  );
}
