import { useState } from "react";
import Modal from "react-modal";
import { Formik, Form, FormikValues } from "formik";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import * as yup from "yup";

import { Button } from "@cdt-ui/react";

import styles from "./styles.module.css";
import { XMarkIcon } from "@heroicons/react/24/outline";

import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/solid";
import { Input } from "../../../../../components/Form/Input";
import { TextArea } from "../../../../../components/Form/TextArea";

interface ModalRegisterBriefingProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const options = [
  { value: "Interno", label: "Interno" },
  { value: "Lançamento", label: "Lançamento" },
  { value: "Relâmpago", label: "Relâmpago" },
];

export function ModalRegisterBriefing({
  isOpen,
  onRequestClose,
}: ModalRegisterBriefingProps) {
  const [clicked, setClicked] = useState(false);
  const animatedComponents = makeAnimated();

  const [collapse, setCollapse] = useState(false);
  function handleCollapse() {
    setCollapse(!collapse);
  }
  const handleSubmit = async (values: FormikValues) => {
    console.log(values);
  };

  const validationSchema = yup.object().shape({
    nameBriefing: yup.string().required("Campo obrigatório"),
    releases: yup
      .array()
      .min(1, "Campo obrigatório")
      .required("Campo obrigatório"),
    description: yup.string().required("Campo obrigatório"),
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react_modal_overlay"
      className="react-modal-registerBriefing"
    >
      <h2 className={styles.title}>Criar curso</h2>
      <p className={styles.subTitle}> Etapas para criação de um curso</p>
      <XMarkIcon
        onClick={onRequestClose}
        className={styles.icon_close}
        title="Fechar"
      />

      <Formik
        initialValues={{
          nameBriefing: "",
          releases: [] || [0],
          description: "",
        }}
        onSubmit={(values) => handleSubmit(values)}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ errors, isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className={styles.progress}>
              <p>detales</p>
              <p>Vincular a curso</p>
              <p>Conteúdos relacionados</p>
              <p>Revisão</p>
            </div>
            <div className={styles.container_inputs}>
              <Input
                name="nameBriefing"
                type="text"
                title="Nome da Aula"
                labelStyleAbsolute
                error={errors.nameBriefing}
              />

              <Input
                name="nameBriefing"
                type="text"
                title="Nome do Professor"
                labelStyleAbsolute
                error={errors.nameBriefing}
              />
              {/*
              <div className={styles.container_select}>
                <label className={styles.label_select}>
                  Tipos de Lançamentos
                </label>
                <Select
                  name="releases"
                  onFocus={() => setClicked(true)}
                  onBlur={() => setClicked(false)}
                  className={styles.select}
                  placeholder=""
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#1565c0",
                    },
                  })}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minHeight: "50px",
                      maxHeight: "auto",
                      backgroundColor: "#fff",
                      border: "1px solid #afafaf",
                      borderRadius: "12px",
                      paddingLeft: "7px",
                      outline: "none",
                      // border: (state.isFocused || state.menuIsOpen || clicked) ? '1px solid red' : '1px solid #afafaf',
                    }),
                  }}
                  id="releases"
                  onChange={(event) =>
                    setFieldValue(
                      "releases",
                      event.map((value) => value.value)
                    )
                  }
                  defaultValue={options[0]}
                  options={options}
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  isMulti
                />
                {errors && errors.releases && (
                  <span className={styles.text_error}>{errors.releases}</span>
                )}
              </div>*/}

              <TextArea
                title="Descrição da Aula"
                name="description"
                error={errors.description}
                labelStyleAbsolute
              />

              <Input
                name="nameBriefing"
                type="text"
                title="Link da aula"
                labelStyleAbsolute
                error={errors.nameBriefing}
              />
            </div>

            <div className={styles.container_buttons}>
              <Button
                variant="tertiary"
                size="full"
                type="button"
                onClick={onRequestClose}
                className={styles.buttonMore}
              >
                Cancelar
              </Button>

              <Button
                variant="greenDark"
                size="full"
                className={styles.buttons}
              >
                Salvar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {/* 
      <div
        className={collapse ? `${styles.aside_collapse}` : `${styles.aside}`}
      >
        {collapse ? (
          <>
            <div className={styles.containerHeaderCollapse}>
              <div className={styles.Containertext}>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Nunc tortor felis
                  ultricies malesuada eget enim turpis lorem. Tempus massa diam
                  condimentum elementum proin. Feugiat suspendisse aenean
                  lacinia suspendisse pharetra molestie eget. Tempor auctor
                  nulla imperdiet arcu rutrum ut semper. Vitae vestibulum ac id
                  augue faucibus justo.
                </p>
                <div onClick={handleCollapse}>
                  <p className={styles.ContainerAnimation}>
                    ------------------------------------ Esconder transcrição
                    <ChevronUpIcon width={15} />
                    ----------------------------------
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.containerHeaderCollapse}>
              <div className={styles.buttonHamburguer} onClick={handleCollapse}>
                <p className={styles.ContainerAnimation}>
                  ------------------------------------ Exibir transcrição
                  <ChevronDownIcon width={15} />
                  ----------------------------------
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      */}
    </Modal>
  );
}
