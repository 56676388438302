import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  indicator: [],
};

export const IndicatorListSlice = createSlice({
  name: "indicator",
  initialState,
  reducers: {
    setindicator: (state, action: PayloadAction<any>) => {
      state.indicator = action.payload;
    },
  },
});

export const { setindicator } = IndicatorListSlice.actions;

export const selectIndicatorList = (state: any) => state.indicator;

export default IndicatorListSlice.reducer;
