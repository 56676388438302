import { useEffect, useState } from "react";
import {
    ChevronDoubleRightIcon,
    ChevronDoubleLeftIcon,
    ArrowsUpDownIcon
} from "@heroicons/react/24/outline";
import { CSSTransition } from 'react-transition-group';

import { NewHeaderSidebar } from "../NewSideberCefx/NewHeaderSidebar";
import { NavigationSidebarView } from "./NavigationSidebarView";
import { FooterViewImage } from "../FooterViewImage";

import styles from './styles.module.css';

interface NewSidebarViewProps {
    handleSelectedImage: (image: any) => void;
    pacienteAllImagensArray: any[];
    pacientAllImagens3d: any[];
    infoPaciente: any;
    image3d?: boolean;
    getImagenRotation: (rotate: number) => void;
    imageParams?: string;
    handleChanceTheSelectedImage: (urlImage: string) => void;
    theSelectImage?: string;
    oViewer?: any;
    viwerActives: any[];
    onViwerActivesChange: (newViwerActives: any) => void;
    selectedImageChange: any;
    imagesSelectedChange: any;
    ocefx: any;
    handleGetCollapse: (collapse: boolean) => void;
}

export function NewSidebarView({
    imageParams,
    ocefx,
    oViewer,
    infoPaciente,
    pacienteAllImagensArray,
    pacientAllImagens3d,
    theSelectImage,
    selectedImageChange,
    onViwerActivesChange,
    handleChanceTheSelectedImage,
    getImagenRotation,
    handleGetCollapse
}: NewSidebarViewProps) {
    const [imageRotation, setImageRotation] = useState(0);
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [disableScroll, setDisableScroll] = useState(false);
    const isMobile = width <= 880;

    const [collapse, setCollapse] = useState(isMobile ? true : false);

    const handleCollapse = () => {
        setCollapse(!collapse);
        handleGetCollapse(!collapse)
    };

    const handleRotateHorizontal = () => {
        setImageRotation((prevRotation) => (prevRotation === 90 ? -90 : 90));
    };

    const handleRotateVertical = () => {
        setImageRotation((prevRotation) => (prevRotation === 0 ? 180 : 0));
    };

    const closeViwer = () => {
        window.history.back();
    };

    useEffect(() => {
        getImagenRotation(imageRotation);
    }, [imageRotation]);

    return (
        <>
            <CSSTransition
                in={collapse}
                timeout={300}
                classNames="containerIcons"
                unmountOnExit
            >
                <div
                    style={{
                        position: "absolute",
                        right: "40px", top: "10px"
                    }}
                >
                    <div className={`${styles.containerIcons} ${styles.asideClose}`}>
                        <div
                            className={styles.ButtonXMark}
                            onClick={closeViwer}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 25 25"
                                stroke-width="1.5"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </div>

                        <div
                            className={styles.buttonChevronRight}
                            onClick={() => handleCollapse()}
                        >
                            <ChevronDoubleLeftIcon />
                        </div>

                        {
                            !isMobile &&
                            <>
                                <div
                                    title="Horizontal"
                                    className={styles.buttonArrowPath}
                                    onClick={handleRotateHorizontal}
                                >
                                    <ArrowsUpDownIcon
                                        style={{ transform: 'rotate(90deg)' }}
                                    />
                                </div>

                                <div
                                    title="Vertical"
                                    className={styles.buttonArrowPath}
                                    onClick={handleRotateVertical}
                                >
                                    <ArrowsUpDownIcon />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </CSSTransition>

            <CSSTransition
                in={!collapse}
                timeout={300}
                classNames="asideView"
                unmountOnExit
            >

                <aside className={styles.asideView}>
                    {
                        !imageParams.includes('dfx') && (
                            <div className={styles.containerIcons}>
                                <button
                                    type="button"
                                    className={styles.buttonChevronRight}
                                    onClick={() => handleCollapse()}
                                    title="Tela cheia"
                                >
                                    <ChevronDoubleRightIcon />
                                </button>

                            </div>
                        )
                    }

                    <NewHeaderSidebar
                        infoPaciente={infoPaciente}
                        loadingPacientSearchCefx={false}
                    />

                    <NavigationSidebarView
                        imageParams={imageParams}
                        ocefx={ocefx}
                        oViewer={oViewer}
                        pacienteAllImagensArray={pacienteAllImagensArray}
                        pacientAllImagens3d={pacientAllImagens3d}
                        theSelectImage={theSelectImage}
                        handleChanceTheSelectedImage={handleChanceTheSelectedImage}
                        selectedImageChange={selectedImageChange}
                        getImagenRotation={getImagenRotation}
                        onViwerActivesChange={onViwerActivesChange}
                        isMobile={isMobile}
                        handleCollapse={handleCollapse}
                    />

                    <FooterViewImage modoCefx />
                </aside>
            </CSSTransition>
        </>
    )
}