import { useState } from 'react';
import { useSelectedReport } from '../../../SelectedReportContext';

import styles from './styles.module.css';

interface CardRelatorioProps {
    nome: string;
    codigo: any;
    getRelatoriosSelect?: any;
    img?: any;
}

export function CardRelatorio({ nome, codigo, getRelatoriosSelect, img }: CardRelatorioProps) {

    const { selectedCheckboxes } = useSelectedReport();

    const handleCheckboxChange = () => {
        getRelatoriosSelect(codigo);
    };

    return (
        <div className={`${styles.wrapperImage}`}>
            <div className={`${styles.containerImage}`}>
                <img src={img} alt={nome}/>

                <div className={styles.containerImage_active} onClick={handleCheckboxChange}>
                    <input
                        className={styles.inputCheckbox}
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        checked={selectedCheckboxes.some(item => item == codigo)}
                    />
                </div>
            </div>
            <div className={styles.containerContentImage}>
                <strong>{nome}</strong>
            </div>
        </div>
    );
}