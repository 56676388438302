import { IMaskInput } from "react-imask";

import styles from "./styles.module.css";

interface InputMaskProps {
  name: string;
  type: string;
  value?: string;
  label?: string;
  onChange?: any;
  placeholder: string;
  mask?: string;
  onBlur?: any;
  setFieldValue?: any;
  textCenter?: boolean;
  title?: string;
  labelStyleAbsolute?: boolean;
  error?: any;
  disabled?: boolean;
}

export function InputMask({
  disabled,
  error,
  title,
  labelStyleAbsolute,
  textCenter,
  name,
  type,
  value,
  label,
  onChange,
  placeholder,
  mask,
  onBlur,
  setFieldValue,
}: InputMaskProps) {
  return (
    <div className={styles.textInputContainer}>
      <IMaskInput
        onBlur={(ev) => !!onBlur && onBlur(ev, setFieldValue)}
        className={`${styles.input} ${error && styles.inputError}`}
        name={name}
        value={value}
        type={type}
        onChange={onChange}
        mask={mask}
        placeholder={placeholder}
        style={{
          opacity: disabled && ".5",
        }}
      />
      <label
        className={
          labelStyleAbsolute ? `${styles.labelAbsolute}` : `${styles.label}`
        }
        style={{
          opacity: disabled && ".8",
          color: error && "#c80000",
        }}
      >
        {title ? title : "description"}
      </label>

      <div className={styles.container_error}>
        <span className={styles.textError}>{error}</span>
      </div>
    </div>
  );
}
