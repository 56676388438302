export function ClockIcon({ containerClassName }) {
  return (
    <div className={containerClassName}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M8 5V8"
          stroke="#465364"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.6 9.5L8 8"
          stroke="#465364"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.5127 6.23145H14.0127V3.73145"
          stroke="#465364"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.8877 11.8875C11.1187 12.6571 10.1387 13.1814 9.07172 13.394C8.0047 13.6066 6.8986 13.4979 5.89335 13.0818C4.88809 12.6656 4.02885 11.9607 3.4243 11.0561C2.81975 10.1515 2.49707 9.08799 2.49707 8C2.49707 6.91201 2.81975 5.84847 3.4243 4.9439C4.02885 4.03933 4.88809 3.33439 5.89335 2.91824C6.8986 2.50209 8.0047 2.39343 9.07172 2.60601C10.1387 2.81859 11.1187 3.34286 11.8877 4.1125L14.0127 6.23125"
          stroke="#465364"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}
