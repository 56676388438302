import { useEffect, useState } from "react";
import Modal from "react-modal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

import styles from "./styles.module.css";
import { useOpcoes } from "../../NewSideberCefx/OpcoesContext";

interface ModalTableOfFactorsProps {
  isOpen: boolean;
  onRequestClose: () => void;
  ocefx: any;
}

export function ModalTableOfFactors({
  isOpen,
  onRequestClose,
  ocefx,
}: ModalTableOfFactorsProps) {

  const { analiseCheckboxSelected } = useOpcoes();

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  });
  const [showOption, setShowOption] = useState(false);
  const [selectedAnalyze, setSelectedAnalyze] = useState('');
  const [selectedCodigo, setSeletedCodigo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [getAnalyze, setGetAnalyze] = useState([]);
  const [disableScroll, setDisableScroll] = useState(false);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setDragStart({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      const deltaX = event.clientX - dragStart.x;
      const deltaY = event.clientY - dragStart.y;
      setModalPosition({
        x: modalPosition.x + deltaX,
        y: modalPosition.y + deltaY,
      });
      setDragStart({ x: event.clientX, y: event.clientY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const customStyles = {
    content: {
      top: `${modalPosition.y}px`,
      left: `${modalPosition.x}px`,
    },
  };

  const handleShowOptionSelect = () => {
    setShowOption(!showOption);
  };

  const handleGetfatores = (analyze: string, code: number) => {
    setSelectedAnalyze(analyze)
    setSeletedCodigo(code)
  }

  useEffect(() => {
    let codigo = null;
    if (selectedCodigo !== null) {
      codigo = selectedCodigo;
    } else if (analiseCheckboxSelected.length > 0) {
      codigo = analiseCheckboxSelected[0].codigo;
    }

    if (codigo !== null) {
      ocefx.fatores(codigo, setLoading, setGetAnalyze);
    }
  }, [selectedCodigo, analiseCheckboxSelected]);

  useEffect(() => {
    if (isOpen) {
      setDisableScroll(true);
    } else {
      setDisableScroll(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const htmlBody = document.querySelector('html, body') as HTMLElement | null;

    if (htmlBody) {
      if (disableScroll) {
        htmlBody.style.overflow = 'hidden';
      } else {
        htmlBody.style.overflow = 'visible';
      }
    }

    return () => {
      if (htmlBody) {
        htmlBody.style.overflow = 'visible';
      }
    };
  }, [disableScroll]);

  return (
    <div
      className="modal-draggable-container"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      style={{ position: "absolute" }}
    >
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react_modal_overlay_2"
        className="react-modal-AddAnalyze"
        style={customStyles}
      >
        <header className={styles.header}>
          <div className={styles.containerIconDragAndDrop}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.6002 7.20001C18.4839 7.20001 19.2002 7.91635 19.2002 8.8C19.2002 9.68365 18.4839 10.4 17.6002 10.4C16.7166 10.4 16.0002 9.68365 16.0002 8.8C16.0002 7.91635 16.7166 7.20001 17.6002 7.20001ZM12.0001 7.20001C12.8838 7.20001 13.6001 7.91635 13.6001 8.8C13.6001 9.68365 12.8838 10.4 12.0001 10.4C11.1165 10.4 10.4001 9.68365 10.4001 8.8C10.4001 7.91635 11.1165 7.20001 12.0001 7.20001ZM8 8.8C8 7.91635 7.28366 7.20001 6.40001 7.20001C5.51637 7.20001 4.80003 7.91635 4.80003 8.8C4.80003 9.68365 5.51637 10.4 6.40001 10.4C7.28366 10.4 8 9.68365 8 8.8ZM7.99941 15.2002C7.99941 14.3165 7.28308 13.6002 6.39943 13.6002C5.51578 13.6002 4.79944 14.3165 4.79944 15.2002C4.79944 16.0838 5.51578 16.8002 6.39943 16.8002C7.28308 16.8002 7.99941 16.0838 7.99941 15.2002ZM13.5995 15.2002C13.5995 14.3165 12.8832 13.6002 11.9995 13.6002C11.1159 13.6002 10.3995 14.3165 10.3995 15.2002C10.3995 16.0838 11.1159 16.8002 11.9995 16.8002C12.8832 16.8002 13.5995 16.0838 13.5995 15.2002ZM19.1997 15.2002C19.1997 14.3165 18.4833 13.6002 17.5997 13.6002C16.716 13.6002 15.9997 14.3165 15.9997 15.2002C15.9997 16.0838 16.716 16.8002 17.5997 16.8002C18.4833 16.8002 19.1997 16.0838 19.1997 15.2002Z"
                fill="#AFAFAF"
              />
            </svg>
          </div>

          <div
            className={styles.containerSelect}
            onClick={handleShowOptionSelect}
          >

            <div className={styles.wrapperHeader}>
              <div className={styles.containerAnaliseIcon}>
                <img src="/iconCefx/analiseIcon.svg" alt="Ícone de Análise" />
              </div>

              <div>
                <p>LISTA DE FATORES - ANÁLISE </p>
                <h2>
                  {
                    !selectedAnalyze ? analiseCheckboxSelected[0]?.nome : selectedAnalyze
                  }
                  {
                    analiseCheckboxSelected.length > 0 && (
                      showOption ? (
                        <ChevronUpIcon width={16} />
                      ) : (
                        <ChevronDownIcon width={16} />
                      )
                    )
                  }
                </h2>
              </div>
            </div>

            {
              <AnimatePresence>
                {showOption && (
                  <motion.div
                    className={styles.showInputs}
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 1 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.3 }}
                  >
                    <div className={styles.showSelect}>
                      {
                        analiseCheckboxSelected.map((analyze: { nome: string, codigo: number }, index: number) => (
                          <p
                            key={index}
                            onClick={() => handleGetfatores(analyze.nome, analyze.codigo)}
                            className={selectedAnalyze ? (analyze.nome === selectedAnalyze && styles.analyzeSelected) : (analiseCheckboxSelected.length > 0 && analiseCheckboxSelected[0].nome === analyze.nome) ? styles.analyzeSelected : ''}
                          >

                            {analyze.nome}
                          </p>
                        ))
                      }
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            }
          </div>

          <XMarkIcon title="Fechar" width={24} onClick={onRequestClose} />
        </header>

        <div className={styles.tableContainer}>
          <table className={styles.tableModalOfFactors}>
            <thead>
              <tr>
                <th>Item</th>
                <th>Obtido</th>
                <th>Padrão</th>
                <th>Desvio</th>
                <th>Observação</th>
              </tr>
            </thead>

            {
              loading ?
                <div className={styles.loading_container}>
                  <div className={styles.loading_spinne}></div>
                  <p>Carregando...</p>
                </div>
                : getAnalyze.length == 0 ?
                  <p style={
                    {
                      textAlign: 'center',
                      marginTop: '20px',
                      fontWeight: '600',
                      fontSize: '14px'
                    }}>
                    Sem fatores no momento.
                  </p>
                  :
                  getAnalyze.map((option: any, index: number) => {
                    const corRGB = `rgb(${(option.cor >> 16) & 255}, ${(option.cor >> 8) & 255}, ${option.cor & 255})`;
                    let corRGBNormalidade = `rgb(${(option.corNormalidade >> 16) & 255}, ${(option.corNormalidade >> 8) & 255}, ${option.corNormalidade & 255})`;
                    if (corRGBNormalidade === 'rgb(0, 0, 0)') {
                      corRGBNormalidade = 'rgb(255, 255, 255)';
                    }
                    return (
                      <tbody key={index}>
                        <tr>
                          <td>{option.regiao}</td>
                          <td style={{ color: `${corRGBNormalidade}` }}>{option.obtido}</td>
                          <td>{option.padrao}</td>
                          <td>{option.desvio}</td>
                          <td>{option.obs}</td>
                        </tr>
                      </tbody>
                    )
                  })
            }
          </table>
        </div>

      </Modal>
    </div>
  );
}
