import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState = {
    researchPatient: <any>false,
}

export const ResearchpatientSlice = createSlice({
    name: 'researchPatient',
    initialState,
    reducers: {
        setResearchPatient: (state, action: PayloadAction<any>) => {
            state.researchPatient = action.payload;
        },
    }
});

export const { setResearchPatient } = ResearchpatientSlice.actions;

export const selectResearchPatient = (state: any) => state.researchPatient;

export default ResearchpatientSlice.reducer;

