import { Text } from "@cdt-ui/react";
import styles from "./styles.module.css";

import { HomeIcon, XMarkIcon } from "@heroicons/react/24/outline";

export function Badge({ name = "Assunto 1", type }) {
  return (
    <div
      className={`${styles.badgeContainer} ${
        type === 1 ? styles.badge1 : styles.badge2
      }`}
    >
      <HomeIcon color={type === 1 ? "#151515" : "#854D0B"} width={12} />
      <Text
        className={`${styles.subject} ${
          type === 1 ? styles.title1 : styles.title2
        }`}
      >
        {name}
      </Text>
      {/*  <XMarkIcon
        width={12}
        color={`${type === 1 ? "#666666" : "#854D0B"}`}
        className={styles.XMark}
      /> */}
    </div>
  );
}
