import React from 'react';
import Sheet from 'react-modal-sheet';
import styles from './styles.module.css';
import { ChevronDownIcon, GlobeAmericasIcon, PencilSquareIcon, EyeIcon, ArrowDownTrayIcon, ShareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

interface ModalSheetChoiceProps {
    isOpen: boolean;
    onRequestClose: () => void;
    handleOpenModalSheetShareProfile: () => void;
    image: any;
}

export const ModalSheetChoice = ({
    isOpen,
    onRequestClose,
    handleOpenModalSheetShareProfile,
    image }: ModalSheetChoiceProps) => {

    const navigate = useNavigate();
    const idClient = localStorage.getItem("idClient");

    const ViewImage = () => {
        if (image.length > 1) {
            navigate(`/dashboard/visualizar-imagens/?id=${encodeURIComponent(
                idClient
            )}${image.map(link => `&image=${encodeURIComponent(link)}`)}`);
        } else {
            navigate(`/dashboard/visualizar-imagens/?id=${encodeURIComponent(
                idClient
            )}&image=${encodeURIComponent(image)}`);
        }
    }

    return (
        <Sheet isOpen={isOpen} onClose={onRequestClose} detent="content-height">
            <Sheet.Container style={{ borderRadius: '30px 30px 0px 0px', overflowY: 'scroll' }}>
                <Sheet.Header />
                <Sheet.Content>
                    <div className={styles.container} >
                        <div className={styles.textButtons}>
                            <div className={styles.textButton} onClick={() => ViewImage()}>
                                <EyeIcon color='#8E8E8E' width={28} height={28} />
                                <p>Visualizar</p>
                            </div>
                            <div className={styles.textButton}>
                                <ArrowDownTrayIcon color='#8E8E8E' width={28} height={28} />
                                <p>Baixar</p>
                            </div>
                            <div className={styles.textButton} onClick={() => {
                                onRequestClose();
                                handleOpenModalSheetShareProfile();
                            }}>
                                <ShareIcon color='#8E8E8E' width={28} height={28} />
                                <p>Compartilhar</p>
                            </div>
                            <div className={styles.textButton}>
                                <TrashIcon color='#8E8E8E' width={28} height={28} />
                                <p>Excluir</p>
                            </div>
                        </div>
                    </div>
                </Sheet.Content>
                <footer></footer>
            </Sheet.Container>
            <Sheet.Backdrop />
        </Sheet>
    )
}
