import React, { useState } from "react";
import Sheet from "react-modal-sheet";
import styles from "./styles.module.css";
import { Avatar, Button, Heading } from "@cdt-ui/react";
import {
  ChevronDownIcon,
  GlobeAmericasIcon,
  PencilSquareIcon,
  PaperAirplaneIcon,
  LinkIcon,
  XMarkIcon,
  ShareIcon,
  PhotoIcon,
  PlusSmallIcon,
} from "@heroicons/react/24/outline";

import { Formik, Form, FormikValues } from "formik";
import { FilePdf } from "@phosphor-icons/react";
import { color } from "framer-motion";
import Select from "react-select";
import * as yup from "yup";
import { Input } from "../Form/Input";
import { TextArea } from "../Form/TextArea";

import Api from "../../helpers/Api";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/userSlice";
import toast from "react-hot-toast";

interface ModalSheetShareProps {
  isOpen: boolean;
  onRequestClose: () => void;
  selectedImages?: string[];
  PatientNameAndId?: any;
}

export const ModalShareProfileMobile = ({
  isOpen,
  onRequestClose,
  selectedImages,
  PatientNameAndId,
}: ModalSheetShareProps) => {
  const [allImagens, setAllImagens] = useState(selectedImages);
  const [errorMensagem, setErrorMensagem] = useState(false);
  const [uniqueLink, setUniqueLink] = useState("");
  // const endsWithPDF = allImagens?.every(url => url.endsWith('.pdf'));
  const endsWithPDF = allImagens?.some((url) => String(url).endsWith(".pdf"));

  // console.log(selectedImages);
  const { user } = useSelector(selectUser);
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("E-mail inválido, verifique e tente novamente")
      .max(45, ({ max }) => `máximo ${max} caracteres`)
      .required("Campo obrigatório"),
    dentistName: yup.string().required("Campo obrigatório"),
    subject: yup.string().required("Campo obrigatório"),

  });
  const [objShare, SetObjShared] = useState([]);

  const getShared = async () => {
    try {
      const shared = await Api.GetShared(PatientNameAndId.Id);
      if (shared.isSuccess) {
        SetObjShared(shared.data);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const handleSubmit = async ({
    email,
    dentistName,
    message,
    subject,

  }: FormikValues) => {
    try {
      console.log({
        id: 0,
        idPatient: PatientNameAndId.Id,
        idClient: PatientNameAndId.idClient,
        safeSequentialCare: PatientNameAndId.ss,
        email: email,
        name: dentistName,
        subject: subject,
        body: message,
        active: true,
      }, "data")

      const { data } = await Api.createShared({
        id: 0,
        idPatient: PatientNameAndId.Id,
        idClient: PatientNameAndId.idClient,
        safeSequentialCare: PatientNameAndId.ss,
        email: email,
        name: dentistName,
        subject: subject,
        body: message,
        active: true,
      });
      console.log(data, "data")
      setUniqueLink(data);

      toast("Compartilhamento Cadastrado", {
        style: {
          background: "#008a00",
          color: "#fff",
        },
      });
      getShared();
    } catch (error) {
      toast("Error ao salvar", {
        style: {
          background: "#e71010",
          color: "#fff",
        },
      });
    }
  };

  const handleRemoveImage = (indexToRemove?: number) => {
    if (allImagens?.length <= 1) {
      setErrorMensagem(true);
      return;
    }

    const newImages = allImagens.filter((_, index) => index !== indexToRemove);
    setAllImagens(newImages);
  };
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text.uniqueLink)
      .then(() => {
        toast("Link copiado para a área de transferência", {
          style: {
            background: "#008a00",
            color: "#fff",
          },
        });
      })
      .catch((error) => {
        console.error("Error copying link to clipboard:", error);
        toast("Erro ao copiar o link para a área de transferência", {
          style: {
            background: "#e71010",
            color: "#fff",
          },
        });
      });
  };

  return (
    <Sheet isOpen={isOpen} onClose={onRequestClose} detent="content-height">
      <Sheet.Container
        style={{ borderRadius: "30px 30px 0px 0px", overflowY: "scroll" }}
      >
        <Sheet.Header />
        <Sheet.Content>
          <div className={styles.container}>
            <div className={styles.textButtons}>
              <div className={styles.textButtonHeader}>
                <h2>Compartilhar perfil público</h2>
                <small>
                  O destinatário poderá visualizar os dados e as imagens, sem
                  login.
                </small>
              </div>
              <div className={styles.textButton}>
                <h3>Paciente</h3>
                <div className={styles.profile}>
                  <div>
                    <strong>
                      {PatientNameAndId && PatientNameAndId.Paciente
                        ? PatientNameAndId.Paciente
                        : `Nome paciente`}
                    </strong>
                    <span>
                      {PatientNameAndId && PatientNameAndId.Paciente
                        ? `#${PatientNameAndId.Id}`
                        : `Id paciente`}
                    </span>
                  </div>
                </div>
              </div>

              <Formik
                initialValues={{
                  email: "",
                  dentistName: "",
                  message: "",
                  subject: "",

                  viewType: "",
                }}
                onSubmit={(values) => handleSubmit(values)}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={validationSchema}
              >
                {({ errors, isSubmitting, values, setFieldValue }: any) => (
                  <Form>
                    <div className={styles.input_group}>
                      <div className={styles.input_wrapper}>
                        <Input
                          type="email"
                          name="email"
                          title="E-mail"
                          placeholder="E-mail para:"
                          error={errors.email}
                        />
                      </div>

                      <div className={styles.input_wrapper}>
                        <Input
                          type="text"
                          name="dentistName"
                          title="Dentista"
                          placeholder="Nome do dentista"
                          error={errors.dentistName}
                        />
                      </div>

                      <div className={styles.input_wrapper}>

                        <Input
                          type="text"
                          name="subject"
                          title="Assunto"
                          placeholder="Assunto do E-mail"
                          error={errors.subject}
                        />
                      </div>

                      <div className={styles.input_wrapper}>
                        <TextArea
                          title="Mensagem"
                          name="message"
                          placeholder="mensagem"
                        />
                      </div>
                    </div>

                    <div className={styles.container_buttons}>
                      <Button type="submit" variant="greenDark" size="full">
                        <PaperAirplaneIcon />
                        Enviar
                      </Button>

                      <Button
                        type="submit"
                        variant="tertiary"
                        size="full"
                        onClick={() => copyToClipboard(uniqueLink)}
                        disabled={uniqueLink === ""}
                        className={styles.buttonMore}
                        style={{
                          color: '#fff',
                          background: 'transparent'
                        }}
                      >
                        <LinkIcon />
                        Copiar Link
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Sheet.Content>
        <footer></footer>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
