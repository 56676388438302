import React from "react";
import Sheet from "react-modal-sheet";
import styles from "./styles.module.css";
import {
  ChevronDownIcon,
  GlobeAmericasIcon,
  PencilSquareIcon,
  EyeIcon,
  ArrowDownTrayIcon,
  ShareIcon,
  PrinterIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { link } from "fs";

interface ModalSheetChoiceProps {
  isOpen: boolean;
  onRequestClose: () => void;
  handleOpenModalSheetShareProfile: () => void;
  selectedImages: string[];
}

export const ModalSheetChoice = ({
  isOpen,
  onRequestClose,
  handleOpenModalSheetShareProfile,
  selectedImages,
}: ModalSheetChoiceProps) => {
  const openPDF = async () => {
    for (const link of selectedImages) {
      const googleDocsUrl = `${link}`;
      await window.open(googleDocsUrl, "_blank");
    }
  };

  const downloadPDF = async () => {
    for (const link of selectedImages) {
      if (link) {
        await window.open(link);
      }
    }
  };

  return (
    <Sheet isOpen={isOpen} onClose={onRequestClose} detent="content-height">
      <Sheet.Container
        style={{ borderRadius: "30px 30px 0px 0px", overflowY: "scroll" }}
      >
        <Sheet.Header />
        <Sheet.Content>
          <div className={styles.container}>
            <div className={styles.textButtons}>
              <div className={styles.textButton} onClick={openPDF}>
                <EyeIcon color="#8E8E8E" width={28} height={28} />
                <p>Visualizar</p>
              </div>
              <div className={styles.textButton} onClick={downloadPDF}>
                <ArrowDownTrayIcon color="#8E8E8E" width={28} height={28} />
                <p>Baixar</p>
              </div>
              <div
                className={styles.textButton}
                onClick={() => {
                  onRequestClose();
                  handleOpenModalSheetShareProfile();
                }}
              >
                <ShareIcon color="#8E8E8E" width={28} height={28} />
                <p>Compartilhar</p>
              </div>
              <div className={styles.textButton}>
                <PrinterIcon color="#8E8E8E" width={28} height={28} />
                <p>Imprimir</p>
              </div>
              <div className={styles.textButton}>
                <TrashIcon color="#8E8E8E" width={28} height={28} />
                <p>Excluir</p>
              </div>
            </div>
          </div>
        </Sheet.Content>
        <footer></footer>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
