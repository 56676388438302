import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    patientsWithImages: <any>[],
};

export const patientWithImagesSlice = createSlice({
    name: "patientsWithImages",
    initialState,
    reducers: {
        setPatientWithImagens: (state, action: PayloadAction<any>) => {
            state.patientsWithImages = action.payload;
        },
        addPatientWithImagens: (state, action: PayloadAction<any>) => {
            state.patientsWithImages = [...state.patientsWithImages, action.payload];
        },
        clearPatientWithImagens: (state) => {
            state.patientsWithImages = [];
        },
    },
});

export const { setPatientWithImagens, addPatientWithImagens, clearPatientWithImagens } = patientWithImagesSlice.actions;

export const selectPatientsWithImages = (state: any) => state.patientsWithImages;

export default patientWithImagesSlice.reducer;
