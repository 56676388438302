import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./components/ErrorFallback/ErrorFallback";

const ErrorBoundary = ({ children, pathname }) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        console.log("Resetando o erro");
      }}
      onError={(error, errorInfo) => {
        console.error("Erro capturado pelo Error Boundary:", error, errorInfo);
      }}
      resetKeys={[pathname]}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
