import styles from "./styles.module.css";

export function Loading() {
  return (
    <div className={styles.loading}>
      <div className={styles.loading_container}>
        <div className={styles.loading_spinne}></div>
      </div>
      <p className={styles.loading_content}>
        Aguarde, estamos buscando os dados
      </p>
    </div>
  );
}
