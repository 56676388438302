import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';

const AppLayout = () => {
    const location = useLocation();
    return (
        <ErrorBoundary pathname={location.pathname}>
            <Outlet />
        </ErrorBoundary>
    );
};

export default AppLayout;
