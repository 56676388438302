import styles from "./styles.module.css";

interface LoadingProps {
  text?: string;
  fullScreen?: boolean;
  isMobileListPacient?: boolean;
  loadingPacientList?: boolean;
}

export function Loading({ text, fullScreen, isMobileListPacient, loadingPacientList }: LoadingProps) {
  return (
    <div
      className={`${loadingPacientList ? styles.loading_container_PacientList : styles.loading_container} ${fullScreen ? styles.fullScreen : ''} ${isMobileListPacient ? styles.isMobileListPacient : ''}`}>
      {
        fullScreen &&
        <div className={styles.containerImage}>
          <img src="/logo_serodonto_verde.png" width={"200px"} />
        </div>
      }
      <div className={styles.loading_spinne} />
      <p>{text}</p>
    </div>
  );
}
