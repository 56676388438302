//import { state } from 'react';
import $ from 'jquery';
import { CalcMedida, CalcMedidaMM, CalcAngulo, angle, projeLinha, pontoMedio2, Perpendicular, Paralelo, MenorDistancia, PosicaoMediaEntreAngulo } from './Funcoes';
import { Interseccao, rotateVector, pontoMedio3, Angulo, Bezier4 } from './Funcoes';
import { Point } from './Funcoes';
import { toast } from "react-hot-toast";
//import { number } from 'yup';
//import { all } from 'q';

//Erro abaixo = som nao existe
//Failed to load because no supported source was found.

class CefxLib {
    constructor() {
        this.callbackMostrarModalIa = null;
    }

    //sim - as cefalometrias estao vindo do serverodonto - o povo que nao cadastra
    setCanvas(pcanvas) {
        const debugar = (process.env.REACT_APP_DEBUGCEF === 'S');

        this.estado = {
            _servCefx: (debugar ? "http://localhost:50932" : 'https://cdtsoftware.com.br/cefx'),
            canvas: pcanvas,
            angFusao: 0,
            linhas: [],
            scaleFactor: 1.1,
            maxScale: 5,
            minScale: 0.2,
            currentScale: 1,
            pontos: [],
            listaImagens: [],
            pontosFoto: [],
            ctx: pcanvas.getContext('2d'),
            cdtImagemAtual: new Image(),
            cdtFotoAtual: new Image(),
            cdtFotoSelecionada: new Image(),
            aparelho: 0,
            calib: 1.0,
            to96: 15,
            fotoCali: 0.0,
            paciente: { clinica: 0, atd: 0, tipo: 1, prefixo: 'cx', RXFoto: 1 },
            opcoes: { pontos: true, desenhos: true, imagem: true, foto: false, fusao: false, som: true, tracado: true, transpFusao: 0.4 },
            listaAnalises: [],
            anatomicos: [],
            tracado: [],
            estruturas: [],
            estruturasFoto: [],
            listaEstuturas: [],
            listaDentes: [],
            grupoDentes: 1,
            ptAtual: 0,
            bzAtual: -1,
            pernaFer1: -1,
            pernaFer2: -1,
            estrAtual: -1,
            brilho: 100,
            contraste: 100,

            ptc: { cx: 0, cy: 0 },

            ptMover: { x: 0, y: 0 },
            ptAngular: { x: 0, y: 0 },

            comDireito: false,
            estruturaCopiar: [],
            pontosCopiar: [],
            ferramenta: 0,    // 0 - nenhuma // 1- medir // 2- calibrar // 3 - marcar ponto // 4 - ajustar desenho // 5 - mover perfil
            modoCefx: true,
            cefs: "001|",
            desenhoCentral: 26,
            estrCentral: 0
        };



        this.carregaImagemAtual = this.carregaImagemAtual.bind(this);
        this.erroImagemAtual = this.erroImagemAtual.bind(this);

        let onPontoChange;
        let onGuiaChange;
        let onIdentificacaoChange;
        let onLoadData;
        this.qtdCarregados = 0;
        this.erroImg = 0;

        this.estado.cdtImagemAtual.onload = this.carregaImagemAtual;
        this.estado.cdtImagemAtual.onerror = this.erroImagemAtual;


        this.inicializaContexto();
        this.limpouPontos = false;
        this.limpouDesenhos = false;

        this.auxFerramenta = [];
        for (var i = 0; i < 7; i++) {
            this.auxFerramenta.push(new Point(0, 0));
        }
        this.PrecisaSalvar = true;
    }

    //-------------CARREGA CEFALOMETRIA - PONTO INICIAL
    loadCeph(clinica, atd, tipo) {
        var iTipo = parseInt(tipo);

        this.estado.anatomicos.splice(0, this.estado.anatomicos.length);
        this.estado.pontos.splice(0, this.estado.pontos.length);
        this.estado.pontosFoto.splice(0, this.estado.pontosFoto.length);
        this.estado.estruturas.splice(0, this.estado.estruturas.length);
        this.estado.estruturasFoto.splice(0, this.estado.estruturasFoto.length);
        this.estado.listaDentes.splice(0, this.estado.listaDentes.length);

        this.estado.paciente.clinica = clinica;
        this.estado.paciente.atd = atd;
        this.estado.paciente.tipo = iTipo;

        this.setImagem(this.estado._servCefx + '/api/web/arquivos/getimage/' + clinica + '/' + atd + '/' + iTipo + '/');
        //

        if (iTipo === 1) {
            this.estado.desenhoCentral = 26;
            this.estado.estrCentral = 0;
        }

        if (iTipo === 2) {
            this.estado.desenhoCentral = 1;
            this.estado.estrCentral = 7;
        }
    }

    //---- carrega imagem por URL

    setModoCefx(v) {
        this.estado.modoCefx = v;
        if (v == false)
            this.setTamanhoCanvas(false);
    }
    setImagem(url) {
        //this.onGuiaChange(2);

        this.estado.cdtImagemAtual.src = url;
    }

    setTamanhoCanvas(breal) {

        //var windowWidth = window.innerWidth;
        //var newCanvasWidth = windowWidth - 305;
        var windowHeight = window.innerHeight;
        var newCanvasHeight = windowHeight - 105;

        if (breal == false) {
            this.estado.canvas.width = window.innerWidth;
            this.estado.canvas.height = newCanvasHeight;
        }
        else {
            this.estado.canvas.width = window.innerWidth;
            this.estado.canvas.height = newCanvasHeight;
        }
    }

    //---- carrega foto por URL
    setFoto(url) {
        this.estado.cdtFotoAtual.src = url;
    }

    selecionaAlvo(alvo, id) {
        if (alvo == 1)
            this.selecionaImagem(id);
        if (alvo == 2)
            this.selecionaFoto(id);
        if (alvo == 3)
            this.selecionaFusao(id);
    }

    selecionaImagem(tipo) {
        var iTipo = parseInt(tipo);
        if (this.estado.ferramenta === 5) {
            toast("Ative a ferramenta 'Mover Imagem'.", {
                position: "top-center",
                style: {
                    background: "#f09500",
                    color: "#fff",
                    textAlign: 'center'
                },
            });
            return;
        }
        this.estado.paciente.RXFoto = 1;

        if (iTipo !== this.estado.paciente.tipo) {
            this.loadCeph(this.estado.paciente.clinica, this.estado.paciente.atd, iTipo);
        }
        else {
            this.getPontosAnalises(this.estado.paciente.clinica, this.estado.paciente.tipo);
        }
        this.estado.opcoes.imagem = true;
        this.estado.opcoes.foto = false;
        this.estado.opcoes.fusao = false;
        this.pintar();
    }

    selecionaFoto(tipo) {
        if (this.estado.ferramenta == 5) {
            toast("Ative a ferramenta 'Mover Imagem'.", {
                position: "top-center",
                style: {
                    background: "#f09500",
                    color: "#fff",
                    textAlign: 'center'
                },
            });
            // alert('Desligue a ferramenta Mover perfil');
            return;
        }

        this.estado.paciente.RXFoto = 2;

        if (tipo == 3) {
            this.selecionaImagem(8);
            return;
        }
        if (tipo != this.estado.paciente.tipo) {
            this.loadCeph(this.estado.paciente.clinica, this.estado.paciente.atd, tipo);
        }
        else {
            this.getPontosAnalises(this.estado.paciente.clinica, this.estado.paciente.tipo);
        }
        if (typeof this.estado.cdtFotoAtual == 'undefined' || this.estado.cdtFotoAtual.height == 0)
            return;

        this.estado.opcoes.imagem = false;
        this.estado.opcoes.foto = true;
        this.estado.opcoes.fusao = false;
        this.pintar();
    }

    selecionaFusao(tipo) {
        if (this.estado.ferramenta == 5) {
            toast('Ative a ferramenta "Mover Imagem".', {
                position: "top-center",
                style: {
                    background: "#f09500",
                    color: "#fff",
                    textAlign: 'center'
                },
            });
            // alert('Desligue a ferramenta Mover perfil');
            return;
        }

        if (this.estado.estruturas.length == 0 || this.estado.ferramenta == 5) {
            return;
        }
        if (typeof this.estado.cdtFotoAtual == 'undefined' || this.estado.cdtFotoAtual.height == 0)
            return;

        this.estado.opcoes.imagem = true;
        this.estado.opcoes.fusao = true;
        this.estado.opcoes.foto = false;
        if (this.estado.ferramenta === 5)
            this.estado.ferramenta = 0;
        this.pintar();
    }

    reloadAnalises(allSelectedAnalyzes) {

        for (var i = 0; i < this.estado.listaAnalises.length; i++) {
            this.estado.listaAnalises[i].selecionada = false;
        }

        for (var j = 0; j < allSelectedAnalyzes.length; j++) {
            var anlz = this.estado.listaAnalises.find(e => e.codigo === allSelectedAnalyzes[j].codigo);
            anlz.selecionada = true;
        }

        this.getPontosAnalises(this.estado.paciente.clinica, this.estado.paciente.tipo);
        this.onLoadData('pontos');
    }

    //---- INICIALIZA CONTEXTO

    inicializaContexto() {
        //document.title = "e-Vol Cloud";
        let canvas = this.estado.canvas;
        let ctx = this.estado.ctx //= canvas.getContext('2d');
        //let ferramenta = this.ferramenta;
        let linhas = this.estado.linhas;
        let to96 = this.estado.to96;

        let cdtImagemAtual = this.estado.cdtImagemAtual;
        //var ptc = this.estado.ptc;
        //var comDireito = this.estado.comDireito;
        //var ptAtual = this.estado.ptAtual;
        var estruturas = this.estado.estruturas;
        var estruturasFoto = this.estado.estruturasFoto;
        //var bzAtual = this.estado.bzAtual;
        //var ptMover = this.estado.ptMover;
        //var ptAngular = this.estado.ptAngular;
        //var estrAtual = this.estado.estrAtual;
        //var pontosFoto = this.estado.pontosFoto;
        //var estruturaCopiar = this.estado.estruturaCopiar;

        let scaleFactor = this.estado.scaleFactor;
        let maxScale = this.estado.maxScale;
        let minScale = this.estado.minScale;
        let currentScale = this.estado.currentScale;

        this.setTamanhoCanvas(true);


        this.trackTransforms(ctx);

        //this.redraw();

        var lastX = canvas.width / 2,
            lastY = canvas.height / 2;

        var dragStart, dragged;

        const isto = this;


        window.addEventListener("resize", function () {
            //teste - redimensionamento da imagem, parece ok mas nao vi as coordenadas no banco
            //deve estar certo
            isto.setTamanhoCanvas(true);
            isto.trackTransforms(ctx);
            isto.redraw();
        });

        canvas.addEventListener('mousedown', function (evt) {
            document.body.style.mozUserSelect = document.body.style.webkitUserSelect = document.body.style.userSelect = 'none';
            lastX = evt.offsetX || (evt.pageX - canvas.offsetLeft);
            lastY = evt.offsetY || (evt.pageY - canvas.offsetTop);

            //HERANCA DO DOCTOR ->PASSAR AS IMAGENS EM SEQUENCIA
            //if (lastX < 50) {
            //    imagemAnte();
            //    return;
            //}
            //if (lastX > canvas.width - 50) {
            //    imagemProx();
            //    return;
            //}
            dragStart = ctx.transformedPoint(lastX, lastY);
            isto.estado.ptc.cx = lastX;
            isto.estado.ptc.cy = lastY;

            if (evt.button === 2)
                isto.estado.comDireito = true;
            if (evt.button === 0) {
                if (isto.estado.ferramenta === 1 || isto.estado.ferramenta === 2) {
                    var cada = {};
                    cada.x1 = dragStart.x;
                    cada.y1 = dragStart.y;
                    cada.x2 = dragStart.x;
                    cada.y2 = dragStart.y;
                    linhas.push(cada);
                    isto.PrecisaSalvar = true;
                }
                //mudei para o mouse up
                //if (isto.estado.ferramenta == 3)//marcar ponto
                //{
                //    isto.addPonto(isto.estado.ptAtual, dragStart.x * to96, dragStart.y * to96);
                //    isto.proximoPonto(isto.estado.ptAtual + 1);
                //}
                if (isto.estado.ferramenta === 3) {
                    var objPtSel = isto.estado.anatomicos.find(el => el.id == isto.estado.ptAtual);
                    if (objPtSel && objPtSel.auxiliar + "" != "") {

                        isto.estado.pernaFer1 = -1;
                        isto.estado.pernaFer2 = -1;
                        var hhant = 1000;

                        for (var i = 1; i < 7; i++) {
                            var hh = CalcMedida(dragStart.x, dragStart.y, isto.auxFerramenta[i].x / to96, isto.auxFerramenta[i].y / to96);
                            if (hh < hhant) {
                                hhant = hh;
                                isto.estado.pernaFer1 = i;
                            }
                        }

                        if (objPtSel.auxiliar === "Reta") isto.estado.pernaFer1 = -1;
                        if (objPtSel.auxiliar === "Reta Continua") isto.estado.pernaFer1 = -1;
                        if (objPtSel.auxiliar === "Linha Paralela") isto.estado.pernaFer1 = -1;
                        if (objPtSel.auxiliar === "Linha Paralela Móvel") isto.estado.pernaFer1 = 3;
                        if (objPtSel.auxiliar === "Linha Perpendicular") isto.estado.pernaFer1 = 3;
                        if (objPtSel.auxiliar === "Linha Perp. Horz.") isto.estado.pernaFer1 = 1;
                        if (objPtSel.auxiliar === "Bissetriz 45 Graus") {
                            isto.estado.pernaFer1 = 1;
                            isto.estado.pernaFer2 = 2;
                        }
                        //If DadosPonto.Ferramenta = "Reta" Then iNear = -1
                        //If DadosPonto.Ferramenta = "Reta Continua" Then iNear = -1
                        //If DadosPonto.Ferramenta = "Linha Paralela" Then iNear = -1
                        //If DadosPonto.Ferramenta = "Linha Paralela Móvel" Then iNear = 3
                        //If DadosPonto.Ferramenta = "Linha Perpendicular" Then iNear = 3
                        //If DadosPonto.Ferramenta = "Linha Perp. Horz." Then iNear = 1
                        //If DadosPonto.Ferramenta = "Bissetriz 45 Graus" Then iNear = 1
                    }
                    //else
                    //    bzAtual = -1;
                }
                if (isto.estado.ferramenta === 4)//ajustar bezier
                {
                    isto.findBezier(dragStart.x * to96, dragStart.y * to96, estruturas);
                    isto.pintar();
                }
                if (isto.estado.ferramenta === 5)//mover
                {
                    isto.estado.bzAtual = -1;
                    isto.findBezier(dragStart.x * to96, dragStart.y * to96, estruturasFoto);
                    isto.copiarPontosTemp();

                    //mover perfil
                    if (Math.abs(dragStart.x * to96 - isto.estado.ptMover.x) < 150 && Math.abs(dragStart.y * to96 - isto.estado.ptMover.y) < 150)
                        isto.estado.bzAtual = -2;
                    //redimensionar perfil
                    if (Math.abs(dragStart.x * to96 - isto.estado.ptAngular.x) < 150 && Math.abs(dragStart.y * to96 - isto.estado.ptAngular.y) < 150)
                        isto.estado.bzAtual = -3;
                }
            }
            dragged = false;
        }, false);

        canvas.addEventListener('mousemove', function (evt) {
            lastX = evt.offsetX || (evt.pageX - canvas.offsetLeft);
            lastY = evt.offsetY || (evt.pageY - canvas.offsetTop);
            dragged = true;
            //nao deu certo o zoom
            //var z = canvas.height / cdtImagemAtual.height;

            if (dragStart) {
                var pt = ctx.transformedPoint(lastX, lastY);
                if (isto.estado.comDireito === true || isto.estado.ferramenta === 0) {

                    ctx.translate(pt.x - dragStart.x, pt.y - dragStart.y);
                    isto.pintar();

                }
                else {
                    if ((isto.estado.ferramenta === 1 || isto.estado.ferramenta === 2)) {
                        var cada = linhas[linhas.length - 1];
                        cada.x2 = pt.x;
                        cada.y2 = pt.y;
                        isto.pintar();
                        isto.PrecisaSalvar = true;
                    }

                    if (isto.estado.ferramenta === 3)//marcar ponto
                    {
                        if (isto.estado.pernaFer1 >= 1)//posicionar ferramenta
                        {

                            var svb = { x: isto.auxFerramenta[isto.estado.pernaFer1].x, y: isto.auxFerramenta[isto.estado.pernaFer1].y };

                            var ax = pt.x * to96;
                            var ay = pt.y * to96;
                            var vt = { x: svb.x - ax, y: svb.y - ay };

                            //console.log(bzAtual, svb.x);
                            isto.auxFerramenta[isto.estado.pernaFer1].x -= vt.x;
                            isto.auxFerramenta[isto.estado.pernaFer1].y -= vt.y;
                            if (isto.estado.pernaFer2 > 0) {
                                isto.auxFerramenta[isto.estado.pernaFer2].x -= vt.x;
                                isto.auxFerramenta[isto.estado.pernaFer2].y -= vt.y;

                            }

                            isto.estado.ptc.cx = lastX;
                            isto.estado.ptc.cy = lastY;
                            isto.pintar();
                        }
                    }
                    if ((isto.estado.ferramenta == 4 && isto.estado.estrAtual >= 0 && isto.estado.bzAtual >= 0))//ajustar bezier
                    {
                        var bz = isto.estado.estruturas[isto.estado.estrAtual].desenhos[isto.estado.bzAtual];
                        var svb = { x: bz.x, y: bz.y };//salva o ponto antes de mudar para usar no vetor
                        bz.x = pt.x * to96;
                        bz.y = pt.y * to96;
                        var oestAtual = isto.estado.listaEstuturas.find(e => e.codigo == isto.estado.estrAtual);
                        if (oestAtual && oestAtual.tipoEstrutura > 0) {

                            for (var i = 0; i < isto.estado.estruturas[isto.estado.estrAtual].desenhos.length; i++) {
                                if (i != isto.estado.bzAtual && isto.estado.bzAtual == 0) {
                                    var bz = isto.estado.estruturas[isto.estado.estrAtual].desenhos[i];
                                    var vt = { x: svb.x - pt.x * to96, y: svb.y - pt.y * to96 };//calcula o quanto ponto movido mudou e aplica nos outros
                                    bz.x -= vt.x;
                                    bz.y -= vt.y;
                                }
                            }
                        }
                        isto.estado.ptc.cx = lastX;
                        isto.estado.ptc.cy = lastY;
                        isto.pintar();
                        isto.PrecisaSalvar = true;
                    }
                    if (isto.estado.ferramenta == 5)//mover
                    {
                        var vt = { x: isto.estado.ptMover.x - pt.x * to96, y: isto.estado.ptMover.y - pt.y * to96 };
                        //mover perfil
                        if (isto.estado.bzAtual == -2) {
                            isto.estado.ptMover.x -= vt.x;
                            isto.estado.ptMover.y -= vt.y;

                            isto.estado.ptAngular.x -= vt.x;
                            isto.estado.ptAngular.y -= vt.y;
                        }
                        //redimensionar perfil
                        if (isto.estado.bzAtual == -3) {
                            vt = { x: isto.estado.ptAngular.x - pt.x * to96, y: isto.estado.ptAngular.y - pt.y * to96 };
                            isto.estado.ptAngular.x -= vt.x;
                            isto.estado.ptAngular.y -= vt.y;
                        }
                        //     pintar();
                        //cx = lastX;
                        //cy = lastY;
                        //mover perfil
                        if (isto.estado.bzAtual == -2) {//estomio - mover
                            for (var ip = 0; ip < isto.estado.pontosFoto.length; ip++) {
                                var pf = isto.estado.pontosFoto[ip];
                                pf.x -= vt.x;
                                pf.y -= vt.y;
                            }
                            for (var j = 0; j < isto.estado.estruturasFoto.length; j++) {
                                var estr = isto.estado.estruturasFoto[j];
                                for (var i = 0; i < estr.desenhos.length; i++) {
                                    var ptFoto = estr.desenhos[i];
                                    ptFoto.x -= vt.x;
                                    ptFoto.y -= vt.y;
                                }
                            }
                        }
                        else if (isto.estado.bzAtual == -3) //redimensionar perfil
                        {

                            var xest1 = isto.estado.ptMover.x;
                            var yest1 = isto.estado.ptMover.y;
                            var xgla1 = isto.estado.ptAngular.x;
                            var ygla1 = isto.estado.ptAngular.y;

                            var xest2 = isto.estado.estruturaCopiar[isto.estado.estrCentral].desenhos[isto.estado.desenhoCentral].x;
                            var yest2 = isto.estado.estruturaCopiar[isto.estado.estrCentral].desenhos[isto.estado.desenhoCentral].y;
                            var xgla2 = isto.estado.estruturaCopiar[0].desenhos[0].x;
                            var ygla2 = isto.estado.estruturaCopiar[0].desenhos[0].y;


                            var ang = CalcAngulo(xest1, yest1, xgla1, ygla1);
                            var med = CalcMedida(xest1, yest1, xgla1, ygla1);

                            var ang2 = CalcAngulo(xest2, yest2, xgla2, ygla2);
                            var med2 = CalcMedida(xest2, yest2, xgla2, ygla2);

                            var prop = med / med2;
                            var difa = ang - ang2;

                            for (var i = 0; i < isto.estado.pontosFoto.length; i++) {
                                var pf = isto.estado.pontosFoto[i];
                                var ptc = isto.estado.pontosCopiar[i];
                                var angp = CalcAngulo(xest1, yest1, ptc.x, ptc.y);
                                var medp = CalcMedida(xest1, yest1, ptc.x, ptc.y);

                                pf.x = xest2 + (medp * prop) * Math.cos(angp + difa);
                                pf.y = yest2 + (medp * prop) * Math.sin(angp + difa);
                            }


                            for (var j = 0; j < isto.estado.estruturasFoto.length; j++) {
                                var estr = isto.estado.estruturasFoto[j];
                                for (var i = 0; i < estr.desenhos.length; i++) {
                                    var ptCopia = isto.estado.estruturaCopiar[j].desenhos[i];
                                    var angp = CalcAngulo(xest1, yest1, ptCopia.x, ptCopia.y);
                                    var medp = CalcMedida(xest1, yest1, ptCopia.x, ptCopia.y);

                                    var ptFoto = estr.desenhos[i];
                                    ptFoto.x = xest2 + (medp * prop) * Math.cos(angp + difa);
                                    ptFoto.y = yest2 + (medp * prop) * Math.sin(angp + difa);
                                }
                            }

                            //aqui
                        }
                        isto.pintar();
                        isto.estado.cx = lastX;
                        isto.estado.cy = lastY;
                        isto.PrecisaSalvar = true;
                    }
                }
            }
        }, false);

        canvas.addEventListener('mouseup', function (evt) {

            if (isto.estado.ferramenta == 3) {
                //marcar ponto
                if (!dragged && evt.button == 0) {//colocar tambem se tem ferramenta no ponto atual.
                    isto.addPonto(isto.estado.ptAtual, dragStart.x * to96, dragStart.y * to96);
                    var objPtSel = isto.estado.anatomicos.find(el => el.id == isto.estado.ptAtual);
                    objPtSel.marcado = true;
                    isto.proximoPonto(isto.estado.ptAtual + 1);
                    isto.PrecisaSalvar = true;
                }
                if (!dragged && isto.estado.comDireito) {
                    isto.findPontoXY(dragStart.x * to96, dragStart.y * to96)

                }
            }

            dragStart = null;//isso é usado acima - nao mover para cima

            //var svDireito = isto.estado.comDireito;
            isto.estado.comDireito = false;
            if (!dragged) {
                //if (isto.estado.ferramenta == 0) {
                //    if (ControleLaudo == "") //typeof onTrataImagem_click != 'function')
                //        zoom(evt.shiftKey || svDireito == true ? -5 : 5);
                //    else
                //        onTrataImagem_click();
                //}
            }
            if (isto.estado.ferramenta == 2 && evt.button == 0) {
                var cada = linhas[linhas.length - 1];
                var med = CalcMedidaMM(cada.x1, cada.y1, cada.x2, cada.y2);
                med = med / isto.estado.calib;
                var retval = prompt("Valor real em mm", med);
                if (retval) {
                    isto.setCalibragem(retval / (med * 1.3));
                }
                isto.estado.ferramenta = 1;
                isto.pintar();
                isto.PrecisaSalvar = true;
            }

        }, false);

        // var scaleFactor = 1.1; troquei

        var zoom = function (clicks) {
            // var pt = ctx.transformedPoint(lastX, lastY);
            // ctx.translate(pt.x, pt.y);
            // var factor = Math.pow(scaleFactor, clicks);
            // ctx.scale(factor, factor);
            // ctx.translate(-pt.x, -pt.y);
            // isto.pintar();

            const pt = ctx.transformedPoint(lastX, lastY);
            ctx.translate(pt.x, pt.y);
            let factor = Math.pow(scaleFactor, clicks);
            let newScale = currentScale * factor;
            if (newScale > maxScale) {
                factor = maxScale / currentScale;
            } else if (newScale < minScale) {
                factor = minScale / currentScale;
            }
            currentScale *= factor;

            ctx.scale(factor, factor);
            ctx.translate(-pt.x, -pt.y);
            isto.pintar();
        };

        var handleScroll = function (evt) {
            //console.log('entrou!')
            var delta = evt.wheelDelta ? evt.wheelDelta / 250 : evt.detail ? -evt.detail : 0;

            if (delta) zoom(delta);
            return evt.preventDefault() && false;
        };

        canvas.addEventListener('DOMMouseScroll', handleScroll, false);
        canvas.addEventListener('mousewheel', handleScroll, false);
        canvas.addEventListener("contextmenu", function (e) {
            e.preventDefault();
        }, false);

        // touch
        // canvas.addEventListener("touchstart", function (e) {
        //     e.preventDefault();
        //     mousePos = getTouchPos(canvas, e);
        //     var touch = e.touches[0];
        //     var mouseEvent = new MouseEvent("mousedown", {
        //         clientX: touch.clientX,
        //         clientY: touch.clientY
        //     });
        //     canvas.dispatchEvent(mouseEvent);
        // }, false);
        // canvas.addEventListener("touchend", function (e) {
        //     e.preventDefault();
        //     var mouseEvent = new MouseEvent("mouseup", {});
        //     canvas.dispatchEvent(mouseEvent);
        // }, false);
        // canvas.addEventListener("touchmove", function (e) {
        //     e.preventDefault();
        //     var touch = e.touches[0];
        //     var mouseEvent = new MouseEvent("mousemove", {
        //         clientX: touch.clientX,
        //         clientY: touch.clientY
        //     });
        //     canvas.dispatchEvent(mouseEvent);
        // }, false);

        // Get the position of a touch relative to the canvas
        function getTouchPos(canvasDom, touchEvent) {
            var rect = canvasDom.getBoundingClientRect();
            return {
                x: touchEvent.touches[0].clientX - rect.left,
                y: touchEvent.touches[0].clientY - rect.top
            };
        }

        //document.addEventListener("keypress", function (e) {
        //var char = e.keyCode || e.which;
        //    if (char == 77 || char == 109) { //m OU m
        //        isto.estado.ferramenta = 1;
        //    }
        //    if (char == 67 || char == 99) {// c OU C
        //        isto.estado.ferramenta = 2;
        //    }
        //    if (char == 68 || char == 100) {// d OU D
        //        isto.setCalibragem(1.0);
        //    }
        //    if (char == 69 || char == 101) {// e OU E
        //        isto.setCalibragem(0.52);
        //    }
        //    if (char == 65 || char == 97) {// a OU A
        //        isto.setPermanente();
        //    }
        //    if (char == 66 || char == 98) {// b OU B
        //        isto.setDeciduo();
        //    }
        //}, false);

        document.addEventListener("keydown", function (e) {
            var char = e.keyCode || e.which;
            if (e.altKey && (char == 78 || char == 110)) {
                var nmap = prompt("Nome do Aparelho: " + isto.estado.calib, "");
                if (nmap + "" != "" && nmap != null)
                    isto.novoAparelho(nmap);
            }
            //    if (char == 37) {
            //        // if (numAtual > 0) {
            //        //     numAtual--;
            //        //     ativarConteudo();
            //        //     return;
            //        // }
            //    }
            //    if (char == 39) {
            //        // if (lstImagens[numAtual + 1]) {
            //        //     numAtual++;
            //        //     ativarConteudo();
            //        //     return;
            //        // }

            //    }
        }, false);

        canvas.focus();
    } //fim da funcao
    // --------------------------- fim da funcao 'inicializaContexto' --------------------------------------

    redimensionamentoInicial() {

        const ctx = this.estado.ctx;
        const canvas = this.estado.canvas;
        const cdtImagemAtual = this.estado.cdtImagemAtual;
        const cdtFotoAtual = this.estado.cdtFotoAtual;
        const cali = this.estado.calib;

        var aImagem = this.estado.cdtImagemAtual;
        if (this.estado.paciente.RXFoto == 2)
            aImagem = this.estado.cdtFotoAtual;

        ctx.setTransform(1, 0, 0, 1, 0, 0);
        var ix = (canvas.height * cali) / (aImagem.height * cali);
        if (aImagem.height < 100) {
            ix = 1;
        }

        ctx.scale(ix / cali, ix / cali);
        var lx = (canvas.width) / 2.0 - (aImagem.width * ix) / 2.0;
        var ly = (canvas.height) / 2.0 - (aImagem.height * ix) / 2.0;
        var pt = ctx.transformedPoint(lx, ly);
        ctx.translate(pt.x, pt.y);
    }
    carregaImagemAtual() {
        //ctx.drawImage(this.estado.cdtImagemAtual, 0, 0);
        this.redimensionamentoInicial();
        //this.primeiroPaint();

        if (this.estado.modoCefx == false) {
            this.redraw();
        }
        else {
            this.carregarPaciente(this.estado.paciente.clinica, this.estado.paciente.atd, this.estado.paciente.tipo, "");

            this.setFoto(this.estado._servCefx + '/api/web/arquivos/getimage/' + this.estado.paciente.clinica + '/' + this.estado.paciente.atd + '/' + (this.estado.paciente.tipo + 10) + '/');
        }
    }

    erroImagemAtual() {
        this.erroImg++;
        if (this.erroImg == 1) {
            this.estado.cdtImagemAtual.src = "/logocdt.png";
            this.erroImg = 0;
        }
    }

    // --------------------------- trackTransforms

    trackTransforms(ctx) {
        var svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
        var xform = svg.createSVGMatrix();
        ctx.getTransform = function () {
            return xform;
        };

        var savedTransforms = [];
        var save = ctx.save;
        ctx.save = function () {
            savedTransforms.push(xform.translate(0, 0));
            return save.call(ctx);
        };

        var restore = ctx.restore;
        ctx.restore = function () {
            xform = savedTransforms.pop();
            return restore.call(ctx);
        };

        var scale = ctx.scale;
        ctx.scale = function (sx, sy) {
            xform = xform.scaleNonUniform(sx, sy);
            return scale.call(ctx, sx, sy);
        };

        var rotate = ctx.rotate;
        ctx.rotate = function (radians) {
            xform = xform.rotate(radians * 180 / Math.PI);
            return rotate.call(ctx, radians);
        };

        var translate = ctx.translate;
        ctx.translate = function (dx, dy) {
            xform = xform.translate(dx, dy);
            return translate.call(ctx, dx, dy);
        };

        var transform = ctx.transform;
        ctx.transform = function (a, b, c, d, e, f) {
            var m2 = svg.createSVGMatrix();
            m2.a = a;
            m2.b = b;
            m2.c = c;
            m2.d = d;
            m2.e = e;
            m2.f = f;
            xform = xform.multiply(m2);
            return transform.call(ctx, a, b, c, d, e, f);
        };

        var setTransform = ctx.setTransform;
        ctx.setTransform = function (a, b, c, d, e, f) {
            xform.a = a;
            xform.b = b;
            xform.c = c;
            xform.d = d;
            xform.e = e;
            xform.f = f;
            return setTransform.call(ctx, a, b, c, d, e, f);
        };

        var pt = svg.createSVGPoint();
        ctx.transformedPoint = function (x, y) {
            pt.x = x;
            pt.y = y;
            return pt.matrixTransform(xform.inverse());
        };
    }//trackTransforms

    pintar() {
        this.redraw();
    }

    primeiroPaint() {
        this.qtdCarregados++;
        if (this.qtdCarregados >= 14) {
            this.redraw();
        }
    }

    //--------------------------REDRAW - PAINT
    redraw() {


        const cdtImagemAtual = this.estado.cdtImagemAtual;
        const cdtFotoAtual = this.estado.cdtFotoAtual;
        const cali = this.estado.calib;
        const opcoes = this.estado.opcoes;
        // Clear the entire canvas
        var p1 = this.estado.ctx.transformedPoint(0, 0);
        var p2 = this.estado.ctx.transformedPoint(this.estado.canvas.width, this.estado.canvas.height);
        this.estado.ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);

        this.estado.ctx.save();
        this.estado.ctx.setTransform(1, 0, 0, 1, 0, 0);
        this.estado.ctx.clearRect(0, 0, this.estado.canvas.width, this.estado.canvas.height);
        this.estado.ctx.restore();


        if (opcoes.imagem) {
            const listaImagens = this.estado.listaImagens;
            if (listaImagens.length === 0) {
                this.estado.ctx.filter = "brightness(" + this.estado.brilho + "%) contrast(" + this.estado.contraste + "%)";

                this.estado.ctx.drawImage(cdtImagemAtual, 0, 0, cdtImagemAtual.width * cali, cdtImagemAtual.height * cali);

                this.estado.ctx.filter = 'none';
            }

            else if (listaImagens.length === 1) {
                this.estado.ctx.drawImage(listaImagens[0], 0, 0, listaImagens[0].width * cali, listaImagens[0].height * cali);
            }
            else {
                const LARGURA_SIDEBAR = 340;
                const ESPACAMENTO_ENTRE_IMAGENS = 20;

                const larguraDisponivel = this.estado.ctx.canvas.width - LARGURA_SIDEBAR;
                const larguraTotalImagens = larguraDisponivel - ESPACAMENTO_ENTRE_IMAGENS * (listaImagens.length - 1);
                const larguraMediaImagem = larguraTotalImagens / listaImagens.length;
                const alturaCanvas = this.estado.ctx.canvas.height;

                //let larguraMaxima = listaImagens.reduce((max, img) => Math.max(max, img.width), 0);

                listaImagens.forEach((imagemAtual, index) => {
                    const novaLarguraImagem = larguraMediaImagem;
                    const novaAlturaImagem = (novaLarguraImagem * imagemAtual.height) / imagemAtual.width;
                    let x = 0;
                    let y = 0;

                    if (listaImagens.length === 4) {
                        x = (index % 2) * (larguraMediaImagem + ESPACAMENTO_ENTRE_IMAGENS);
                        y = Math.floor(index / 2) * (alturaCanvas / 2);
                    } else {
                        x = index * (larguraMediaImagem + ESPACAMENTO_ENTRE_IMAGENS);
                        y = 0;
                    }

                    this.estado.ctx.drawImage(imagemAtual, x, y, novaLarguraImagem, novaAlturaImagem);
                });
            }
        }
        if (opcoes.foto || this.estado.ferramenta == 5) {
            if (this.estado.fotoCali == 0) {
                this.estado.fotoCali = 1;//this.estado.calib;
                //fotoCali = this.estado.fotoCali;//a variavel local nao atualiza o valor, parece nao ser por referencia

                if (cdtFotoAtual.height > (cdtImagemAtual.height * 1.5)) {
                    //this.estado.fotoCali = cdtImagemAtual.height / (cdtFotoAtual.height);
                }
            }
            this.estado.ctx.drawImage(cdtFotoAtual, 0, 0, cdtFotoAtual.width * this.estado.fotoCali, cdtFotoAtual.height * this.estado.fotoCali);
        }
        if (opcoes.fusao)// && ferramenta==5)       
        {
            this.drawFusion(this.estado.cdtFotoAtual);
        }

        this.drawObjects();

    }


    drawFusion(image) {
        const ctx = this.estado.ctx;
        const estruturasFoto = this.estado.estruturasFoto;
        const opcoes = this.estado.opcoes;
        const estruturas = this.estado.estruturas;
        if (this.estado.fotoCali == 0)
            this.estado.fotoCali = 1;
        const fotoCali = this.estado.fotoCali;
        const to96 = this.estado.to96;

        // if (angFusao == 1)
        //     debugger;
        var estF = estruturasFoto[this.estado.estrCentral].desenhos[this.estado.desenhoCentral];
        var glaF = estruturasFoto[0].desenhos[0];

        var estT = estruturas[this.estado.estrCentral].desenhos[this.estado.desenhoCentral];
        var glaT = estruturas[0].desenhos[0];

        var angF = CalcAngulo(estF.x, estF.y, glaF.x, glaF.y);
        var medF = CalcMedida(estF.x, estF.y, glaF.x, glaF.y);

        var angT = CalcAngulo(estT.x, estT.y, glaT.x, glaT.y);
        var medT = CalcMedida(estT.x, estT.y, glaT.x, glaT.y);

        var prop = medT / medF;
        var difa = angF - angT;

        var width = image.width * fotoCali;//coloca na escala do desenho
        var height = image.height * fotoCali;
        var w = width * prop; //redimensiona para ficar do tamanho da tele
        var h = height * prop;

        ctx.globalAlpha = opcoes.transpFusao;
        this.imagemRotacionada(image, estT.x / to96, estT.y / to96, estF.x * prop / to96, estF.y * prop / to96, -difa, w, h);
        ctx.globalAlpha = 1.0;
    }

    imagemRotacionada(image, positionX, positionY, pontoGiroX, pontoGiroY, angulo, w, h) {
        //where (positionX, positionY) is the coordinates on the canvas that I want the image to be located at
        //pontoGiroX , pontoGiroY the point on the image where I want the image to rotate
        const ctx = this.estado.ctx;

        ctx.save();
        ctx.translate(positionX, positionY);
        ctx.rotate(angulo);
        ctx.translate(-pontoGiroX, -pontoGiroY);
        ctx.drawImage(image, 0, 0, w, h);
        ctx.restore();
    }


    desenhaImagem96(image, x, y, w, h) {
        var to96 = this.estado.to96;
        this.desenhaImagem(image, x / to96, y / to96, w, h);
    }

    desenhaImagem(image, x, y, w, h) {
        this.estado.ctx.drawImage(image, x, y, w, h);
    }

    desenhaLinhaSombra96(x1, y1, x2, y2, cor, offx, offy, offcor) {
        var to96 = this.estado.to96;
        this.desenhaLinhaSombra(x1 / to96, y1 / to96, x2 / to96, y2 / to96, cor, offx, offy, offcor);
    }

    desenhaLinhaSombra(x1, y1, x2, y2, cor, offx, offy, offcor) {
        const ctx = this.estado.ctx;
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.strokeStyle = cor;
        ctx.shadowOffsetX = 1;
        ctx.shadowOffsetY = 1;
        ctx.shadowColor = offcor;
        ctx.stroke();

    }

    desenhaLinha96(x1, y1, x2, y2, cor) {
        var to96 = this.estado.to96;
        this.desenhaLinha(x1 / to96, y1 / to96, x2 / to96, y2 / to96, cor);
    }

    desenhaLinha(x1, y1, x2, y2, cor) {
        const ctx = this.estado.ctx;
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.strokeStyle = cor;
        ctx.stroke();
    }

    desenhaPonto96(x, y, raio, cor) {
        var to96 = this.estado.to96;
        this.desenhaPonto(x / to96, y / to96, raio, cor);
    }

    desenhaPonto(x, y, raio, cor) {
        const ctx = this.estado.ctx;
        ctx.fillStyle = cor;
        ctx.beginPath();
        ctx.arc(x, y, raio, 0, 2 * Math.PI);
        ctx.fill();
    }

    desenhaCirculo(x, y, raio, cor) {
        const ctx = this.estado.ctx;
        ctx.strokeStyle = cor;
        ctx.beginPath();
        ctx.arc(x, y, raio, 0, 2 * Math.PI);
        ctx.stroke();
    }

    desenhaArco(x, y, raio, cor, a1, a2) {
        //var to96 = this.estado.to96;
        const ctx = this.estado.ctx;
        ctx.strokeStyle = cor;
        ctx.beginPath();
        ctx.arc(x, y, raio, a1 * Math.PI / 180.0, a2 * Math.PI / 180.0);
        ctx.stroke();

    }

    //-------------------------DRAWOBJECTS
    drawObjects() {

        var linhas = this.estado.linhas;
        var opcoes = this.estado.opcoes;
        //var cali = this.estado.calib;
        var ferramenta = this.estado.ferramenta;
        var pontos = this.estado.pontos;
        var pontosFoto = this.estado.pontosFoto;
        var ptMover = this.estado.ptMover;
        var ptAngular = this.estado.ptAngular;
        // opcoes.pontos = true; AQUI NAO ESTAVA CONSEGUINDO DESMARCAR OS PONTOS 
        //opcoes.imagem = true;
        var to96 = this.estado.to96;

        const ctx = this.estado.ctx;

        //desenha medidas
        for (var id = 0; id < linhas.length; id++) {
            var cada = linhas[id];
            this.desenhaLinhaSombra(cada.x1, cada.y1, cada.x2, cada.y2, 'white', 1, 1, 'black');

            var med = CalcMedidaMM(cada.x1, cada.y1, cada.x2, cada.y2);

            ctx.font = "30px Arial";
            ctx.fillStyle = 'green';
            ctx.fillText(med.toFixed(2), cada.x2, cada.y2);
        }

        //desenha pontos
        if (opcoes.pontos || ferramenta == 3) {
            //pontos rx
            if ((opcoes.imagem || opcoes.fusao) && ferramenta != 5) {
                for (var pt = 0; pt < this.estado.pontos.length; pt++) {
                    var p = this.estado.pontos[pt];
                    this.desenhaPonto96(p.x, p.y, 3, 'lightgreen');
                }
                //ponto atual
                var p = pontos.find(e => e.id == this.estado.ptAtual);
                if (p)
                    this.desenhaPonto96(p.x, p.y, 5, 'yellow');
            }

            //ponto na foto
            if (opcoes.foto) {
                if (opcoes.pontos) {
                    for (var pt = 0; pt < pontosFoto.length; pt++) {
                        var p = pontosFoto[pt];
                        //nao pode usar to96 pq é da tele
                        this.desenhaPonto((p.x / 15)/**cali*/, (p.y / 15)/**cali*/, 3, 'lightgreen');
                    }
                }
            }
            //ferramenta de marcaçao
            if (ferramenta == 3)
                this.desenhaFerramenta();


        }
        //linhas de movimentar perfil
        if (ferramenta == 5) {
            ctx.beginPath();
            ctx.moveTo(ptMover.x / to96, ptMover.y / to96);
            ctx.lineTo(ptAngular.x / to96, ptAngular.y / to96);
            ctx.stroke();

            ctx.fillStyle = 'yellow';
            ctx.beginPath();
            ctx.arc(ptMover.x / to96, ptMover.y / to96, 3, 0, 2 * Math.PI);
            ctx.fill();
            ctx.beginPath();
            ctx.arc(ptAngular.x / to96, ptAngular.y / to96, 3, 0, 2 * Math.PI);
            ctx.fill();

        }

        //desenha beziers
        if (opcoes.desenhos) {

            if ((opcoes.imagem || opcoes.fusao) && ferramenta != 5) {
                this.desenhaBezier(this.estado.estruturas, to96, 0);
            }
            if ((opcoes.foto) || ferramenta == 5) {
                this.desenhaBezier(this.estado.estruturasFoto, 15, 1);

            }

        }





        //desenha traçado
        if (opcoes.tracado && ferramenta != 5) {
            for (var id = 0; id < this.estado.tracado.length; id++) {
                var cada = this.estado.tracado[id];
                this.desenhaLinhaSombra(cada.x1 / to96, cada.y1 / to96,
                    cada.x2 / to96, cada.y2 / to96, cada.cor, 1, 1, 'black');
                //ctx.font = "30px Arial";
                //ctx.fillStyle = 'green';
                //ctx.fillText(med.toFixed(2), cada.x2, cada.y2);
            }
        }

    }

    //----------------------------DESENHA BEZIER
    desenhaBezier(estr, qProp, onde) {
        const ctx = this.estado.ctx;
        for (var i = 0; i < estr.length; i++) {
            var oest = this.estado.listaEstuturas.find(e => e.codigo === estr[i].codigo);
            if (oest !== undefined) {
                //TODO: arrumar, esta pegando o visivel do RX
                var Dest = this.estado.estruturas.find(e => e.codigo === estr[i].codigo);
                if (Dest.visivel) {
                    var desenhos = estr[i].desenhos;
                    if (oest.codigo == 18 && this.estado.paciente.tipo == 1) {//porio
                        var p1 = desenhos[0];
                        if (p1 !== undefined)
                            this.desenhaCirculo(p1.x / qProp, (p1.y + 260) / qProp, 260 / qProp, oest.corWeb);
                    }
                    else if (oest.tipoEstrutura == 0) {
                        ctx.strokeStyle = oest.corWeb;//'green';
                        ctx.beginPath();

                        for (var pt = 0; pt < desenhos.length; pt += 3) {
                            var p1 = desenhos[pt];
                            var p2 = desenhos[pt + 1];
                            var p3 = desenhos[pt + 2];
                            var p4 = desenhos[pt + 3];
                            if (p3 && p4) {
                                ctx.moveTo(p1.x / qProp, p1.y / qProp);
                                ctx.bezierCurveTo(p2.x / qProp, p2.y / qProp, p3.x / qProp, p3.y / qProp, p4.x / qProp, p4.y / qProp);
                            }
                        }
                        ctx.stroke();
                    }
                    else {

                        //TODO: padronizar no banco, ta no JS e no C# com valores fixos - codigo duplicado

                        var fig = {};
                        fig.c = oest.codigo;
                        fig.a = 23;
                        if (oest.codigo === 20) fig.a = 23;
                        if (oest.codigo === 22) fig.a = 180 - 27;
                        if (oest.codigo === 19) fig.a = -25;
                        if (oest.codigo === 21) fig.a = 180 - 15;
                        if (oest.codigo === 23) fig.a = 180;

                        if (oest.codigo === 34) fig.a = CalcAngulo(0, 0, -795.23681640625, -1987.605224609375) + 25;//2s
                        if (oest.codigo === 35) fig.a = CalcAngulo(0, 0, -681.2630615234375, -2056.262939453125) + 20;//3s
                        if (oest.codigo === 36) fig.a = CalcAngulo(0, 0, -986.3157958984375, -1701.4736328125) + 31;//4s
                        if (oest.codigo === 37) fig.a = CalcAngulo(0, 0, -863.31585693359375, -1515.500244140625) + 32;//5s
                        if (oest.codigo === 38) fig.a = CalcAngulo(0, 0, 712.772705078125, -1125.04541015625) - 30;//7s

                        if (oest.codigo === 39) fig.a = 180 + CalcAngulo(0, 0, -343.27142333984375, 1519.67138671875) - 13;//7i
                        if (oest.codigo === 40) fig.a = 180 + CalcAngulo(0, 0, -723.3870849609375, 1559.79052734375) - 26;//5i
                        if (oest.codigo === 41) fig.a = 180 + CalcAngulo(0, 0, -872.8387451171875, 1818.290283203125) - 28;//3i
                        if (oest.codigo === 42) fig.a = 180 + CalcAngulo(0, 0, -772.193603515625, 1668.91943359375) - 27;//2i
                        if (oest.codigo === 43) fig.a = 180 + CalcAngulo(0, 0, - 741.3548583984375, 1700.790283203125) - 26;//4i

                        if (this.estado.paciente.tipo == 2) {
                            if (oest.codigo == 13) fig.a = -25;         //6s
                            if (oest.codigo == 14) fig.a = 180 - 7;    //6i
                            if (oest.codigo == 15) fig.a = 20;         //1s
                            if (oest.codigo == 16) fig.a = 180 - 20;    //1i
                            if (oest.codigo == 17) fig.a = 18;         //1se
                            if (oest.codigo == 18) fig.a = 180 - 18;         //1ie
                            if (oest.codigo == 19) fig.a = 2;    //6se
                            if (oest.codigo == 20) fig.a = 180 - 35;         //6ie


                            //if (oest.tipoEstrutura == 1) fig.a = -68.30995332114531;
                            //if (oest.tipoEstrutura == 2) fig.a = 95.99132780182076;
                            //if (oest.tipoEstrutura == 3) fig.a = - 109.51761246591687;
                            //if (oest.tipoEstrutura == 4) fig.a = 109.04476483076795;
                            //if (oest.tipoEstrutura == 5) fig.a = - 89.30972280213493;
                            //if (oest.tipoEstrutura == 6) fig.a = 125.06110520372692;
                            //if (oest.tipoEstrutura == 7) fig.a = - 106.3742044759119;
                            //if (oest.tipoEstrutura == 8) fig.a = 108.27710960922013;

                            //fig.a = 0;

                        }

                        //console.log(angle(0, 0, 432.39093017578125, -1087.10009765625));
                        //console.log(angle(0, 0, -159.368408203125, 1518.5));
                        //console.log(angle(0, 0, -646.552734375, -1824.0264892578125));
                        //console.log(angle(0, 0, -572.0150146484375, 1657.04541015625));
                        //console.log(angle(0, 0, 15, - 1245));
                        //console.log(angle(0, 0, -899.861083984375, 1282.22216796875));
                        //console.log(angle(0, 0, -544.368408203125, -1852.684326171875));
                        //        console.log(angle(0, 0, -540, 1635));


                        if (oest.tipoEstrutura != 16) {
                            if (this.estado.paciente.tipo == 2)//nao sei pq no backend ta somando 20 neste valor
                                this.desenhaDente(oest.tipoEstrutura - 20, fig, onde);
                            else
                                this.desenhaDente(oest.tipoEstrutura, fig, onde);
                        }
                    }
                    //desenhas os pontos de controle
                    if (this.estado.ferramenta == 4) {
                        var fu = Math.floor((desenhos.length - 1) / 3);
                        for (var pt = 0; pt <= fu * 3; pt++) {
                            var resto = pt % 3;

                            if (pt <= desenhos.length - 2 && resto == 0 && oest.tipoEstrutura == 0) {
                                var p = desenhos[pt];

                                try {
                                    this.desenhaPonto(p.x / qProp, p.y / qProp, 3, 'blue');


                                } catch (e) {

                                }
                            }

                        }
                        //linhas da ponta

                        if (i == this.estado.estrAtual) {
                            if (oest.tipoEstrutura == 0) {
                                var menor = Math.floor(this.estado.bzAtual / 3) * 3;
                                if (menor == fu * 3)
                                    menor = menor - 3;
                                var p1 = desenhos[menor + 0];
                                var p2 = desenhos[menor + 1];
                                var p3 = desenhos[menor + 2];
                                var p4 = desenhos[menor + 3];

                                try {
                                    this.desenhaLinha(p1.x / qProp, p1.y / qProp, p2.x / qProp, p2.y / qProp, 'green');
                                    this.desenhaLinha(p3.x / qProp, p3.y / qProp, p4.x / qProp, p4.y / qProp, 'green');
                                    this.desenhaPonto(p2.x / qProp, p2.y / qProp, 3, 'blue');
                                    this.desenhaPonto(p3.x / qProp, p3.y / qProp, 3, 'blue');
                                    resto = 0;//para exibir
                                }
                                catch { }
                            }

                        }
                        //
                    }
                    //fim controles
                }
            }
        }
    }

    setDeciduo() {
        this.setGrupoDente(2);
    }

    setPermanente() {
        this.setGrupoDente(1);
    }

    setGrupoDente(grp) {
        this.estado.grupoDentes = grp;
        this.getDentes();
    }

    //----------------DESENHA DENTES
    desenhaDente(codigo, fig, onde) {
        const ctx = this.estado.ctx;
        var estruturas = this.estado.estruturas

        var to96 = this.estado.to96;
        const listaEstuturas = this.estado.listaEstuturas;
        var listaDentes = this.estado.listaDentes;


        var origem = estruturas;
        if (onde == 1)
            origem = this.estado.estruturasFoto;

        if (listaDentes.length == 0 || origem.length == 0)
            return;

        var iest = fig.c;
        var oest = listaEstuturas.find(e => e.codigo == iest);
        var estDes = estruturas.find(e => e.codigo == iest);
        if (estDes.visivel == false)
            return;
        var apice = origem.find(e => e.codigo == iest).desenhos[0];
        var incisal = origem.find(e => e.codigo == iest).desenhos[3];
        //if (fig.inv) {
        //incisal = origem.find(e => e.codigo == iest).desenhos[0];
        //apice = origem.find(e => e.codigo == iest).desenhos[3];
        //}

        var apiceRX = estruturas.find(e => e.codigo == iest).desenhos[0];
        var incisalRX = estruturas.find(e => e.codigo == iest).desenhos[3];

        if (apiceRX === undefined) {
            return;
        }
        if (incisalRX === undefined) {
            return;
        }

        var drx = CalcMedida(apiceRX.x, apiceRX.y, incisalRX.x, incisalRX.y);
        var dOri = CalcMedida(apice.x, apice.y, incisal.x, incisal.y);
        //esta como foto mas como ele pega do atual, serve para os dois
        var difFator = dOri / drx;

        var ang2 = 0;

        if (apice.x != 0 && incisal.x != 0) {
            ang2 = angle(apice.x, apice.y, incisal.x, incisal.y);
        }

        var lst = listaDentes.find(e => e.codigo == codigo);
        if (!lst)
            return;
        for (var k = 0; k < lst.estruturas.length; k++) {
            var objK = lst.estruturas[k];

            // angula dente
            var novaLista = new Array();
            for (var pt = 0; pt < objK.desenhos.length; pt++) {
                var p1 = objK.desenhos[pt];
                var px = { x: p1.x + apice.x, y: p1.y + apice.y };

                // if (p4) {
                var ang = angle(px.x, px.y, apice.x, apice.y);
                ang = ang + ang2 + fig.a;//+ AngDenteOriginal(k)

                var d = CalcMedida(px.x, px.y, apice.x, apice.y);


                var a = apice.x + d * difFator * Math.sin(ang / 180 * Math.PI)
                var b = apice.y - d * difFator * Math.cos(ang / 180 * Math.PI)
                var qq = { x: a, y: b };

                novaLista.push(qq);
                //}

            }
            // fim angula dente

            for (var pt = 0; pt < novaLista.length; pt += 3) {
                var p1 = novaLista[pt];
                if (pt == 0) {
                    ctx.strokeStyle = oest.corWeb;
                    ctx.beginPath();
                    ctx.moveTo((p1.x) / to96, (p1.y) / to96);
                }

                var p2 = novaLista[pt + 1];
                var p3 = novaLista[pt + 2];
                var p4 = novaLista[pt + 3];
                if (p2 && p3 && p4) {
                    //if ((p1.x + apice.x) <= 0 || (p2.x + apice.x) <= 0 || (p3.x + apice.x) <= 0 || (p4.x + apice.x) <= 0)
                    //    debugger;
                    ctx.bezierCurveTo((p2.x) / to96, (p2.y) / to96,
                        (p3.x) / to96, (p3.y) / to96,
                        (p4.x) / to96, (p4.y) / to96);
                }
            }
            ctx.stroke();
        }

        this.desenhaPonto96(apice.x, apice.y, 3, oest.corWeb);
        this.desenhaPonto96(incisal.x, incisal.y, 3, oest.corWeb);
    }

    desenhaFerramenta() {

        var pontos = this.estado.pontos;
        var anatomicos = this.estado.anatomicos;

        var ptAtual = this.estado.ptAtual;

        if (ptAtual == 0)
            return;

        var penL1 = 0;//new jxPen(new jxColor("yellow"), 1);
        var penL2 = 0;//new jxPen(new jxColor("red"), 1);
        //var gr = 0;

        //var toolL1 = new jxLine(new jxPoint(0, 0), new jxPoint(1, 1), penL1);
        //var toolL2 = new jxLine(new jxPoint(0, 0), new jxPoint(1, 1), penL2);
        //var toolL3 = new jxLine(new jxPoint(0, 0), new jxPoint(1, 1), penL1);
        //var toolL4 = new jxLine(new jxPoint(0, 0), new jxPoint(1, 1), penL2);
        //var toolL5 = new jxLine(new jxPoint(0, 0), new jxPoint(1, 1), penL2);
        //var toolL6 = new jxLine(new jxPoint(0, 0), new jxPoint(1, 1), penL2);
        //var toolL7 = new jxLine(new jxPoint(0, 0), new jxPoint(1, 1), penL2);
        //var toolArc = new jxArcSector(new jxPoint(0, 0), 11, 11, 0, 45, penL2);
        //var toolCirc = new jxCircle(new jxPoint(0, 0), 11, penL2);


        //ptAtual = pt;
        //objPtSel = objPontos[ptAtual];
        var objPtSel = anatomicos.find(el => el.id == ptAtual);

        //if (objPtSel.auxiliar != "" && objPtSel.auxiliar != "0")
        //    debugger;

        var ptAuxiliar1 = new Point(this.auxFerramenta[1].x, this.auxFerramenta[1].y);
        var ptAuxiliar2 = new Point(this.auxFerramenta[2].x, this.auxFerramenta[2].y);
        var ptAuxiliar3 = new Point(this.auxFerramenta[3].x, this.auxFerramenta[3].y);
        var ptAuxiliar4 = new Point(this.auxFerramenta[4].x, this.auxFerramenta[4].y);
        var ptAuxiliar5 = new Point(this.auxFerramenta[5].x, this.auxFerramenta[5].y);
        var ptAuxiliar6 = new Point(this.auxFerramenta[6].x, this.auxFerramenta[6].y);

        if (objPtSel.auxiliar != "" && objPtSel.auxiliar != "0") {

            if (this.auxFerramenta[0].x != ptAtual)//usei apenas para nao criar variavel - mudar depois
            {
                var pt1 = pontos.find(e => e.id == parseInt(objPtSel.pf1));
                var pt2 = pontos.find(e => e.id == parseInt(objPtSel.pf2));
                var pt3 = pontos.find(e => e.id == parseInt(objPtSel.pf3));
                var pt4 = pontos.find(e => e.id == parseInt(objPtSel.pf4));
                var pt5 = pontos.find(e => e.id == parseInt(objPtSel.pf5));
                var pt6 = pontos.find(e => e.id == parseInt(objPtSel.pf6));

                var fatZ = 67.5;


                var pernaAuxiliar = 0;

                for (var i = 0; i < 7; i++) {
                    this.auxFerramenta.push(new Point(0, 0));
                }


                if (pt1 && parseInt(objPtSel.pf1) != 0) {
                    ptAuxiliar1 = new Point(pt1.x + objPtSel.dxf1 * fatZ, pt1.y + objPtSel.dyf1 * fatZ);
                    //if (this.auxFerramenta[1].x > 0) ptAuxiliar1 = new Point(this.auxFerramenta[1].x, this.auxFerramenta[1].y);
                }
                if (pt2 && parseInt(objPtSel.pf2) != 0) {
                    ptAuxiliar2 = new Point(pt2.x + objPtSel.dxf2 * fatZ, pt2.y + objPtSel.dyf2 * fatZ);
                    //if (this.auxFerramenta[2].x > 0) ptAuxiliar2 = new Point(this.auxFerramenta[2].x, this.auxFerramenta[2].y);
                }
                if (pt3 && parseInt(objPtSel.pf3) != 0) {
                    ptAuxiliar3 = new Point(pt3.x + objPtSel.dxf3 * fatZ, pt3.y + objPtSel.dyf3 * fatZ);
                    //if (this.auxFerramenta[3].x > 0) ptAuxiliar3 = new Point(this.auxFerramenta[3].x, this.auxFerramenta[3].y);
                }
                if (pt4 && parseInt(objPtSel.pf4) != 0) {
                    ptAuxiliar4 = new Point(pt4.x + objPtSel.dxf4 * fatZ, pt4.y + objPtSel.dyf4 * fatZ);
                    //if (this.auxFerramenta[4].x > 0) ptAuxiliar4 = new Point(this.auxFerramenta[4].x, this.auxFerramenta[4].y);
                }
                if (pt5 && parseInt(objPtSel.pf5) != 0) {
                    ptAuxiliar5 = new Point(pt5.x + objPtSel.dxf5 * fatZ, pt5.y + objPtSel.dyf5 * fatZ);
                    //if (this.auxFerramenta[5].x > 0) ptAuxiliar5 = new Point(this.auxFerramenta[5].x, this.auxFerramenta[5].y);
                }
                if (pt6 && parseInt(objPtSel.pf6) != 0) {
                    ptAuxiliar6 = new Point(pt6.x + objPtSel.dxf6 * fatZ, pt6.y + objPtSel.dyf6 * fatZ);
                    //if (this.auxFerramenta[6].x > 0) ptAuxiliar6 = new Point(this.auxFerramenta[6].x, this.auxFerramenta[6].y);
                }

                //if (this.auxFerramenta[1].x == 0)
                this.auxFerramenta[1].x = ptAuxiliar1.x; this.auxFerramenta[1].y = ptAuxiliar1.y;
                //if (this.auxFerramenta[2].x == 0)
                this.auxFerramenta[2].x = ptAuxiliar2.x; this.auxFerramenta[2].y = ptAuxiliar2.y;
                //if (this.auxFerramenta[3].x == 0)
                this.auxFerramenta[3].x = ptAuxiliar3.x; this.auxFerramenta[3].y = ptAuxiliar3.y;
                // if (this.auxFerramenta[4].x == 0)
                this.auxFerramenta[4].x = ptAuxiliar4.x; this.auxFerramenta[4].y = ptAuxiliar4.y;
                // if (this.auxFerramenta[5].x == 0)
                this.auxFerramenta[5].x = ptAuxiliar5.x; this.auxFerramenta[5].y = ptAuxiliar5.y;
                // if (this.auxFerramenta[6].x == 0)
                this.auxFerramenta[6].x = ptAuxiliar6.x; this.auxFerramenta[6].y = ptAuxiliar6.y;

                this.auxFerramenta[0].x = ptAtual
            }
            else {
            }





        }

        //function desenhaAuxiliar() {

        if (objPtSel.auxiliar == "Reta" || objPtSel.auxiliar == "Reta Móvel" || objPtSel.auxiliar == "Reta Continua" || objPtSel.auxiliar == "Linha Paralela" || objPtSel.auxiliar == "Linha Paralela Móvel") {
            var d1 = 0;
            if (objPtSel.auxiliar == "Reta Móvel" || objPtSel.auxiliar == "Reta Continua" || objPtSel.auxiliar == "Linha Paralela" || objPtSel.auxiliar == "Linha Paralela Móvel")
                d1 = 120;
            var ptp1 = projeLinha(ptAuxiliar1, ptAuxiliar2, d1);
            var ptp2 = projeLinha(ptAuxiliar2, ptAuxiliar1, d1);

            this.desenhaLinha96(ptp1.x, ptp1.y, ptp2.x, ptp2.y, 'red');

        }
        if (objPtSel.auxiliar == "Linha Mediana") {
            var ptp1 = projeLinha(ptAuxiliar1, ptAuxiliar2, 0);
            var ptp2 = projeLinha(ptAuxiliar2, ptAuxiliar1, 0);

            this.desenhaLinha96(ptp1.x, ptp1.y, ptp2.x, ptp2.y, 'yellow');

            var ptp3 = pontoMedio2(ptp1, ptp2);
            var ptp4 = Perpendicular(ptp1, ptp2, ptp3);

            var d1 = (ptp3.x - ptp4.x) / 8;
            var d2 = (ptp3.y - ptp4.y) / 8;
            this.desenhaLinha96(ptp3.x - d1, ptp3.y - d2, ptp3.x + d1, ptp3.y + d2, 'red');
        }
        if (objPtSel.auxiliar == "Linha Paralela" || objPtSel.auxiliar == "Linha Paralela Móvel") {
            var ptp1 = projeLinha(ptAuxiliar1, ptAuxiliar2, 120);
            var ptp2 = projeLinha(ptAuxiliar2, ptAuxiliar1, 120);
            var ptp3 = Paralelo(ptp1.x, ptp1.y, ptp2.x, ptp2.y, ptAuxiliar3.x, ptAuxiliar3.y);

            var ptpa = projeLinha(ptAuxiliar3, ptp3, 120);
            var ptpb = projeLinha(ptp3, ptAuxiliar3, 120);
            this.desenhaLinha96(ptpa.x, ptpa.y, ptpb.x, ptpb.y, 'red');
        }
        if (objPtSel.auxiliar == "Linha Perpendicular") {
            var pMD = MenorDistancia(ptAuxiliar1, ptAuxiliar2, ptAuxiliar3);

            var ptp1 = projeLinha(ptAuxiliar1, ptAuxiliar2, 100);
            var ptp2 = projeLinha(ptAuxiliar2, ptAuxiliar1, 100);

            this.desenhaLinha96(ptp1.x, ptp1.y, ptp2.x, ptp2.y, 'yellow');

            var ptp3 = projeLinha(ptAuxiliar3, pMD, 100);
            var ptp4 = projeLinha(pMD, ptAuxiliar3, 100);

            this.desenhaLinha96(ptp3.x, ptp3.y, ptp4.x, ptp4.y, 'red');
        }
        if (objPtSel.auxiliar == "Linha Perp. Horz.") {
        }
        if (objPtSel.auxiliar == "Bissetriz") {
            var ptp1 = projeLinha(ptAuxiliar1, ptAuxiliar2, 0);
            var ptp2 = projeLinha(ptAuxiliar2, ptAuxiliar1, 0);
            var ptp3 = projeLinha(ptAuxiliar2, ptAuxiliar3, 0);
            var ptp4 = projeLinha(ptAuxiliar3, ptAuxiliar2, 0);

            this.desenhaLinha96(ptp1.x, ptp1.y, ptp2.x, ptp2.y, 'yellow');


            this.desenhaLinha96(ptp3.x, ptp3.y, ptp4.x, ptp4.y, 'yellow');

            var d1 = (CalcMedida(ptp2.x, ptp2.y, ptp1.x, ptp1.y)) / 2;

            var ptp5 = PosicaoMediaEntreAngulo(ptp1.x, ptp1.y, ptp2.x, ptp2.y, ptp4.x, ptp4.y, d1);
            this.desenhaLinha96(ptp2.x, ptp2.y, ptp5.x, ptp5.y, 'red');
        }
        if (objPtSel.auxiliar == "Bissetriz 4 Pontos") {
            var ptp1 = projeLinha(ptAuxiliar1, ptAuxiliar1, 0);
            var ptp2 = projeLinha(ptAuxiliar2, ptAuxiliar2, 0);
            var ptp3 = projeLinha(ptAuxiliar3, ptAuxiliar3, 0);
            var ptp4 = projeLinha(ptAuxiliar4, ptAuxiliar4, 0);

            var ptp5 = Interseccao(ptp1, ptp2, ptp3, ptp4);

            this.desenhaLinha96(ptp1.x, ptp1.y, ptp5.x, ptp5.y, 'red');


            this.desenhaLinha96(ptp5.x, ptp5.y, ptp4.x, ptp4.y, 'red');

            var d1 = (CalcMedida(ptp1.x, ptp1.y, ptp5.x, ptp5.y)) / 2;

            var ptp6 = PosicaoMediaEntreAngulo(ptp1.x, ptp1.y, ptp5.x, ptp5.y, ptp4.x, ptp4.y, d1);
            this.desenhaLinha96(ptp5.x, ptp5.y, ptp6.x, ptp6.y, 'yellow');
        }
        if (objPtSel.auxiliar == "Bissetriz 45 Graus") //Ok
        {
            var ptp1 = projeLinha(ptAuxiliar1, ptAuxiliar2, 0);
            var ptp2 = projeLinha(ptAuxiliar2, ptAuxiliar1, 0);

            this.desenhaLinha96(ptp1.x, ptp1.y, ptp2.x, ptp2.y, 'red');

            var ptp3 = rotateVector(ptp1, ptp2, -90);
            this.desenhaLinha96(ptp1.x, ptp1.y, ptp3.x, ptp3.y, 'red');

            var ptp4 = pontoMedio3(ptp1, ptp2, ptp3);
            this.desenhaLinha96(ptp1.x, ptp1.y, ptp4.x, ptp4.y, 'yellow');
        }
        if (objPtSel.auxiliar == "Circulo") {
            var ptp1 = projeLinha(ptAuxiliar1, ptAuxiliar2, 0);
            var ptp2 = projeLinha(ptAuxiliar2, ptAuxiliar1, 0);

            //toolCirc.center.x = ptp1.x;
            //toolCirc.center.y = ptp1.y;
            var d1 = (CalcMedida(ptp1.x, ptp1.y, ptp2.x, ptp2.y));
            this.desenhaCirculo(ptp1.x, ptp1.y, d1, 'yellow');
            //toolCirc.radius = d1;
            //toolCirc.draw(gr);

            this.desenhaLinha96(ptp1.x, ptp1.y, ptp2.x, ptp2.y, 'yellow');

        }
        if (objPtSel.auxiliar == "Meia Lua") {
            var ptp1 = projeLinha(ptAuxiliar1, ptAuxiliar2, 0);
            var ptp2 = projeLinha(ptAuxiliar2, ptAuxiliar1, 0);

            this.desenhaLinha96(ptp1.x, ptp1.y, ptp2.x, ptp2.y, 'yellow');

            var d1 = CalcMedida(ptp1.x, ptp1.y, ptp2.x, ptp2.y);

            var ang = Angulo(ptp1.x, ptp1.y, ptp2.x, ptp2.y);
            var a1 = 360 - ang - 45;
            var a2 = 360 - ang - 45;
            //if( a1 < 0)
            //    a1 = (360) + a1;
            //if( a2 > (360) )
            //    a2 = a2 - 360;



            //toolArc.width=d1;
            //toolArc.height=d1;
            //toolArc.arcAngle=250;
            //toolArc.startAngle=0;toolArc.center=new jxPoint(ptp1.x,ptp1.y);
            //toolArc.draw(gr);
            //toolArc=new jxArcSector(new jxPoint(ptp1.x,ptp1.y),d1,d1,0,180, pen);
            //toolArc.draw(gr);
            //if(!toolArc)
            //  toolArc=new jxArcSector(new jxPoint(ptp1.x,ptp1.y),d1,d1,a1,a2,penL1);
            //else
            //{ 

            //this.desenhaArco(ptp1.x / this.estado.to96, ptp1.y / this.estado.to96, d1 / this.estado.to96, 'red', a1, a2);
            this.desenhaCirculo(ptp1.x / this.estado.to96, ptp1.y / this.estado.to96, d1 / this.estado.to96, 'red');
            //toolArc.width = d1;
            //toolArc.height = d1;
            //toolArc.startAngle = a1;
            //toolArc.arcAngle = 90;
            //toolArc.center.x = ptp1.x; toolArc.center.y = ptp1.y;
            ////}
            //toolArc.draw(gr);
        }
        if (objPtSel.auxiliar == "Paquimetro") {
            var XP1 = ptAuxiliar1.x, YP1 = ptAuxiliar1.y;
            var XP2 = ptAuxiliar2.x, YP2 = ptAuxiliar2.y;
            //var XP3=ptAuxiliar3.x, YP3=ptAuxiliar3.y;
            //var XP4=ptAuxiliar4.x, YP4=ptAuxiliar4.y;
            //var XP5=ptAuxiliar5.x, YP5=ptAuxiliar5.y;
            //var XP6=ptAuxiliar6.x, YP6=ptAuxiliar6.y;

            //picInterno.ForeColor = corFerramenta2
            //DesenhaLinhaFerramenta XP1, YP1, XP2, YP2

            //toolL3.fromPoint = new jxPoint();
            //toolL3.toPoint = new jxPoint();
            //toolL3.draw(gr);
            this.desenhaLinha96(XP1, YP1, XP2, YP2, 'yellow');

            var tmp = Perpendicular(ptAuxiliar1, ptAuxiliar2, ptAuxiliar1);
            var tmp2 = { x: (tmp.x - XP1) / 2, y: (tmp.y - YP1) / 2 };
            //Ytmp = modCefCalculos.Perpendicular(XP1, YP1, XP2, YP2, XP1, YP1).y
            //Xtmp2 = (Xtmp - XP1) / 2
            //Ytmp2 = (Ytmp - YP1) / 2
            //picInterno.ForeColor = corFerramenta1
            this.desenhaLinha96(XP1 - tmp2.x, YP1 - tmp2.y, tmp.x - tmp2.x, tmp.y - tmp2.y, 'yellow');

            //DesenhaLinhaFerramenta XP1 - Xtmp2, YP1 - Ytmp2, Xtmp - Xtmp2, Ytmp - Ytmp2

            tmp = Perpendicular(ptAuxiliar1, ptAuxiliar2, ptAuxiliar2);
            tmp2 = { x: (tmp.x - XP2) / 2, y: (tmp.y - YP2) / 2 };
            //Ytmp = modCefCalculos.Perpendicular(XP1, YP1, XP2, YP2, XP2, YP2).y
            //Xtmp2 = (Xtmp - XP2) / 2
            //Ytmp2 = (Ytmp - YP2) / 2
            //DesenhaLinhaFerramenta XP2 - Xtmp2, YP2 - Ytmp2, Xtmp - Xtmp2, Ytmp - Ytmp2
            this.desenhaLinha96(XP2 - tmp2.x, YP2 - tmp2.y, tmp.x - tmp2.x, tmp.y - tmp2.y, 'yellow');

        }
        if (objPtSel.auxiliar == "Xi") {

            var XP1 = ptAuxiliar1.x, YP1 = ptAuxiliar1.y;
            var XP2 = ptAuxiliar2.x, YP2 = ptAuxiliar2.y;
            var XP3 = ptAuxiliar3.x, YP3 = ptAuxiliar3.y;
            var XP4 = ptAuxiliar4.x, YP4 = ptAuxiliar4.y;
            var XP5 = ptAuxiliar5.x, YP5 = ptAuxiliar5.y;
            var XP6 = ptAuxiliar6.x, YP6 = ptAuxiliar6.y;



            var tmp = Paralelo(XP5, YP5, XP6, YP6, XP1, YP1); //'Linha Paralela ao plano Frank saindo do ad2


            var tmp2 = MenorDistancia(new Point(XP1, YP1), tmp, new Point(XP4, YP4)); //'Canto Sup Esq

            var tmp3 = MenorDistancia(new Point(XP1, YP1), tmp, new Point(XP2, YP2)); //'Canto Sup Direito


            tmp = Paralelo(XP5, YP5, XP6, YP6, XP3, YP3); // 'Linha Paralela ao plano Frank saindo do ad2+


            var tmp4 = MenorDistancia(new Point(XP3, YP3), tmp, new Point(XP4, YP4)); //'Canto inf Esq


            var tmp5 = MenorDistancia(new Point(XP3, YP3), tmp, new Point(XP2, YP2)); //'Canto inf Direito


            XP1 = tmp2.x; YP1 = tmp2.y;
            XP2 = tmp3.x; YP2 = tmp3.y;
            XP3 = tmp4.x; YP3 = tmp4.y;
            XP4 = tmp5.x; YP4 = tmp5.y;


            //var ptp1=projeLinha(ptAuxiliar1,ptAuxiliar1,0);
            //var ptp2=projeLinha(ptAuxiliar2,ptAuxiliar2,0);
            //var ptp3=projeLinha(ptAuxiliar3,ptAuxiliar3,0);
            //var ptp4=projeLinha(ptAuxiliar4,ptAuxiliar4,0);





            var ptp5 = projeLinha(ptAuxiliar5, ptAuxiliar6, 100);
            var ptp6 = projeLinha(ptAuxiliar6, ptAuxiliar5, 100);
            //DesenhaLinhaFerramenta XP5, YP5, XP6, YP6, True 'Porio-orbitario 
            this.desenhaLinha96(ptp5.x, ptp5.y, ptp6.x, ptp6.y, 'red');




            //DesenhaLinhaFerramenta XP1, YP1, XP2, YP2
            this.desenhaLinha96(XP1, YP1, XP2, YP2, 'red');
            // toolL1.pen = penControle;



            //DesenhaLinhaFerramenta XP2, YP2, XP4, YP4
            this.desenhaLinha96(XP2, YP2, XP4, YP4, 'red');


            //DesenhaLinhaFerramenta XP4, YP4, XP3, YP3

            this.desenhaLinha96(XP4, YP4, XP3, YP3, 'red');



            //DesenhaLinhaFerramenta XP3, YP3, XP1, YP1
            this.desenhaLinha96(XP3, YP3, XP1, YP1, 'red');


            //DesenhaLinhaFerramenta XP1, YP1, XP4, YP4
            this.desenhaLinha96(XP1, YP1, XP4, YP4, 'red');


            //DesenhaLinhaFerramenta XP2, YP2, XP3, YP3
            this.desenhaLinha96(XP2, YP2, XP3, YP3, 'red');
        }

    }

    setFerramenta(ferr) {
        this.estado.ferramenta = ferr;
        this.redraw();
    }

    setFerramentaPadrao() {
        this.setFerramenta(0);
    }

    setFerramentaMedir() {
        this.setFerramenta(1);
    }

    setFerramentaCalibrar() {
        this.setFerramenta(2);
    }

    setFerramentaPonto() {
        this.setFerramenta(3);
        this.proximoPonto(0);

    }

    setFerramentaDesenho() {
        const paciente = this.estado.paciente;
        if (this.estado.estruturas.length == 0) {
            //alert('nao temos desenhos');
            this.getDesenhos(paciente.clinica, paciente.atd, paciente.tipo, true);
        }
        this.setFerramenta(4);
        this.redraw();
    }



    setFerramentaMover() {
        let pontosFoto = this.estado.pontosFoto;
        //let estruturasFoto = this.estado.estruturasFoto;
        //let ptMover = this.estado.ptMover;
        //let ptAngular = this.estado.ptAngular;

        if (this.estado.cdtFotoAtual.height == 0)
            return;
        if (this.estado.estruturas.length == 0) {
            return;
        }

        var existeMarcado = pontosFoto.find(e => e.x != 0 && e.y != 0);

        if (this.estado.pontosFoto.length == 0 || typeof existeMarcado == 'undefined')
            this.clonaPontos();
        else {
            if (this.estado.estruturasFoto[this.estado.estrCentral].desenhos[this.estado.desenhoCentral].x == 0 || this.estado.estruturasFoto[0].desenhos[0].x == 0) {
                this.estado.pontosFoto = new Array();
                this.clonaPontos();
            }
        }
        this.estado.opcoes.fusao = false;
        this.setFerramenta(5);
        this.estado.ptMover.x = this.estado.estruturasFoto[this.estado.estrCentral].desenhos[this.estado.desenhoCentral].x;
        this.estado.ptMover.y = this.estado.estruturasFoto[this.estado.estrCentral].desenhos[this.estado.desenhoCentral].y;
        this.estado.ptAngular.x = this.estado.estruturasFoto[0].desenhos[0].x;
        this.estado.ptAngular.y = this.estado.estruturasFoto[0].desenhos[0].y;
        this.redraw();
    }

    setOnPontoChange(evt) {
        this.onPontoChange = evt;
    }

    setOnGuiaChange(evt) {
        this.onGuiaChange = evt;
    }

    setOnIdentificacaoChange(evt) {
        this.onIdentificacaoChange = evt;
    }

    setOnLoadData(evt) {
        this.onLoadData = evt;
    }

    proximoPonto(v) {
        this.estado.ptAtual = this.findMarcarZerado(v);
        if (this.estado.ptAtual == 0) {
            this.estado.ptAtual = this.findMarcar(v);
            if (this.estado.ptAtual == 0) {
                alert('Nenhum ponto para marcar!');
            }
        }
        if (this.estado.ptAtual != 0) {
            this.setPontoAtual(this.estado.ptAtual);
            var ana = this.estado.anatomicos.find(el => el.id == this.estado.ptAtual);
            this.onPontoChange(ana.nome);
        }
    }

    setPontoAtual(codigo) {
        this.estado.ptAtual = codigo;
        var ana = this.estado.anatomicos.find(el => el.id == codigo);
        if (ana) {
            this.mostraAjuda(ana);
            if (this.estado.opcoes.som == true) {
                //    var audio = new Audio("mp3/fim.wav");
                //    audio.play();
                var audio = new Audio(this.estado._servCefx + "/api/web/arquivos/getAudio/" + this.estado.paciente.prefixo + "/" + ana.sigla);
                audio.play();
            }
        }
        this.redraw();
    }

    mostraAjuda(ana) {
        //disparar evento
        //document.getElementById("imgAjuda").src = _servCefx + '/s/HandleAjuda.ashx?i=1&p=' + ana.id + '&c=1&prefixo=' + paciente.prefixo;
    }


    findMarcarZerado(ini) {
        var pontos = this.estado.pontos;
        var anatomicos = this.estado.anatomicos;
        var pid = ini;
        while (pid < 200) {
            const prox = pontos.find(Element => Element.x == 0 && Element.y == 0 && Element.id > pid);
            if (prox) {
                const ana = anatomicos.find(element => element.id == prox.id);
                if (ana.selecionado) {
                    return ana.id;
                }
            }
            else {
                //const ana = anatomicos.find(element => element.id == 1);
                return 0;
            }
            pid++;
        }
    }

    findMarcar(ini) {
        var pontos = this.estado.pontos;
        var anatomicos = this.estado.anatomicos;
        var pid = ini;
        while (pid < 200) {
            const ana = anatomicos.find(element => element.id >= pid && element.selecionado);
            if (ana) {
                const prox = pontos.find(Element => Element.x == 0 && Element.y == 0 && Element.id == pid);
                if (!prox) {
                    return ana.id;
                }
            }
            else {
                const ana = anatomicos.find(element => element.id == 1);
                return 0;
            }
            pid++;
        }
    }


    addPonto(pid, px, py) {
        //if (this.estado.opcoes.imagem) 
        {
            const pontos = this.estado.pontos;
            var pt = pontos.find(p => p.id == pid);
            if (!pt) {
                pt = { id: pid, x: px, y: py };
                pontos.push(pt);
            }
            else {
                pt.x = px;
                pt.y = py;
            }
        }
        if (this.estado.opcoes.foto) {
            //
            const pontosF = this.estado.pontosFoto;
            var ptf = pontosF.find(p => p.id == pid);
            if (!ptf) {
                ptf = { id: pid, x: px, y: py };
                pontosF.push(ptf);
            }
            else {
                ptf.x = px;
                ptf.y = py;
            }

            //
        }
    }

    selecionaBezier(px, py, estr) {
        var menorqq = 1000000;
        for (var i = 0; i < estr.length; i++) {
            var oest = this.estado.listaEstuturas.find(e => e.codigo == estr[i].codigo);
            if (estr[i].visivel && oest.TipoEstrutura != 0) {
                var desenhos = estr[i].desenhos;
                var fu = Math.floor((desenhos.length - 1) / 3);
                for (var pt = 0; pt <= fu * 3; pt++) {
                    var p = desenhos[pt];
                    var p3 = desenhos[pt + 3];
                    if (p && p3) {
                        //calcula a quantidade de pontos para a funcao Bezier4
                        var qntPontos = 0;
                        if (Math.abs(p.x - p3.x) > Math.abs(p.y - p3.y))
                            qntPontos = Math.abs(p.x - p3.x) / 5;
                        else
                            qntPontos = Math.abs(p.y - p3.y) / 5;

                        for (var j = 0; j < qntPontos; j += 1) {
                            var pf = Bezier4(p, desenhos[pt + 1], desenhos[pt + 2], p3, j / qntPontos);
                            var qq = CalcMedida(px, py, pf.x, pf.y);
                            if (qq < menorqq)
                                menorqq = qq;
                            if (qq <= 16) {
                                this.estado.estrAtual = i;
                                this.estado.bzAtual = pt;
                                //des = item;
                                //return des;
                            }
                        }
                    }
                }
            }
        }
        //console.log(menorqq);
    }

    findBezier(px, py, estr) {
        this.estado.estrAtual = -1;
        this.estado.bzAtual = -1;
        var menor = 100;
        for (var i = 0; i < estr.length; i++) {
            var oest = this.estado.listaEstuturas.find(e => e.codigo == estr[i].codigo);
            if (estr[i].visivel && oest.TipoEstrutura != 0) {
                var desenhos = estr[i].desenhos;
                var fu = Math.floor((desenhos.length - 1) / 3);
                for (var pt = 0; pt <= fu * 3; pt++) {
                    var p = desenhos[pt];
                    var mm = CalcMedida(px, py, p.x, p.y);
                    if (mm < menor) {
                        this.estado.estrAtual = i;
                        this.estado.bzAtual = pt;
                        menor = mm;


                    }
                }
            }
        }
        if (menor == 100)
            return this.selecionaBezier(px, py, estr);

    }

    findPontoXY(px, py) {
        var isel = -1;
        var menor = 100;
        for (var i = 0; i < this.estado.pontos.length; i++) {
            var p = this.estado.pontos[i];
            var mm = CalcMedida(px, py, p.x, p.y);
            if (mm < menor) {
                isel = i;
                menor = mm;
            }
        }
        if (isel >= 0) {
            this.setPontoAtual(this.estado.pontos[isel].id);
            var ana = this.estado.anatomicos.find(el => el.id == this.estado.ptAtual);
            this.onPontoChange(ana.nome);

        }
    }


    copiarPontosTemp() {
        this.estado.estruturaCopiar = [];
        for (var j = 0; j < this.estado.estruturasFoto.length; j++) {
            var estr = this.estado.estruturasFoto[j];
            var estr2 = {};
            estr2.desenhos = new Array();
            for (var i = 0; i < estr.desenhos.length; i++) {
                var ptFoto = estr.desenhos[i];
                var nFoto = {};
                nFoto.x = ptFoto.x;
                nFoto.y = ptFoto.y;
                estr2.desenhos.push(nFoto);
            }
            this.estado.estruturaCopiar.push(estr2);
        }
        this.estado.pontosCopiar = [];
        for (var i = 0; i < this.estado.pontosFoto.length; i++) {
            var ptFoto = this.estado.pontosFoto[i];
            this.estado.pontosCopiar.push({ x: ptFoto.x, y: ptFoto.y });
        }
        //ptMover.x = estruturasFoto[this.estado.estrCentral].desenhos[this.estado.desenhoCentral].x;
        //ptMover.y = estruturasFoto[this.estado.estrCentral].desenhos[this.estado.desenhoCentral].y;
        //ptAngular.x = estruturasFoto[0].desenhos[0].x;
        //ptAngular.y = estruturasFoto[0].desenhos[0].y;
    }

    clonaPontos() {

        this.estado.pontosFoto = [];
        for (var i = 0; i < this.estado.pontos.length; i++) {
            var ptFoto = {};
            ptFoto.id = this.estado.pontos[i].id;
            ptFoto.x = this.estado.pontos[i].x;
            ptFoto.y = this.estado.pontos[i].y;
            this.estado.pontosFoto.push(ptFoto);
        }

        this.estado.estruturasFoto = [];
        for (var j = 0; j < this.estado.estruturas.length; j++) {
            var estr = this.estado.estruturas[j];
            var novaEstr = {};
            novaEstr.codigo = estr.codigo;
            novaEstr.desenhos = new Array();
            novaEstr.visivel = estr.visivel;
            for (var k = 0; k < estr.desenhos.length; k++) {
                var ptDesF = {};
                ptDesF.id = estr.desenhos[k].id;
                ptDesF.x = estr.desenhos[k].x;
                ptDesF.y = estr.desenhos[k].y;
                novaEstr.desenhos.push(ptDesF);
            }
            this.estado.estruturasFoto.push(novaEstr);
        }
        //this.load_IAFoto();
    }

    setBrilho(v) {
        this.estado.brilho = v;
        this.redraw();
    }

    setContraste(v) {
        this.estado.contraste = v;
        this.redraw();
    }

    setTranspFusao(v) {
        this.estado.opcoes.transpFusao = v / 100.0;
        this.redraw();
    }

    ligaSom(value) {
        this.estado.opcoes.som = value
    }

    //chamadas backend

    paciData(minimo) {
        const pontos = this.estado.pontos;
        const pontosFoto = this.estado.pontosFoto;
        const estruturas = this.estado.estruturas;
        const estruturasFoto = this.estado.estruturasFoto;
        const paciente = this.estado.paciente;
        var ncefs = "";

        localStorage.setItem("lastVisit", new Date().getTime().toString());

        //var novosPontos = new Array();
        for (var i = 0; i < pontos.length; i++) {
            var nvpt = pontos[i];
            var nvptf = pontosFoto[i];
            if (nvptf) {
                nvpt.xf = nvptf.x;
                nvpt.yf = nvptf.y;
            }
        }

        for (var j = 0; j < estruturas.length; j++) {
            var estfoto = estruturasFoto[j];
            for (var i = 0; i < estfoto.desenhos.length; i++) {
                var npt = estruturas[j].desenhos[i];
                var ptf = estruturasFoto[j].desenhos[i];
                if (npt && ptf) {
                    npt.xf = ptf.x;
                    npt.yf = ptf.y;
                }
                else {
                    //debugger;
                }
            }
        }

        for (var k = 0; k < this.estado.listaAnalises.length; k++) {
            var oana = this.estado.listaAnalises[k];

            if (oana.selecionada) {
                var fmtd = "000" + oana.codigo + "|";
                fmtd = fmtd.substr(fmtd.length - 4);
                ncefs += fmtd;
            }
        }

        if (this.PrecisaSalvar == false && minimo == true)//post minimo
        {
            var paciM = {
                clinica: paciente.clinica,
                atd: paciente.atd,
                tipo: paciente.tipo,
                RXFoto: this.estado.paciente.RXFoto,
                grupoDentes: this.estado.grupoDentes,
                cefs: ncefs,
                dados: "carregar"
            };
            return paciM;
        }

        var paci = {
            clinica: paciente.clinica,
            atd: paciente.atd,
            tipo: paciente.tipo,
            pts: pontos,
            ptsFoto: pontosFoto,
            estruturas: estruturas,
            estruturasFoto: estruturasFoto,
            calibragem: this.estado.calib,
            aparelho: this.estado.aparelho,
            limpouPontos: this.limpouPontos,
            limpouDesenhos: this.limpouDesenhos,
            RXFoto: this.estado.paciente.RXFoto,
            grupoDentes: this.estado.grupoDentes,
            cefs: ncefs,
            espessura: 0,
            dados: "salvar"
        };
        return paci;
    }

    setCalibragem(v) {
        this.setCalibragemAparelho(v, 0);
    }

    setCalibragem(v, naparelho) {
        var pontos = this.estado.pontos;
        var estruturas = this.estado.estruturas;
        var cali = this.estado.calib;
        var estruturasFoto = this.estado.estruturasFoto;

        for (var id = 0; id < this.estado.linhas.length; id++) {
            this.estado.linhas[id].x1 = this.estado.linhas[id].x1 / cali * v;
            this.estado.linhas[id].x2 = this.estado.linhas[id].x2 / cali * v;
            this.estado.linhas[id].y1 = this.estado.linhas[id].y1 / cali * v;
            this.estado.linhas[id].y2 = this.estado.linhas[id].y2 / cali * v;
        }


        for (var i = 0; i < pontos.length; i++) {
            this.estado.pontos[i].x = pontos[i].x / cali * v;
            this.estado.pontos[i].y = pontos[i].y / cali * v;
        }

        for (var j = 0; j < estruturas.length; j++) {
            var estfoto = estruturasFoto[j];
            for (var k = 0; k < estfoto.desenhos.length; k++) {
                //var npt = estruturas[j].desenhos[k];
                //var ptf = estruturasFoto[j].desenhos[k];

                this.estado.estruturas[j].desenhos[k].x = estruturas[j].desenhos[k].x / cali * v;
                this.estado.estruturas[j].desenhos[k].y = estruturas[j].desenhos[k].y / cali * v;

                this.estado.estruturasFoto[j].desenhos[k].x = estruturasFoto[j].desenhos[k].x / cali * v;
                this.estado.estruturasFoto[j].desenhos[k].y = estruturasFoto[j].desenhos[k].y / cali * v;
            }
        }



        this.estado.calib = v;
        this.estado.fotoCali = v;
        this.redraw();
    }

    salvarCefx(setGetResultSalvation, setLoadingSalvation) {
        const isto = this;
        setLoadingSalvation(true)
        $.post(this.estado._servCefx + "/api/web/pontos/salvarPontos", this.paciData(false), function (data, textStatus) {
            setGetResultSalvation(textStatus)
            setLoadingSalvation(false)
            isto.PrecisaSalvar = false;
            //data contains the JSON object
            //textStatus contains the status: success, error, etc
        }, "json");
    }

    // printCefX() {
    //     //funciona bem
    //     /*
    //     const dataUrl = canvas.toDataURL();
    //     document.getElementById("imagemSaida").src = dataUrl;
    //     printMe(document.getElementById("divImpressao"));
    //     */

    //     this.onLoadData('loading');

    //     var sels = "0";
    //     for (var i = 0; i < this.estado.listaAnalises.length; i++) {
    //         if (this.estado.listaAnalises[i].selecionada)
    //             sels += "-" + this.estado.listaAnalises[i].codigo;
    //     }
    //     //var paci = { clinica: paciente.clinica, atd: paciente.atd, tipo: paciente.tipo, pts: pontos, estruturas: estruturas, calibragem: cali };

    //     $.post(this.estado._servCefx + "/api/web/pontos/imprimir" + sels + "/1-2", this.paciData(), function (data, textStatus) {

    //         //jeito novo - deu certo - implementar o botao fechar
    //         //elm.innerHTML = "<div style='position:absolute;top:0;left:0;width:500px;height:650px'><embed src='/laudos/" + paciente.clinica + "/" + paciente.atd +"/cefx.pdf/' width='500' height='375' type = 'application/pdf' ></div>";

    //         this.onLoadData('relatorio');

    //         //data contains the JSON object
    //         //textStatus contains the status: success, error, etc
    //     }, "json");

    // }

    // load_IA() {
    //     const isto = this;
    //     const toastId = <ModalLoadingProcessIa isOpen={true} onRequestClose={() => { }} />

    //     $.post(this.estado._servCefx + "/api/web/pontos/load_AI_Points", this.estado.paciente,
    //         function (json) {
    //             isto.processarPontos(json, false);
    //             isto.redraw();
    //             isto.onLoadData('pontos');

    //             toast.dismiss(toastId);
    //         })
    //         .fail(function () {
    //             setTimeout(() => {
    //                 toast.dismiss(toastId);
    //             }, 500);
    //         });
    // }

    load_IA(callback) {
        const isto = this;

        $.post(this.estado._servCefx + "/api/web/pontos/load_AI_Points", this.estado.paciente,
            function (json) {
                isto.processarPontos(json, false);
                isto.redraw();
                isto.onLoadData('pontos');

                if (callback) callback();
            })
            .fail(function () {
                setTimeout(() => {
                    if (callback) callback();
                }, 500);
            });
    }

    load_IAFoto() {
        const isto = this;
        const toastId = toast.loading('Processando... CEFX está marcando os pontos', {
            position: "top-center",
            style: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
            },
        });

        $.post(this.estado._servCefx + "/api/web/pontos/load_AI_Points/fpd", this.estado.paciente,
            function (json) {
                isto.processarPontos(json, true);
                isto.redraw();
                isto.onLoadData('pontos');

                toast.dismiss(toastId);
            })
            .fail(function () {
                setTimeout(() => {
                    toast.dismiss(toastId);
                }, 500);
            });
    }

    novoAparelho(nomeAparelho) {
        var novo = {
            CodigoAparelho: 0,
            NomeAparelho: nomeAparelho,
            Mensagem: ' ',
            Magnificacao: 0,
            FatorCalibracao: this.estado.calib,
        };

        $.post(this.estado._servCefx + "/api/web/aparelho/AddAparelho/", novo,
            function (json) {
            });
    }

    mostrarModalIa(value) {
        if (this.callbackMostrarModalIa) {
            this.callbackMostrarModalIa(value); 
        }
    }

    setMostrarModalIaCallback(callback) {
        this.callbackMostrarModalIa = callback; 
    }

    carregarPaciente(clinica, atd, tipo, analise) {//nao deveria ter essa analise como parametro pq é a primeira chamada
        const isto = this;
        $.post(this.estado._servCefx + "/api/web/pontos/CarregarPaciente/" + analise, this.estado.paciente,
            function (json) {
                isto.estado.cefs = json.cefs;
                if (isto.estado.cefs == null)
                    isto.estado.cefs = "024|";
                isto.processarPontos(json.Pts, false);
                isto.processaPontosAnalise(json.Anatomicos);
                isto.processaEstruturas(json.Estruturas);
                isto.processaDentes(json.Dentes);
                isto.processaAnalises(json.Analises);
                isto.processaDesenhos(json.Desenhos);
                isto.redraw();
                document.getElementById("spnLoading").innerHTML = "";
                if (json.DataFileExists == "S")
                    isto.PrecisaSalvar = false;
                else
                    isto.PrecisaSalvar = true;
                if (json.Pts.length == 0 && tipo == 1) {
                    isto.mostrarModalIa(true)
                } else {
                    isto.mostrarModalIa(false)
                }
            }).fail(function (xhr, textStatus, errorThrown) {
                document.getElementById("spnLoading").innerHTML = "Erro!";
                alert('Erro: servidor indisponivel');// + xhr.responseText
            });
    }

    CarregarPontosIa(callback) {
        this.load_IA(callback);
    }

    getPontosAnalises(clinica, tipo) {
        const listaAnalises = this.estado.listaAnalises;
        //const anatomicos = this.estado.anatomicos;
        const isto = this;

        var sels = "0";
        for (var i = 0; i < listaAnalises.length; i++) {
            if (listaAnalises[i].selecionada)
                sels += "-" + listaAnalises[i].codigo;
        }
        $.getJSON(this.estado._servCefx + "/api/web/pontos/getAnatomicos/" + tipo + '/' + this.estado.paciente.prefixo + '/' + sels + "/" + this.estado.paciente.RXFoto,
            function (registro) {
                isto.processaPontosAnalise(registro);
            });

    }

    processaPontosAnalise(registro) {
        this.estado.anatomicos.splice(0, this.estado.anatomicos.length);

        for (var val = 0; val < registro.length; val++) {
            var ptn = {
                id: registro[val].id,
                nome: registro[val].nome,
                sigla: registro[val].sigla,
                auxiliar: registro[val].ferramenta,
                pf1: registro[val].pontoF1,
                pf2: registro[val].pontoF2,
                pf3: registro[val].pontoF3,
                pf4: registro[val].pontoF4,
                pf5: registro[val].pontoF5,
                pf6: registro[val].pontoF6,
                dxf1: registro[val].dxf1,
                dyf1: registro[val].dyf1,
                dxf2: registro[val].dxf2,
                dyf2: registro[val].dyf2,
                dxf3: registro[val].dxf3,
                dyf3: registro[val].dyf3,
                dxf4: registro[val].dxf4,
                dyf4: registro[val].dyf4,
                dxf5: registro[val].dxf5,
                dyf5: registro[val].dyf5,
                dxf6: registro[val].dxf6,
                dyf6: registro[val].dyf6,
                selecionado: registro[val].selecionado
            };
            this.estado.anatomicos.push(ptn)
        }
        // geraTabelaPontos();
        // geraTabelaImagens();
        // redraw();
        this.primeiroPaint();
        this.onLoadData('pontos');
    }

    //getPontos(clinica, atd, tipo) {

    //    const isto = this;

    //    $.post(this.estado._servCefx + "/api/web/pontos/Carregar", this.estado.paciente,
    //        function (json) {
    //            isto.processarPontos(json);
    //        });

    //}//getpontos

    processarPontos(json, nafoto) {
        //var cali = this.estado.calib;
        //var to96 = this.estado.to96;
        const pontos = this.estado.pontos;
        const pontosFoto = this.estado.pontosFoto;
        //lista = json;
        //Append each row to html table  
        for (var i = 0; i < json.length; i++) {
            if (nafoto == false) {
                var pt = {};
                pt.id = json[i].ponto;
                pt.x = json[i].x;
                pt.y = json[i].y;
                this.estado.calib = json[i].calibragem;
                if (this.estado.calib == 0)
                    this.estado.calib = 1.0;

                //auxiliar = json[i].ferramenta;


                this.estado.to96 = this.estado.calib * 15;
                this.estado.to96 = 15;//estou redimensionando a imagem
                pontos.push(pt)


                var ptf = {};
                ptf.id = json[i].ponto;
                ptf.x = json[i].xf;
                ptf.y = json[i].yf;
                pontosFoto.push(ptf);
            }
            else {
                var nnn = pontosFoto.find(e => e.id == json[i].ponto);
                if (nnn) {
                    nnn.x = json[i].x;
                    nnn.y = json[i].y;
                }
            }

        }
        //ctx.setTransform(1, 0, 0, 1, 0, 0);
        //var ix = (canvas.height * cali) / (cdtImagemAtual.height * cali);
        //ctx.scale(  ix/cali,   ix/cali);
        //this.redraw();
        //carregaImagemAtual();
        this.redimensionamentoInicial();
        this.primeiroPaint();
        this.onLoadData('pontosPaciente');
        this.onLoadData('imagens');
    }

    getDesenhos(clinica, atd, tipo, gerar) {


        const isto = this;

        var url = this.estado._servCefx + "/api/web/pontos/CarregarDesenhos";
        if (gerar)
            url = this.estado._servCefx + "/api/web/pontos/GerarDesenhos";

        $.post(url, this.paciData(true),
            function (json) {
                isto.processaDesenhos(json);
                isto.PrecisaSalvar = false;
            });

    }//get desenhos

    getDesenhosEstrutura(clinica, atd, tipo, estru) {
        const isto = this;

        var url = this.estado._servCefx + "/api/web/pontos/GerarDesenhos/" + estru;

        $.post(url, this.paciData(true),
            function (json) {
                isto.processaDesenhos(json);
                isto.PrecisaSalvar = false;
            });

    }//get desenhos

    processaDesenhos(json) {

        //var cali = 0;
        //var to96 = 0;

        //const pontos = this.estado.pontos;
        //const pontosFoto = this.estado.pontosFoto;

        //const estruturas = this.estado.estruturas;
        //const estruturasFoto = this.estado.estruturasFoto;
        //const paciente = this.estado.paciente;
        // lista = json;
        var ant = 0;

        for (var i = 0; i < json.length; i++) {
            if (json[i].estrutura != ant) {
                var qestr = this.estado.listaEstuturas.find(e => e.codigo == json[i].estrutura);
                var est = {}
                est.codigo = json[i].estrutura;
                est.desenhos = new Array();
                est.visivel = qestr.mostraRX;
                this.estado.estruturas.push(est);

                var est2 = {}
                est2.codigo = json[i].estrutura;
                est2.desenhos = new Array();
                est2.visivel = qestr.mostraRX;
                this.estado.estruturasFoto.push(est2);
            }

            var estDes = this.estado.estruturas.find(e => e.codigo == json[i].estrutura);
            //var ultimo = this.estado.estruturas.find(p=>p // this.estado.estruturas.length - 1;
            //tele
            var pt = {};
            pt.ponto = json[i].ponto;
            pt.estrutura = json[i].estrutura;
            pt.x = json[i].x;
            pt.y = json[i].y;
            if (pt.x <= 0 || pt.y <= 0)
                estDes.visivel = false;
            estDes.desenhos.push(pt);
            //fim tele

            var estDesF = this.estado.estruturasFoto.find(e => e.codigo == json[i].estrutura);
            //foto
            var pt2 = {};
            pt2.ponto = json[i].ponto;
            pt2.estrutura = json[i].estrutura;
            pt2.x = json[i].xf;
            pt2.y = json[i].yf;
            if (pt2.x == undefined)
                pt2.x = 0;
            if (pt2.y == undefined)
                pt2.y = 0;
            if (pt2.x <= 0 || pt2.y <= 0 || (estDesF.visivel == false))
                estDesF.visivel = false;
            estDesF.desenhos.push(pt2);
            //fim foto

            ant = json[i].estrutura;
        }
        //geraTabelaEstruturas();
        this.redraw();
        this.onLoadData('estruturas');
        //this.primeiroPaint();
    }


    getListaEstruturas() {
        const paciente = this.estado.paciente;
        const isto = this;
        $.post(this.estado._servCefx + "/api/web/pontos/getEstruturas/" + paciente.tipo + "/" + paciente.prefixo, paciente,
            function (json) {
                isto.processaEstruturas(json)
            });

    }//lista estruturas

    processaEstruturas(json) {

        const listaEstuturas = this.estado.listaEstuturas;
        this.estado.listaEstuturas.splice(0, this.estado.listaEstuturas.length);
        //var tr;
        //Append each row to html table  
        for (var i = 0; i < json.length; i++) {
            var pt = {};
            pt.codigo = json[i].Codigo;
            pt.nome = json[i].Nome;
            pt.cor = json[i].Cor;
            pt.corWeb = json[i].CorWeb;

            pt.estilo = json[i].Estilo;
            pt.espessura = json[i].Espessura;
            pt.mostraRX = json[i].MostraRX;
            pt.mostraFoto = json[i].MostraFoto;
            pt.mostraFusao = json[i].MostraFusao;
            pt.tipoEstrutura = json[i].TipoEstrutura;
            listaEstuturas.push(pt)
        }
        //geraTabelaEstruturas();
        this.primeiroPaint();
        this.onLoadData('estruturas');
    }

    getAnalises() {

        const paciente = this.estado.paciente;
        //const isto = this;

        $.post(this.estado._servCefx + "/api/web/pontos/getAnalises/" + paciente.tipo + "/" + paciente.prefixo, paciente,
            function (json) {
                this.processaAnalises(json);
            });

    }

    processaAnalises(json) {
        const listaAnalises = this.estado.listaAnalises;
        this.estado.listaAnalises.splice(0, this.estado.listaAnalises.length);
        //listaAnalises = json;
        for (var i = 0; i < json.length; i++) {
            var oana = {};
            oana.codigo = json[i].Codigo;
            oana.nome = json[i].Nome;
            var fmtd = "000" + oana.codigo + "|";
            fmtd = fmtd.substr(fmtd.length - 4);

            if (this.estado.cefs.indexOf(fmtd) >= 0) {
                oana.selecionada = true;
            }

            else
                oana.selecionada = false;

            listaAnalises.push(oana)

        }
        // geraTabelaAnalises();
        this.primeiroPaint();
        this.onLoadData('analises');
        //this.onLoadData('pontos');
    }

    getDentes() {

        const paciente = this.estado.paciente;
        const isto = this;
        $.post(this.estado._servCefx + "/api/web/pontos/getDentes/" + paciente.tipo + "/" + paciente.prefixo + "/" + this.estado.grupoDentes, paciente,
            function (json) {
                isto.processaDentes(json);
                isto.redraw();
            });

    }


    processaDentes(json) {
        const listaDentes = this.estado.listaDentes;
        this.estado.listaDentes.splice(0, this.estado.listaDentes.length);
        for (var i = 0; i < json.length; i++) {
            var den = {};
            den.codigo = json[i].codigo;
            den.estruturas = new Array();
            var old = -1;
            for (var j = 0; j < json[i].desenhos.length; j++) {
                if (json[i].desenhos[j].estrutura !== old) {
                    var estrutura = {};
                    estrutura.codigo = json[i].desenhos[j].estrutura;
                    estrutura.desenhos = new Array();
                    den.estruturas.push(estrutura);
                    old = json[i].desenhos[j].estrutura;
                }
                var pt = {};
                pt.estrutura = json[i].desenhos[j].estrutura;
                pt.x = json[i].desenhos[j].x;
                pt.y = json[i].desenhos[j].y;
                //if(pt.x>0 && pt.y>0)
                den.estruturas[den.estruturas.length - 1].desenhos.push(pt);
            }
            listaDentes.push(den);
        }
        this.primeiroPaint();
        //isto.onLoadData({ tp: 'dentes', lista: listaDentes });
    }

    verificaMarcados() {
        for (var i = 0; i < this.estado.anatomicos.length; i++) {
            const idp = this.estado.anatomicos[i].id;
            this.estado.anatomicos[i].marcado = false;
            var pm = this.estado.pontos.find(e => e.id === idp);
            if (pm && pm.x !== 0 && pm.y !== 0)
                this.estado.anatomicos[i].marcado = true;
        }

    }

    getList(tp) {
        if (tp === 'pontos') {
            this.verificaMarcados();
            return this.estado.anatomicos;
        }
        if (tp === 'pontosPaciente') {
            return this.estado.pontos;
        }
        if (tp === 'estruturas') return this.estado.listaEstuturas;
        if (tp === 'analises') return this.estado.listaAnalises;
        if (tp === 'imagens') return this.getListaImagens();
    }

    getListaImagens() {
        const paciente = this.estado.paciente;
        var lstImagens = [];
        for (var i = 1; i < 9; i++) {
            var img = {
                src: '/s/HandleAjuda.ashx?tpi=' + i + "&atd=" + paciente.atd + "&clinica=" + paciente.clinica + "&tw=200&th=200",
                atd: paciente.atd,
                cli: paciente.clinica,
                onclick: 'selecionaImagem(' + i + ');',
                alvo: 1,
                id: i

            }
            lstImagens.push(img);
            if (i <= 3) {
                var img2 = {
                    src: '/s/HandleAjuda.ashx?tpi=' + (i + 17) + "&atd=" + paciente.atd + "&clinica=" + paciente.clinica + "&tw=200&th=200",
                    atd: paciente.atd,
                    cli: paciente.clinica,
                    onclick: 'selecionaFoto(' + i + ');',
                    alvo: 2,
                    id: i
                }
                lstImagens.push(img2);
            }
            else {
                lstImagens.push({
                    src: '/images/brilho.png',
                    atd: paciente.atd,
                    cli: paciente.clinica,
                    onclick: 'return;',
                    alvo: 2,
                    id: i
                });
            }
            if (i <= 2) {
                var img3 = {
                    src: '/s/HandleAjuda.ashx?tpi=' + (i + 17) + "&atd=" + paciente.atd + "&clinica=" + paciente.clinica + "&tw=200&th=200",
                    atd: paciente.atd,
                    cli: paciente.clinica,
                    onclick: 'selecionaFusao(' + i + ');',
                    alvo: 3,
                    id: i

                }
                lstImagens.push(img3);
            }
            else {
                lstImagens.push({
                    src: '/images/brilho.png',
                    atd: paciente.atd,
                    cli: paciente.clinica,
                    onclick: 'return;',
                    alvo: 2,
                    id: i
                });
            }
        }
        return lstImagens;
    }

    getTracado(analise, setLoadingGetTracado) {
        setLoadingGetTracado(true);
        //const paciente = this.estado.paciente;
        const isto = this;

        $.post(this.estado._servCefx + "/api/web/pontos/getTracado/" + analise, this.paciData(true),
            function (json) {
                //debugger;
                isto.estado.tracado = [];
                for (var i = 0; i < json.length; i++) {
                    var ln = {};
                    ln.id = json[i].ponto;
                    ln.cor = json[i].CorWeb;
                    ln.x1 = json[i].x1;
                    ln.y1 = json[i].y1;
                    ln.x2 = json[i].x2;
                    ln.y2 = json[i].y2;
                    isto.estado.tracado.push(ln)
                }
                isto.redraw();
                setLoadingGetTracado(false);
                isto.PrecisaSalvar = false;
            });
    }

    // toggleSelEstruturas(iest) {
    //     var oest = this.estado.listaEstuturas.find(e => e.codigo == iest);
    //     oest.selecionada = (!oest.selecionada);

    //     this.redraw();
    // }

    toggleSelEstruturas(cod) {
        var an = this.estado.estruturas.find(e => e.codigo === cod);
        if (an) {
            an.visivel = (!an.visivel);
            var anF = this.estado.estruturasFoto.find(e => e.codigo === cod);
            if (anF)
                anF.vi = an.visivel;
        }
        //getPontosAnalises(paciente.clinica, paciente.tipo);
        this.redraw();
    }

    ligarSelEstruturas(cod, ligado) {
        var an = this.estado.estruturas.find(e => e.codigo === cod);
        if (an) {
            an.visivel = (ligado);
            var anF = this.estado.estruturasFoto.find(e => e.codigo === cod);
            if (anF)
                anF.vi = an.visivel;
        }
        //getPontosAnalises(paciente.clinica, paciente.tipo);
        this.redraw();
    }

    toggleSelAnalise(cod) {
        var an = this.estado.listaAnalises.find(e => e.codigo === cod);
        an.selecionada = (!an.selecionada);
        this.getPontosAnalises(this.estado.paciente.clinica, this.estado.paciente.tipo);
    }

    ligarSelAnalise(cod, ligado) {
        var an = this.estado.listaAnalises.find(e => e.codigo === cod);
        an.selecionada = ligado;
        //this.getPontosAnalises(this.estado.paciente.clinica, this.estado.paciente.tipo);
    }

    printCefX(sels, relSele, setLoading, setGetClinica, setGetAtend, setStatus, setGetJsonData) {
        const isto = this;
        const clinica = this.estado.paciente.clinica;
        const atd = this.estado.paciente.atd;

        setLoading(true);

        isto.onLoadData('loading');

        $.post(this.estado._servCefx + "/api/web/pontos/imprimir/" + sels + "/" + relSele, this.paciData(false), function (data, textStatus) {
            if (data.indexOf('Erro:') > 0 || data.indexOf('erro:') > 0) {
                alert(data);
            } else {
                const modifiedData = data.map(item => item.replace('.pdf', '_m.jpg'));
                isto.onLoadData('relatorio');
                setLoading(false);
                setGetClinica(clinica);
                setGetAtend(atd);
                setStatus(true);
                setGetJsonData(data);
                isto.PrecisaSalvar = false;
            }
        }, "json");
    }

    PDFsGerados(id, seqAtend, setLoading, setFiles) {
        const url = `https://cefx.cdtsoftware.com.br/api/web/arquivos/listFiles/${id}/${seqAtend}`;
        setLoading(true);

        $.get(url, (data, textStatus) => {
            if (data && Array.isArray(data)) {
                setFiles(data);
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            } else {
                alert("Erro ao carregar arquivos ou resposta inválida.");
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            }
        }, "json").fail((jqXHR, textStatus, errorThrown) => {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        });
    }

    deletePdf(id, seqAtend, pdfNumber, setLoadingDeletePdf, setResponseDeletePdf) {
        const url = `https://cefx.cdtsoftware.com.br/api/web/arquivos/excluirRel/${id}/${seqAtend}/${pdfNumber}`;
        setLoadingDeletePdf(true);

        $.ajax({
            url: url,
            success: (data, textStatus) => {
                setTimeout(() => {
                    toast("PDF excluído com sucesso.", {
                        position: "top-right",
                        style: {
                            background: "#008a00",
                            color: "#fff",
                            textAlign: 'center'
                        },
                    });
                    setLoadingDeletePdf(false);
                    setResponseDeletePdf(textStatus);
                }, 1000);
            },
            error: (jqXHR, textStatus, errorThrown) => {
                setTimeout(() => {
                    setLoadingDeletePdf(false);
                    setResponseDeletePdf(textStatus);
                    toast("Ocorreu um erro ao excluir o PDF.", {
                        position: "top-right",
                        style: {
                            background: "#c80000",
                            color: "#fff",
                            textAlign: 'center'
                        },
                    });
                }, 1000);

                console.error('Erro na requisição:', textStatus, errorThrown);
            }
        });
    }

    fatores(sels, setLoading, setGetAnalyze) {
        setLoading(true)
        const isto = this;

        $.post(this.estado._servCefx + `/api/web/pontos/getFatores/${sels}/0`, this.paciData(true), function (data, textStatus) {
            isto.onLoadData('relatorio');
            setGetAnalyze(data)
            setLoading(false);
            isto.PrecisaSalvar = false;
        }, "json");
    }

    ligaPontos(ctr) {
        this.estado.opcoes.pontos = ctr;
        this.redraw();
    }

    ligaDesenhos(ctr) {
        this.estado.opcoes.desenhos = ctr;
        this.redraw();
    }

    ligaImagem(ctr) {
        this.estado.opcoes.imagem = ctr;
        this.redraw();
    }

    //funçoes de reiniciar e limpar 

    limparPontos(crt) {
        //this.estado.pontos = [];
        //this.estado.pontosFoto = [];
        //this.estado.estruturas = [];
        //this.estado.estruturasFoto = [];

        this.estado.pontos.splice(0, this.estado.pontos.length);
        this.estado.pontosFoto.splice(0, this.estado.pontosFoto.length);
        this.estado.estruturas.splice(0, this.estado.estruturas.length);
        this.estado.estruturasFoto.splice(0, this.estado.estruturasFoto.length);



        this.estado.ptAtual = 0;
        this.limpouPontos = true;
        this.limpouDesenhos = true;
        this.redraw();
    }

    excluirPontoAtual(ctr) {

        const pontos = this.estado.pontos;
        var pt = pontos.find(p => p.id === this.estado.ptAtual);
        if (pt) {
            const index = this.estado.pontos.indexOf(pt);
            this.estado.pontos.splice(index, 1);
        }
        this.redraw();
    }

    reiniciarFerramenta(crt) {
        //console.log(crt)
    }

    reiniciarDesenhos(crt, callback) {
        const paciente = this.estado.paciente;
        //this.estado.estruturas = [];
        this.estado.estruturas.splice(0, this.estado.estruturas.length);
        this.estado.estruturasFoto.splice(0, this.estado.estruturasFoto.length);

        this.limpouDesenhos = true;
        this.getDesenhos(paciente.clinica, paciente.atd, paciente.tipo, true);

        if (callback && typeof callback === 'function') {
            callback(true);
        }
    }

    reiniciarEstruturaAtual(crt) {
        const paciente = this.estado.paciente;
        //var estDesF = this.estado.estruturasFoto.find(e => e.codigo == json[i].estrutura);

        this.estado.estruturas[this.estado.estrAtual].desenhos = [];
        this.estado.estruturasFoto[this.estado.estrAtual].desenhos = [];
        //trocar pelo formato abaixo
        //this.estado.estruturas.splice(0, this.estado.estruturas.length);
        //this.estado.estruturasFoto.splice(0, this.estado.estruturasFoto.length);

        this.getDesenhosEstrutura(paciente.clinica, paciente.atd, paciente.tipo, this.estado.estruturas[this.estado.estrAtual].codigo);
        this.redraw();
    }

    adicionarImagem(urlImg, adicionar = true) {
        if (adicionar) {
            urlImg.forEach(url => {
                const novo = new Image();
                novo.src = url;
                this.estado.listaImagens.push(novo);
            });
        } else {
            this.estado.listaImagens = this.estado.listaImagens.filter(img => img.src !== urlImg);
        }

        this.redraw();
    }

    removerImagem(urlImg) {
        this.estado.listaImagens = this.estado.listaImagens.filter(img => img.src !== urlImg);
        this.redraw();
    }

    removerPdf() {

    }

    reDownload(complemento) {
        $.get(this.estado._servCefx + "/api/web/arquivos/reDownload/" + this.estado.paciente.clinica + "/" + this.estado.paciente.atd, complemento,
            function (json) {
            });
    }

}//classe
export default CefxLib;

