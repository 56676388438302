import { useState } from 'react';
import Modal from 'react-modal';

import { XMarkIcon, PlusSmallIcon, MinusSmallIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import {
    TransformWrapper,
    TransformComponent,
    useControls
} from "react-zoom-pan-pinch";

import styles from './styles.module.css';

SwiperCore.use([Navigation, Pagination, Autoplay]);

interface ModalViewImageMobileProps {
    isOpen?: boolean;
    onRequestClose: () => void;
    image: string;
    imagensPaciente: any[];
    screenSize?: any;
};

export function ModalViewImageMobile({ isOpen, image, imagensPaciente, screenSize, onRequestClose }: ModalViewImageMobileProps) {
    const [imageSelected, setImageSelected] = useState(image);
    const [imageStyle, setImageStyle] = useState({
        width: 0,
        height: 0,
    });

    const handleImageClick = (url) => {
        setImageSelected(url);
    };

    const handleStyleImage = ({ width, height }) => {
        setImageStyle({ width, height })
    }

    const Controls = () => {
        const { zoomIn, zoomOut, resetTransform } = useControls();
        return (
            <header className={styles.header}>
                <button onClick={() => zoomIn()}>
                    <PlusSmallIcon />
                </button>
                <button onClick={() => zoomOut()}>
                    <MinusSmallIcon />
                </button>
                <button onClick={() => resetTransform()}>
                    <ArrowPathIcon />
                </button>
            </header>
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react_modal_overlay-viewImageMobile"
            className="react-modal-viewImageMobile"
        >

            <div
                title='Fechar'
                onClick={onRequestClose}
                className={styles.iconClose}
            >
                <XMarkIcon />
            </div>


            <main className={styles.main}>
                <div className={styles.imageSelected}>
                    <TransformWrapper>
                        <Controls />
                        <TransformComponent>
                            <img src={imageSelected} alt="Selected Image" />
                        </TransformComponent>
                    </TransformWrapper>
                </div>

                <div className={styles.modal}>
                    <Swiper
                        spaceBetween={10}
                        navigation
                        slidesPerView={screenSize.width >= 768 ? 4.2 : 2.2}
                    // pagination={{ clickable: true }}
                    >
                        {imagensPaciente
                            .filter(image => {
                                const extensionsToExclude = ['.stl', '.pdf', '.ply', '.obj', '.rar', 'dfx', 'zip'];
                                return !extensionsToExclude.some(extension => image.link.endsWith(extension) || image.complemento.endsWith(extension));
                            })

                            .map((image, index) => (
                                <SwiperSlide key={index}>
                                    <div
                                        onClick={() => handleImageClick(image.link)}
                                        className={styles.imageCarrosel}
                                    >
                                        <img
                                            className={`${image.link === imageSelected ? styles.selectedImage : ''
                                                }`}
                                            onClick={() => handleImageClick(image.link)}
                                            src={image.link}
                                            alt={image.nome}
                                        />
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </main>
        </Modal>
    )
}