import Modal from 'react-modal';
import styles from './styles.module.css';
import { XMarkIcon, TrashIcon } from '@heroicons/react/24/outline';

interface ModalDeleteExamRequest {
  isOpen: boolean;
  onRequestClose: () => void;
  handleDeleteExamRequest: () => void;
  totalNumberOfExamsSelected?: any;
}

export const ModalDeleteExamRequest = ({ isOpen, onRequestClose, handleDeleteExamRequest, totalNumberOfExamsSelected }: ModalDeleteExamRequest) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react_modal_overlay"
      className={styles.react_modal_Send_Request}
    >
      <div className={styles.xIconWrapper}>
        <XMarkIcon title="Fechar" onClick={onRequestClose} />
      </div>

      <div className={styles.trashIconWrapper}>
        <div className={styles.circle}>
          <TrashIcon width={24} color='#C80000' />
        </div>
      </div>

      <div className={styles.textContainer}>
        <h2>Confirmar Exclusão da Requisição de Exame?</h2>
        <p>A requisição será excluído permanentemente.</p>
        {
          totalNumberOfExamsSelected.length > 1 && (
            <span>
              <strong>Total: </strong>{`${totalNumberOfExamsSelected.length} exames serão excluídos`}
            </span>
          )
        }
      </div>

      <div className={styles.buttonsContainer}>
        <div className={styles.containerButtonCancel}>
          <button onClick={onRequestClose}>
            Cancelar
          </button>
        </div>

        <div className={styles.containerButtonDelete}>
          <button onClick={handleDeleteExamRequest}>
            Excluir
          </button>
        </div>
      </div>

    </Modal>
  )
}