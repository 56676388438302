import { useSelector } from "react-redux";
import { useRef, useEffect, useState } from "react";

import { Text } from "@cdt-ui/react";

import styles from "./styles.module.css";

import { selectUser } from "../../../../store/slices/userSlice";
import { NoClass } from "../../../../pages/Clinic/components/NoClass";
import { NoClassempty } from "../../../../pages/Clinic/components/NoClassempty";

export function Notification({
  isActive,
  setIsActive,
  notificationRef,
  notificationDetails,
  onclick,
}) {
  const [allNotifications, setAllNotifications] = useState(true);
  const { user } = useSelector(selectUser);

  const roles = user[0]?.roles;

  const newRef = useRef(null);

  const handleAllNotifications = () => {
    setAllNotifications(!allNotifications);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (newRef.current && !newRef.current.contains(e.target)) {
        if (notificationRef && notificationRef.current && notificationRef.current.contains(e.target)) {
          return;
        }
        setIsActive(false);
      }
    };

    if (isActive) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isActive, setIsActive, notificationRef]);

  return (
    <>
      {isActive && (
        <div className={styles.mainContainer} ref={newRef}>
          {roles === "NAOAUTENTICADO" ? (
            <NoClass
              text1={"Conta não cadastrada"}
              text2={"Clique em  Criar conta para fazer o cadastro"}
              ModoNotication
            />
          ) : (
            <div>
              <div className={styles.topContainer}>
                <Text className={styles.topTitle}>Notificações</Text>
                <div className={styles.topCard}>
                  {allNotifications ? (
                    <div className={styles.topDiv}>
                      <Text
                        style={{
                          color: "#007E69",
                        }}
                      >
                        Todas
                        <span
                          style={{
                            display: 'block',
                            alignSelf: "stretch",
                            height: 1,
                            background: "#007E69",
                            borderRadius: 16,
                          }}
                        />
                      </Text>
                      <Text
                        className={styles.topNo}
                        onClick={() => handleAllNotifications()}
                      >
                        Não lidas
                        <span
                          style={{
                            display: 'block',
                            alignSelf: "stretch",
                            height: 1,
                            background: "#8E8E8E",
                            borderRadius: 16,
                          }}
                        />
                      </Text>
                    </div>
                  ) : (
                    <div className={styles.topDiv}>
                      <Text onClick={() => handleAllNotifications()}>
                        Todas
                        <span
                          style={{
                            display: 'block',
                            alignSelf: "stretch",
                            height: 1,
                            background: "#8E8E8E",
                            borderRadius: 16,
                          }}
                        />
                      </Text>
                      <Text
                        style={{
                          color: "#007E69",
                        }}
                      >
                        Não lidas
                        <span
                          style={{
                            display: 'block',
                            alignSelf: "stretch",
                            height: 1,
                            background: "#007E69",
                            borderRadius: 16,
                          }}
                        />
                      </Text>
                    </div>
                  )}

                  {/* <Link
                    to="/dashboard/notificacoes"
                    className={styles.topAllMore}
                  >
                    Ver mais
                  </Link> */}
                </div>
              </div>

              {/* {notificationDetails && notificationDetails.length > 0 ? (
                allNotifications ? (
                  notificationDetails.map((item, index) => (
                    <Card
                      key={index}
                      notification={item.notification}
                      info={item}
                    />
                  ))
                ) : (
                  notificationDetails
                    .filter((item) => !item.read)
                    .map((item, index) => (
                      <Card
                        key={index}
                        notification={item.notification}
                        info={item}
                      />
                    ))
                )
              ) : (
                <NoClassempty />
              )} */}
              <NoClassempty />
            </div>
          )}
        </div>
      )}
    </>
  );
}
