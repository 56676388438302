

import { ModalRegisterBriefing } from "./components/Modal";
import { Filters } from "./components/Filter";
import { Content } from "./components/Content";
import Api from "../../../helpers/Api";
import { useEffect, useState } from "react";
import { selectUser } from "../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { NoClass } from "../../Clinic/components/NoClass";
import { Loading } from "../../IndicadoresOportunidade/components/Loading";

import styles from "./styles.module.css";

export function ViewCourses() {
  const { user } = useSelector(selectUser);
  const [isModalNewRegisterOpen, setsModalNewRegisterOpen] = useState(false);
  const [content, setsContent] = useState(false);
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [search, setSearch] = useState("");

  const handleOpenModalView = () => {
    setsModalNewRegisterOpen(true);
  };
  const handleCloseModalNewRegister = () => {
    setsModalNewRegisterOpen(false);
  };

  const GetAllcourses = async () => {
    setLoading(true);

    try {
      const results = await Api.getAllCourse();
      setCourses(results.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const Getcategory = async () => {
    setLoading(true);

    try {
      const results = await Api.getAllCategory();

      setCategory(results.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllcourses();
  }, []);

  useEffect(() => {
    if (courses.length > 0) {
      Getcategory();
    }
  }, [courses]);

  useEffect(() => {
  }, [filteredCourses]);
  const roles = user[0]?.roles;

  useEffect(() => {
    let filtered = [...courses];

    if (search) {
      let filteredCategories = category.filter((category) => {
        return category.name.toLowerCase().includes(search.toLowerCase());
      });
      const isCourseInFilteredCategories = (id: number) => {
        return filteredCategories.some((category) => category.id === id);
      }
      filtered = filtered.filter((course) => {
        return (
          course.name.toLowerCase().includes(search.toLowerCase()) ||
          course.description.toLowerCase().includes(search.toLowerCase()) ||
          isCourseInFilteredCategories(course.idCourseCategory)
        );
      });
    }

    setFilteredCourses(filtered);
  }, [courses, search, category]);

  return (
    <div className={styles.wrapperAcademy}>
      {roles === "NAOAUTENTICADO" ? (
        <NoClass text1={"Conta não cadastrada"} text2={"O seu guia de aprendizagem completo, ajudando colaboradores e radiologistas com cursos focados em atendimento, gestão e softwares especializados."}></NoClass>
      ) : (<section className={styles.containerAulas}>
        <div className={styles.container}>

          {/* <Filters
            courses={courses}
            setFilteredCourses={setFilteredCourses}
            categories={category}
          /> */}
        </div>

        {category && filteredCourses.length > 0 ? (
          category
            .filter((item) => {
              return filteredCourses.some(
                (course) => course.idCourseCategory === item.id
              );
            })
            .map((item, index) => (
              <Content
                key={item.id}
                title={item.name}
                idCategory={item.id}
                courses={filteredCourses}
              />
            ))
        ) : (
          <Loading />
        )}

        {isModalNewRegisterOpen && (
          <ModalRegisterBriefing
            isOpen={isModalNewRegisterOpen}
            onRequestClose={handleCloseModalNewRegister}
          />
        )}
      </section>)}
    </div>
  );
}