import { useEffect, useState } from 'react';
import {
    ArrowsUpDownIcon
} from "@heroicons/react/24/outline";
import { MdRotateLeft, MdRotateRight, MdOutlineKeyboardDoubleArrowDown, MdKeyboardDoubleArrowUp, MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

import { useOpcoes } from '../../../NewSideberCefx/OpcoesContext';
import { isJpnAndPngUtils } from '../../../../../utils/fileTypes/isJpgAndPngUtils';

import { ArrowLineDown, ArrowLineLeft, ArrowLineRight, ArrowLineUp, ArrowUDownLeft, ArrowUDownRight } from '@phosphor-icons/react';

import styles from './styles.module.css';

interface OpcoesProps {
    imageParams?: string;
    getImagenRotation: (rotate: number) => void;
    theSelectImage?: string;
    oViewer?: any;
};

export function OpcoesView({ imageParams, theSelectImage, oViewer, getImagenRotation }: OpcoesProps) {
    const [imageRotation, setImageRotation] = useState(0);

    const { brightness, handleBrightnessChange, contrast, handleContrastChange } = useOpcoes();

    const handleRotateHorizontal = () => {
        setImageRotation((prevRotation) => (prevRotation === 90 ? -90 : 90));
    };

    const handleRotateVertical = () => {
        setImageRotation((prevRotation) => (prevRotation === 0 ? 180 : 0));
    };

    const handlePosition3d = (position: string, theSelectImage: string, imageParams: string) => {
        let urlImage = theSelectImage || imageParams
        oViewer.selecionarPosicao3D(position, urlImage);
    };

    useEffect(() => {
        getImagenRotation(imageRotation);
    }, [imageRotation]);

    return (
        <div className={styles.containerOption}>
            <div>
                <p className={styles.title}>
                    Opções Básicas
                </p>

                <div>
                    <div className={styles.containerInputOpcoesBasicas}>
                        <span>
                            Brilho
                            <span>{brightness}</span>
                        </span>
                        <input
                            type="range"
                            min={0}
                            max={200}
                            value={brightness}
                            onChange={handleBrightnessChange}
                        />
                    </div>

                    <div className={styles.containerInputOpcoesBasicas}>
                        <span>
                            Contraste
                            <span>{contrast}</span>
                        </span>
                        <input
                            type="range"
                            min={0}
                            max={100}
                            value={contrast}
                            onChange={handleContrastChange}
                        />
                    </div>

                    {
                        isJpnAndPngUtils(theSelectImage || imageParams) && (
                            <div
                                className={styles.containerButtons}
                                style={{ display: imageParams.includes("dfx") ? 'none' : 'flex' }}
                            >
                                <div className={styles.containerButton}>
                                    <button
                                        type="button"
                                        onClick={handleRotateHorizontal}
                                    >
                                        <ArrowsUpDownIcon width={16} />
                                        Horizontal
                                    </button>
                                </div>

                                <div className={styles.containerButton}>
                                    <button
                                        type="button"
                                        onClick={handleRotateVertical}
                                    >
                                        <ArrowsUpDownIcon width={16} />
                                        Vertical
                                    </button>
                                </div>
                            </div>
                        )
                    }

                    {
                        !isJpnAndPngUtils(theSelectImage || imageParams) && (
                            <div className={styles.containerButton3dPosition}>
                                <button
                                    type='button'
                                    title='Anterior'
                                    onClick={() => handlePosition3d('A', theSelectImage, imageParams)}
                                >
                                    <MdRotateLeft size={24}/>
                                </button>

                                <button
                                    type='button'
                                    title='Posterior'
                                    onClick={() => handlePosition3d('P', theSelectImage, imageParams)}
                                >
                                    <MdRotateRight size={24}/>
                                </button>

                                <button
                                    type='button'
                                    title='Inferior'
                                    onClick={() => handlePosition3d('I', theSelectImage, imageParams)}
                                >
                                    <MdOutlineKeyboardDoubleArrowDown size={24}/>
                                </button>

                                <button
                                    type='button'
                                    title='Superior'
                                    onClick={() => handlePosition3d('S', theSelectImage, imageParams)}
                                >
                                    <MdKeyboardDoubleArrowUp size={24}/>
                                </button>

                                <button
                                    type='button'
                                    title='Esquerda'
                                    onClick={() => handlePosition3d('L', theSelectImage, imageParams)}
                                >
                                    <MdOutlineKeyboardDoubleArrowLeft size={24}/>
                                </button>

                                <button
                                    type='button'
                                    title='Direta'
                                    onClick={() => handlePosition3d('R', theSelectImage, imageParams)}
                                >
                                    <MdOutlineKeyboardDoubleArrowRight size={24}/>
                                </button>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}