import React from "react";
import { useState, useRef } from "react";
import { ModalSendMessage } from "../../components/Modals/ModalSendMessage";
import { ModalSendCRM } from "../../components/Modals/ModalSendCRM";
import { ModalSMWhats } from "../../components/Modals/ModalSendMsgWhats";
import { ModalSendEmail } from "../../components/Modals/ModalSendEmail";

import { Formik, FormikValues, Field } from "formik";
import * as yup from "yup";

import Select from "react-select";

import { TopCard } from "./components/TopCard";

import {
  PaperAirplaneIcon,
  MagnifyingGlassIcon,
  PlayCircleIcon,
} from "@heroicons/react/24/solid";

import { ChevronUpDownIcon } from "@heroicons/react/24/outline";

import { EnviosIcon } from "./components/EnviosIcon";
import { ExamesBadge } from "./components/ExamesBadge";
import { WarningBadge } from "./components/WarningBadge";
import Avatar from "react-avatar";

import { TableData } from "./components/TableData";

import styles from "./styles.module.css";
import { selectIndicatorList } from "../../../../store/slices/indicatorListSlice";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export function SelectedCard() {
  const [isModalSendMessageOpen, setModalSendMessageOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isAllRowsChecked, setAllRowsChecked] = useState(false);

  const handleOpenModalSendMessage = () => {
    setSelectedOption(null);
    setModalSendMessageOpen(true);
  };

  const handleCloseModalSendMessage = () => {
    setModalSendMessageOpen(false);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    handleCloseModalSendMessage();
  };

  const handleCloseModals = () => {
    setSelectedOption(false);
  };

  const handleAllRowsChecked = () => {
    setAllRowsChecked(!isAllRowsChecked);
  };

  const renderModals = () => {
    switch (selectedOption) {
      case "whatsapp":
        return (
          <ModalSMWhats isOpen={true} onRequestClose={handleCloseModals} />
        );
      case "e-mail":
        return (
          <ModalSendEmail isOpen={true} onRequestClose={handleCloseModals} />
        );
      case "crm":
        return (
          <ModalSendCRM isOpen={true} onRequestClose={handleCloseModals} />
        );
      default:
        return null;
    }
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
  });

  const Handlesearch = async (values: FormikValues): Promise<void> => {
    console.log(values);
  };

  const optionsLabels = [
    { value: "tomografia", label: "Tomografia" },
    { value: "raiox", label: "RAIO-X" },
    { value: "atm", label: "ATM" },
  ];
  const formRef = useRef<any>();

  /* const [ selectedRows, setSelectedRows ] = useState([]);

  const handleSelectedRows = (newRowId) => {
    if(selectedRows.includes(newRowId)) {
      setSelectedRows(selectedRows.filter(id => id != newRowId));
    }
    setSelectedRows([...selectedRows, newRowId]);
    console.log(selectedRows);
  } */
  const { id } = useParams();
  const { indicator } = useSelector(selectIndicatorList);
  const filteredPatients = indicator.filter(
    (patient) => patient.id === parseInt(id)
  );

  return (
    <div>
      {filteredPatients && (
        <TopCard styles={styles} filteredPatients={filteredPatients} />
      )}
      <div className={styles.topBarContainer}>
        <span className={styles.topBarLeftContainer}>
          <Formik
            initialValues={{
              patient: "",
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            onSubmit={Handlesearch}
            innerRef={formRef}
          >
            <div className={styles.wrapper_input}>
              <MagnifyingGlassIcon width={16} className={styles.searchIcon} />
              <Field
                name="search"
                type="text"
                placeholder="Buscar"
                className={styles.searchInput}
              />
            </div>
          </Formik>

          <Select
            name="exames"
            className={styles.selectOption}
            placeholder={
              <div className={styles.selectContainer}>
                <PlayCircleIcon width={20} className={styles.playIcon} />
                Exames
              </div>
            }
            styles={{
              menu: (baseStyles, state) => ({
                ...baseStyles,
                width: "230px",
                zIndex: "11",
                borderRadius: "0px 0px 12px 12px",
                border: "1px solid #D3D3D3",
                boxShadow: "none"
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused || state.isSelected ? "#E4E4E4" : "",
                fontWeight: state.isSelected || state.isFocused ? "600" : "400",
                margin: "0px auto",
                width: "99%",
                borderRadius: "12px",
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,
                transform: state.isFocused ? "rotate(-180deg)" : "",
                cursor: "pointer",
              }),
              indicatorSeparator: (baseStyles, state) => ({
                ...baseStyles,
                display: "none",
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                width: "230px",
                minHeight: "41px",
                maxHeight: "auto",
                backgroundColor: "#EFEFEF",
                borderRadius: "12px",
                zIndex: 10,
                paddingLeft: "7px",
                outline: "none",
                padding: "3px",
                border:
                  state.isFocused
                    ? "1px solid #EFEFEF"
                    : "1px solid #EFEFEF",
                boxShadow: "none",
                "&:hover": {
                  borderColor: "#EFEFEF",
                },
              }),
            }}
            id="situation"
            // onChange={(event) => setFieldValue("situation", event.map(value => value.value))}
            closeMenuOnSelect={true}
            isMulti
            options={optionsLabels}
          />
        </span>

        <button
          className={styles.sendMessageBtn}
          onClick={handleOpenModalSendMessage}
        >
          <PaperAirplaneIcon width={16} />
          <p>Enviar Mensagem</p>
        </button>
      </div>
      <div className={styles.container_table}>
        <table className={styles.patientsTable}>
          <thead>
            <tr>
              <th>
                <div>
                  <input
                    className={styles.inputCheckbox}
                    checked={isAllRowsChecked}
                    type="checkbox"
                    onChange={handleAllRowsChecked}
                  />
                </div>
              </th>
              <th>
                <div>
                  Paciente (324)
                  <ChevronUpDownIcon width={12} />
                </div>
              </th>
              <th>
                <div>
                  Envios
                  <ChevronUpDownIcon width={12} />
                </div>
              </th>
              <th>
                <div>
                  Idade
                  <ChevronUpDownIcon width={12} />
                </div>
              </th>
              <th>
                <div>
                  E-mail
                  <ChevronUpDownIcon width={12} />
                </div>
              </th>
              <th>
                <div>
                  Whatsapp
                  <ChevronUpDownIcon width={12} />
                </div>
              </th>
              <th>
                <div>
                  Exames
                  <ChevronUpDownIcon width={12} />
                </div>
              </th>
              <th>
                <div>
                  Última Consulta
                  <ChevronUpDownIcon width={12} />
                </div>
              </th>
              <th>
                <div>
                  Tempo
                  <ChevronUpDownIcon width={12} />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredPatients[0].patients.map((patient) => (
              <TableData
                key={patient.id}
                nome={patient.paciente}
                photoURL={patient.imagem}
                envios={[
                  { type: "whatsapp", filled: false },
                  { type: "email", filled: false },
                  { type: "cdt", filled: false },
                ]}
                idade={patient.idade}
                email={patient.email}
                tel={patient.telefone}
                exames={[
                  { type: patient.exames[0].servico },
                  { type: "raiox" },
                  { type: "atm" },
                ]}
                ultConsulta={patient.dataAtend}
                tempo="1 ano"
                /*  onSelectedRows={handleSelectedRows("Luana")}
                selectedRows={selectedRows} */
                styles={styles}
                isAllRowsChecked={isAllRowsChecked}
                handleAllRowsChecked={handleAllRowsChecked}
              />
            ))}
            {/* {list?.data.map((patient: PatientProps, index: number) => {
            return (
              <TableBody key={index} bodyContents={!patient ? [] : patient} />
            );
          })} */}
          </tbody>
        </table>
      </div>
      <>
        {isModalSendMessageOpen && (
          <ModalSendMessage
            isOpen={isModalSendMessageOpen}
            onRequestClose={handleCloseModalSendMessage}
            onSelectOption={handleOptionSelect}
          />
        )}
        {selectedOption && renderModals()}
      </>
    </div>
  );
}
