import { Field } from 'formik'
import styles from './styles.module.css'
import { CSSProperties } from 'react';

interface CheckboxEmail {
  name: string;
  customStyle?: CSSProperties;
  error?: any;
}

export function CheckboxEmail({ name, customStyle, error }: CheckboxEmail) {

  return (
    <div className={styles.checkbox_container}>
      <div className={styles.checkbox_variants}>
        <Field
          className="mr-2"
          name={name}
          type="checkbox"
          style={customStyle}
        />

        <label
          htmlFor="checkbox"
          id="checkbox"
          className={styles.text_checkbox}
        >
          Quero receber notificações por e-mail
        </label>
      </div>
      <div className={styles.containerError}>
        <span className={styles.error}>{error}</span>
      </div>
    </div>
  )
}

