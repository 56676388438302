import { useDispatch, useSelector } from "react-redux";
import { useRef, useEffect, useState, useContext } from "react";

import { Text } from "@cdt-ui/react";
import { Card } from "./Card";

import styles from "./styles.module.css";
import {
  setpatients,
  selectPatientsList,
  clearPatients
} from "../../../../store/slices/patientListSlice";
import { clearPatientCefxAConcluir } from "../../../../store/slices/patientCefxAConcluirSlice";
import { clearPatientCefxConcluidos } from "../../../../store/slices/patientCefxConcluidosSlice";
import { clearPatientCefxEmAndamento } from "../../../../store/slices/patientCefxEmAndamentoSlice";

import Api from "../../../../helpers/Api";
import {
  setUser,
  selectUser
} from "../../../../store/slices/userSlice";
import { Avatar } from "@cdt-ui/react";

import { setindicator } from "../../../../store/slices/indicatorListSlice";
import { setClinic } from "../../../../store/slices/clinicSlice";
import Cookies from "js-cookie";
import { selectIsCefalometria, setIsCefalometria } from "../../../../store/slices/isCefalometriaSlice";
import { setResearchPatient } from "../../../../store/slices/researchPatientSlice";
import { PatientContext } from "../../../../pages/PatientList/PatientProvider";
import { setProcesso } from "../../../../store/slices/processoSlice";
import { setToConcludeCefx } from "../../../../store/slices/toConcludeCefxSlice";
import { clearAllConcludesLoaded } from "../../../../store/slices/loadedConcludesSlices";
import { clearPatientWithImagens } from "../../../../store/slices/patientsWithImagesSlice";
import { setIsClinicConnectionsComplete } from "../../../../store/slices/isClinicConnectionsCompleteSlice";
import { clearPaginationAConcluir } from "../../../../store/slices/toConcludePaginationAConcluirSlice";
import { clearPaginationConcluidos } from "../../../../store/slices/toConcludePaginationConcluidosSlice";
import { clearPaginationEmAndamento } from "../../../../store/slices/toConcludePaginationEmAndamentoSlice";
import { useCheckList } from "../../../../pages/PatientList/CheckListCefx/ChecklistContext";

export function ProfileMenu({ isActive, setIsActive, avatarRef }) {
  const { handleTabClick } = useCheckList();
  const { isCefalometria } = useSelector(selectIsCefalometria);

  const [userDetails, setUserDetails] = useState<any>([]);
  const { resetPatientLoading } = useContext(PatientContext);

  const dispatch = useDispatch();

  let abortController;

  const handleLogout = () => {

    if (abortController) {
      abortController.abort();
      console.log('Requisições de pacientes canceladas');
    }

    localStorage.setItem("scrollValue", "0");
    localStorage.setItem("totalPages", "0");
    localStorage.setItem("page", "0");
    localStorage.setItem("atd", "null");
    localStorage.setItem("toConclude", "0");
    localStorage.setItem("idClientLogin", null);

    resetPatientLoading();
    dispatch(setUser(false));
    dispatch(clearPatientWithImagens());
    dispatch(setpatients([]));
    dispatch(clearPatients());
    
    dispatch(setindicator(false));
    dispatch(setClinic(false));
    dispatch(setResearchPatient(false));
    setIsClinicConnectionsComplete(false);
    
    dispatch(clearPatientCefxAConcluir());
    dispatch(clearPatientCefxConcluidos());
    dispatch(clearPatientCefxEmAndamento());
    dispatch(clearAllConcludesLoaded());
    
    dispatch(setIsCefalometria(false));
    dispatch(setProcesso(-2));
    dispatch(setToConcludeCefx(0));
    dispatch(clearPaginationAConcluir());
    dispatch(clearPaginationConcluidos());
    dispatch(clearPaginationEmAndamento());
    handleTabClick("checklistAConcluir");
    localStorage.setItem("recentDocument", null);

    Cookies.remove("userMain");
  };

  const { patients } = useSelector(selectPatientsList);
  const { user } = useSelector(selectUser);
  const hash = user[0]?.hash;
  const roles = user[0]?.roles;

  const getUserDetails = async () => {
    const hash = user?.hash;
    const idClient = user?.idClient;

    if (hash) {
      const payload = {
        idClient,
        hash,
      };

      try {
        const result = await Api.GetUserDetails(payload);

        if (result.isSuccess === true) {
          setUserDetails(result);
        } else {
          console.error(
            "A requisição não foi bem-sucedida:",
            result.errorMessage
          );
        }
      } catch (error) {
        console.error("Erro ao fazer a requisição:", error);
      }
    } else {
      console.error("Erro");
    }
  };

  // useEffect(() => {
  //   getUserDetails();
  // }, []);

  const newRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (newRef.current && !newRef.current.contains(e.target)) {
        if (avatarRef && avatarRef.current && avatarRef.current.contains(e.target)) {
          return;
        }
        setIsActive(false);
      }
    };

    if (isActive) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isActive, setIsActive, avatarRef]);

  return (
    <>
      {isActive ? (
        <div className={styles.mainContainer} ref={newRef}>
          <div className={styles.topContainer}>
            <Avatar className={styles.profilePic} />
            <div className={styles.profileDetailsContainer}>
              {hash?.includes("PC") ? (
                <div>
                  <Text className={styles.heading1}>
                    {patients[0].paciente}
                  </Text>
                  <Text className={styles.subheading}>Paciente</Text>
                </div>
              ) : (
                <div>
                  <Text className={styles.heading1}>
                    {userDetails?.data?.nome}
                  </Text>
                  <Text className={styles.subheading}>Dentista</Text>
                </div>
              )}
            </div>
          </div>
          <div className={styles.bodyContainer}>
            <Text className={styles.settings}>CONFIGURAÇÕES</Text>
            <div className={styles.selectionsContainer}>
              {roles === "NAOAUTENTICADO" && !hash?.includes("PC") ? (
                <div>
                  <Card
                    type="VC"
                    setIsActive={setIsActive}
                    isCefalometria={isCefalometria}
                  />

                  <Card
                    type="dados"
                    setIsActive={setIsActive}
                    isCefalometria={isCefalometria}
                  />
                  <Card
                    type="sair"
                    setIsActive={setIsActive}
                    signOut={handleLogout}
                    isCefalometria={isCefalometria}
                  />
                </div>
              ) : hash?.includes("PC") ? (
                <Card
                  type="sair"
                  setIsActive={setIsActive}
                  signOut={handleLogout}
                  isCefalometria={isCefalometria}
                />
              ) : roles === 'VINCULADO' ? (
                <Card
                  signOut={handleLogout}
                  type="sair"
                  setIsActive={setIsActive}
                  isCefalometria={isCefalometria}
                />
              ) : (
                <>
                  <Card
                    type="VC"
                    setIsActive={setIsActive}
                    isCefalometria={isCefalometria}
                  />

                  <Card
                    type="dados"
                    setIsActive={setIsActive}
                    isCefalometria={isCefalometria}
                  />

                  <Card
                    signOut={handleLogout}
                    type="sair"
                    setIsActive={setIsActive}
                    isCefalometria={isCefalometria}
                  />
                </>
              )}
            </div>
          </div>
        </div >
      ) : (
        <></>
      )
      }
    </>
  );
}
