import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import Switch from "react-switch";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

import { InputSearch } from "../../Components/InputSearch";

import styles from './styles.module.css'
import { useOpcoes } from "../../OpcoesContext";
import { ModalReiniciarDesenho } from "./ModalReiniciarDesenho";

interface EstruturasCefx {
    ocefx: any;
};

export function Estruturas({ ocefx }: EstruturasCefx) {
    const { estruturas, brightness, handleBrightnessChange, contrast, handleContrastChange, switchDesenho, desenhoOnOrOff } = useOpcoes();

    const [search, setSearch] = useState("");
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [permanentTooth, setPermanentTooth] = useState(true);
    const [isModalReiniciarDesenho, setIsModalReiniciarDesenho] = useState(false);

    const handleDeleteItems = () => {
        setSearch("");
        setItems([]);
    };

    const handleCheckboxChange = (codigo, ligado) => {
        const updatedItems = items.map((estrutura) => {
            if (estrutura.codigo === codigo) {
                return {
                    ...estrutura,
                    selecionado: !estrutura.selecionado,
                };
            }
            return estrutura;
        });

        setItems(updatedItems);
        ocefx.ligarSelEstruturas(codigo, !ligado);
    };

    const handleModalReiniciarDesenho = () => {
        setIsModalReiniciarDesenho(!isModalReiniciarDesenho)
    }

    const handleSearchInput = () => {
        if (search.length > 0) {
            setLoading(false);
            setItems(
                estruturas.filter((estruturas: any) =>
                    estruturas?.name.toLowerCase().includes(search.toLowerCase())
                )
            );

            setTimeout(() => {
                setLoading(true);
            }, 2000);

            return;
        }
        setItems(estruturas);
    };

    const handleChangeDesenhoSwitch = () => {
        desenhoOnOrOff(!switchDesenho);
        ocefx.ligaDesenhos(!switchDesenho);
    };

    useEffect(() => {
        handleSearchInput();
    }, [search]);

    return (
        <div className={styles.containerStructures}>
            <p>Estruturas</p>

            <Formik
                initialValues={{
                    searchE: "",
                }}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values) => { }}
            >
                {({ errors, isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <InputSearch
                            name="searchE"
                            placeholder="Buscar"
                            value={search}
                            search={search.length}
                            onChange={(e) => setSearch(e.target.value)}
                            handleDeleteItems={handleDeleteItems}
                        />

                        <div className={styles.containerCheckbox}>
                            <div className={styles.containerSearchResult}>
                                {
                                    items.length == 0 ?
                                        <p className={styles.textNoResult}>
                                            Sem Resultado
                                        </p>
                                        :
                                        items.map((estrutura: any, index: number) => (
                                            <div key={index}>
                                                <input
                                                    className={styles.inputCheckbox}
                                                    type="checkbox"
                                                    // onChange={() => ocefx.toggleSelEstruturas(estrutura.codigo)}
                                                    onChange={() => handleCheckboxChange(estrutura.codigo, estrutura.selecionado)}
                                                    checked={estrutura.selecionado}
                                                />
                                                <span>{estrutura.name}</span>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

            <div className={styles.containerSettings}>
                <p>Configurações de visualização</p>

                <div className={styles.containerOnOff}>
                    <div>
                        <Switch
                            onChange={handleChangeDesenhoSwitch}
                            checked={switchDesenho}
                            className="react-switch"
                            onColor="#007E69"
                            height={22}
                            width={44}
                        />
                        <strong>Mostrar Desenho</strong>
                    </div>

                    <button
                        title="Reiniciar Desenho"
                        type='button'
                        //onClick={() => ocefx.reiniciarDesenhos()}
                        onClick={handleModalReiniciarDesenho}
                    >
                        <ArrowPathIcon width={20} />
                    </button>
                </div>

                <div className={styles.containerButtonsPoints}>
                    <button
                        type='button'
                        className={permanentTooth ? styles.buttonActive : ''}
                        onClick={() => {
                            ocefx.setPermanente();
                            setPermanentTooth(true);
                        }}
                    >
                        Dente Permanente
                    </button>

                    <button
                        type='button'
                        className={!permanentTooth ? styles.buttonActive : ''}
                        onClick={() => {
                            ocefx.setDeciduo();
                            setPermanentTooth(false);
                        }}
                    >
                        Dente Decíduo
                    </button>
                </div>

                <div className={styles.containerInputsRange}>
                    <div className={styles.containerInputOpcoesBasicas}>
                        <span>
                            Brilho
                        </span>
                        <input
                            type="range"
                            min={0}
                            max={200}
                            value={brightness}
                            onChange={handleBrightnessChange}
                        />
                        <div>
                            <span>
                                0
                            </span>
                            <span>
                                {brightness}
                            </span>
                        </div>
                    </div>

                    <div className={styles.containerInputOpcoesBasicas}>
                        <span>
                            Contraste
                        </span>
                        <input
                            type="range"
                            min={0}
                            max={100}
                            value={contrast}
                            onChange={handleContrastChange}
                        />
                        <div>
                            <span>
                                0
                            </span>
                            <span>
                                {contrast}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {
                isModalReiniciarDesenho && (
                    <ModalReiniciarDesenho
                        isOpen={isModalReiniciarDesenho}
                        onRequestClose={handleModalReiniciarDesenho}
                        ocefx={ocefx}
                    />
                )
            }
        </div>
    )
}