import React from "react";
import Sheet from "react-modal-sheet";
import styles from "./styles.module.css";
import {
  ChevronDownIcon,
  GlobeAmericasIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";

interface ModalSheetDentistDetails {
  isOpen: boolean;
  onRequestClose: () => void;
  newObjectDataShare: any;
}

export const ModalSheetDentistDetails = ({
  isOpen,
  onRequestClose,
  newObjectDataShare,
}: ModalSheetDentistDetails) => {
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(newObjectDataShare.uniqueLink)
      .then(() => {
        toast("Link copiado para a área de transferência", {
          style: {
            background: "#008a00",
            color: "#fff",
          },
        });
      })
      .catch((error) => {
        console.error("Error copying link to clipboard:", error);
        toast("Erro ao copiar o link para a área de transferência", {
          style: {
            background: "#e71010",
            color: "#fff",
          },
        });
      });
  };
  return (
    <Sheet isOpen={isOpen} onClose={onRequestClose} detent="content-height">
      <Sheet.Container
        style={{ borderRadius: "30px 30px 0px 0px", overflowY: "scroll" }}
      >
        <Sheet.Header />
        <Sheet.Content>
          <div className={styles.container}>
            <div className={styles.textButtons}>
              <div className={styles.textButton}>
                <h3>Dentista</h3>
                <p>{newObjectDataShare.name}</p>
              </div>
              <div className={styles.textButton}>
                <h3>E-mail</h3>
                <p>
                  {newObjectDataShare.email == undefined
                    ? `-------`
                    : newObjectDataShare.email}
                </p>
              </div>
              <div className={styles.textButton}>
                <h3>O que compartilhou</h3>
                <div className={styles.Content}>
                  <div className={styles.textContent} onClick={copyToClipboard}>
                    <GlobeAmericasIcon color="#919DAF" width={18} height={18} />

                    <p>Clique para Copiar</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Sheet.Content>
        <footer></footer>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
