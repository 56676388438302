import Modal from "react-modal";
import { ExclamationTriangleIcon, XMarkIcon, ArrowRightIcon } from "@heroicons/react/24/solid";

import styles from './styles.module.css';

interface ModalIaProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onLoadIa?: any;
    ocefx?: any;
    overlayClassName?: any;
}

export function ModalIa({ isOpen, onLoadIa, onRequestClose, overlayClassName }: ModalIaProps) {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName='no-overlay'
            className="react-modal-ia"
        >
            <header className={styles.header}>
                <div className={styles.containerIcon}>
                    <ExclamationTriangleIcon width={26} />
                </div>

                <div className={styles.containerTitle}>
                    <h2>
                        Marcar Pontos com IA
                    </h2>
                    <span>
                        Pontos inseridos manualmente serão alterados, isso não poderá ser desfeito.
                    </span>
                </div>

                <XMarkIcon
                    title="Fechar"
                    width={26}
                    onClick={onRequestClose}
                    className={styles.iconClose}
                />
            </header>

            <div className={styles.containerButtons}>
                <button
                    type="button"
                    onClick={onRequestClose}
                >
                    <XMarkIcon width={24} />
                    Cancelar
                </button>

                <button
                    type="button"
                    onClick={onLoadIa}
                >
                    Continuar
                    <ArrowRightIcon width={24} />
                </button>
            </div>
        </Modal>
    )
}