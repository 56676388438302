import { EnviosIcon } from "../EnviosIcon";
import { ExamesBadge } from "../ExamesBadge";
import { WarningBadge } from "../WarningBadge";
import Avatar from "react-avatar";

import { useEffect, useState } from "react";
import rowStyles from "./styles.module.css";

export function TableData({
  styles,
  nome,
  photoURL,
  envios,
  idade,
  email,
  tel,
  exames,
  ultConsulta,
  tempo,
  isAllRowsChecked,
  handleAllRowsChecked,
}) {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = () => {
    setIsChecked(!isChecked);
    if (isAllRowsChecked && isChecked) handleAllRowsChecked();
  };

  useEffect(() => {
    if (isAllRowsChecked) {
      setIsChecked(true);
    }
  }, [isAllRowsChecked]);

  return (
    <tr className={isChecked ? rowStyles.selected_row : ""}>
      <td>
        <div>
          <input
            className={styles.inputCheckbox}
            checked={isChecked}
            type="checkbox"
            onChange={handleChange}
          />
        </div>
      </td>

      <td>
        <div>
          <Avatar className={styles.avatar} size="35" src={photoURL} />
          <div className={styles.titleName}>{nome}</div>
        </div>
      </td>
      <td>
        <div>
          {envios.map((envio) => (
            <>
              <EnviosIcon type={envio.type} filled={envio.filled} />
            </>
          ))}
        </div>
      </td>
      <td>
        <div>{idade}</div>
      </td>
      <td>
        <div>{email}</div>
      </td>
      <td>
        <div>{tel}</div>
      </td>
      <td>
        <div className={styles.examsTDContainer}>
          {exames.map(({ type }) => (
            <>
              <ExamesBadge styles={styles} type={type} />
            </>
          ))}
        </div>
      </td>
      <td>
        <div>{ultConsulta}</div>
      </td>
      <td>
        <WarningBadge styles={styles} tempo={tempo} />
      </td>
    </tr>
  );
}
