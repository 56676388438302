import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { UserIcon, ArrowUpOnSquareStackIcon, PrinterIcon, SpeakerXMarkIcon, AdjustmentsHorizontalIcon, SpeakerWaveIcon, QuestionMarkCircleIcon, ArrowUturnLeftIcon, ArrowUturnRightIcon } from "@heroicons/react/24/outline";
import { ModalTableOfFactors } from "../../Modals/ModalTableOfFactors";
import { ModalReport } from "../../Modals/ModalReport";
import { SelectedReportProvider } from "../../Modals/ModalReport/SelectedReportContext";

import { FloppyDisk } from "@phosphor-icons/react";
import { ModalExit } from "../../../../components/ModalExit";
import { ModalHelp } from "../../Modals/ModalHelp";
import { selectIsCefalometria } from "../../../../store/slices/isCefalometriaSlice";
import { ModalAparelhos } from "../../Modals/ModalAparelhos/modalAparelhos";

import Api from "../../../../helpers/Api";
import { ModalIa } from "../../Modals/ModalIa/ModalIa";
import { ModalLoadingProcessIa } from "../../Modals/ModalIa/ModalLoadingProcessIa/ModalLoadingProcessIa";
import { useOpcoes } from "../../NewSideberCefx/OpcoesContext";

import styles from "./styles.module.css";

export function HeaderCefxComponent({
  handleModoCefX,
  selecionarImagem,
  selecionarFerramentaPonto,
  selecionarFerramentaDesenho,
  handleModoCefXViewImage,
  selecionarFerramentaMover,
  analysisSelected,
  ocefx,
  ligaPontos,
  textPont,
  pontos,
  pointNameOnTableClick,
  getfunctionSelected,
  infoPaciente,
  handleGetNameEndCodAnalyze,
}: any) {

  const [checked, setChecked] = useState(true);
  const [isModalTableOfFactorsOpen, setModalTableOfFactorsOpen] = useState(false);
  const [isModalReportOpen, setModalReportOpen] = useState(false);
  const [divSelecionada, setDivSelecionada] = useState('moverImagem');
  const [ligaSom, setLigaSom] = useState(true);
  const [isModalSair, setIsModalSair] = useState(false);
  const [isModalOpenHelp, setModalOpenHelp] = useState(false);
  const [isModalOpenAparelho, setModalOpenAparelho] = useState(false);
  const [handleClickPont, setHandleClickPont] = useState({});
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showPoints, setShowPoints] = useState(false);
  const [getResultSalvation, setGetResultSalvation] = useState('');
  const [loadingSalvation, setLoadingSalvation] = useState(false);
  const [loadingPublicarPdf, setLoadingPdf] = useState(false);
  const [isModalIa, setIsModalIa] = useState(false);
  const [isModalLoadingOpen, setIsModalLoadingOpen] = useState(false);

  const token = sessionStorage.getItem("token");

  const location = useLocation();

  const queryString = location.search.substring(1);
  const queryParams = new URLSearchParams(queryString);

  const pacienteQuery = infoPaciente?.paciente || queryParams.get("paciente")
  const ssParams = infoPaciente?.ss || queryParams.get('ss');
  const idClientParams = infoPaciente?.idClient || queryParams.get("id");
  const seqAtendParams = infoPaciente?.seqAtend || queryParams.get("atd");
  const idPacienteParams = infoPaciente?.idPaciente || queryParams.get("idPacient");

  const { isCefalometria } = useSelector(selectIsCefalometria);
  const { setLoadingPontIa, handleCefxSelectedTool } = useOpcoes();

  const toggleBackground = (divId) => {
    if (divSelecionada && divId !== 'Som' || divId !== 'calibrar') {
      const divAnterior = document.getElementById(divSelecionada);
      setDivSelecionada(divId);
      handleCefxSelectedTool(divId);
      if (divAnterior) {
        divAnterior.style.backgroundColor = "transparent";
      }
    }

    const divAtual = document.getElementById(divId);

    if (divAtual) {
      if (divId === 'Som' || divId === 'calibrar') {
        getfunctionSelected(divId, !ligaSom);
      } else {
        divAtual.style.backgroundColor = "#F8F8F8";
        setDivSelecionada(divId);
        handleCefxSelectedTool(divId);
        getfunctionSelected(divId, !ligaSom);
      }
    }
  };

  const isAuthenticated = useSelector(
    (state: { user }) => state.user.user !== false
  );

  const handleChange = () => {
    setChecked(false);
    handleModoCefXViewImage(false);
  };

  const handleOPenModalTableOfFactors = () => {
    setModalTableOfFactorsOpen(!isModalTableOfFactorsOpen);
  };

  const handleOPenModalReport = () => {
    setModalReportOpen(!isModalReportOpen);
  };

  const handleLigasom = () => {
    setLigaSom(!ligaSom);
    ocefx.ligaSom(!ligaSom);
  };

  const handleOpenModalExit = () => {
    setIsModalSair(!isModalSair);
  };

  const handleOPenModalHelp = () => {
    setModalOpenHelp(!isModalOpenHelp);
  };

  const handleOpenModalAparelho = () => {
    setModalOpenAparelho(!isModalOpenAparelho);
  }

  const handleOpenModalIa = () => {
    setIsModalIa(!isModalIa);
  }

  const handlePublicarNoServerodonto = async (idClient: number, atd: number) => {
    setLoadingPdf(true);
    try {
      const result = await Api.GetPublicarNoServerodonto(idClient, atd);
      if (result.isSuccess) {
        toast("PDF salvo com sucesso!", {
          position: "top-right",
          style: {
            background: "#008a00",
            color: "#fff",
            textAlign: 'center'
          },
        });
        setLoadingPdf(false);
        return;
      }
    } catch (error) {
      toast("Erro ao salvar o PDF", {
        position: "top-right",
        style: {
          background: "#c80000",
          color: "#fff",
          textAlign: 'center'
        },
      });
      setLoadingPdf(false);
      console.error("Erro na autenticação", error);
    }
  };

  const closeViwer = () => {
    if (token == 'null' || token == null) {
      window.history.back();
    } else {
      window.history.go(-2);
      // navigate("/dashboard/lista-pacientes");
    }
  };

  const closeModalIa = () => {
    setIsModalIa(false);
  };

  const openModalIa = () => setIsModalIa(true);

  const openModalLoading = () => setIsModalLoadingOpen(true);

  const closeModalLoading = () => { setIsModalLoadingOpen(false); setLoadingPontIa(false); };

  const handleLoadIa = () => {
    setLoadingPontIa(true);
    openModalLoading();
    ocefx.CarregarPontosIa(() => {
      closeModalLoading();
    });
    closeModalIa();
  };

  // useEffect(() => {
  //   const shouldOpenIa = sessionStorage.getItem("carregarPontosIa") === "true";
  //   console.log(shouldOpenIa)
  //   if (shouldOpenIa) {
  //     openModalIa();
  //   } else {
  //     closeModalIa();
  //   }
  // }, [carregarPontosIa]);

  useEffect(() => {
    handleModoCefX(checked);
  }, [checked]);

  useEffect(() => {
    let openTimer;
    let closeTimer;

    if (textPont) {
      pontos.map((point) => point.id.nome === textPont && setHandleClickPont({ ponto: point.id.id, nome: point.id.nome }))
      openTimer = setTimeout(() => {
        setTooltipOpen(true);
        setShowPoints(true)

        closeTimer = setTimeout(() => {
          setTooltipOpen(false);
        }, 2500);
      }, 0);
    }

    return () => {
      clearTimeout(openTimer);
      clearTimeout(closeTimer);
    };
  }, [textPont]);

  useEffect(() => {
    if (getResultSalvation === 'success') {
      toast("Alterações salva com sucesso!", {
        position: "top-right",
        style: {
          background: "#008a00",
          color: "#fff",
          textAlign: 'center'
        },
      });
    } else if (getResultSalvation === 'error') {
      toast("Erro ao salvar as Alterações!", {
        position: "top-right",
        style: {
          background: "#c80000",
          color: "#fff",
          textAlign: 'center'
        },
      });
    } else {
      return
    }
  }, [getResultSalvation]);

  return (
    <SelectedReportProvider>
      <header className={styles.wrapper}>
        <div className={styles.containerLogo}>

          {/* <div className={styles.groups}>
            <div className={styles.containerIcon}>
              <img src="/logo.svg" />
            </div>
          </div> */}

          <div className={styles.containerIconLogo}>
            <img src="/logo.svg" />
          </div>


          {/* <div className={styles.groups}>
            <div
              className={styles.containerIcon}
              onClick={() => {
                closeViwer();
              }
              }
            >
              <button
                id="Desfazer"
                type="button"
                title="Voltar"
              >
                <div>
                  <ArrowUturnLeftIcon width={12} />
                </div>
              </button>
            </div>

            <div
              className={styles.containerIcon}
              onClick={() => toggleBackground("Refazer")}
            >
              <button
                id="Refazer"
                type="button"
                // onClick={selecionarImagem}
                title="Refazer"
                disabled={true}
              >
                <div>
                  <ArrowUturnRightIcon width={12} />
                </div>
              </button>
            </div>
          </div> */}

          <div className={styles.groups}>
            <div
              className={styles.containerIcon}
              onClick={() => toggleBackground("Som")}
            >
              <button
                id="Som"
                type="button"
                onClick={() => handleLigasom()}
                title="Ativar ou desativar som"
              >
                <div>
                  {
                    !ligaSom ?
                      <SpeakerXMarkIcon />
                      :
                      <SpeakerWaveIcon />
                  }
                </div>
              </button>
            </div>

            <div
              className={styles.containerIcon}
            >
              <button
                type="button"
                onClick={handleOpenModalAparelho}
                title="Calibrar"
              >
                <div>
                  <AdjustmentsHorizontalIcon />
                </div>
              </button>
            </div>

            <div className={styles.containerIcon}>
              <button
                type="button"
                onClick={handleOPenModalHelp}
                title="Ajuda"
              >
                <div>
                  <QuestionMarkCircleIcon />
                </div>
              </button>
            </div>

          </div>

          <div className={styles.groups}>
            <div
              className={styles.containerIcon}
              onClick={() => toggleBackground("moverImagem")}
            >
              <button
                id="moverImagem"
                type="button"
                onClick={selecionarImagem}
                title="Mover Imagem"
                className={divSelecionada === 'moverImagem' && styles.buttonSelected}
              >
                <div>
                  <img
                    src="/iconCefx/moverIcon.svg" alt="Mover"
                  />
                </div>
              </button>
            </div>

            <div
              className={styles.containerIcon}
              onClick={() => toggleBackground("marcarPonto")}
            >
              <button
                id="marcarPonto"
                type="button"
                onClick={selecionarFerramentaPonto}
                title="Marcar Ponto"
                className={divSelecionada === 'marcarPonto' && styles.buttonSelected}
              >
                <div>
                  <img src="/iconCefx/pontosIcon.svg" alt="Pontos" />
                </div>
              </button>
            </div>

            <div
              className={styles.containerIcon}
              onClick={() => toggleBackground("desenho")}
            >
              <button
                id="desenho"
                type="button"
                onClick={selecionarFerramentaDesenho}
                title="Ajustar Desenho"
                className={divSelecionada === 'desenho' && styles.buttonSelected}
              >
                <div>
                  <img src="/iconCefx/estruturaIcon.svg" alt="Estruturas" />
                </div>
              </button>
            </div>

            <div
              className={styles.containerIcon}
              onClick={() => toggleBackground("fusao")}
            >
              <button
                id="fusao"
                type="button"
                onClick={selecionarFerramentaMover}
                title="Sobrepor pontos na foto"
                className={divSelecionada === 'fusao' && styles.buttonSelected}
              >
                <div>
                  <img src="/iconCefx/fusaoIcon.svg" alt="Fusão" />
                </div>
              </button>
            </div>
          </div>

          <div className={styles.groups}>
            <div className={styles.containerIcon}>
              <button
                id="div5"
                type="button"
                onClick={handleOPenModalTableOfFactors}
                title="Exibir fatores"
              >
                <div>
                  <img src="/iconCefx/fatoresIcon.svg" alt="Fatores" />
                </div>
              </button>
            </div>

            <div className={styles.containerIcon}>
              <button
                type="button"
                onClick={handleOPenModalReport}
                title="Imprimir/Gerar PDF"
              >
                <div>
                  <PrinterIcon />
                </div>
              </button>
            </div>

            <div className={styles.containerIcon}>
              <button
                type="button"
                onClick={() => handlePublicarNoServerodonto(idClientParams, seqAtendParams)}
                title="Enviar PDF para o Serverodonto"
              >
                <div>
                  {
                    loadingPublicarPdf ?
                      <div className={styles.container_buttons_loading}>
                        <div className={styles.loading_spinne}></div>
                      </div>
                      :
                      <ArrowUpOnSquareStackIcon />
                  }
                </div>
              </button>
            </div>
          </div>

          <div className={styles.buttonIa}>
            <button
              type="button"
              title="Ativar IA"
              onClick={() => handleOpenModalIa()}
            >
              IA
            </button>
          </div>
        </div>

        <div className={styles.container_onOffAndButton}>
          {
            isCefalometria && isAuthenticated && (
              <Link
                to={{
                  pathname: `/dashboard/perfil-paciente/${ssParams}`,
                  search: `&paciente=${pacienteQuery}&seqAtend=${seqAtendParams}&idClient=${idClientParams}&idPacient=${idPacienteParams}`,
                }}
                target="_blank"
              >
                <button
                  className={styles.buttonPatientProfile}
                  title="Visualizar perfil do paciente"
                >
                  <UserIcon />
                  Perfil
                </button>
              </Link>
            )
          }

          <div className={styles.containerButton_download}>
            <button
              type="button"
              className={styles.buttonPatientProfile}
              onClick={() => ocefx.salvarCefx(setGetResultSalvation, setLoadingSalvation)}
              disabled={loadingSalvation}
            >

              {loadingSalvation ? (
                <div className={styles.container_buttons_loading}>
                  <div className={styles.loading_spinne}></div>
                </div>
              ) : (
                <>
                  <FloppyDisk />
                  Salvar
                </>
              )}
            </button>
          </div>
        </div>

        {isModalTableOfFactorsOpen && (
          <ModalTableOfFactors
            isOpen={isModalTableOfFactorsOpen}
            onRequestClose={handleOPenModalTableOfFactors}
            ocefx={ocefx}
          />
        )}

        {isModalReportOpen && (
          <ModalReport
            isOpen={isModalReportOpen}
            onRequestClose={handleOPenModalReport}
            ocefx={ocefx}
            analysisSelected={analysisSelected}
            handleGetNameEndCodAnalyze={handleGetNameEndCodAnalyze}
          />
        )}

        {isModalSair && (
          <ModalExit
            isOpen={isModalSair}
            onRequestClose={handleOpenModalExit}
            ocefx={ocefx}
          />
        )}

        {isModalOpenHelp && (
          <ModalHelp
            onRequestClose={handleOPenModalHelp}
            isOpen={isModalOpenHelp}
            handleClickPont={handleClickPont}
            pointNameOnTableClick={pointNameOnTableClick}
            ocefx={ocefx}
          />
        )}

        {
          isModalOpenAparelho && (
            <ModalAparelhos
              isOpen={isModalOpenAparelho}
              onRequestClose={handleOpenModalAparelho}
              ocefx={ocefx}
            />
          )
        }

        <>
          {(isModalIa || isModalLoadingOpen) && (
            <div className="react_modal_overlayIa" />
          )}

          {isModalIa && (
            <ModalIa
              isOpen={isModalIa}
              onRequestClose={closeModalIa}
              onLoadIa={handleLoadIa}
            />
          )}

          {isModalLoadingOpen && (
            <ModalLoadingProcessIa
              isOpen={isModalLoadingOpen}
              onRequestClose={closeModalLoading}
            />
          )}
        </>

      </header>
    </SelectedReportProvider >
  );
}
