import { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { selectIsCefalometria } from '../../../store/slices/isCefalometriaSlice';

const OpcoesContext = createContext();

export const OpcoesProvider = ({ children, ocefx }) => {
    const { isCefalometria } = useSelector(selectIsCefalometria);

    const [switchDesenho, setSwitchDesenho] = useState(true);
    const [switchPontos, setSwitchPontos] = useState(true);
    const [swtichImage, setSwitchImage] = useState(true);
    const [selectedTracado, setSelectedTracado] = useState('');
    const [brightness, setBrightness] = useState(100);
    const [contrast, setContrast] = useState(100);
    const [handleClickPont, setHandleClickPont] = useState({ ponto: null, nome: '', tipo: 0 });
    const [loadingPontIa, setLoadingPontIa] = useState(false);
    const [cefxSelectedTool, setCefxSelectedTool] = useState('');
    const [pontos, setPontos] = useState([]);
    const [estruturas, setEstruturas] = useState([]);
    const [imagensCefx, setImagensCefx] = useState([]);
    const [analises, setAnalises] = useState([]);
    const [analiseCheckboxSelected, setAnaliseCheckboxSelected] = useState([]);
    const [isSincronizarImagens, setIsSincronizarImagens] = useState(false);

    const desenhoOnOrOff = (switchDesenho) => {
        setSwitchDesenho(switchDesenho);
    };

    const pontosOnOrOff = (switchPontos) => {
        setSwitchPontos(switchPontos);
    };

    const imageOnOrOff = (switchImage) => {
        setSwitchImage(switchImage);
    };

    const handleSelectedTracado = (tracado) => {
        setSelectedTracado(tracado);
    };

    const handleBrightnessChange = (event) => {
        const newBrightness = event.target.value;
        setBrightness(newBrightness);
        if (isCefalometria) ocefx.setBrilho(newBrightness);
    };

    const handleContrastChange = (event) => {
        const newContrast = event.target.value;
        setContrast(newContrast);
        if (isCefalometria) ocefx.setContraste(newContrast);
    };

    const handleCefxSelectedTool = (tool) => {
        setCefxSelectedTool(tool)
    };


    const addSelectedAnalyze = (analyze) => {
        setAnaliseCheckboxSelected([...analiseCheckboxSelected, analyze]);
    };

    const removeSelectedAnalyze = (analyze) => {
        setAnaliseCheckboxSelected(analiseCheckboxSelected.filter(item => item.nome !== analyze.nome));
    };

    useEffect(() => {
        ocefx.setOnLoadData((v) => {
            var lst = ocefx.getList(v);

            if (v == "pontos") {
                var pontosArray = []
                setPontos(pontosArray)
                for (var i = 0; i < lst.length; i++) {
                    var reg = lst[i];
                    var pontos = { id: reg, x: reg, y: reg };
                    pontosArray.push(pontos)
                }
            }
            else if (v == "estruturas") {
                var estruturaArray = [];
                setEstruturas(estruturaArray);
                for (var i = 0; i < lst.length; i++) {
                    var reg = lst[i];
                    var estrutura = { codigo: reg.codigo, name: reg.nome, selecionado: reg.mostraRX };
                    estruturaArray.push(estrutura);
                }
            }

            else if (v == "imagens") {
                var arrayImagens = [];
                setImagensCefx(arrayImagens);
                for (var i = 0; i < lst.length; i++) {
                    var reg = lst[i];
                    var imagem = { atd: reg.atd, cli: reg.cli, onclick: reg.onclick, src: reg.src, alvo: reg.alvo, id: reg.id }
                    arrayImagens.push(imagem)
                };
            }

            else if (v == "analises") {
                var analisesArray = []
                setAnalises(analisesArray)

                for (var i = 0; i < lst.length; i++) {
                    var reg = lst[i];
                    var analise = { codigo: reg.codigo, nome: reg.nome, selecionada: reg.selecionada }
                    analisesArray.push(analise)
                };

                const analisesSelecionadas = analisesArray.filter(analyze => analyze.selecionada);
                const selectedItems = analisesSelecionadas.map(analyze => ({ nome: analyze.nome, codigo: analyze.codigo }));
                setAnaliseCheckboxSelected(selectedItems);
            }
        });
    }, []);
    
    return (
        <OpcoesContext.Provider
            value={{
                switchDesenho,
                desenhoOnOrOff,
                switchPontos,
                pontosOnOrOff,
                swtichImage,
                setSwitchImage,
                selectedTracado,
                handleSelectedTracado,
                brightness,
                handleBrightnessChange,
                contrast,
                handleContrastChange,
                handleClickPont,
                setHandleClickPont,
                loadingPontIa,
                setLoadingPontIa,
                cefxSelectedTool,
                handleCefxSelectedTool,
                pontos,
                estruturas,
                imagensCefx,
                setImagensCefx,
                analises,
                analiseCheckboxSelected,
                addSelectedAnalyze,
                removeSelectedAnalyze,
                isSincronizarImagens,
                setIsSincronizarImagens
            }}
        >
            {children}
        </OpcoesContext.Provider>
    )
}

export const useOpcoes = () => useContext(OpcoesContext);