import React, { useEffect, useState } from "react";
import { Text } from "@cdt-ui/react";
import { Circle } from "@phosphor-icons/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import styles from "./styles.module.css";

interface LessonItemProps {
  lesson: {
    title: string;
    href: string;
    time: string;
    video: string;
  };
  open: boolean;
  onLessonClick: (lesson: any) => void;
}

export function LessonItem({ lesson, open, onLessonClick }: LessonItemProps) {
  const [videoDuration, setVideoDuration] = useState(0);

  return (
    <li onClick={() => onLessonClick(lesson)} className={styles.li}>
      <div
        className={
          open ? `${styles.containerTitle_open}` : `${styles.containerTitle}`
        }
        style={
          open
            ? {
                position: "relative",
                color: "#298e79",
                textDecoration: "none",
                fontWeight: "600",
              }
            : {
                position: "relative",
                color: "#57667A",
                textDecoration: "none",
              }
        }
      >
        <div
          className={
            open ? `${styles.containerIcon_open}` : `${styles.containerIcon}`
          }
        >
          <Circle width={24} height={24} />
          <Text
            className={styles.sectionTitle}
            style={
              open
                ? {
                    color: "#007E69",
                  }
                : {
                    color: "#57667A",
                  }
            }
          >
            {lesson.title}
          </Text>
        </div>
        <div className={styles.sectionTimeEnd}>
          <Text
            className={styles.sectionTime}
            style={
              open
                ? {
                    color: "#007E69",
                  }
                : {
                    color: "#57667A",
                  }
            }
          >
            {lesson.time}
          </Text>
          <div style={{ transform: open ? "rotate(3.142rad)" : "rotate(0)", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ChevronDownIcon
              width={15}
              style={
                open
                  ? {
                      color: "#007E69",
                    }
                  : {
                      color: "#57667A",
                    }
              }
            />
          </div>
        </div>
      </div>
    </li>
  );
}
