import { useState } from 'react';
import { ArrowClockwise, Lock, Tooth, Trash } from '@phosphor-icons/react';
import Switch from "react-switch";

import styles from './styles.module.css';
import { useOpcoes } from '../../OpcoesContext';

interface OpcoesProps {
    ocefx: any;
}

export function Opcoes({ ocefx }: OpcoesProps) {
    const { switchDesenho, switchPontos, desenhoOnOrOff, pontosOnOrOff, swtichImage, setSwitchImage } = useOpcoes();

    const [brightness, setBrightness] = useState(100);
    const [contrast, setContrast] = useState(100);
    const [transpFusao, setTranspFusao] = useState(100);

    const handleChangeImagemSwitch = () => {
        setSwitchImage(!swtichImage);
        ocefx.ligaImagem(!swtichImage);
    };

    const handleChangePontosSwitch = () => {
        pontosOnOrOff(!switchPontos);
        ocefx.ligaPontos(!switchPontos);
    };

    const handleChangeDesenhoSwitch = () => {
        desenhoOnOrOff(!switchDesenho);
        ocefx.ligaDesenhos(!switchDesenho);
    };

    const handleBrightnessChange = (event) => {
        const newBrightness = event.target.value;
        ocefx.setBrilho(newBrightness)
        setBrightness(newBrightness);
    };

    const handleContrastChange = (event) => {
        const newContrast = event.target.value;
        ocefx.setContraste(newContrast);
        setContrast(newContrast);
    };

    const handleTranspFusao = (event) => {
        const newTranspFusao = event.target.value;
        ocefx.setTranspFusao(newTranspFusao)
        setTranspFusao(newTranspFusao);
    };

    return (
        <div className={styles.containerOpcoes}>
            <p>Opções básicas</p>

            <div>
                <div className={styles.containerInputOpcoesBasicas}>

                    <div>
                        <div className={styles.containerInputOpcoesBasicas}>
                            <div className={styles.containerOnOff}>
                                <strong>Imagem</strong>
                                <Switch
                                    onChange={handleChangeImagemSwitch}
                                    checked={swtichImage}
                                    className="react-switch"
                                    onColor="#007E69"
                                    height={22}
                                    width={44}
                                />
                            </div>

                            <div className={styles.containerOnOff}>
                                <strong>Pontos</strong>
                                <Switch
                                    onChange={handleChangePontosSwitch}
                                    checked={switchPontos}
                                    className="react-switch"
                                    onColor="#007E69"
                                    height={22}
                                    width={44}
                                />
                            </div>

                            <div className={styles.containerOnOff}>
                                <strong>Desenhos</strong>
                                <Switch
                                    onChange={handleChangeDesenhoSwitch}
                                    checked={switchDesenho}
                                    className="react-switch"
                                    onColor="#007E69"
                                    height={22}
                                    width={44}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={styles.containerOnOff}>
                        <strong>Permanente</strong>

                        <button
                            className={styles.buttonTrash}
                            type='button'
                            onClick={() => ocefx.setPermanente()}
                        >
                            <Tooth size={16} />
                        </button>
                    </div>

                    <div className={styles.containerOnOff}>
                        <strong>Deciduo</strong>

                        <button
                            className={styles.buttonTrash}
                            type='button'
                            onClick={() => ocefx.setDeciduo()}
                        >
                            <Tooth size={16} />
                        </button>
                    </div>

                    <div className={styles.containerOnOff}>
                        <strong>Limpar Pontos</strong>

                        <button
                            className={styles.buttonTrash}
                            type='button'
                            onClick={() => ocefx.limparPontos(true)}
                        >
                            <Trash size={16} />
                        </button>
                    </div>

                    <div className={styles.containerOnOff}>
                        <strong>Excluir Ponto Atual</strong>

                        <button
                            className={styles.buttonTrash}
                            type='button'
                            onClick={() => ocefx.excluirPontoAtual(true)}
                        >
                            <Trash size={16} />
                        </button>
                    </div>

                    <div className={styles.containerOnOff}>
                        <strong>Reiniciar Ferramenta</strong>

                        <button
                            className={styles.buttonTrash}
                            type='button'
                            onClick={() => ocefx.reiniciarFerramenta(true)}
                        >
                            <ArrowClockwise size={16} />
                        </button>
                    </div>

                    <div className={styles.containerOnOff}>
                        <strong>Reiniciar Desenhos</strong>

                        <button
                            className={styles.buttonTrash}
                            type='button'
                            onClick={() => ocefx.reiniciarDesenhos(true)}
                        >
                            <ArrowClockwise size={16} />
                        </button>
                    </div>

                    <div className={styles.containerOnOff}>
                        <strong>Reiniciar Estrutura Atual</strong>

                        <button
                            className={styles.buttonTrash}
                            type='button'
                            onClick={() => ocefx.reiniciarEstruturaAtual(true)}
                        >
                            <ArrowClockwise size={16} />
                        </button>
                    </div>
                </div>

                <div className={styles.containerInputOpcoesBasicas}>
                    <span>
                        Brilho
                        <span>{brightness}</span>
                    </span>
                    <input
                        type="range"
                        min={0}
                        max={200}
                        value={brightness}
                        onChange={handleBrightnessChange}
                    />
                </div>

                <div className={styles.containerInputOpcoesBasicas}>
                    <span>
                        Contraste
                        <span>{contrast}</span>
                    </span>
                    <input
                        type="range"
                        min={0}
                        max={100}
                        value={contrast}
                        onChange={handleContrastChange}
                    />
                </div>

                {/* <div className={styles.containerInputOpcoesBasicas}>
                    <span>
                        Transp Fusão
                        <span>{transpFusao}</span>
                    </span>
                    <input
                        type="range"
                        min={0}
                        max={100}
                        value={transpFusao}
                        onChange={handleTranspFusao}
                    />
                </div> */}
            </div>
        </div>
    )
}