import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  tempPatients: [],
};

export const tempPatientListSlice = createSlice({
  name: "tempPatients",
  initialState,
  reducers: {
    setTempPatients: (state, action: PayloadAction<any[]>) => {
      state.tempPatients = action.payload;
    },
    addTempPatients: (state, action: PayloadAction<any[]>) => {
      state.tempPatients = [...state.tempPatients, ...action.payload];
    },
    clearTempPatients: (state) => {
      state.tempPatients = [];
      state.tempPatients.length = 0;
    }
  },
});

export const { setTempPatients, addTempPatients, clearTempPatients } = tempPatientListSlice.actions;

export const selectTempPatientsList = (state: any) => state.tempPatients;

export default tempPatientListSlice.reducer;
