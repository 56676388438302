import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    paginationEmAndamento: {},
};

export const ToConcludePaginationEmAndamentoSlice = createSlice({
    name: "paginationEmAndamento",
    initialState,
    reducers: {
        setToConcludePaginationEmAndamento: (state, action: PayloadAction<{ idClient: any, currentPage: number, totalPages: number, toConcludeCefx: any }>) => {
            const { idClient, currentPage, totalPages, toConcludeCefx } = action.payload;
            state.paginationEmAndamento[idClient] = { currentPage, totalPages, toConcludeCefx };
        },
        clearPaginationEmAndamento: (state) => {
            state.paginationEmAndamento = {};
        },
    }
})

export const { setToConcludePaginationEmAndamento, clearPaginationEmAndamento } = ToConcludePaginationEmAndamentoSlice.actions;

export const selectPaginationCefxEmAndamento = (state: any) => state.paginationEmAndamento;

export default ToConcludePaginationEmAndamentoSlice.reducer;
