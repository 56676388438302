import Modal from "react-modal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from 'react-hot-toast';

import styles from './ModalReiniciarDesenho.module.css'
import { useState } from "react";

interface ModalExitProps {
    isOpen: boolean;
    onRequestClose: () => void;
    ocefx?: any;
}

export function ModalReiniciarDesenho({ isOpen, onRequestClose, ocefx }: ModalExitProps) {
    const [RestartDrawingModeWasClicked, setRestartDrawingModeClickded] = useState(false);

    const handleResturarDesenho = () => {
        setRestartDrawingModeClickded(true)

        ocefx.reiniciarDesenhos(null, (foiReiniciado: any) => {
            setTimeout(() => {
                setRestartDrawingModeClickded(false)

                if (foiReiniciado) {
                    toast.success('O desenho foi reiniciado com sucesso!');
                } else {
                    toast.error('Falha ao reiniciar o desenho.');
                }
                onRequestClose();
            }, 1500);
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react_modal_overlay"
            className="react-modal-exit "
        >
            <div className={styles.container}>
                <div className={styles.iconX} onClick={onRequestClose}>
                    <XMarkIcon />
                </div>

                <div className={styles.containerImg}>
                    <img src="/iconCefx/alertIcon.svg" />
                </div>

                <header className={styles.header}>
                    <h2>
                        Deseja restaurar estrutura?
                    </h2>

                    <p>
                        Tudo o que foi alterado será restaurado, <br />
                        essa <b>ação não poderá ser desfeita.</b>
                    </p>
                </header>

                <div className={styles.containerButtons}>
                    <button
                        type="button"
                    >
                        Cancelar
                    </button>

                    <button
                        onClick={handleResturarDesenho}
                        type="button"
                        disabled={RestartDrawingModeWasClicked}
                        style={{
                            cursor: RestartDrawingModeWasClicked ? 'not-allowed' : 'pointer'
                        }}
                    >

                        {RestartDrawingModeWasClicked ? (
                            <div className={styles.container_buttons_loading}>
                                <div className={styles.loading_spinne}></div>
                            </div>
                        )
                            :
                            'Restaurar'
                        }
                    </button>
                </div>
            </div>
        </Modal>
    )
}