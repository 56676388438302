import { useEffect, useState } from "react";
import { Showvisualizar } from "../../Showvisualizar";

import styles from './styles.module.css'

export function Dicom({ onViwerActivesChange }: any) {
    const [isSecondDivVisible1, setIsSecondDivVisible1] = useState(false);
    const [isSecondDivVisible2, setIsSecondDivVisible2] = useState(false);
    const [isSecondDivVisible3, setIsSecondDivVisible3] = useState(false);
    const [isSecondDivVisible4, setIsSecondDivVisible4] = useState(false);
    const [isSecondDivVisible5, setIsSecondDivVisible5] = useState(true);
    const [isSecondDivVisible6, setIsSecondDivVisible6] = useState(false);
    const [isSecondDivVisible7, setIsSecondDivVisible7] = useState(false);
    const [isSecondDivVisible8, setIsSecondDivVisible8] = useState(false);
    const [isSecondDivVisible9, setIsSecondDivVisible9] = useState(false);

    const handleToggleVisualizacoes = (selectedDiv) => {
        setIsSecondDivVisible1(
            selectedDiv === 1 ||
            selectedDiv === 6 ||
            selectedDiv === 7 ||
            selectedDiv === 8 ||
            selectedDiv === 9
        );
        setIsSecondDivVisible2(selectedDiv === 2);
        setIsSecondDivVisible3(selectedDiv === 3);
        setIsSecondDivVisible4(selectedDiv === 4);
        setIsSecondDivVisible5(selectedDiv === 5);
        setIsSecondDivVisible6(selectedDiv === 6);
        setIsSecondDivVisible7(selectedDiv === 7);
        setIsSecondDivVisible8(selectedDiv === 8);
        setIsSecondDivVisible9(selectedDiv === 9);
    };

    useEffect(() => {
        onViwerActivesChange([
            isSecondDivVisible1,
            isSecondDivVisible2,
            isSecondDivVisible3,
            isSecondDivVisible4,
            isSecondDivVisible5,
            isSecondDivVisible6,
            isSecondDivVisible7,
            isSecondDivVisible8,
            isSecondDivVisible9,
        ]);
    }, [
        isSecondDivVisible1,
        isSecondDivVisible2,
        isSecondDivVisible3,
        isSecondDivVisible4,
        isSecondDivVisible5,
        isSecondDivVisible6,
        isSecondDivVisible7,
        isSecondDivVisible8,
        isSecondDivVisible9,
    ]);

    return (
        <div className={styles.containerOption}>
            <div>
                <p className={styles.title}>
                    Visualizador dicom
                </p>

                <Showvisualizar
                    handleToggleVisualizacoes={handleToggleVisualizacoes}
                    isSecondDivVisible1={isSecondDivVisible1}
                    isSecondDivVisible2={isSecondDivVisible2}
                    isSecondDivVisible3={isSecondDivVisible3}
                    isSecondDivVisible4={isSecondDivVisible4}
                    isSecondDivVisible5={isSecondDivVisible5}
                    isSecondDivVisible6={isSecondDivVisible6}
                    isSecondDivVisible7={isSecondDivVisible7}
                    isSecondDivVisible8={isSecondDivVisible8}
                    isSecondDivVisible9={isSecondDivVisible9}
                />
            </div>
        </div>
    )
}