export function CalcMedida(x1, y1, x2, y2) {
  return Math.sqrt(
    Math.pow(Math.abs(y1 - y2), 2) + Math.pow(Math.abs(x1 - x2), 2)
  );
}

export function CalcMedidaMM(x1, y1, x2, y2) {
    return (Math.sqrt(
        Math.pow(Math.abs(y1 - y2), 2) + Math.pow(Math.abs(x1 - x2), 2)) / 96.0 * 25.4/2.0
    );
}

//   export function   CalcMedida(x1, y1, x2, y2) {
//     return Math.sqrt(Math.pow(Math.abs(y1 - y2), 2) + Math.pow(Math.abs(x1 - x2), 2));
//   }

//radiano
export function CalcAngulo(x1, y1, x2, y2) {
  var dy = y2 - y1;
  var dx = x2 - x1;
  var ang = Math.atan2(dy, dx);
  return ang;
}

//graus
export function angle(cx, cy, ex, ey) {
  var dy = ey - cy;
  var dx = ex - cx;
  var theta = Math.atan2(dy, dx); // range (-PI, PI]
  theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
  //if (theta < 0) theta = 360 + theta; // range [0, 360)
  return theta;
}

export function projeLinha(A, B, t) {
    return { x: A.x + t * (A.x - B.x), y: A.y + t * (A.y - B.y) };
}

export function pontoMedio2(A, B) {
    return { x: (A.x + B.x) / 2, y: (A.y + B.y) / 2 };
}

export function Perpendicular(pA, pB, pC) {
    var xini = pA.x, Yini = pA.y, Xfim = pB.x, Yfim = pB.y, XPonto = pC.x, YPonto = pC.y;

    var d = Math.sqrt(Math.pow(Math.abs(Yini - Yfim), 2) + Math.pow(Math.abs(xini - Xfim), 2));
    var ang = Angulo(xini, Yini, Xfim, Yfim) / 180 * 3.14;

    var a = XPonto + d * Math.sin(ang + 3.14);
    var b = YPonto + d * Math.cos(ang + 3.14);
    return {
        x: a,
        y: b
    };
}

export function Angulo(XA, YA, XB, YB) {
    var Var, Ly, Lx;
    var q = 0;
    if (XA > XB) {
        if (YA > YB) q = 2;
        if (YA < YB) q = 3;
    }
    if (XA < XB) {
        if (YA > YB) q = 1;
        if (YA < YB) q = 4;
    }
    Lx = Math.abs(XA - XB);
    Ly = Math.abs(YA - YB);

    if (Lx != 0) Var = (Math.atan(Ly / Lx) * 180 / 3.14);

    if (q == 2) Var = 180 - Var; //'2
    if (q == 3) Var = 180 + Var; //'3
    if (q == 4) Var = 360 - Var;// '4

    if (Lx == 0 && YA < YB) Var = 270; //' 90
    if (Lx == 0 && YA > YB) Var = 90; // '270
    if (XA < XB && Ly == 0) Var = 0;  //'180
    if (XA > XB && Ly == 0) Var = 180; //'  0

    return Var;
}

export function Paralelo(xini, Yini, Xfim, Yfim, XPonto, YPonto) {
    return {
        x: XPonto + (Xfim - xini),
        y: YPonto + (Yfim - Yini)
    };
}

export function MenorDistancia(A, B, C) {
    var x1 = A.x, y1 = A.y, x2 = B.x, y2 = B.y, x3 = C.x, y3 = C.y;
    var px = x2 - x1, py = y2 - y1, dAB = px * px + py * py;
    var u = ((x3 - x1) * px + (y3 - y1) * py) / dAB;
    var x = x1 + u * px, y = y1 + u * py;
    return { x: x, y: y }; //this is D
}


export function PosicaoMediaEntreAngulo(XP1, YP1, XP2, YP2, XP3, YP3, TamanhoLinha) {
    var Ang1, Ang2, Ang3;

    Ang1 = Angulo(XP2, YP2, XP1, YP1) + 90;
    Ang2 = Angulo(XP2, YP2, XP3, YP3) + 90;

    if (Ang1 > 180) Ang1 = Ang1 - 360;
    if (Ang2 > 180) Ang2 = Ang2 - 360;
    if (Math.abs(Ang2 - Ang1) > 180)
        Ang3 = Ang1 + (Ang2 - Ang1) / 2; //'Original
    else
        Ang3 = Ang1 + (Ang2 - Ang1) / 2 + 180;

    Ang3 = Ang1 + (Ang2 - Ang1) / 2;


    Ang3 = Ang3 + 180;

    return {
        x: XP2 + TamanhoLinha * Math.sin(Ang3 * 3.14 / 180),
        y: YP2 + TamanhoLinha * Math.cos(Ang3 * 3.14 / 180)
    };
}


export function Interseccao(A, B, C, D) {
    var XP1 = A.x, YP1 = A.y, XP2 = B.x, YP2 = B.y, XP3 = C.x, YP3 = C.y, XP4 = D.x, YP4 = D.y;

    var k = 60000;
    var a1, b1, k1;
    var a2, b2, k2;
    var delta, deltaX, deltaY;

    XP1 = XP1 + k;
    XP2 = XP2 + k;
    XP3 = XP3 + k;
    XP4 = XP4 + k;
    YP1 = YP1 + k;
    YP2 = YP2 + k;
    YP3 = YP3 + k;
    YP4 = YP4 + k;


    a1 = YP1 - YP2; b1 = XP2 - XP1;
    k1 = (XP1 * YP2) - (YP1 * XP2);

    a2 = YP3 - YP4; b2 = XP4 - XP3;
    k2 = (XP3 * YP4) - (YP3 * XP4);

    delta = (a1 * b2) - (b1 * a2);
    deltaX = (k1 * b2) - (b1 * k2);
    deltaY = (a1 * k2) - (k1 * a2);

    if (delta != 0) {
        return {
            x: Math.abs(deltaX / delta) - k,
            y: Math.abs(deltaY / delta) - k
        };
    }
    else {
        return { x: 0, y: 0 };
    }
}


export function rotateVector(A, B, ang) {
    var vec = { x: B.x - A.x, y: B.y - A.y };

    ang = -ang * (Math.PI / 180);
    var cos = Math.cos(ang);
    var sin = Math.sin(ang);
    return {
        x: A.x + Math.round(10000 * (vec.x * cos - vec.y * sin)) / 10000,
        y: A.y + Math.round(10000 * (vec.x * sin + vec.y * cos)) / 10000
    };
}

export function pontoMedio3(A, B, C) {
    return { x: (A.x + B.x + C.x) / 3, y: (A.y + B.y + C.y) / 3 };
}

export function debugar() {
  //debugger;
}

export function is3D(arquivo) {
  if (
    arquivo.endsWith(".dfx") ||
    arquivo.endsWith(".dfx") ||
    arquivo.endsWith(".obj") ||
    arquivo.endsWith(".obj") ||
    arquivo.endsWith(".ply") ||
    arquivo.endsWith(".ply") ||
    arquivo.endsWith(".stl") ||
    arquivo.endsWith(".stl")
  ) {
    return true;
  } else {
    return false;
  }
}
export function formatarData(originalValor) {
  const dateObj = new Date(originalValor);
  const today = new Date();

  function padZero(number) {
    return (number < 10 ? "0" : "") + number;
  }

  if (
    dateObj.getDate() === today.getDate() &&
    dateObj.getMonth() === today.getMonth() &&
    dateObj.getFullYear() === today.getFullYear()
  ) {
    const hora = padZero(dateObj.getHours());
    const minutos = padZero(dateObj.getMinutes());
    return `Hoje • ${hora}:${minutos}`;
  }

  const dia = padZero(dateObj.getDate());
  const mes = padZero(dateObj.getMonth() + 1);
  const ano = dateObj.getFullYear();
  const hora = padZero(dateObj.getHours());
  const minutos = padZero(dateObj.getMinutes());

  return `${dia}/${mes}/${ano} • ${hora}:${minutos}`;
}

export function Bezier4(P1, p2, p3, p4, mu)
{
            var mum1, mum13, mu3;
    var p = { x:0, y: 0 };

    mum1 = 1 - mu;
    mum13 = mum1 * mum1 * mum1;
    mu3 = mu * mu * mu;

    p.x = (mum13 * P1.x + 3 * mu * mum1 * mum1 * p2.x + 3 * mu * mu * mum1 * p3.x + mu3 * p4.x);
    p.y = (mum13 * P1.y + 3 * mu * mum1 * mum1 * p2.y + 3 * mu * mu * mum1 * p3.y + mu3 * p4.y);

    return p;
}


export class Point {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
}

export function syncSessao()
    {

    ///////////////////// SESSAO ////////////////////////
    // transfers sessionStorage from one tab to another
    var sessionStorage_transfer = function (event) {
        if (!event) { event = window.event; } // ie suq
        if (!event.newValue) return;          // do nothing if no value to work with
        if (event.key == 'getSessionStorage') {
            // another tab asked for the sessionStorage -> send it
            localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
            // the other tab should now have it, so we're done with it.
            localStorage.removeItem('sessionStorage'); // <- could do short timeout as well.
        } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
            // another tab sent data <- get it
            var data = JSON.parse(event.newValue);
            for (var key in data) {
                sessionStorage.setItem(key, data[key]);
            }
        }
    };

    // listen for changes to localStorage
    if (window.addEventListener) {
        window.addEventListener("storage", sessionStorage_transfer, false);
    } else {
        window.attachEvent("onstorage", sessionStorage_transfer);
    };


    // Ask other tabs for session storage (this is ONLY to trigger event)
    if (!sessionStorage.length) {
        localStorage.setItem('getSessionStorage', 'foobar');
        localStorage.removeItem('getSessionStorage', 'foobar');
    };
    ///////////////////// FIM SESSAO ////////////////////////
}