import { Button } from "@cdt-ui/react";

import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";

import { Field, Formik, Form } from "formik";

import { Input } from "../Input";
import { CollapseCard } from "../CollapseCard";

export function SubCard({ styles, utils, title }) {
  const { collapse, handleEdit, errors, values, setFieldValue } = utils;
  return (
    <>
      {collapse ? (
        <CollapseCard
          styles={styles}
          title={title}
          handleEdit={() => handleEdit(values, setFieldValue)}
        />
      ) : (
        <>
          <p className={styles.heading}>CADASTRAR AULA</p>

          <div className={styles.inputsContainer}>
            <Input
              errors={errors.title}
              name="title"
              placeholder="Digite o título da aula"
              title="Título da Aula"
              isTextArea={false}
              label="Título"
            />
            <Input
              errors={errors.desc}
              name="desc"
              placeholder="Digite a descrição da aula (opcional)"
              title="Descrição da Aula"
              isTextArea={true}
              label="Descrição (opcional)"
            />
          </div>
          <div className={styles.horLine}></div>
          <p className={styles.heading}>MÍDIA (VÍDEO)</p>

          <div className={styles.inputsContainer}>
            <Input
              errors={errors.vidLink}
              name="vidLink"
              placeholder="www.youtube.com/812C7vu81"
              title="Link do Vídeo"
              isTextArea={false}
              label="Link do Vídeo"
            />
          </div>
          <div className={styles.horLine}></div>
          <p className={styles.heading}>MATERIAL (CONTEÚDO EXTRA)</p>
          <div className={styles.inputsContainer}>
            <Input
              errors={errors.extra}
              name="extra"
              placeholder="www.youtube.com/812C7vu81"
              title="Conteúdo Extra"
              isTextArea={false}
              label="Link"
            />
          </div>

          <div className={styles.submitContainer}>
            <div className={styles.checkBoxContainer}>
              <Field
                name="createNext"
                id="createNext"
                type="checkbox"
                title="Criar aula em seguida"
                errors={errors.createNext}
              />
              <label htmlFor="createNext">Criar aula em seguida</label>
            </div>
            <Button
              className={styles.submitBtn}
              type="submit"
              variant="greenDark"
              size="full"
            >
              <CheckIcon width={20} />
            </Button>
          </div>
        </>
      )}
    </>
  );
}
