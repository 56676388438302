import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AnyObject } from "yup";
import { useSelector, useDispatch } from "react-redux";
import { Checks, ArrowCircleUp } from "@phosphor-icons/react";
import { CalendarIcon, CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { toast } from "react-hot-toast";

import { selectIsCefalometria } from "../../store/slices/isCefalometriaSlice";
import { selectPatientsList } from "../../store/slices/patientListSlice";

import styles from "./styles.module.css";
import { LoadingClinics } from "../LoadingClinics";
import { selectResearchPatient, setResearchPatient } from "../../store/slices/researchPatientSlice";

interface TableBodyProps {
  bodyContents: AnyObject;
  getScrollTo?: any;
}

export function TableBody({ bodyContents, getScrollTo, }: TableBodyProps) {
  const scrollRef = useRef(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const { isCefalometria } = useSelector(selectIsCefalometria);
  const { researchPatient } = useSelector(selectResearchPatient);

  const [isSeeMoreActive, setSeeMoreActive] = useState<Boolean>(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const bodyContent = {
    id: bodyContents.id,
    name: bodyContents.name,
  };

  const clinica = bodyContents.description[4];

  const renderFirst14Characters = (text: string) => {
    if (String(text).length <= 14) return text;

    return String(text).slice(0, 14);
  };

  const formattedName = bodyContent.name.replace(/\s+/g, "_");

  function handlePatientClick() {
    scrollRef.current = window.scrollY;
    getScrollTo(scrollRef.current);
    // localStorage.setItem("scrollValue", scrollRef.current.toString());
  }

  const handleRowClick = () => {
    const linkTo = {
      pathname: `/dashboard/${!isCefalometria ? `perfil-paciente/${bodyContents.ss}` : 'visualizar-imagens'}/`,
      search: !isCefalometria
        ? `?paciente=${formattedName}&seqAtend=${bodyContents.seqAtend}&idClient=${bodyContents.idClient}&idPacient=${bodyContents.idPacient}`
        : `?&cefalometria=s&paciente=${formattedName}&id=${encodeURIComponent(bodyContents.idClient)}&atd=${bodyContents.seqAtend}&ss=${bodyContents.ss}&idPacient=${bodyContents.idPacient}`,
    };

    if (screenSize.width < 800 && isCefalometria) {
      toast("Visualização CEFALOMETRIA apenas na versão desktop!", {
        position: "top-right",
        style: {
          background: "#f09500",
          color: "#fff",
          textAlign: 'center'
        },
      });
    } else if (isCefalometria && researchPatient) {
      dispatch(setResearchPatient(false));
    } else {
      localStorage.setItem("idPaciente", bodyContents.ss);
      navigate(linkTo.pathname + linkTo.search);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <tbody>
      <tr onClick={handleRowClick}>
        {bodyContents.description.map((description, index) => (
          <td key={index}>
            {description === "Atendido" ? (
              <div className={`${styles.titleClass} ${styles.atendido}`}>
                <Checks width={14} />
                {description}
              </div>
            ) : description === "Agendado" ? (
              <div className={`${styles.titleClass} ${styles.agendado}`}>
                <CalendarIcon width={14} />
                {description}
              </div>
            ) : description === "Em andamento" ? (
              <div className={`${styles.titleClass} ${styles.emAndamento}`}>
                <CheckIcon width={14} />
                {description}
              </div>
            ) : description === "Entregue" ? (
              <div className={`${styles.titleClass} ${styles.entregue}`}>
                <Checks width={14} />
                {description}
              </div>
            ) : description === "Cancelado" ? (
              <div className={`${styles.titleClass} ${styles.cancelado}`}>
                <XMarkIcon width={14} />
                {description}
              </div>
            ) : description === "Alta" ? (
              <div className={`${styles.titleClass} ${styles.alta}`}>
                <ArrowCircleUp width={14} />
                {description}
              </div>
            ) : description === clinica || (clinica && clinica.includes(description)) ? (
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  setSeeMoreActive((prevState) => !prevState);
                }}
              >
                {!isSeeMoreActive ? renderFirst14Characters(description) : description}
                {String(description).length > 14 ? "..." : ""}
              </div>
            ) : index === 3 ? (
              <div>{truncateText(description, 15)}</div>
            ) : description == null || description === undefined ? (
              <div>
                <span>Indisponível</span>
              </div>
            ) : (
              <>{description}</>
            )}
          </td>
        ))}
      </tr>
    </tbody>
  );
}
