import { Button, Text, Topbar } from "@cdt-ui/react";
import { Sidebar } from "./components/Sidebar";

import { Navigate, Route, Routes, useParams, useNavigate } from "react-router";
import { useEffect, useRef } from "react";
import styles from "./styles.module.css";
import {
  ArrowLeft,
  ArrowRight,
  Bookmark,
  TagChevron,
} from "@phosphor-icons/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import { Footer } from "../../../components/Footer";
import { aulas } from "../../../Data/aulas";
import { academy } from "../../../Data/academy";
import { toast } from "react-hot-toast";
import { Teste } from "./components/teste";
import Api from "../../../helpers/Api";
import { UserCard } from "./components/UserCard";
import { AcademyMobile } from "../AreaEstudos";
import { Loading } from "../../IndicadoresOportunidade/components/Loading";

// import { load } from "mime";
interface Teacher {
  name: string;
}
export function Academy({ sidebarState }) {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [lesson, setLesson] = useState([]);
  const savedIndex = localStorage.getItem("currentLessonIndex");
  const initialIndex = savedIndex !== null ? parseInt(savedIndex) : 0;
  const [currentLessonIndex, setCurrentLessonIndex] = useState(initialIndex);
  const [collapse, setCollapse] = useState(true);

  const sortedLesson = lesson.slice().sort((a, b) => a.id - b.id);

  const [teacher, setTeacher] = useState<Teacher | undefined>(undefined);
  const videoRef = useRef(null);
  const iframeRef = useRef(null);
  const navigate = useNavigate();

  const currentLesson =
    sortedLesson.length > 0 ? sortedLesson[currentLessonIndex] : null;
  function handleCollapse() {
    setCollapse(!collapse);
  }

  useEffect(() => {
    const submitSeachClinic = async (id) => {
      try {
        const results = await Api.getLessons(id);
        setLesson(results.data);

        setLoading(true);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    submitSeachClinic(id);
  }, []);

  useEffect(() => {
    localStorage.setItem("currentLessonIndex", currentLessonIndex.toString());
  }, [currentLessonIndex]);

  function nextLesson() {
    if (currentLessonIndex < sortedLesson.length - 1) {
      setCurrentLessonIndex(currentLessonIndex + 1);
    }
  }
  function previusLesson() {
    if (currentLessonIndex > 0) {
      setCurrentLessonIndex(currentLessonIndex - 1);
    }
  }
  const handleButtonClick = () => {
    window.open(currentLesson ? currentLesson.linkExtraContent : "");
  };

  const handleSelectLesson = (selectedLesson: any) => {
    const index = sortedLesson.findIndex(
      (sortedLesson) => sortedLesson.id === selectedLesson.id
    );
    if (index !== -1) {
      setCurrentLessonIndex(index);
      loadVideo();
    }
  };

  useEffect(() => {
    if (currentLesson) {
      (async () => {
        try {
          const t = await Api.getTeacherById(currentLesson.course.idTeacher);
          if (t.isSuccess) {
            setTeacher(t.data);
          }
        } catch (err) {
          console.error(err);
        } finally {
        }
      })();
    }
  }, [currentLesson]);

  const [videoPlayer, setVideoPlayer] = useState(null);

  useEffect(() => {
    if (currentLesson && videoPlayer) {
      // Load the video when the current lesson changes
      videoPlayer.postMessage(
        {
          command: "loadVideo",
          videoUrl: currentLesson.video,
        },
        "*"
      );

      // Play the video (optional)
      // videoPlayer.postMessage({ command: "playVideo" }, "*");
    }
  }, [currentLesson, videoPlayer]);
  // useEffect(() => {
  //   if (videoRef.current) {
  //     // Access the video player's contentWindow
  //     const contentWindow = videoRef.current.contentWindow;

  //     console.log("Setting up event listener");

  //     // Listen for messages from the video player
  //     const handleMessage = (event) => {
  //       console.log("Message received:", event.data);

  //       const message = event.data;
  //       console.log(message.data);
  //       const currentTime = message.data.currentTime;
  //       console.log("Current Time:", currentTime);
  //       // Check if the message contains video time information
  //       if (message && message.event === "timeupdate") {
  //         const currentTime = message.data.currentTime;
  //         console.log("Current Time:", currentTime);

  //         // You can update your state or perform actions here
  //       } else if (message && message.event === "ended") {
  //         // The video has ended
  //         console.log("Video Ended");
  //         // You can perform actions when the video ends here
  //       }
  //     };

  //     // Attach the message event listener
  //     contentWindow.addEventListener("message", handleMessage);

  //     // Set the videoPlayer state
  //     setVideoPlayer(contentWindow);
  //     // Attach the message event listener
  //     contentWindow.addEventListener("message", handleMessage);

  //     // Set the videoPlayer state
  //     setVideoPlayer(contentWindow);
  //   }
  // }, []);
  const handleCallToActionButton = (link) => {
    window.location.href = link;
  };

  const loadVideo = () => {
    if (!videoLoaded && currentLesson) {
      const videoPromise = new Promise((resolve, reject) => {
        const video = document.createElement("video");
        video.src = currentLesson.video;

        video.onloadedmetadata = () => {
          resolve(video);
        };

        video.onerror = (error) => {
          reject(error);
        };
      });

      videoPromise
        .then((video) => {
          const iframe = iframeRef.current;
          if (iframe) {
            iframe.src = video;
          }

          setVideoLoaded(true);
        })
        .catch((error) => {
          console.error("Erro ao carregar o vídeo:", error);
        });

      setVideoLoaded(true);
    }
  };

  useEffect(() => {
    if (!videoLoaded) loadVideo();
  });

  return (
    <section>
      {currentLesson ? (
        <div className={styles.academy}>
          <div className="dashboard-layout">
            {/*<Sidebar links={aulas} /> */}
            <Teste
              links={sortedLesson}
              sidebarState={sidebarState}
              onSelectLesson={handleSelectLesson}
            />
            <main className="dashboard-content">
              <div className={styles.container}>
                <div className={styles.containerHeaderTitle}>
                  <div className={styles.containerButton}>
                    <Text className={styles.containerTitle}>
                      {currentLesson ? currentLesson.title : "Carregando..."}
                    </Text>
                  </div>
                  <div>
                    <Text className={styles.containerSubtitle}>
                      {currentLesson
                        ? `● ${currentLesson.description}`
                        : "Carregando..."}
                    </Text>
                  </div>
                </div>

                {!loading && videoLoaded ? (
                  <div className={styles.containerIMG}>
                    <iframe
                      /*                     ref={videoRef} */
                      ref={iframeRef}
                      id="myYouTubeVideo"
                      className={styles.video}
                      src={currentLesson ? currentLesson.video : ""}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                ) : (
                  <p>Carregando...</p>
                )}
                {/*   <img className={styles.containerIMG} src="/image.png" /> */}

                <div className={styles.containerButton}>
                  <Button
                    variant="greenLight"
                    className={styles.buttons}
                    onClick={previusLesson}
                  >
                    <ArrowLeft /> Aula Anterior
                  </Button>

                  {currentLesson?.linkCallToAction &&
                    currentLesson?.textButtonCallToAction && (
                      <Button
                        variant="greenDark"
                        className={styles.buttons}
                        style={{
                          width: "auto",
                          paddingLeft: "25px",
                          paddingRight: "25px",
                        }}
                        onClick={() =>
                          handleCallToActionButton(
                            currentLesson.linkCallToAction
                          )
                        }
                      >
                        {currentLesson.textButtonCallToAction}
                      </Button>
                    )}

                  <Button
                    variant="greenDark"
                    className={styles.buttons}
                    onClick={nextLesson}
                  >
                    Próxima aula <ArrowRight />
                  </Button>
                </div>
              </div>
              <div
                className={
                  collapse ? `${styles.aside_collapse}` : `${styles.aside}`
                }
              >
                {collapse ? (
                  <>
                    <div className={styles.containerHeaderCollapse}>
                      <div className={styles.containertext}>
                        <div className={styles.containerSelect}>
                          <div
                            className={styles.buttonHamburguer}
                            onClick={handleCollapse}
                          >
                            <p className={styles.containerAnimationOn}>
                              Professor
                            </p>
                            <div className={styles.containerLine}></div>
                          </div>
                          <div
                            className={styles.buttonHamburguer}
                            onClick={handleCollapse}
                          >
                            <p className={styles.containerAnimationOff}>
                              Material de estudo
                            </p>
                          </div>
                        </div>
                        {teacher && <UserCard teacher={teacher} />}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.containerHeaderCollapse}>
                      <div className={styles.containertext}>
                        <div className={styles.containerSelect}>
                          <div
                            className={styles.buttonHamburguer}
                            onClick={handleCollapse}
                          >
                            <p className={styles.containerAnimationOff}>
                              Professor
                            </p>
                          </div>
                          <div
                            className={styles.buttonHamburguer}
                            onClick={handleCollapse}
                          >
                            <p className={styles.containerAnimationOn}>
                              Material de estudo
                            </p>
                            <div className={styles.containerLine}></div>
                          </div>
                        </div>
                        {(currentLesson &&
                          currentLesson.linkExtraContent === null) ||
                        (currentLesson &&
                          currentLesson.linkExtraContent === "") ? (
                          <div></div>
                        ) : (
                          <div
                            className={styles.containerInfoExtra}
                            onClick={handleButtonClick}
                          >
                            <div className={styles.containerInfoExtraContent}>
                              <div>
                                <p>Conteúdo Extra</p>
                                <p>Link para o PDF da aula</p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </main>
          </div>
        </div>
      ) : (
        <Loading />
      )}
      {currentLesson ? (
        <div className={styles.academyMobile}>
          <AcademyMobile sidebarState></AcademyMobile>
        </div>
      ) : (
        <Loading />
      )}
    </section>
  );
}
