import Modal from "react-modal";
import { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select, { SingleValue } from 'react-select';

import { Input } from "../../../components/Form/Input";
import { InputMask } from "../../../components/Form/InputMask";

import styles from "./styles.module.css";
import { customSelectStylesExames } from "../SelectPatienteAndSignature/SelectStylesExames";

interface AddPatientManualProps {
  isOpen: boolean;
  onRequestClose: () => void;
  examRequestToEdit: any;
  restartStyles: () => void;
  setSelectedPatient: any;
}

interface OptionType {
  value: string;
  label: string;
}

export const AddPatientManual = ({
  isOpen,
  onRequestClose,
  examRequestToEdit,
  restartStyles,
  setSelectedPatient,
}: AddPatientManualProps) => {

  const options = [
    { value: 'Masculino', label: 'Masculino' },
    { value: 'Feminino', label: 'Feminino' },
  ];

  const [selectedPatientSex, setSelectedPatientSex] = useState<OptionType | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [cityOptions, setCityOptions] = useState([{ value: " ", label: " " }]);

  const handleChangePatientSex = (selectedOption: SingleValue<OptionType>) => {
    setSelectedPatientSex(selectedOption as OptionType);
  };

  // const validationSchema = Yup.object({
  //   name: Yup.string().required("O nome do paciente é obrigatório!"),
  //   sex: Yup.string().required("O sexo é obrigatório!"),
  //   tell: Yup.string().required("O telefone é obrigatório!"),
  //   DataNasc: Yup.date().required("A data de nascimento é obrigatória!"),
  //   CPF: Yup.string().required("O CPF é obrigatório!"),
  //   RG: Yup.string().required("O RG é obrigatório!"),
  //   Email: Yup.string().email("Formato de email inválido!").required("O email é obrigatório!"),
  //   CEP: Yup.string().required("O CEP é obrigatório!"),
  //   Endereco: Yup.string().required("O endereço é obrigatório!"),
  // });

  const handleChangecpf = (event: any, setFieldValue: any) => {
    let { value } = event.target;

    value = value.replace(/\D/g, "");

    if (value.length > 11) {
      value = value.slice(0, 11);
    }

    if (value.length > 9) {
      value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }

    setFieldValue("CPF", value);
  };

  const handleChangeRg = (event: any, setFieldValue: any) => {
    let { value } = event.target;
    value = value.replace(/\D/g, "");

    if (value.length > 9) {
      value = value.slice(0, 9);
    }

    if (value.length > 1) {
      value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, "$1.$2.$3-$4");
    }

    setFieldValue("RG", value);
  };

  function onBlurCep(ev, setFieldValue) {
    const { value } = ev.target;

    const cep = value?.replace(/[^0-9]/g, "");

    if (cep?.length !== 8) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setFieldValue("estado", data.uf);
        fetchCities(data.uf);
        setFieldValue("Endereco", data?.logradouro);
      })
      .catch((error) => {
        console.error("Error fetching CEP data:", error);
      });
  };

  const fetchCities = (estado: string) => {
    setLoading(true);
    fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/distritos`
    )
      .then((res) => res.json())
      .then((data) => {
        const cities = data.map((item) => ({
          label: item.nome,
          value: item.nome,
        }));
        setCityOptions(cities);
        setTimeout(() => {
          setLoading(false);
        }, 1000)
      })
      .catch((error) => {
        console.error("Error fetching cities data:", error);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  const calculateAgeInYearsAndMonths = (birthDate) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);

    let years = today.getFullYear() - birthDateObj.getFullYear();
    let months = today.getMonth() - birthDateObj.getMonth();

    if (months < 0) {
      years--;
      months += 12;
    }

    if (today.getDate() < birthDateObj.getDate()) {
      months--;
      if (months < 0) {
        years--;
        months += 12;
      }
    }

    return `${years}a ${months}m`;
  };


  const handleManualSubmit = (values) => {
    const idade = calculateAgeInYearsAndMonths(values.DataNasc);

    const patientData = {
      idPaciente: 0,
      paciente: values.name,
      sexo: values.sex,
      tell: values.tell,
      dataNasc: values.DataNasc,
      idade,
      cpf: values.CPF,
      rg: values.RG,
      email: values.Email,
      cep: values.CEP,
      endereco: values.Endereco,
      status: 1,
    };
    setSelectedPatient(patientData);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        onRequestClose()
        setSelectedPatientSex(null);
      }}
      overlayClassName="react_modal_overlay"
      className={styles.react_modal_Send_Request}
    >
      <div className={styles.wrapper}>
        <div className={styles.xIconWrapper}>
          <XMarkIcon
            title="Fechar"
            onClick={() => {
              restartStyles();
              onRequestClose();
            }}
          />
        </div>

        <div className={styles.textContainer}>
          <h2>Enviar Requisição ao Paciente</h2>
          <p>Preencha os dados do paciente</p>
        </div>

        <Formik
          initialValues={{
            name: '',
            sex: '',
            tell: '',
            DataNasc: '',
            CPF: '',
            RG: '',
            Email: '',
            CEP: '',
            Endereco: '',
          }}
          validate={(values) => {
            const errors: any = {};
            if (!values.sex) {
              errors.sex = 'Por favor, selecione um sexo.';
            }
            return errors;
          }}
          onSubmit={(values, { resetForm }) => {
            if (values.sex) {
              handleManualSubmit(values);
              resetForm();
              setSelectedPatientSex(null);
            }
          }}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            errors,
            isSubmitting,
            values,
            setFieldValue,
            handleChange,
          }) => (
            <Form>
              <div className={styles.buttonsContainer}>

                <div className={styles.wrapper_input}>
                  <Input
                    name="name"
                    type="text"
                    title="Nome do Paciente"
                    placeholder="Como será chamado o paciente"
                    error={errors.name}
                  />
                  {/* <Field
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Nome do Paciente"
                    required
                    className={styles.requestName}
                  />
                  <ErrorMessage name="name" component="p" className={styles.error} /> */}
                </div>

                <div className={styles.wrapper_input}>
                  <label htmlFor="sex" className={styles.label}>
                    Sexo
                  </label>
                  <Select
                    value={selectedPatientSex}
                    name="sex"
                    placeholder="Escolha um sexo"
                    options={options}
                    styles={customSelectStylesExames}
                    onChange={(selectedOption: any) => {
                      setFieldValue('sex', selectedOption ? selectedOption.value : '');
                      handleChangePatientSex(selectedOption);
                    }}
                  />
                  {errors.sex && <span style={{ color: 'red' }} className={styles.textError}>{errors.sex}</span>}
                </div>
              </div>

              <div className={styles.buttonsContainer}>
                <div className={styles.wrapper_input}>
                  <Input
                    name="DataNasc"
                    type="date"
                    title="Data de Nascimento"
                    labelStyleAbsolute
                    error={errors.DataNasc}
                  />
                </div>

                <div className={styles.wrapper_input}>
                  <Input
                    type="text"
                    title="CPF"
                    name="CPF"
                    onChange={(event) =>
                      handleChangecpf(event, setFieldValue)
                    }
                    value={values.CPF}
                    placeholder="000.000.000-00"
                    labelStyleAbsolute
                    error={errors.CPF}
                    noRequired
                  />
                </div>
              </div>

              <div className={styles.buttonsContainer}>

                <div className={styles.wrapper_input}>
                  <Input
                    type="text"
                    title="RG"
                    name="RG"
                    onChange={(event) =>
                      handleChangeRg(event, setFieldValue)
                    }
                    value={values.RG}
                    placeholder="00.000.000-0"
                    labelStyleAbsolute
                    error={errors.RG}
                    noRequired
                  />
                </div>

                <div className={styles.wrapper_input}>
                  <InputMask
                    type="text"
                    title="Celular"
                    name="tell"
                    value={values.tell}
                    onChange={handleChange}
                    mask="(00) 00000-0000"
                    placeholder="(00) 00000-0000"
                    labelStyleAbsolute
                    error={errors.tell}
                  />
                  {/* <label htmlFor="tell" className={styles.label}>
                    Telefone
                  </label>
                  <Field
                    id="tell"
                    name="tell"
                    type="text"
                    placeholder="Telefone"
                    required
                    className={styles.requestName}
                  />
                  <ErrorMessage name="tell" component="p" className={styles.error} /> */}
                </div>
              </div>

              <div className={styles.buttonsContainer}>

                <div className={styles.wrapper_input}>
                  <Input
                    name="Email"
                    type="email"
                    title="E-mail"
                    placeholder="exemplo@exemplo.com"
                    error={errors.Email}
                    noRequired
                    labelStyleAbsolute
                  />
                </div>

                <div className={styles.wrapper_input}>
                  <InputMask
                    type="text"
                    title="CEP"
                    onBlur={(ev) => {
                      onBlurCep(ev, setFieldValue);
                    }}
                    name="CEP"
                    value={values.CEP}
                    mask="00000-000"
                    onChange={handleChange}
                    placeholder="00000-000"
                    labelStyleAbsolute
                  // error={
                  //   touched.cep && !values.cep
                  //     ? "Campo obrigatório"
                  //     : errors.cep
                  // }
                  />
                  {/* <label htmlFor="CEP" className={styles.label}>
                    CEP
                  </label>
                  <Field
                    id="CEP"
                    name="CEP"
                    type="text"
                    placeholder="CEP"
                    required
                    className={styles.requestName}
                  />
                  <ErrorMessage name="CEP" component="p" className={styles.error} /> */}
                </div>
              </div>


              <div className={styles.wrapper_input}>
                <Input
                  name="Endereco"
                  type="text"
                  title="Endereço"
                  placeholder="Ex: Rua José de Freitas"
                  /* value={dataAPICep !== null ? String(dataAPICep?.logradouro) : ''} */
                  error={errors.Endereco}
                  noRequired
                />
                {/* <label htmlFor="Endereco" className={styles.label}>
                  Endereço
                </label>
                <Field
                  id="Endereco"
                  name="Endereco"
                  type="text"
                  placeholder="Endereço"
                  required
                  className={styles.requestName}
                />
                <ErrorMessage name="Endereco" component="p" className={styles.error} /> */}
              </div>

              <div className={styles.buttonsContainer}>
                <div className={styles.containerButtonEmail}>
                  <button onClick={() => onRequestClose()}>
                    Cancelar
                  </button>
                </div>

                <div className={styles.containerButtonWhatsapp}>
                  <button type="submit">Salvar</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal >
  );
};
