import { Link } from "react-router-dom";

import styles from './styles.module.css'

export function NotFound() {
    return (
        <section className={styles.container}>
            <div >
                <div className={styles.containerImage}>
                    <img
                        className="w-[200px] md:w-[400px]"
                        src="/logoserodonto.png"
                    />
                </div>
                <div>
                    <div>
                        <h1>404...</h1>
                        <p>Página que você requistou não foi encontrada.</p>
                    </div>
                    <Link to="/" className={styles.link}>
                        <span className="">Retornar à Home</span>
                    </Link>
                </div>

            </div>
        </section>
    )
}