import ImagemMarketing from "../Images/Marketing.png";
import { Paywall } from "../Components";
export function Marketing() {

  const paywall = [
    {
      id: 1,
      videourl: "https://player-vz-fb154304-6eb.tv.pandavideo.com.br/embed/?v=6b84c984-db98-4625-85da-6510eadff487",
      title: "Marketing estratégico: atraia e fidelize clientes para um crescimento saudável e contínuo.",
      link: "https://api.whatsapp.com/send?phone=5512981507852&text=Estou%20interessado%20nas%20ferramentas%20de%20marketing%20do%20Serodonto!",
      subtitles: [
        {
          subtitle: "A Serodonto desenvolveu um módulo de marketing específico para clínicas odontológicas com recursos como pesquisas de satisfação, campanhas de marketing e disparo de mensagens.",
        },
        {
          subtitle: "As pesquisas de satisfação permitem mapear as necessidades dos pacientes, possibilitando a oferta de tratamentos personalizados e campanhas relevantes.",
        },
        {
          subtitle: "As mensagens são enviadas individualmente para cada paciente, com base em seu histórico de atendimentos, permitindo convites assertivos para retornos.",
        },
        {
          subtitle: "O módulo de marketing também permite o envio de mensagens em datas especiais, mantendo o relacionamento com os clientes.",
        },
        {
          subtitle: "A função permite integração com as principais ferramentas do mercado, garantindo agilidade nas rotinas e fortalecendo a reputação da clínica.",
        },
        
      ],
    },
  ];
  return (
    <>
      {paywall.map((item, index) => {
        return (
          <Paywall
            key={index}
            title={item.title}
            subtitles={item.subtitles}
            videourl={item.videourl}
            link={item.link}
            backgroundImage={ImagemMarketing}
          />
        );
      })}
      <></>
    </>
  );
}
