import Select from "react-select";

import {
  FireIcon,
  BuildingOffice2Icon,
  UserCircleIcon,
  CalendarIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

import styles from "../styles.module.css";

export function SelectInput({ name }) {
  let placeholder = null;
  if (name === "negociacao") {
    placeholder = (
      <>
        <FireIcon width={20} className={styles.playIcon} />
        Negociação
      </>
    );
  } else if (name === "clinica") {
    placeholder = (
      <>
        <BuildingOffice2Icon width={20} className={styles.playIcon} />
        Clínica
      </>
    );
  } else if (name === "origem") {
    placeholder = (
      <>
        <UserCircleIcon width={20} className={styles.playIcon} />
        Origem
      </>
    );
  } else if (name === "periodo") {
    placeholder = (
      <>
        <CalendarIcon width={20} className={styles.playIcon} />
        Período
      </>
    );
  } else if (name === "vendedor") {
    placeholder = (
      <>
        <UsersIcon width={20} className={styles.playIcon} />
        Vendedor
      </>
    );
  }
  return (
    <Select
      name={name}
      className={styles.selectOption}
      placeholder={<div className={styles.selectContainer}>{placeholder}</div>}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          width: "auto",
          minHeight: "41px",
          maxHeight: "auto",
          backgroundColor: "#EFEFEF",
          border: "none",
          borderRadius: "80px",
          zIndex: 10,
        }),
      }}
      id="situation"
      // onChange={(event) => setFieldValue("situation", event.map(value => value.value))}
      closeMenuOnSelect={true}
      isMulti
    />
  );
}
