import ImagemFiscal from "../Images/Fiscal.png";
import { Paywall } from "../Components";
export function Supervisor() {
  const paywall = [
    {
      id: 1,
      videourl: "https://player-vz-fb154304-6eb.tv.pandavideo.com.br/embed/?v=7bca813d-fa0c-4b31-88a6-f2aa7f5f9f4b",
      title: "Descomplicando tarefas administrativas e controle de convênios",
      link: "https://api.whatsapp.com/send?phone=5512983206773&text=Estou%20interessado%20nas%20ferramentas%20do%20módulo%20fiscal%20do%20Serodonto!",
      subtitles: [
        {
          subtitle:
            "O Módulo Fiscal da Serodonto automatiza a emissão de notas fiscais, notas de prontuário, notas de serviço e geração do DMED.",
        },
        {
          subtitle:
            "A plataforma permite acompanhar em tempo real os pagamentos e enviar lembretes automáticos para os pacientes, reduzindo inadimplência e erros.",
        },
        {
          subtitle:
            "O controle de convênios facilita a comunicação e negociação com as operadoras, além de auxiliar no controle financeiro e na conciliação de pagamentos.",
        },
        {
          subtitle:
            "Os recursos da plataforma permitem melhorar a eficiência dos processos internos, estabelecer parcerias lucrativas com convênios e planos de saúde, implementar tecnologias avançadas e prestar atendimentos mais personalizados.",
        },
      ],
    },
  ];
  return (
    <>
      {paywall.map((item, index) => {
        return (
          <Paywall
            key={index}
            title={item.title}
            subtitles={item.subtitles}
            videourl={item.videourl}
            link={item.link}
            backgroundImage={ImagemFiscal}
          />
        );
      })}
      <></>
    </>
  );
}
