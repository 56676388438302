import styles from '../../styles.module.css'
import { CardRelatorio } from '../components/cardRelatorio';

export function Atm({ getRelatoriosSelect }: any) {
    const arrayDeRelatorios = [
        { nome: 'Fatores', codigo: 45, img: "../../modalRelatorio/ATM/1.png" },
        { nome: 'Fatores + grafico', codigo: 46, img: "../../modalRelatorio/ATM/2.png" },
        { nome: 'Com imagem', codigo: 47, img: "../../modalRelatorio/ATM/3.png" },
        { nome: 'Com desenho', codigo: 48, img: "../../modalRelatorio/ATM/4.png" },
    ];

    return (
        <div className={styles.wrapper}>
            <div className={styles.container_content}>
                {arrayDeRelatorios.map((relatorio, index) => (
                    <div key={index} className={styles.wrapperImg}>
                        <CardRelatorio
                            nome={relatorio.nome}
                            codigo={relatorio.codigo}
                            getRelatoriosSelect={getRelatoriosSelect}
                            img={relatorio.img}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}