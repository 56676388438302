import { useEffect, useState } from "react";

import {
  RocketLaunchIcon,
  Bars3Icon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";

import styles from "./styles.module.css";

import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import { Text } from "@cdt-ui/react";
import { academy } from "../../../../../Data/academy";
import { Circle } from "@phosphor-icons/react";
import { LessonItem } from "./NavLink";

interface SidebarProps {
  links: any;
  sidebarState: any;
}

type Props = {
  title: string;
  href: string;
};

interface NavLinkProps {
  item: any;
  collapse: boolean;
}

export function Teste({
  links,
  onSelectLesson,
  sidebarState,
}: SidebarProps & { onSelectLesson: (lesson: any) => void }) {
  function handleOpenMenuLink(lesson) {
    // Ensure you're passing the lesson (index here is misleading, maybe rename it)
    setOpenedLessonId(lesson.id);
    onSelectLesson(lesson);
  }
  const [collapse, setCollapse] = useState(false);

  function handleCollapse() {
    setCollapse(!collapse);
  }
  const [openedLessonId, setOpenedLessonId] = useState(null);
  const [open, setOpen] = useState(false);
  const [showLinkCollapse, setShowLinkCollapse] = useState(false);

  const asPath = window.location.pathname;

  return (
    <aside
      className={collapse ? `${styles.aside_collapse}` : `${styles.aside}`}
    >
      {collapse ? (
        <>
          <div
            className={styles.containerHeaderCollapse}
            onClick={handleCollapse}
          >
            <div className={styles.buttonHamburguer}>
              <ChevronDoubleRightIcon />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.containerHeader}>
            <div onClick={handleCollapse}>
              <div className={styles.buttonHamburguer}>
                <ChevronDoubleLeftIcon />
                <Text className={styles.TitleNav}>LISTA DE CONTEúDOS</Text>
              </div>
            </div>
          </div>
          {/* <div>
            <Text className={styles.TitleProgress}>Progresso do curso 1</Text>
            <div className={styles.ContentProgress}>
              <Text className={styles.TextGreen}> 0% </Text>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="138"
                height="12"
                viewBox="0 0 138 12"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.28302 5.89037C0.28302 2.63721 2.92023 0 6.17339 0H131.751C135.004 0 137.642 2.63721 137.642 5.89037C137.642 9.14353 135.004 11.7807 131.751 11.7807H6.17339C2.92023 11.7807 0.28302 9.14353 0.28302 5.89037Z"
                  fill="#007E69"
                />
              </svg>
            </div>
          </div> */}
          {links.map((lesson, idx) => (
            <LessonItem
              key={lesson.id}
              lesson={lesson}
              open={lesson.id === openedLessonId}
              onLessonClick={handleOpenMenuLink}
            />
          ))}
        </>
      )}
    </aside>
  );
}
