import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import Select, { components } from "react-select";
import styles from "./styles.module.css";
import Api from "../../../helpers/Api";
import axios from "axios";
import {
  PrinterIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/solid";

import { selectUser } from "../../../store/slices/userSlice";
import { selectPatientsList } from "../../../store/slices/patientListSlice";
import { setpatients } from "../../../store/slices/patientListSlice";
import {
  ArrowPathIcon,
  ChevronDownIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";

import { customSelectStylesExames } from "./SelectStylesExames";
import { PatientImage } from "../../PatientList/PatientImage";

import { useMediaQuery } from "react-responsive";
import { CircularProgress } from "@mui/material";
import { Loading } from "../../../components/Loading";
import { AddPatientManual } from "../AddPatientManual";

interface SelectPatientAndSignatureProps {
  isForEdit: boolean;
  selectedPatient: any;
  setSelectedPatient: React.Dispatch<React.SetStateAction<any>>;
  signature: HTMLElement;
  insertSignature: (signature: any) => void;
  setSignatureImage: any;
  clinics: any[];
  selectedClinic: any;
  setSelectedClinic: React.Dispatch<React.SetStateAction<any>>;
  patientList: any[];
  patientsLoading: boolean;
  setPatientsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  controller: AbortController;
  setPatientList: React.Dispatch<React.SetStateAction<any[]>>;
}

export const SelectPatientAndSignature = ({
  isForEdit,
  selectedPatient,
  setSelectedPatient,
  signature,
  insertSignature,
  setSignatureImage,
  clinics,
  selectedClinic,
  setSelectedClinic,
  patientList,
  patientsLoading,
  setPatientsLoading,
  controller,
  setPatientList,
}: SelectPatientAndSignatureProps) => {
  const [isSearchable, setIsSearchable] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [showProgress, setShowProgress] = useState(false);

  const { user } = useSelector(selectUser);
  const { patients } = useSelector(selectPatientsList);
  const dispatch = useDispatch();
  const inputFileRef = useRef(null);

  const isTabletDimension = useMediaQuery({ query: "(max-width: 1024px)" });


  useEffect(() => {
    const localPatient = localStorage.getItem("selectedPatient");
    const localClinic = localStorage.getItem("selectedClinic");
    /*     if (
          localPatient &&
          localPatient.trim() !== "" &&
          localPatient !== "undefined"
        ) {
          setSelectedPatient(JSON.parse(localPatient));
        } */
    if (
      localClinic &&
      localClinic.trim() !== "" &&
      localClinic !== "undefined"
    ) {
      setSelectedClinic(JSON.parse(localClinic));
    }
  }, []);

  useEffect(() => {
    clinics.map((clinic) => {
      clinic["value"] = clinic.clinicalName;
      clinic["label"] = clinic.clinicalName;
    });
  }, [clinics]);


  useEffect(() => {
    const fetchPatientsBySearch = async () => {
      if (
        patientList.some((patient) => patient.paciente.includes(inputValue)) ||
        inputValue === ""
      )
        return;
      try {
        setPatientsLoading(true);
        const response = await Api.GetPatientList(
          {
            idClient: selectedClinic.idClient,
            hash: selectedClinic.hash,
          },
          inputValue
        );
        if (response.data.patients) {
          response.data.patients.map((patient) => {
            patient["label"] = patient.paciente;
            patient["value"] = patient.paciente;
          });
          if (controller) {
            controller.abort();
          }
          setPatientList([...patientList, ...response.data.patients]);
        }
      } catch (error) {
      } finally {
        setPatientsLoading(false);
      }
    };
    fetchPatientsBySearch();
  }, [selectedClinic, inputValue]);

  const formatOptionLabel = (option) => {
    return (
      <span className={`${styles.formatOptionLabel} ${styles.patient}`}>
        <img src={option.imagem} alt="" />
        {option.value}
      </span>
    );
  };

  const [isAddPatientModalOpen, setAddPatientModalOpen] = useState(false); // New state to manage modal visibility
  const [manualEntry, setManualEntry] = useState(false);

  const handleAddPatient = (patientData) => {
    setSelectedPatient(patientData);
    setAddPatientModalOpen(false); // Close the modal after adding the patient
  };

  const toggleManualEntry = () => {
    setManualEntry(!manualEntry);
    if (manualEntry) {
      setSelectedPatient(null);
    }
  };

  const handleManualSubmit = (values) => {
    const patientData = {
      idPaciente: 0,
      paciente: values.name,
      sexo: values.sex,
      tell: values.tell,
      idade: values.age,
    };
    setSelectedPatient(patientData);
  };

  const formatOptionLabelClinic = (option) => {
    return (
      <span className={styles.formatOptionLabel}>
        <img
          src={`https://cdt.com.br/cli/p/getImagem.aspx?cmp=${option.idClient === null ? 3889 : option.idClient
            }&nm=2`}
          alt={`logo ${option.clinicalName}`}
          width={'auto'}
          height={20}
        />
        {option.value}
      </span>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDownIcon width={16} height={16} color="#8E8E8E" />
      </components.DropdownIndicator>
    );
  };

  const customLoadingIndicator = (props) => {
    return (
      <components.LoadingIndicator {...props}>
        <CircularProgress sx={{ color: "#006E5A" }} size={10} />
      </components.LoadingIndicator>
    );
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <CircularProgress sx={{ color: "#006E5A" }} size={20} />
      </components.NoOptionsMessage>
    );
  };

  const LoadingMessage = (props) => {
    return (
      <components.LoadingMessage {...props}>
        <CircularProgress sx={{ color: "#006E5A" }} size={20} />
      </components.LoadingMessage>
    );
  };

  const handleChange = (option) => {
    setSelectedPatient(option);
    localStorage.setItem("selectedPatient", JSON.stringify(option));
  };

  const handleChangeClinic = (option) => {
    setSelectedClinic(option);
    localStorage.setItem("selectedClinic", JSON.stringify(option));
  };

  const resetSelectedPatient = () => {
    setSelectedPatient(null);
  };

  const handleClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];

    if (selectedImage && selectedImage.type.startsWith("image/")) {
      const imgElement = document.createElement("img");

      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target.result;
        if (typeof result === "string") {
          imgElement.src = result;
          setSignatureImage(imgElement);
        }
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  useEffect(() => {
    if (selectedPatient) {
      setShowProgress(true);
      const timer = setTimeout(() => {
        setShowProgress(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [selectedPatient]);

  return (
    <div className={styles.wrapper}>
      <Formik initialValues={{}} onSubmit={() => { }}>
        {({ }) => (
          <Form>
            <div className={styles.wrapper_input}>
              <label htmlFor="searchClinic" className={styles.label}>
                Clínica
              </label>
              <Select
                value={selectedClinic}
                styles={customSelectStylesExames}
                isSearchable={isSearchable}
                name="searchClinic"
                placeholder="Digite o nome da clínica desejada"
                options={clinics}
                onChange={handleChangeClinic}
                formatOptionLabel={formatOptionLabelClinic}
                components={{ DropdownIndicator }}
                isDisabled={selectedPatient ? true : false}
              />
            </div>

            <>
              {showProgress && !isTabletDimension ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    opacity: showProgress ? 1 : 0,
                    transform: showProgress ? "scale(1)" : "scale(0.9)",
                    transition: "opacity 0.5s ease, transform 0.5s ease",
                  }}
                >
                  <CircularProgress sx={{ color: "#006E5A" }} />
                </div>
              ) : selectedPatient && !isTabletDimension ? (
                <div
                  className={styles.patientDetails}
                  style={{
                    opacity: !showProgress ? 1 : 0,
                    transform: !showProgress ? "scale(1)" : "scale(0.9)",
                    transition: "opacity 0.5s ease, transform 0.5s ease",
                  }}
                >
                  <div className={styles.patientDetailsHeader}>
                    <span>
                      <img
                        src={selectedPatient?.imagem || 'https://www.ecp.org.br/wp-content/uploads/2017/12/default-avatar-300x300.png'}
                        alt={selectedPatient.paciente}
                        onError={(e) => {
                          e.currentTarget.src = 'https://www.ecp.org.br/wp-content/uploads/2017/12/default-avatar-300x300.png';
                        }}
                      />
                    </span>
                    <div>
                      <h4>#{selectedPatient.idPaciente}</h4>
                      <h3>{selectedPatient.paciente}</h3>
                      <p>{selectedPatient.idade}</p>
                    </div>
                    <div
                      className={styles.circle}
                      onClick={() => {
                        if (!isForEdit) {
                          resetSelectedPatient();
                        }
                      }}
                      title="Desfazer"
                    >
                      <ArrowPathIcon width={14} />
                    </div>
                  </div>

                  <div className={styles.patientDetailsBody}>
                    <div>
                      <h6>Sexo</h6>
                      <h5>{selectedPatient?.sexo}</h5>
                    </div>
                    <div>
                      <h6>CPF</h6>
                      <h5>{selectedPatient?.cpf || "-----"}</h5>
                    </div>
                    <div>
                      <h6>RG</h6>
                      <h5>{selectedPatient?.rg || "-----"}</h5>
                    </div>
                  </div>
                </div>
              ) : selectedClinic && patientList.length > 0 ? (
                <>
                  <div className={styles.containerPacient}>
                    <p className={styles.title}>Paciente</p>

                    <div className={styles.containerButtonAddSignature}>
                      <button
                        type="button"
                        className={styles.buttonAddSignature}
                        onClick={() => setAddPatientModalOpen(true)}
                      >
                        <PlusIcon width={20} />
                        Adicionar Paciente
                      </button>
                    </div>
                  </div>

                  <div className={styles.wrapper_input}>
                    <label htmlFor="searchPatients" className={styles.label}>
                      Paciente
                    </label>
                    <Select
                      defaultValue={selectedPatient}
                      isSearchable={isSearchable}
                      name="searchPatients"
                      placeholder="Digite o nome do paciente desejado"
                      options={patientList}
                      onInputChange={setInputValue}
                      styles={customSelectStylesExames}
                      onChange={handleChange}
                      formatOptionLabel={formatOptionLabel}
                      isLoading={patientsLoading}
                      components={{
                        DropdownIndicator,
                        LoadingIndicator: customLoadingIndicator,
                        NoOptionsMessage,
                        LoadingMessage,
                      }}
                    />
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: '1rem'
                  }}
                >
                  <CircularProgress sx={{ color: "#006E5A" }} />
                </div>
              )}
            </>

            <p className={styles.title}>
              Assinatura
            </p>
            <div className={styles.wrapper_input}>
              {/*             <label htmlFor="" className={styles.label}>Assinatura</label> */}
              {/*             <Select
              isSearchable={isSearchable}
              name="searchSignature"
              placeholder="Selecione a assinatura: "
              options={signatureOptions}
              styles={selectStyles}
              onChange={insertSignature}
              components={{ DropdownIndicator }}
              formatOptionLabel={ formatOptionLabelSignature }
            />  */}
              <div className={styles.containerButtonAddSignature}>
                <button
                  className={styles.buttonAddSignature}
                  onClick={handleClick}
                >
                  <PlusIcon width={20} />
                  Adicionar Assinatura
                </button>
              </div>
            </div>
            <input
              ref={inputFileRef}
              type="file"
              accept="/images*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </Form>
        )}
      </Formik>

      <AddPatientManual
        isOpen={isAddPatientModalOpen}
        onRequestClose={() => setAddPatientModalOpen(false)}
        examRequestToEdit={null}
        restartStyles={() => { }}
        setSelectedPatient={handleAddPatient}
      />
    </div>
  );
};
