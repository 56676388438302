import { useState, useRef, useEffect, useMemo } from "react";
import CefxLib from "../../helpers/CefxLib";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";

import { NewSidebarCefx } from "./NewSideberCefx";
import { CefxComponent } from "./CefXComponent";
import { ViewImageComponent } from "./ViewImageComponent";
import {
  selectResearchPatient,
  setResearchPatient,
} from "../../store/slices/researchPatientSlice";
import { OpcoesProvider } from "./NewSideberCefx/OpcoesContext";

import CViewer3d from "./Viewer3d/CViewer3d";
import { selectIsCefalometria } from "../../store/slices/isCefalometriaSlice";
import { selectUser } from "../../store/slices/userSlice";
import { NewSidebarView } from "./NewSidebarView";
import { selectPatientsWithImages } from "../../store/slices/patientsWithImagesSlice";
import Api from "../../helpers/Api";
import { ModalIa } from "./Modals/ModalIa/ModalIa";
import { ModalLoadingProcessIa } from "./Modals/ModalIa/ModalLoadingProcessIa/ModalLoadingProcessIa";
import { isJpnAndPngUtils } from "../../utils/fileTypes/isJpgAndPngUtils";
import { is3dTypeUtils } from "../../utils/fileTypes/is3dTypeUtils";
import { isDicomTypeUtils } from "../../utils/fileTypes/isDicomTypeUtils";
import { isPdfTypeUtils } from "../../utils/fileTypes/isPdfTypeUtils";
import { isRarTypeUtils } from "../../utils/fileTypes/isRarTypeUtils";

import styles from "./styles.module.css";

export function ViewImages() {
  const location = useLocation();
  const dispatch = useDispatch();

  const queryString = location.search.substring(1);
  const queryParams = new URLSearchParams(queryString);

  const API_URL = process.env.REACT_APP_API_URL;

  const imageParams = queryParams.get("image") || "";
  const idParams = queryParams.get("id");
  const seqAtendParams = queryParams.get("atd");
  const idPacienteParams = queryParams.get("idPacient");
  const tamanhoParams = queryParams.get("tamanho");

  const [showModalIa, setShowModalIa] = useState(false);
  const [imageRotation, setImageRotation] = useState(0);
  const [image3d, setImage3d] = useState(false);
  const [modoCefXViewImage, setModoCefXViewImage] = useState(false);
  const [selectedImage, setSelectImage] = useState([]);
  const [theSelectImage, setTheSelectImage] = useState("");
  const [analysisSelected, setAnalysisSelected] = useState([]);
  const [textPont, setTextPonto] = useState("");
  const [getCollapse, setGetCollapse] = useState(false);
  const [pointNameOnTableClick, setPointNameOnTableClick] = useState({});
  const [pacientSearchCefx, setPacientSearchCefx] = useState([]);
  const [loadingPacientSearchCefx, setLoadingPacientSearchCefx] =
    useState(false);

  const [isModalIaOpen, setIsModalIaOpen] = useState(false);
  const [isModalLoadingOpen, setIsModalLoadingOpen] = useState(false);

  const { isCefalometria } = useSelector(selectIsCefalometria);
  const { researchPatient } = useSelector(selectResearchPatient);
  const { patientsWithImages } = useSelector(selectPatientsWithImages);
  const { user } = useSelector(selectUser);

  const [resultSearch, setResultSearch] = useState(researchPatient);

  const getPacient = () => {
    let pacientIsCefalometria;

    if (resultSearch && !isCefalometria) {
      return patientsWithImages.find(
        (p) =>
          p.seqAtend == seqAtendParams &&
          p.idPaciente == idPacienteParams &&
          (p.idClient == idParams || p.idClient == "" || idParams == "")
      );
    }

    if (!resultSearch && !isCefalometria) {
      return patientsWithImages.find(
        (p) =>
          p.seqAtend == seqAtendParams &&
          p.idPaciente == idPacienteParams &&
          (p.idClient == idParams || p.idClient == "" || idParams == "")
      );
    } else if (!resultSearch && isCefalometria && !pacientIsCefalometria) {
      pacientIsCefalometria = patientsWithImages.find(
        (p) =>
          (p.idClient == idParams || p.idClient == "" || idParams == "") &&
          p.seqAtend == seqAtendParams &&
          p.idPaciente == idPacienteParams
      );

      if (resultSearch && !pacientIsCefalometria && isCefalometria) {
        pacientIsCefalometria = patientsWithImages.find(
          (p) =>
            (p.idClient == idParams || p.idClient == "" || idParams == "") &&
            p.seqAtend == seqAtendParams &&
            p.idPaciente == idPacienteParams
        );
      }
      return pacientIsCefalometria;
    }
    return undefined;
  };

  let getPacienteCondicionado = () => {
    if (
      (resultSearch &&
        resultSearch.idClient == idParams &&
        resultSearch.idPaciente == idPacienteParams) ||
      (resultSearch.seqAtend == seqAtendParams &&
        resultSearch.idPaciente == idPacienteParams)
    ) {
      return resultSearch;
    } else {
      return getPacient();
    }
  };

  let paciente = getPacienteCondicionado();

  const gettingAllPngOrJpgImagens = paciente?.imagensPaciente?.filter(
    (imagem) =>
      imagem.link &&
      isJpnAndPngUtils(imagem.link) &&
      !imagem.link.includes("tomo_1")
  );

  const gettingAllImagens3d = paciente?.imagensPaciente?.filter(
    (imagem) => imagem.link && is3dTypeUtils(imagem.link)
  );

  const pacienteAllImagensArray = useMemo(() => {
    return gettingAllPngOrJpgImagens;
  }, [paciente]);

  const pacientAllImagens3d = useMemo(() => {
    return gettingAllImagens3d;
  }, [paciente]);

  const handleSelectedImage = (image) => {};

  const handleModoCefXViewImage = (cefX) => setModoCefXViewImage(cefX);

  const getImagenRotation = (rotate) => setImageRotation(rotate);

  const handleChanceTheSelectedImage = (urlImage) =>
    setTheSelectImage(urlImage);

  const handleGetNameEndCodAnalyze = (analysisSelected) =>
    setAnalysisSelected(analysisSelected);

  const getTextPont = (textPonto) => setTextPonto(textPonto);

  const handleGetCollapse = (collapse) => setGetCollapse(collapse);

  const handlePontName = (pont) => setPointNameOnTableClick(pont);

  const closeModalIa = () => {
    setIsModalIaOpen(false);
  };

  const openModalIa = () => setIsModalIaOpen(true);

  const openModalLoading = () => setIsModalLoadingOpen(true);

  const closeModalLoading = () => setIsModalLoadingOpen(false);

  const handleLoadIa = () => {
    openModalLoading();
    ocefx.CarregarPontosIa(() => {
      closeModalLoading();
    });
    closeModalIa();
  };

  useEffect(() => {
    if (showModalIa) {
      openModalIa();
    } else {
      closeModalIa();
    }
  }, [showModalIa]);

  useEffect(() => {
    ocefx.setMostrarModalIaCallback((value) => {
      setShowModalIa(value);
    });
  }, []);

  useEffect(() => {
    if (
      (imageParams && is3dTypeUtils(imageParams)) ||
      is3dTypeUtils(imageParams) ||
      isDicomTypeUtils(imageParams)
    ) {
      setImage3d(true);
    }
  }, [imageParams]);

  useEffect(() => {
    if (Object.keys(pacientSearchCefx).length === 0 && paciente == undefined) {
      setLoadingPacientSearchCefx(true);
      const getPacientCefx = () => {
        const filteredUser = user?.find((u) => u.idClient === idParams);
        const { hash, idClient } = filteredUser;
        const page = 0;

        return Api.GetPatientListCefx(
          { idClient, hash, page },
          { process_id: -2, to_conclude: -1 },
          { searchName: seqAtendParams }
        )
          .then((result) => {
            if (result.isSuccess === true) {
              const foundPatient = result.data.patients.find(
                (patient) => patient.seqAtend == seqAtendParams
              );

              if (foundPatient) {
                foundPatient.imagem = `${API_URL}${foundPatient.imagem}`;
                return foundPatient;
              }
            } else {
              console.error("A requisição falhou:", result.errorMessage);
              return null;
            }
          })
          .catch((error) => {
            console.error(error);
            return null;
          });
      };

      getPacientCefx().then((result) => {
        setPacientSearchCefx(result);
        setLoadingPacientSearchCefx(false);
        return;
      });
    }
  }, []);

  useEffect(() => {
    if (resultSearch) {
      dispatch(setResearchPatient(false));
    }
  }, []);

  /*---------- 3d ---------*/
  const myViewer = useRef(new CViewer3d());
  const oViewer = myViewer.current;
  const [viwerActives, setViwerActives] = useState([]);

  const handleViwerActivesChange = (newViwerActives) => {
    setViwerActives(newViwerActives);
  };

  /*---------- Cefx ---------*/
  const myCefx = useRef(new CefxLib());
  const ocefx = myCefx.current;
  const [selectedImages, setSelectedImages] = useState();

  const selectedImageChange = (newCheckedImage) => {
    setSelectedImages(newCheckedImage);
  };

  return (
    <div className={styles.wrapper}>
      {!modoCefXViewImage && imageParams ? (
        <OpcoesProvider ocefx={ocefx}>
          <main className={styles.main}>
            <ViewImageComponent
              tamanhoParams={tamanhoParams}
              getCollapse={getCollapse}
              theSelectImage={theSelectImage}
              handleSelectedImage={handleSelectedImage}
              handleModoCefX={handleModoCefXViewImage}
              pacienteAllImagensArray={pacienteAllImagensArray}
              pacientAllImagens3d={pacientAllImagens3d}
              imageParams={imageParams}
              selectedImage={selectedImage}
              valueImageRotate={imageRotation}
              viwerActives={viwerActives}
              oViewer={oViewer}
              ocefx={ocefx}
              selectedImages={selectedImages}
            />
          </main>

          <NewSidebarView
            imageParams={imageParams}
            infoPaciente={paciente}
            handleSelectedImage={handleSelectedImage}
            pacienteAllImagensArray={pacienteAllImagensArray}
            pacientAllImagens3d={pacientAllImagens3d}
            getImagenRotation={getImagenRotation}
            image3d={image3d}
            handleChanceTheSelectedImage={handleChanceTheSelectedImage}
            oViewer={oViewer}
            viwerActives={viwerActives}
            valueImageRotate={imageRotation}
            onViwerActivesChange={handleViwerActivesChange}
            selectedImageChange={selectedImageChange}
            ocefx={ocefx}
            handleGetCollapse={handleGetCollapse}
            theSelectImage={theSelectImage}
          />
        </OpcoesProvider>
      ) : (
        <OpcoesProvider ocefx={ocefx}>
          <main className={styles.main}>
            <CefxComponent
              infoPaciente={paciente || pacientSearchCefx}
              handleModoCefX={() => {}}
              handleModoCefXViewImage={handleModoCefXViewImage}
              imageParams={imageParams}
              ocefx={ocefx}
              analysisSelected={analysisSelected}
              getTextPont={getTextPont}
              textPont={textPont}
              pointNameOnTableClick={pointNameOnTableClick}
              handleGetNameEndCodAnalyze={handleGetNameEndCodAnalyze}
            />
          </main>

          <NewSidebarCefx
            loadingPacientSearchCefx={loadingPacientSearchCefx}
            infoPaciente={paciente || pacientSearchCefx}
            textPont={textPont}
            ocefx={ocefx}
            handleGetNameEndCodAnalyze={handleGetNameEndCodAnalyze}
            handlePontName={handlePontName}
          />
        </OpcoesProvider>
      )}

      <>
        {(isModalIaOpen || isModalLoadingOpen) && (
          <div className="react_modal_overlayIa" />
        )}

        {isModalIaOpen && (
          <ModalIa
            isOpen={isModalIaOpen}
            onRequestClose={closeModalIa}
            onLoadIa={handleLoadIa}
          />
        )}

        {isModalLoadingOpen && (
          <ModalLoadingProcessIa
            isOpen={isModalLoadingOpen}
            onRequestClose={closeModalLoading}
          />
        )}
      </>
    </div>
  );
}
