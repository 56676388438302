import styles from '../../styles.module.css'
import { CardRelatorio } from '../components/cardRelatorio';

export function Sobreposicao({ getRelatoriosSelect }: any) {
    const arrayDeRelatorios = [
        { nome: '24', codigo: 24, img: "../../modalRelatorio/sobreposicao/1.png" },
        { nome: '25', codigo: 25, img: "../../modalRelatorio/sobreposicao/2.png" },
        { nome: '26', codigo: 26, img: "../../modalRelatorio/sobreposicao/3.png" },
        { nome: '27', codigo: 27, img: "../../modalRelatorio/sobreposicao/4.png" },
        { nome: '28', codigo: 28, img: "../../modalRelatorio/sobreposicao/5.png" },
        { nome: '29', codigo: 29, img: "../../modalRelatorio/sobreposicao/6.png" },
        { nome: '30', codigo: 30, img: "../../modalRelatorio/sobreposicao/7.png" },
    ];

    return (
        <div className={styles.wrapper}>
            <div className={styles.container_content}>
                {arrayDeRelatorios.map((relatorio, index) => (
                    <div key={index} className={styles.wrapperImg}>
                        <CardRelatorio
                            nome={relatorio.nome}
                            codigo={relatorio.codigo}
                            getRelatoriosSelect={getRelatoriosSelect}
                            img={relatorio.img}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}