import { useEffect, useState } from "react";
import { Heading } from "@cdt-ui/react";
import { format } from "date-fns";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from 'react-router-dom';

import { Checks, Check, WhatsappLogo } from "@phosphor-icons/react";
import { EnvelopeOpenIcon, ShareIcon } from "@heroicons/react/24/solid";
import { ModalShareProfile } from "../../../components/ModalShareProfile";
import { useMediaQuery } from "react-responsive";
import { ModalSheetShare } from "./ModalSheet/ModalSheetShare";

import styles from "./styles.module.css";
import { patientData } from "../../../Data/patientData";
import { ModalShareProfileMobile } from "../../../components/ModalShareProfileMobile";
import { Link } from "react-router-dom";

export function Header({ infoPacient }: any) {
  const navigate = useNavigate();

  const token = sessionStorage.getItem("token");

  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalSheetShareOpen, setModalSheetShareOpen] = useState(false);
  const [imagemErro, setImagemErro] = useState(false);

  const idadeNumerica = parseFloat(infoPacient.idade);
  const idadeAnos = Math.floor(idadeNumerica);

  const idadeFormatada = `${idadeAnos} anos`;

  const isMobileScreen = useMediaQuery({ query: "(max-width: 684px)" });

  function isObjectEmpty(obj: any) {
    return Object.keys(obj)?.length === 0;
  }

  const handleOPenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModalSheetShare = () => {
    setModalSheetShareOpen(true);
  };

  const handleCloseModalSheetShare = () => {
    setModalSheetShareOpen(false);
  };

  const handleClose = () => {
    if (token == 'null' || token == null) {
      navigate('/dashboard/lista-pacientes');
    } else {
      window.history.go(-2);
    }
    sessionStorage.setItem("token", null);
  };

  const newObjectWithPatientNameAndId = {
    seqAtend: infoPacient.seqAtend,
    idClient: infoPacient.idClient,
    Paciente: infoPacient.paciente,
    Id: infoPacient.id,
    ss: infoPacient.ss,
  };

  const OpenEmail = () => {
    window.location.href = "mailto:" + infoPacient.email;
  };

  const OpenWhatsapp = () => {
    var numeros = infoPacient.telefone.replace(/\D/g, "");
    var numeroDesejado = numeros.substring(0, 10);
    window.location.href = "https://wa.me/55" + numeroDesejado;
  };
  var url = window.location.href;

  return (
    <div>
      {isObjectEmpty(infoPacient) ? (
        <div className={styles.wrapper}>
          <p>Informações do paciente não disponíveis.</p>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.containerAvatar}>
            <div
              className={styles.containerAvatar_image}
              style={{ padding: imagemErro && '5px' }}
            >
              <img
                src={`${infoPacient.imagem}`}
                alt="Foto do perfil do paciente"
                className={styles.imagePerfilPaciente}
                onError={(e: any) => {
                  e.target.onerror = null;
                  e.target.src = 'https://cdt.com.br/cli/p/getImagem.aspx?cmp=3889&nm=2';
                  setImagemErro(true);
                }}
              />
            </div>

            <div className={styles.containerAvatar_content}>
              <p className={styles.id}>
                #{infoPacient.seqAtend}
              </p>

              <div>
                <Heading as="h2">{infoPacient.paciente}</Heading>

                <strong>{!isNaN(idadeAnos) && idadeAnos !== null && idadeFormatada}</strong>
              </div>
            </div>
          </div>

          {!isMobileScreen && (
            <div className={styles.containerContent}>
              <div>
                <div className={styles.containerContent_description}>
                  <span>SEXO</span>
                  <p>{infoPacient.sexo}</p>
                </div>

                <div className={styles.containerContent_description}>
                  <span>STATUS</span>
                  <div
                    className={`${styles.patientStatus2} ${infoPacient.status === null ? styles.patientStatusNull2 : ""
                      }`}
                  >
                    {infoPacient.status == null ? (
                      <>Indisponivel</>
                    ) : (
                      <>
                        <Check />
                        {infoPacient.status}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <div className={styles.containerContent_description}>
                  <span>ENTRADA</span>
                  <p>
                    {format(
                      new Date(infoPacient.dataAtend),
                      "dd/MM/yyyy HH:mm:ss"
                    )}
                  </p>
                </div>

                <div className={styles.containerContent_description}>
                  <span>PREVISÃO DE ENTREGA</span>
                  <p>----</p>
                </div>
              </div>
            </div>
          )}
          {url.indexOf("perfil-paciente") !== -1 &&
            <div className={styles.containerIcons}>
              {!isMobileScreen && (
                <div
                  className={styles.containerIcons_icon}
                  onClick={OpenWhatsapp}
                >
                  <WhatsappLogo />
                </div>
              )}

              {!isMobileScreen && (
                <a className={styles.containerIcons_icon} onClick={OpenEmail}>
                  <EnvelopeOpenIcon />
                </a>
              )}

              <div
                title="compartilhar"
                className={styles.containerIcons_icon}
                onClick={
                  !isMobileScreen ? handleOPenModal : handleOpenModalSheetShare
                }
              >
                <ShareIcon />
              </div>
            </div>
          }

          <div
            className={styles.containerIconClose}
            onClick={handleClose}
            title="Fechar perfil paciente"
          >
            <XMarkIcon width={22} />
          </div>

          {isMobileScreen ? (
            <ModalShareProfileMobile
              isOpen={isModalOpen}
              onRequestClose={handleCloseModal}
              PatientNameAndId={newObjectWithPatientNameAndId}
            />
          ) : (
            isModalOpen && (
              <ModalShareProfile
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                PatientNameAndId={newObjectWithPatientNameAndId}
              />
            )
          )}
          {isModalSheetShareOpen && (
            <ModalSheetShare
              isOpen={isModalSheetShareOpen}
              onRequestClose={handleCloseModalSheetShare}
              PatientNameAndId={newObjectWithPatientNameAndId}
              PatientImageSource={infoPacient.imagem}
            />
          )}
        </div>
      )}
    </div>
  );
}
