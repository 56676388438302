import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/solid";

import styles from "./styles.module.css";

interface PaginationProps {
  listUsers: any;
  items?: {
    id: number;
    name: string;
  }[];
  setListItems?: (data: any[]) => void;
  getLastPage?: (lastPage: boolean) => void;
  loadingNextPage?: boolean;
  setPrevURL: React.Dispatch<React.SetStateAction<string>>;
}

interface Item {
  id: number;
  name: string;
}

export function Pagination({
  listUsers,
  items = [],
  setListItems,
  getLastPage,
  loadingNextPage,
  setPrevURL
}: PaginationProps) {
  const history = useNavigate();

  const [itensPerPage, setItensPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(3);

  const searchParams = new URLSearchParams(window.location.search);
  const pageFromURL = searchParams.get("page");

  const pages: number = Math.ceil(items.length / itensPerPage);
  const startIndex: number = currentPage * itensPerPage;
  const endIndex: number = startIndex + itensPerPage;
  const currentItens: any[] = items?.slice(startIndex, endIndex);
  const minPage: number = currentPage - 2 >= 0 ? currentPage - 2 : 0;
  const maxPage: number = currentPage + 2 < pages ? currentPage + 2 : pages - 1;

  const handlePrevbtn = (): void => {
    setCurrentPage(currentPage < 1 ? currentPage : currentPage - 1);
    history(`/dashboard/lista-pacientes?page=${currentPage}`);
  };

  const handleNextbtn = (): void => {
    setCurrentPage(currentPage + 1 == pages ? currentPage : currentPage + 1);
    history(`/dashboard/lista-pacientes?page=${currentPage + 2}`);
  };

  const handleClick = (index: number): void => {
    setCurrentPage(index);
    history(`/dashboard/lista-pacientes?page=${index + 1}`);
    if (index === 0) setPrevURL("/dashboard/lista-pacientes?page=1");
    // localStorage.setItem("currentPageNumber", JSON.stringify(index + 1));
  };

  useEffect(() => {
    if (setListItems) {
      setListItems(currentItens);
    }
    if (currentPage + 1 == pages && currentPage !== 0) {
      getLastPage(true);
    } else {
      getLastPage(false);
    }
  }, [currentPage, items]);

  useEffect(() => {
    setCurrentPage(0);
  }, [items]);

  useEffect(() => {
    if (pageFromURL) {
      const pageNumber = parseInt(pageFromURL, 10);
      const startIndex = (pageNumber - 1) * itensPerPage;
      const currentItens = items?.slice(startIndex, startIndex + itensPerPage);
      setCurrentPage(pageNumber - 1);
      setListItems(currentItens);
    }
  }, [pageFromURL, items]);


  // useEffect(() => {
  //   if (isCefalometria) {
  //     const url = "/dashboard/lista-pacientes";
  //     const params = new URLSearchParams(window.location.search);
  //     params.set("page", "1");
  //     const newUrl = `${url}?${params.toString()}`;
  //     console.log(newUrl)
  //     window.history.pushState({ path: newUrl }, "", newUrl);
  //   }
  // }, [toConcludeCefx]);

  return (
    <div className={styles.wrapper}>
      <button
        onClick={handlePrevbtn}
        disabled={currentPage == 0}
        type="button"
        className={styles.buttonIcon}
        title="Anterior"
        style={{
          cursor: currentPage == 0 ? "not-allowed" : "pointer",
          display: pages == 0 ? 'none' : 'block'
        }}
      >
        <ChevronLeftIcon width={16} />
      </button>

      <div className={styles.containerButtonsPage}>
        {Array.from(Array(pages), (_, index) => {
          if (index >= minPage && index <= maxPage) {
            return (
              <button
                key={index}
                onClick={() => handleClick(index)}
                className={
                  index === currentPage
                    ? `${styles.buttonPage}`
                    : `${styles.buttonPage_active}`
                }
              >
                {index + 1}
              </button>
            );
          } else {
            return null;
          }
        })}
      </div>

      {loadingNextPage  /* && totalPages !== pageLastRequest */ ? (
        <div className={styles.container_buttons_loading}>
          <div className={styles.loading_spinne}></div>
        </div>
      ) : (
        <button
          onClick={handleNextbtn}
          disabled={currentPage + 1 == pages}
          type="button"
          className={styles.buttonIcon}
          title="Próximo"
          style={{
            cursor: currentPage + 1 == pages ? "not-allowed" : "pointer",
            display: pages == 0 ? 'none' : 'block'
          }}
        >
          <ChevronRightIcon width={16} />
        </button>
      )}
    </div>
  );
}
