import React, { useRef, useEffect, useState } from "react";

import { HeaderCefxComponent } from "./HeaderCefxComponent";
import { useLocation } from "react-router-dom";
import { ArrowUturnLeftIcon, AdjustmentsHorizontalIcon, ArrowUturnRightIcon, SpeakerWaveIcon, SpeakerXMarkIcon } from "@heroicons/react/24/outline";

import { CSSTransition } from 'react-transition-group';
import { useOpcoes } from "../NewSideberCefx/OpcoesContext";

import styles from "./styles.module.css";

export const CefxComponent = ({
    handleModoCefXViewImage,
    handleModoCefX,
    getAllPontos,
    ocefx,
    imageParams,
    analysisSelected,
    getTextPont,
    textPont,
    pointNameOnTableClick,
    infoPaciente,
    handleGetNameEndCodAnalyze,
    ...props }) => {
    const location = useLocation();

    const { pontos, desenhoOnOrOff, pontosOnOrOff } = useOpcoes();

    const queryString = location.search.substring(1);
    const queryParams = new URLSearchParams(queryString);

    const [texto, setTexto] = useState("Ponto");
    const [allAnalises, setAllAnalises] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [funcationSelected, setFunctionSelected] = useState('');
    const [activeText, setActiveText] = useState('');
    const [som, SetSom] = useState(false);

    const canvasRef = useRef(null);
    const initialized = useRef(false);
    const atdPacientParams = queryParams.get("atd")
    const atdPacientLocalStorage = localStorage.getItem('atd');
    const atd = atdPacientParams;
    const idClient = queryParams.get("id");

    const getfunctionSelected = (funcao: string, ligaSom: boolean) => {
        if (funcao !== 'Voltar' && funcao !== 'Ajuda') {
            setFunctionSelected(funcao);
        }
        SetSom(ligaSom)
    };

    useEffect(() => {
        if (funcationSelected == 'desenho') {
            getTextPont('desenho')
        }
    }, [funcationSelected])

    const renderContent = () => {
        switch (funcationSelected) {
            case "Desfazer":
                return (
                    <span className={styles.textInfo}>
                        <ArrowUturnLeftIcon />
                        Desfazer
                    </span>
                );
            case "Refazer":
                return (
                    <span className={styles.textInfo}>
                        <ArrowUturnRightIcon />
                        Refazer
                    </span>
                );
            case "Som":
                return (
                    <span className={styles.textInfo}>
                        {
                            som ? (
                                <>
                                    <SpeakerWaveIcon />
                                    Som ativo
                                </>
                            ) : (
                                <>
                                    <SpeakerXMarkIcon />
                                    Som inativo
                                </>
                            )
                        }

                    </span>
                );
            case "moverImagem":
                return (
                    <span className={styles.textInfo}>
                        <img src="/iconCefx/moverIcon.svg" alt="Mover" />
                        Mover imagem ativo
                    </span>
                );
            case "calibrar":
                return (
                    <span className={styles.textInfo}>
                        <AdjustmentsHorizontalIcon />
                        Calibrar aparelho
                    </span>
                );
            case "marcarPonto":
                return (
                    <span className={styles.textInfo}>
                        <img src="/iconCefx/pontosIcon.svg" alt="Pontos" />
                        Criar pontos ativo
                    </span>
                );
            case "desenho":
                return (
                    <span className={styles.textInfo}>
                        <img src="/iconCefx/estruturaIcon.svg" alt="Estruturas" />
                        Estrutura ativa
                    </span>
                );
            case "fusao":
                return (
                    <span className={styles.textInfo}>
                        <img src="/iconCefx/fusaoIcon.svg" alt="Fusão" />
                        Fusão de imagens ativo
                    </span>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            const canvas = canvasRef.current;
            ocefx.setCanvas(canvas);

            ocefx.setOnPontoChange((v) => {
                getTextPont(v)
                setTexto(v);
                // vou atualizar a imagem da ajuda!
            });

            ocefx.setOnGuiaChange((v) => {
                alert("guia");
            });


            ocefx.setOnGuiaChange((v) => {
                alert("guia");
            });

            ocefx.setModoCefx(true);
            ocefx.loadCeph(idClient, atd, 1);
        }
    }, [canvasRef]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (funcationSelected) {
                setFunctionSelected('');
            }
        }, 2500);
        return () => clearTimeout(timeout);
    }, [funcationSelected]);

    return (
        <>
            <HeaderCefxComponent
                infoPaciente={infoPaciente}
                analysisSelected={analysisSelected}
                handleModoCefX={handleModoCefX}
                ocefx={ocefx}
                handleModoCefXViewImage={handleModoCefXViewImage}
                selecionarImagem={() => ocefx.setFerramentaPadrao()}
                selecionarFerramentaPonto={() => {
                    ocefx.setFerramentaPonto();
                    ocefx.ligaPontos(true);
                    pontosOnOrOff(true);
                }}
                selecionarFerramentaDesenho={() => {
                    ocefx.setFerramentaDesenho();
                    ocefx.ligaDesenhos(true);
                    desenhoOnOrOff(true);
                }
                }
                selecionarFerramentaMover={() => ocefx.setFerramentaMover()}
                getfunctionSelected={getfunctionSelected}
                textPont={textPont}
                pontos={pontos}
                pointNameOnTableClick={pointNameOnTableClick}
                handleGetNameEndCodAnalyze={handleGetNameEndCodAnalyze}
            />

            <div
                className={styles.containerImage}
            >
                <span
                    id="spnLoading"
                    className={styles.spanLoading}
                >
                    Carregando...
                </span>

                <canvas
                    className={styles.canvas}
                    ref={canvasRef}
                    {...props}
                />

                {
                    funcationSelected &&
                    <CSSTransition
                        in={!!funcationSelected}
                        timeout={500}
                        classNames={{
                            enter: styles.fadeEnter,
                            enterActive: styles.fadeEnterActive,
                            exit: styles.fadeExit,
                            exitActive: styles.fadeExitActive,
                        }}
                        unmountOnExit
                    >
                        <div className={styles.information}>
                            {renderContent()}
                        </div>
                    </CSSTransition>
                }
            </div>

        </>
    );
};
