import { useState, useEffect } from "react";

import { createPortal } from "react-dom";

import { Button } from "@cdt-ui/react";
import styles from "./styles.module.css";

import { Draggable } from "react-beautiful-dnd";

import {
  EllipsisVerticalIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";

import { Field, Formik, Form, FormikValues } from "formik";

import { Input } from "./components/Input";

import { CollapseCard } from "./components/CollapseCard";

import * as yup from "yup";

import { SubCard } from "./components/SubCard";

export function Class({ classesData, title = "", id, provided, collapse }) {
  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .required("Este campo é obrigatório!")
      .min(5, "O título deve ter pelo menos 5 caracteres!"),
    desc: yup.string(),
    vidLink: yup.string().required("Este campo é obrigatório!"),
    extra: yup.string(),
    createNext: yup.bool(),
  });

  const [operation, setOperation] = useState("cadastrar");

  /* const [collapse, setCollapse] = useState(false); */

  const { classes, setClasses } = classesData;

  const handleSubmit = async ({
    title,
    extra,
    desc,
    vidLink,
    createNext,
  }: FormikValues) => {
    const results = classes.filter((element) => {
      if (Object.keys(element).length !== 0) {
        return true;
      }
      return false;
    });

    if (operation === "cadastrar") {
      setClasses([
        ...results,
        {
          title,
          extra,
          desc,
          vidLink,
          id,
          createNext,
          collapse: true,
          isFromAPI: false,
        },
      ]);
    } else if (operation === "editar") {
      const myObj = classes.find((el) => el.id === id);
      myObj.title = title;
      myObj.desc = desc;
      myObj.extra = extra;
      myObj.vidLink = vidLink;
      myObj.createNext = createNext;
      myObj.collapse = true;
      const arrCopy = [myObj];

      arrCopy.push(...classes.filter((el) => el.id !== id));
      const newClass = reorder(classes);
      setClasses(newClass);
    }
    setOperation("cadastrar");
    if (createNext === true) {
      setClasses([
        ...results,
        { title, extra, desc, vidLink, id, createNext },
        {},
      ]);
      return;
    }
  };

  const reorder = (array) => {
    return array.sort((a, b) => {
      return a.id - b.id;
    });
  };

  function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }

    return true;
  }

  const handleEdit = (values: FormikValues, setFieldValue) => {
    setOperation("editar");
    const classy = classes.find((e) => e.id === id);
    values.id = id;
    classy.collapse = false;

    const isEqual = shallowEqual(classy, values);

    if (!isEqual) {
      setFieldValue("title", classy.title);
      setFieldValue("desc", classy.desc);
      setFieldValue("vidLink", classy.vidLink);
      setFieldValue("extra", classy.extra);
      setFieldValue("createNext", classy.createNext);
    }
    setClasses([...classes]);
  };
  return (
    <>
      <Formik
        initialValues={{
          title: "",
          desc: "",
          vidLink: "",
          extra: "",
          createNext: false,
        }}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={false}
      >
        {({ errors, values, setFieldValue }) => (
          <Form>
            {collapse ? (
              <>
                <Draggable key={id + 1} draggableId={String(id)} index={id}>
                  {(provided, snapshot) => {
                    return (
                      <li
                        className={`${styles.draggableList} ${styles.classesContainer}`}
                        key={id}
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                      >
                        <div className={`${styles.outerContainer}`}>
                          <EllipsisVerticalIcon
                            width={28}
                            color="#AFAFAF"
                            className={styles.dndIcon}
                          />

                          <div className={styles.boxContainer}>
                            <SubCard
                              styles={styles}
                              utils={{
                                validationSchema,
                                handleSubmit,
                                collapse,
                                handleEdit,
                                setFieldValue,
                                values,
                                errors,
                              }}
                              title={title}
                            />
                          </div>
                        </div>
                      </li>
                    );
                  }}
                </Draggable>
                {provided.placeholder}
              </>
            ) : (
              <div
                className={`${styles.expandedContainer} ${styles.classesContainer}`}
              >
                <div className={styles.boxContainer}>
                  <SubCard
                    styles={styles}
                    utils={{
                      validationSchema,
                      handleSubmit,
                      collapse,
                      handleEdit,
                      values,
                      errors,
                    }}
                    title={title}
                  />
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}
