import styles from "./styles.module.css";

import { Text } from "@cdt-ui/react";

import { useNavigate } from "react-router-dom";

export function NoClassempty() {
  const navigate = useNavigate();

  const navigateToCadastro = () => {
    navigate("/Cadastro");
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.containerContent}>
        <div>
          <Text className={styles.containerText}>
            Não há notificações
          </Text>
        </div>
        <div className={styles.containerImg}>
          <img src="/emptystate.png" alt="" />
        </div>
      </div>
    </section>
  );
}
