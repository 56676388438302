import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    processo: -2
}

export const ProcessoSlice = createSlice({
    name: 'processo',
    initialState,
    reducers: {
        setProcesso: (state, action) => {
            state.processo = action.payload
        }
    }
})

export const { setProcesso } = ProcessoSlice.actions;

export const selectProcesso = (state: any) => state.processo || -2;

export default ProcessoSlice.reducer;
