import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  NoSymbolIcon,
  PencilSquareIcon
} from "@heroicons/react/24/solid";
import { Checkbox } from "../components/Checkbox";


import styles from "./styles.module.css";

interface TableBodyProps {
  bodyContents: any;
  isSelected: (id: number) => boolean;
  handleChangeExamsRequest: (id: number) => void;
  handleConfirmDeleteUnique: (id: number) => void;
  handleConfirmDeleteSelecteds: () => void
}

export function TableBody({
  bodyContents,
  isSelected,
  handleChangeExamsRequest,
  handleConfirmDeleteUnique,
  handleConfirmDeleteSelecteds
}: TableBodyProps) {
  const navigate = useNavigate();
  const scrollRef = useRef(0);

  const { id, name, idClient, createdDate, dentist } = bodyContents;
  const formattedDate = new Date(createdDate).toLocaleDateString("pt-BR");

  const handleEditExamRequest = (id: number) => {
    navigate(`/dashboard/requisição-exames/editar-requisicao/${id}`);
  };

  /*   const renderSituation = () => {
    switch (situation.toLowerCase()) {
      case "realizado":
        return (
          <div className={`${styles.situationCard} ${styles.situationSuccess}`}>
            <CheckIcon width={16} />
            Realizado
          </div>
        );
      case "não realizado":
        return (
          <div
            className={`${styles.situationCard} ${styles.situationNoSuccess}`}
          >
            <XMarkIcon width={16} />
            Não realizado
          </div>
        );
    }
  }; */

  return (
    <tbody>
      <tr>
        <td>
          <Checkbox
            id={id}
            inputId="exam-request"
            isSelected={isSelected}
            handleChange={handleChangeExamsRequest}
            add={{
              width: "20px",
              height: "20px",
            }}
          />
        </td>
        <td>{name}</td>
        {/*         <td>{renderSituation()}</td> */}
        <td>{dentist}</td>
        <td>{formattedDate}</td>
        {/* <td>
          <PencilSquareIcon
            width={20}
            onClick={() => handleEditExamRequest(id)}
          />
        </td> */}
        <td>
          <NoSymbolIcon 
            width={20} 
            className={styles.noSymbolIcon}
            onClick={() => {
              if(isSelected(id)) {
                handleConfirmDeleteSelecteds();
              } else {
                handleConfirmDeleteUnique(id);
              }
            }} 
          />
        </td>
      </tr>
    </tbody>
  );
}
