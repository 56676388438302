import { UserCard } from "./components/UserCard";
import { AboutCard } from "./components/AboutCard";
import { Video } from "./components/Video";

import styles from "./styles.module.css";
import { useEffect, Suspense, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "../VisualizarCurso/components/Filter/components/LoadingModal";
import Api from "../../../helpers/Api";

export function Especialista() {
  const { id } = useParams();

  const [teacher, setTeacher] = useState({});
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const t = await Api.getTeacherById(id);
        if (t.isSuccess) {
          setTeacher(t.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div className={styles.mainContainer}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <UserCard teacher={teacher} />
          <div className={styles.bodyContainer}>
            <AboutCard teacher={teacher} />
            <Video teacher={teacher} />
          </div>
        </>
      )}
    </div>
  );
}
