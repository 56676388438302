export const menuLinksPatients = [
  {
    id: 1,
    sectionTitle: "Paciente",
    title: "Paciente",
    icon: "UserGroupIcon",
    children: [
      {
        title: "Paciente",
        href: "lista-pacientes",
      },
    ],
  },
  {
    id: 5,
    sectionTitle: "Academy",
    title: "Academy",
    icon: "AcademicCapIcon",
    children: [
      {
        title: "Academy",
        href: "academy/curso",
      },
      // {
      //   title: "Adicionar Curso",
      //   href: "academy/cadastrar",
      // },
    ],
  },

];
