import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
 isClinicConnectionsComplete: <boolean>false,
};

export const isClinicConnectionsCompleteSlice = createSlice({
  name: "isClinicConnectionsComplete",
  initialState,
  reducers: {
    setIsClinicConnectionsComplete: (state, action: PayloadAction<any>) => {
      state.isClinicConnectionsComplete = action.payload;
    },
    resetIsClinicConnectionsComplete: (state) => {
      state.isClinicConnectionsComplete = false;
    }
  },
});

export const { setIsClinicConnectionsComplete, resetIsClinicConnectionsComplete } = isClinicConnectionsCompleteSlice.actions;

export const selectisClinicConnectionsComplete = (state: any) => state.isClinicConnectionsComplete;

export default isClinicConnectionsCompleteSlice.reducer;
