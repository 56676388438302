import React, { useEffect, useState, useRef } from 'react';
import { Line } from 'rc-progress';
import toast from 'react-hot-toast'

import { ModalProcessing } from '../../Modals/ModalProcessing/ModalProcessing';

import styles from "./styles.module.css";


interface ShowvisualizarProps {
  handleToggleVisualizacoes: (arg: number) => void;
  isSecondDivVisible1: boolean;
  isSecondDivVisible2: boolean;
  isSecondDivVisible3: boolean;
  isSecondDivVisible4: boolean;
  isSecondDivVisible5: boolean;
  isSecondDivVisible6: boolean;
  isSecondDivVisible7: boolean;
  isSecondDivVisible8: boolean;
  isSecondDivVisible9: boolean;
}

export function Showvisualizar({
  handleToggleVisualizacoes,
  isSecondDivVisible1,
  isSecondDivVisible2,
  isSecondDivVisible3,
  isSecondDivVisible4,
  isSecondDivVisible5,
  isSecondDivVisible6,
  isSecondDivVisible7,
  isSecondDivVisible8,
  isSecondDivVisible9,
}: ShowvisualizarProps) {

  const [isLoadingDicom, setLoadingDicom] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [label, setLabel] = useState('');

  const progressMap = {
    iniciando: { label: 'Inicializando' },
    baixando: { label: 'Baixando' },
    abrindo: { label: 'Baixando' },
    Descompactando: { label: 'Descompactando' },
    'Gerando reconstrução': { label: 'Finalizando...' },
    Carregado: { label: 'Completo!' }
  };

  useEffect(() => {
    const checkLoadingStyle = () => {
      const progressLoadedDicom = sessionStorage.getItem('progressLoadedDicom');
      const spnLoading = document.getElementById('spnLoading');
      const progress = Number(progressLoadedDicom);
      const dicomStatus = sessionStorage.getItem('dicom');
      const { percent, label } = progressMap[dicomStatus] || { percent: 0, label: '' };

      setProgressValue(progress);
      setLabel(label);

      if (spnLoading && spnLoading.style.display !== 'none' && progress < 100) {
        if (!isLoadingDicom) {
          setLoadingDicom(true);
        }
      }

      if (spnLoading && spnLoading.style.display === 'none') {
        setLoadingDicom(false);
      }
    };

    const intervalId = setInterval(checkLoadingStyle, 500);

    return () => {
      clearInterval(intervalId);
      setLoadingDicom(false);
    };
  }, []);

  return (
    <div>
      <ModalProcessing
        isOpen={isLoadingDicom}
        onRequestClose={() => {}}
        progressValue={progressValue}
        label={label}
      />

      <div className={styles.showvisualizar}>
        <div
          className={styles.visualizar1}
          onClick={() => !isLoadingDicom && handleToggleVisualizacoes(6)}
        >
          {isSecondDivVisible1 ? (
            <div
              className="DicomView"
              style={{
                width: 52,
                height: 52,
                position: "relative",
                background: "#ADE1DA",
                borderRadius: 16,
                border: "2px #007E69 solid",
              }}
            />
          ) : (
            <div
              style={{
                width: 52,
                height: 52,
                position: "relative",
                borderRadius: 16,
                border: "2px #535353 solid",
                cursor: isLoadingDicom && 'not-allowed'
              }}
            />
          )}
        </div>
        <div
          className={styles.visualizar2}
          onClick={() => !isLoadingDicom && handleToggleVisualizacoes(2)}
        >
          {isSecondDivVisible2 ? (
            <div
              style={{
                width: 52,
                height: 52,
                position: "relative",
                background: "#ADE1DA",
                borderRadius: 16,
                overflow: "hidden",
                border: "2px #007E69 solid",
              }}
            >
              <div
                style={{
                  width: 1.5,
                  height: 48,
                  left: 50,
                  top: 25,
                  position: "absolute",
                  transform: "rotate(90deg)",
                  transformOrigin: "0 0",
                  background: "#007E69",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                width: 52,
                height: 52,
                position: "relative",
                borderRadius: 16,
                overflow: "hidden",
                border: "2px #535353 solid",
                cursor: isLoadingDicom && 'not-allowed'
              }}
            >
              <div
                style={{
                  width: 1.5,
                  height: 48,
                  left: 50,
                  top: 25,
                  position: "absolute",
                  transform: "rotate(90deg)",
                  transformOrigin: "0 0",
                  background: "#535353",
                }}
              />
            </div>
          )}
        </div>
        <div
          className={styles.visualizar3}
          onClick={() => !isLoadingDicom && handleToggleVisualizacoes(3)}
        >
          {isSecondDivVisible3 ? (
            <div
              style={{
                width: 52,
                height: 52,
                position: "relative",
                background: "#ADE1DA",
                borderRadius: 16,
                overflow: "hidden",
                border: "2px #007E69 solid",
              }}
            >
              <div
                style={{
                  width: 1.5,
                  height: 48,
                  left: 27,
                  top: 50,
                  position: "absolute",
                  transform: "rotate(-180deg)",
                  transformOrigin: "0 0",
                  background: "#007E69",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                width: 52,
                height: 52,
                position: "relative",
                borderRadius: 16,
                overflow: "hidden",
                border: "2px #535353 solid",
                cursor: isLoadingDicom && 'not-allowed'
              }}
            >
              <div
                style={{
                  width: 1.5,
                  height: 48,
                  left: 27,
                  top: 50,
                  position: "absolute",
                  transform: "rotate(-180deg)",
                  transformOrigin: "0 0",
                  background: "#535353",
                }}
              />
            </div>
          )}
        </div>
        <div
          className={styles.visualizar4}
          onClick={() => !isLoadingDicom && handleToggleVisualizacoes(4)}
        >
          {isSecondDivVisible4 ? (
            <div
              className="DicomView"
              style={{
                width: 52,
                height: 52,
                position: "relative",
                background: "#ADE1DA",
                borderRadius: 16,
                overflow: "hidden",
                border: "2px #007E69 solid",
              }}
            >
              <div
                className="Line"
                style={{
                  width: 1.5,
                  height: 48,
                  left: 18.5,
                  top: 50,
                  position: "absolute",
                  transform: "rotate(-180deg)",
                  transformOrigin: "0 0",
                  background: "#007E69",
                }}
              />
              <div
                className="Line"
                style={{
                  width: 1.5,
                  height: 48,
                  left: 35.5,
                  top: 50,
                  position: "absolute",
                  transform: "rotate(-180deg)",
                  transformOrigin: "0 0",
                  background: "#007E69",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                width: 52,
                height: 52,
                position: "relative",
                borderRadius: 16,
                overflow: "hidden",
                border: "2px #535353 solid",
                cursor: isLoadingDicom && 'not-allowed'
              }}
            >
              <div
                style={{
                  width: 1.5,
                  height: 48,
                  left: 18.5,
                  top: 50,
                  position: "absolute",
                  transform: "rotate(-180deg)",
                  transformOrigin: "0 0",
                  background: "#535353",
                }}
              />
              <div
                style={{
                  width: 1.5,
                  height: 48,
                  left: 35.5,
                  top: 50,
                  position: "absolute",
                  transform: "rotate(-180deg)",
                  transformOrigin: "0 0",
                  background: "#535353",
                }}
              />
            </div>
          )}
        </div>
        <div
          className={styles.visualizar5}
          onClick={() => !isLoadingDicom && handleToggleVisualizacoes(5)}
        >
          {isSecondDivVisible5 ? (
            <div
              className="DicomView"
              style={{
                width: 52,
                height: 52,
                position: "relative",
                background: "#ADE1DA",
                borderRadius: 16,
                overflow: "hidden",
                border: "2px #007E69 solid",
              }}
            >
              <div
                className="Line"
                style={{
                  width: 1.5,
                  height: 48,
                  left: 27,
                  top: 50,
                  position: "absolute",
                  transform: "rotate(-180deg)",
                  transformOrigin: "0 0",
                  background: "#007E69",
                }}
              />
              <div
                className="Line"
                style={{
                  width: 1.5,
                  height: 48,
                  left: 2,
                  top: 27,
                  position: "absolute",
                  transform: "rotate(-90deg)",
                  transformOrigin: "0 0",
                  background: "#007E69",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                width: 52,
                height: 52,
                position: "relative",
                borderRadius: 16,
                overflow: "hidden",
                border: "2px #535353 solid",
                cursor: isLoadingDicom && 'not-allowed'
              }}
            >
              <div
                style={{
                  width: 1.5,
                  height: 48,
                  left: 27,
                  top: 50,
                  position: "absolute",
                  transform: "rotate(-180deg)",
                  transformOrigin: "0 0",
                  background: "#535353",
                }}
              />
              <div
                style={{
                  width: 1.5,
                  height: 48,
                  left: 2,
                  top: 27,
                  position: "absolute",
                  transform: "rotate(-90deg)",
                  transformOrigin: "0 0",
                  background: "#535353",
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div
        style={isSecondDivVisible1 ? { display: "block" } : { display: "none" }}
      >
        <div
          style={{
            width: '280px',
            marginLeft: '15px',
            height: 0,
            border: "1.5px #535353 solid",
            margin: 20,
            borderRadius: '16px'
          }}
        ></div>
        <div className={styles.showvisualizar}>
          <div
            className={styles.visualizar1}
            onClick={() => !isLoadingDicom && handleToggleVisualizacoes(6)}
          >
            {isSecondDivVisible6 ? (
              <div
                style={{
                  width: 52,
                  height: 52,
                  position: "relative",
                  background: "#ADE1DA",
                  borderRadius: 16,
                  overflow: "hidden",
                  border: "2px #007E69 solid",
                }}
              >
                <div
                  style={{
                    left: 15,
                    top: 15,
                    position: "absolute",
                    textAlign: "center",
                    color: "#007E69",
                    fontSize: 14,
                    fontFamily: "Segoe UI Variable",
                    fontWeight: "600",
                    wordWrap: "break-word",
                    cursor: 'default'
                  }}
                >
                  3D
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: 52,
                  height: 52,
                  position: "relative",
                  borderRadius: 16,
                  overflow: "hidden",
                  border: "2px #535353 solid",
                  cursor: isLoadingDicom && 'not-allowed'
                }}
              >
                <div
                  style={{
                    left: 15,
                    top: 15,
                    position: "absolute",
                    textAlign: "center",
                    color: "white",
                    fontSize: 14,
                    fontFamily: "Segoe UI Variable",
                    fontWeight: "600",
                    wordWrap: "break-word",
                    cursor: 'default'
                  }}
                >
                  3D
                </div>
              </div>
            )}
          </div>

          <div
            className={styles.visualizar2}
            onClick={() => !isLoadingDicom && handleToggleVisualizacoes(7)}
          >
            {isSecondDivVisible7 ? (
              <div
                style={{
                  width: 52,
                  height: 52,
                  position: "relative",
                  background: "#ADE1DA",
                  borderRadius: 16,
                  overflow: "hidden",
                  border: "2px #007E69 solid",
                }}
              >
                <div
                  style={{
                    left: 15,
                    top: 15,
                    position: "absolute",
                    textAlign: "center",
                    color: "#007E69",
                    fontSize: 14,
                    fontFamily: "Segoe UI Variable",
                    fontWeight: "600",
                    wordWrap: "break-word",
                    cursor: 'default'
                  }}
                >
                  Axi
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: 52,
                  height: 52,
                  position: "relative",
                  borderRadius: 16,
                  overflow: "hidden",
                  border: "2px #535353 solid",
                  cursor: isLoadingDicom && 'not-allowed'
                }}
              >
                <div
                  style={{
                    left: 15,
                    top: 15,
                    position: "absolute",
                    textAlign: "center",
                    color: "white",
                    fontSize: 14,
                    fontFamily: "Segoe UI Variable",
                    fontWeight: "600",
                    wordWrap: "break-word",
                    cursor: 'default'
                  }}
                >
                  Axi
                </div>
              </div>
            )}
          </div>
          <div
            className={styles.visualizar3}
            onClick={() => !isLoadingDicom && handleToggleVisualizacoes(8)}
          >
            {isSecondDivVisible8 ? (
              <div
                style={{
                  width: 52,
                  height: 52,
                  position: "relative",
                  background: "#ADE1DA",
                  borderRadius: 16,
                  overflow: "hidden",
                  border: "2px #007E69 solid",
                }}
              >
                <div
                  style={{
                    left: 9,
                    top: 15,
                    position: "absolute",
                    textAlign: "center",
                    color: "#007E69",
                    fontSize: 14,
                    fontFamily: "Segoe UI Variable",
                    fontWeight: "600",
                    wordWrap: "break-word",
                    cursor: 'default'
                  }}
                >
                  Sagi
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: 52,
                  height: 52,
                  position: "relative",
                  borderRadius: 16,
                  overflow: "hidden",
                  border: "2px #535353 solid",
                  cursor: isLoadingDicom && 'not-allowed'
                }}
              >
                <div
                  style={{
                    left: 9,
                    top: 15,
                    position: "absolute",
                    textAlign: "center",
                    color: "white",
                    fontSize: 14,
                    fontFamily: "Segoe UI Variable",
                    fontWeight: "600",
                    wordWrap: "break-word",
                    cursor: 'default'
                  }}
                >
                  Sagi
                </div>
              </div>
            )}
          </div>
          <div
            className={styles.visualizar4}
            onClick={() => !isLoadingDicom && handleToggleVisualizacoes(9)}
          >
            {isSecondDivVisible9 ? (
              <div
                style={{
                  width: 52,
                  height: 52,
                  position: "relative",
                  background: "#ADE1DA",
                  borderRadius: 16,
                  overflow: "hidden",
                  border: "2px #007E69 solid",
                }}
              >
                <div
                  style={{
                    left: 9,
                    top: 15,
                    position: "absolute",
                    textAlign: "center",
                    color: "#007E69",
                    fontSize: 14,
                    fontFamily: "Segoe UI Variable",
                    fontWeight: "600",
                    wordWrap: "break-word",
                    cursor: 'default'
                  }}
                >
                  Coro
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: 52,
                  height: 52,
                  position: "relative",
                  borderRadius: 16,
                  overflow: "hidden",
                  border: "2px #535353 solid",
                  cursor: isLoadingDicom && 'not-allowed'
                }}
              >
                <div
                  style={{
                    left: 9,
                    top: 15,
                    position: "absolute",
                    textAlign: "center",
                    color: "white",
                    fontSize: 14,
                    fontFamily: "Segoe UI Variable",
                    fontWeight: "600",
                    wordWrap: "break-word",
                    cursor: 'default'
                  }}
                >
                  Coro
                </div>
              </div>
            )}
          </div>
        </div>{" "}
      </div>
    </div>
  );
}
