import { useEffect, useState } from "react";

import Modal from "react-modal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";

import styles from './styles.module.css'
import { useLocation, useNavigate } from "react-router-dom";

interface ModalExitProps {
    isOpen: boolean;
    onRequestClose: () => void;
    ocefx?: any;
    modoExcluir?: boolean;
    namePdfSelect?: string;
    getDeletedPdfStatus?: (status: string) => void;
}

export function ModalExit({ isOpen, getDeletedPdfStatus, onRequestClose, ocefx, modoExcluir = false, namePdfSelect = '' }: ModalExitProps) {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const token = sessionStorage.getItem("token");

    const [getResultSalvation, setGetResultSalvation] = useState('');
    const [loadingSalvation, setLoadingSalvation] = useState(null);
    const [loadingDeletePdf, setLoadingDeletePdf] = useState(false);
    const [responseDeletePdf, setResponseDeletePdf] = useState('');

    const idParams = queryParams.get("id");
    const seqAtendParams = queryParams.get("atd");

    const regexNamePdfSelect = namePdfSelect.match(/\d+/);
    const pdfNumber = regexNamePdfSelect ? regexNamePdfSelect[0] : 0;

    const navigate = useNavigate();

    const closeViwer = () => {
        if (token == 'null' || token == null) {
            window.history.back();
        } else {
            window.history.go(-2);
        }
    };

    const handleSaveCefx = () => {
        ocefx?.salvarCefx(setGetResultSalvation, setLoadingSalvation);
    };

    const excluirPdf = () => {
        ocefx.deletePdf(idParams, seqAtendParams, pdfNumber, setLoadingDeletePdf, setResponseDeletePdf);
    };

    useEffect(() => {
        if (getResultSalvation === 'success') {
            toast("Alterações salva com sucesso!", {
                position: "top-right",
                style: {
                    background: "#008a00",
                    color: "#fff",
                    textAlign: 'center'
                },
            });
            setTimeout(() => {
                if (token === 'null' || token === null) {
                    window.history.back();
                } else {
                    window.history.go(-2);
                }
            }, 700);
        } else if (getResultSalvation === 'error') {
            toast("Erro ao salvar as Alterações!", {
                position: "top-right",
                style: {
                    background: "#c80000",
                    color: "#fff",
                    textAlign: 'center'
                },
            });
        } else {
            return
        }
    }, [getResultSalvation]);

    useEffect(() => {
        if (getDeletedPdfStatus) {
            getDeletedPdfStatus(responseDeletePdf)
        }
    }, [responseDeletePdf]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react_modal_overlay"
            className="react-modal-exit "
        >
            <div className={styles.container}>
                <div className={styles.iconX} onClick={onRequestClose}>
                    <XMarkIcon />
                </div>

                <div className={styles.containerImg}>
                    <img src="/iconCefx/alertIcon.svg" />
                </div>


                <header className={styles.header}>
                    <h2>
                        {
                            modoExcluir
                                ?
                                'Confirma a exclusão deste PDF?'
                                :
                                'Deseja salvar antes de sair?'
                        }
                    </h2>

                    <p>
                        {
                            modoExcluir
                                ?
                                'Excluir o PDF o removerá permanentemente, sem possibilidade de recuperação.'
                                :
                                'Suas alterações serão perdidas se sair sem salvar.'
                        }
                    </p>
                </header>

                <div className={styles.containerButtons}>
                    <button
                        type="button"
                        onClick={() => !modoExcluir ? closeViwer() : onRequestClose()}
                    >
                        {
                            modoExcluir
                                ?
                                'Não excluir'
                                :
                                'Não salvar'
                        }
                    </button>

                    <button
                        type="button"
                        disabled={loadingDeletePdf || loadingSalvation}
                        onClick={() => !modoExcluir ? handleSaveCefx() : excluirPdf()}
                        style={{
                            cursor: loadingDeletePdf || loadingSalvation ? 'not-allowed' : 'pointer'
                        }}
                    >
                        {loadingSalvation || loadingDeletePdf ? (
                            <div className={styles.container_buttons_loading}>
                                <div className={styles.loading_spinne}></div>
                            </div>
                        ) : (
                            <>
                                {
                                    modoExcluir
                                        ?
                                        ' Sim, excluir'
                                        :
                                        'Sim, salvar'
                                }
                            </>
                        )}
                    </button>
                </div>
            </div>
        </Modal >
    )
}