import { Headset } from '@phosphor-icons/react'
import styles from './styles.module.css'

interface FooterViewImageProps {
    modoCefx?: boolean;
}

export function FooterViewImage({ modoCefx = false }: FooterViewImageProps) {
    return (
        <footer className={modoCefx ? `${styles.footerWrapper_cefx}` : `${styles.footerWrapper}`}>
            <p>
                <Headset size={20} />
                Precisa de ajuda? Fale conosco
            </p>
            <div className={styles.containerLogos}>
                <img src="/logo_cdt.svg" alt="logo" width={79} height={20} />
                <img src="/logo_serodonto.svg" />
            </div>
        </footer>
    )
}