import styles from '../../styles.module.css'
import { CardRelatorio } from '../components/cardRelatorio';

import img1 from "../../modalRelatorio/Panoramica/1.png"
import img2 from "../../modalRelatorio/Panoramica/2.png"
import img3 from "../../modalRelatorio/Panoramica/3.png"
import img4 from "../../modalRelatorio/Panoramica/4.png"
import img5 from "../../modalRelatorio/Panoramica/5.png"
import img6 from "../../modalRelatorio/Panoramica/6.png"

export function Panoramica({ getRelatoriosSelect }: any) {

    const arrayDeRelatorios = [
        { nome: 'Fatores', codigo: 39, img: img1 },
        { nome: 'Fatores + grafico', codigo: 40, img: img2 },
        { nome: 'Com imagem', codigo: 41, img: img3 },
        { nome: 'Com desenho', codigo: 42, img: img4 },
        { nome: 'Imagem com fatores', codigo: 43, img: img5 },
        { nome: '1.2', codigo: 44, img: img6 },
    ];
    return (
        <div className={styles.wrapper}>
            <div className={styles.container_content}>
                {arrayDeRelatorios.map((relatorio, index) => (
                    <div key={index} className={styles.wrapperImg}>
                        <CardRelatorio
                            nome={relatorio.nome}
                            codigo={relatorio.codigo}
                            getRelatoriosSelect={getRelatoriosSelect}
                            img={relatorio.img}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}