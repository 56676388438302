import { ErrorInfo, useState } from "react";
import { Formik, Form, FormikValues } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@cdt-ui/react";
import { toast } from "react-hot-toast";
import * as yup from "yup";
import { useSelector } from "react-redux";

import { Layout } from "../../components/Layout";
// import { ModalRecoverPassword } from '../../components/ModalRecoverPassword';
import { Input } from "../../components/Form/Input";
import { BoxVideoInstitutional } from "../../components/BoxVideoInstitutional";
import { Footer } from "../../components/Footer";

import styles from "./styles.module.css";
import axios from "axios";
import Api from "../../helpers/Api";
import { selectUser } from "../../store/slices/userSlice";
import { ModalErrorOrSucessMessage } from "../../components/ModalErrorOrSucessMessage";

export function RecoverPassword({ ...props }) {
  const { user } = useSelector(selectUser);

  const idClient = user?.idClient;

  const [isModalErroOrSucessOpen, setIsModalErroOrSucessOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const token = queryParams.get("token");

  const navigate = useNavigate();

  const navigateToDashboardScreen = () => {
    navigate("/");
  };

  const handleCloseModalErrorOrSucess = () => {
    setIsModalErroOrSucessOpen(false);
  };

  const validationSchema = yup.object().shape({
    newPassword: yup
      .string()
      .min(8, ({ min }) => `A senha deve ter ${min} caracteres`)
      .max(80, ({ max }) => `A senha deve ter ${max} caracteres`),
    confirmPassword: yup
      .string()
      .required("Campo obrigatório")
      .oneOf([yup.ref("newPassword"), null], "As senhas devem corresponder"),
  });

  const handleSubmit = async (values: FormikValues) => {
    setLoading(true);
    const { newPassword, confirmPassword } = values;

    const payload = {
      password: confirmPassword,
    };

    try {
      const result = await Api.ChangePassword(payload, token);
      if (result === true) {
        setLoading(false);
        setIsModalErroOrSucessOpen(true);
        setModalType('success');
      } else {
/*         toast("Ocorreu um erro!", {
          position: "top-right",
          style: {
            background: "#c80000",
            color: "#fff",
          },
        }); */
        setLoading(false);
        setIsModalErroOrSucessOpen(true);
        setModalType('errorEmail');
      }
    } catch (error) {
/*       if (axios.isAxiosError(error)) {
        toast("Ocorreu um erro!", {
          position: "top-right",
          style: {
            background: "#c80000",
            color: "#fff",
          },
        });
      } */
      setIsModalErroOrSucessOpen(true);
      setModalType('errorEmail');
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className={styles.login}>
        <div className={styles.wrapper}>
          <div className={styles.wrapper_form}>
            <div className={styles.container_form}>
              <img
                className={styles.image}
                src={`https://cdt.com.br/cli/p/getImagem.aspx?cmp=${
                  idClient ? idClient : 3889
                }&nm=2`}
              />

              <div className={styles.container_title}>
                <h1>Recuperar Senha</h1>
                <p>Preencha os campos abaixo com sua nova senha!</p>
              </div>

              <Formik
                initialValues={{
                  newPassword: "",
                  confirmPassword: "",
                }}
                onSubmit={(values, { resetForm }) => {
                  handleSubmit(values);
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={validationSchema}
              >
                {({ errors, isSubmitting, values }: any) => (
                  <Form>
                    <div className={styles.container_inputs}>
                      <Input
                        name="newPassword"
                        type="password"
                        title="Nova senha"
                        error={errors.newPassword}
                      />

                      <Input
                        name="confirmPassword"
                        type="password"
                        title="Confirmar nova senha"
                        error={errors.confirmPassword}
                      />

                      <div className={styles.containerCheckbox}></div>
                    </div>

                    <div className={styles.container_buttons}>
                      <Button
                        variant="greenDark"
                        size="full"
                        type="submit"
                        disabled={
                          values.newPassword === "" ||
                          values.confirmPassword === "" ||
                          loading
                        }
                      >
                        {loading ? (
                          <div className={styles.container_buttons_loading}>
                            <div className={styles.loading_spinne}></div>
                          </div>
                        ) : (
                          "Salvar nova senha"
                        )}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          <div className={styles.BoxVideoInstitutional}>
            <BoxVideoInstitutional />
          </div>
        </div>
        <Footer backgroundColorTrue />
        {isModalErroOrSucessOpen && (
          <ModalErrorOrSucessMessage
            type={modalType}
            isOpen={isModalErroOrSucessOpen}
            handleClose={handleCloseModalErrorOrSucess}
            onRequestClose={handleCloseModalErrorOrSucess}
            handleToNavigate={navigateToDashboardScreen}
            customTItle
            customDescription={modalType === 'errorEmail' ? 'Ocorreu um erro ao tentar atualizar a senha' : undefined}
          />
        )}
      </div>
    </Layout>
  );
}
