import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import Switch from "react-switch";

import { ImageComponent } from './ImageComponent'
import { isRarTypeUtils } from "../../../../../utils/fileTypes/isRarTypeUtils";
import { isDicomTypeUtils } from "../../../../../utils/fileTypes/isDicomTypeUtils";

import styles from './styles.module.css'

interface Imagens2DProps {
    imageParams?: string;
    ocefx: any;
    oViewer: any;
    pacienteAllImagensArray: any[];
    theSelectImage?: string;
    selectedImageChange: any;
    handleChanceTheSelectedImage: (urlImage: string) => void;
}

export function Imagens2D({
    imageParams,
    ocefx,
    oViewer,
    pacienteAllImagensArray,
    theSelectImage,
    selectedImageChange,
    handleChanceTheSelectedImage
}: Imagens2DProps) {
    const imageRefs = useRef([]);

    const [addedMultipleImages, setAddedMultipleImages] = useState([]);
    const [checkedSwitchDesenho, setCheckedSwitchDesenho] = useState(false);
    const [selectedImages, setSelectedImages] = useState<string[]>([imageParams]);
    const [selectedAllImages, setSelectedAllImages] = useState([]);
    const [isModalSair, setIsModalSair] = useState(false);

    const handleChangeDesenhoSwitch = () => {
        setCheckedSwitchDesenho(!checkedSwitchDesenho);
    };

    const handleImageClick = (imageLink: string) => {
        setSelectedImages((prevSelected) =>
            prevSelected.includes(imageLink)
                ? prevSelected?.filter((link) => link !== imageLink)
                : [...prevSelected, imageLink]
        );

    };

    const handleImageSelect = (imageLink) => {
        if (addedMultipleImages.length >= 6 && !addedMultipleImages.includes(imageLink)) {
            toast("Permitido um máximo de 6 imagens.", {
                style: {
                    background: "#f09500",
                    color: "#fff",
                    textAlign: 'center'
                },
            });
            return;
        }

        if (!addedMultipleImages.includes(imageLink) && checkedSwitchDesenho) {
            const updatedSelectedImages = [imageLink];
            ocefx.adicionarImagem(updatedSelectedImages, true);
            setAddedMultipleImages(prev => [...prev, imageLink]);
        } else if (addedMultipleImages.includes(imageLink)) {
            ocefx.removerImagem(imageLink);
            setAddedMultipleImages(prev => prev.filter(img => img !== imageLink));
        }
    };

    useEffect(() => {
        if (checkedSwitchDesenho) {
            setAddedMultipleImages([]);
        }
    }, [checkedSwitchDesenho]);

    useEffect(() => {
        const targetLink = theSelectImage || imageParams;
        const imageIndex = pacienteAllImagensArray.findIndex(
            (image) => image.link === targetLink
        );

        if (imageIndex !== -1 && imageRefs.current[imageIndex]) {
            const element = imageRefs.current[imageIndex];
            const container = document.querySelector(`.${styles.containerImagens}`);

            if (element && container) {
                setTimeout(() => {
                    const elementRect = element.getBoundingClientRect();
                    const containerRect = container.getBoundingClientRect();

                    const offset = elementRect.top - containerRect.top + container.scrollTop;

                    container.scrollTo({
                        top: offset,
                        behavior: 'smooth',
                    });
                }, 100); 

            }
        }
    }, [imageParams, theSelectImage, pacienteAllImagensArray]);

    return (
        <div className={styles.containerImages}>
            <p className={styles.title}> Imagens 2D</p>

            <div>

                {
                    pacienteAllImagensArray.length > 0 ?
                        <>

                            <div className={styles.containerOnOff}>
                                <h5>Múltiplas imagens</h5>
                                <Switch
                                    onChange={handleChangeDesenhoSwitch}
                                    checked={checkedSwitchDesenho}
                                    className="react-switch"
                                    onColor="#007E69"
                                    height={22}
                                    width={44}
                                />
                            </div>

                            <div className={styles.containerImagens}>
                                {pacienteAllImagensArray
                                    ?.filter(
                                        (image) =>
                                            !image.complemento ||
                                            !isRarTypeUtils(image.complemento) ||
                                            !isDicomTypeUtils(image.complemento)
                                    )
                                    .map((image, index) => (
                                        <div
                                            key={index}
                                            ref={(el) => (imageRefs.current[index] = el)}
                                        >
                                            <ImageComponent
                                                ocefx={ocefx}
                                                theSelectImage={theSelectImage}
                                                checkedSwitchDesenho={checkedSwitchDesenho}
                                                image={image}
                                                handleImageClick={handleImageClick}
                                                handleChanceTheSelectedImage={handleChanceTheSelectedImage}
                                                handleImageSelect={handleImageSelect}
                                                addedMultipleImages={addedMultipleImages}
                                                imageParams={imageParams}
                                                oViewer={oViewer}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </>
                        :
                        <div className={styles.WrapperNotResult}>
                            <span>Sem resultado</span>
                        </div>
                }
            </div>
        </div>
    )
}