import Modal from "react-modal";
import { Heading } from "@cdt-ui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

import styles from "./styles.module.css";

interface ModalVideoPopupProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onNoShowAgain: any;
}

export function ModalVideoPopup({
  isOpen,
  onRequestClose,
  onNoShowAgain,
}: ModalVideoPopupProps) {
  const handleNaoVerMaisClick = () => {
    localStorage.setItem("modalNaoVerMais", JSON.stringify(true));
    onRequestClose();
  };

  const handleNoShowAgain = () => {
    onNoShowAgain();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react_modal_overlay"
      className="react-modal-videopPopup"
    >
      <div className={styles.wrapper}>
        <XMarkIcon
          title="Fechar"
          onClick={onRequestClose}
          className={styles.iconClose}
        />

        <header className={styles.header}>
          <div className={styles.header_content}>
            <p
              style={{
                width: "90%",
              }}
            >
              Navegue e compartilhe com facilidade: conheça o seu novo
              visualizador. Conheça as funcionalidades do visualizador Serodonto
              e leve sua clínica odontológica a um novo patamar de excelência e
              crescimento.
            </p>

            <p className={styles.not} onClick={handleNoShowAgain}>
              Não quero ver novamente
            </p>
          </div>
        </header>

        <div className={styles.containerVideo}>
          <iframe
            width="560"
            height="315"
            className={styles.video}
            src="https://player-vz-fb154304-6eb.tv.pandavideo.com.br/embed/?v=fd5afa5e-efdf-472d-86fb-c6e5927a8c5a"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </Modal>
  );
}
