import { PencilSquareIcon } from "@heroicons/react/24/outline";

export function CollapseCard({ styles, title, handleEdit }) {
  return (
    <div className={styles.collapseContainer}>
      <div className={styles.boldTitle}>{title}</div>
      <PencilSquareIcon
        width={24}
        color="#8E8E8E"
        className={styles.editIcon}
        onClick={handleEdit}
      />
    </div>
  );
}
