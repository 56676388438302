
import { TrashSimple } from "@phosphor-icons/react";
import Api from "../../../../helpers/Api";
import { useContext, useState } from "react";
import { PatientContext } from "../../../PatientList/PatientProvider";

export function Cards({
  styles,
  address,
  id,
  city,
  neighborhood,
  clinicalName,
  deleteCallback,
  logon,
  idClient = [],
}) {
  const { resetPatientLoading } = useContext(PatientContext);

  const [loadingRemoveClinic, setLoadingRemoveClinic] = useState(false);

  const deleteClinic = async (afterDelete: Function) => {
    setLoadingRemoveClinic(true);
    try {
      await Api.deleteClinics(id);
      afterDelete();
    } catch (error) {
      console.error("Falha ao deletar a clínica:", error);
    } finally {
      resetPatientLoading();
    }
  };

  return (
    <div className={styles.containerGrid}>
      <div className={styles.cardHeader}>
        <h3 className={styles.cardTitle}>
          {clinicalName} <br />
          <span> {logon} </span>
        </h3>

        <img
          className={styles.cardImg}
          src={`https://cdt.com.br/cli/p/getImagem.aspx?cmp=${idClient}&nm=2`}
          alt="logo"
        />
      </div>

      <div className={styles.examsContainer}>
        <h4 className={styles.examsText}>
          {address} {city} {neighborhood}
        </h4>
        
        <div className={styles.buttonContainer}>
          <div
            onClick={() => deleteClinic(deleteCallback)}
            className={styles.trash}
          >
            {
              loadingRemoveClinic ?
                <div className={styles.container_buttons_loading}>
                  <div className={styles.loading_spinne}></div>
                </div>
                :
                <TrashSimple width={20} />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
