import { Text } from "@cdt-ui/react";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
import styles from "../../styles.module.css";
import { Container } from "./Container";
import { Autoplay, FreeMode, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery } from "react-responsive";

import {
  ArrowPathIcon,
  ChevronRightIcon,
  ArrowRightOnRectangleIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
// Import Swiper styles
import "swiper/css";
import { academy } from "../../../../../Data/academy";
import { useEffect, useState } from "react";
import Api from "../../../../../helpers/Api";
import { ContainerMobile } from "./ContainerMobile";
import { Loading } from "../../../../../components/Loading";

interface PropsCategory {
  title: string;
  idCategory: number;
  courses: any[];
}

export function Content({ title, idCategory, courses }: PropsCategory) {
  const [loading, setLoading] = useState(false);
  /* const [courses, setCourses] = useState([]); */
  const [slidesPerView, setSlidesPerView] = useState(3);
  const [spaceBetween, setSpaceBetween] = useState(8);

  /* const GetAllcourses = async () => {
    try {
      const results = await Api.getAllCourse();
      setCourses(results.data);
      setLoading(true);
    } catch (error) {}
  };

  useEffect(() => {
    GetAllcourses();
  }, []); */

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 590) {
        setSlidesPerView(1);
      } else if (window.innerWidth <= 850) {
        setSlidesPerView(2);
      } else if (window.innerWidth <= 1729) {
        setSlidesPerView(3);
      } else {
        setSlidesPerView(4);
        setSpaceBetween(32);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /* const widthPerSlide = courses.filter((item) => item.idCourseCategory === idCategory).length; */

  return (
    <div className={styles.allContent}>
      <div>
        <div
          className={styles.title}
          style={{
            width: `${(1 / slidesPerView) * 100}%`,
            paddingRight: spaceBetween,
          }}
        >
          <Text className={styles.titleNav}>{title}</Text>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.2803 11.4697C16.5732 11.7626 16.5732 12.2374 16.2803 12.5303L8.78033 20.0303C8.48744 20.3232 8.01256 20.3232 7.71967 20.0303C7.42678 19.7374 7.42678 19.2626 7.71967 18.9697L14.6893 12L7.71967 5.03033C7.42678 4.73744 7.42678 4.26256 7.71967 3.96967C8.01256 3.67678 8.48744 3.67678 8.78033 3.96967L16.2803 11.4697Z"
              fill="#535353"
            />
          </svg>
          {/*           <div className={styles.buttonHamburguer}>
            <ChevronDoubleRightIcon />
          </div> */}
        </div>
      </div>

      <div className={styles.ContainerPaddings}>
        {!loading ? (
          <Swiper slidesPerView={slidesPerView} spaceBetween={spaceBetween}>
            {courses &&
              courses
                .filter((item) => item.idCourseCategory === idCategory)
                .map((item, index) => (
                  <SwiperSlide
                    key={index}
                    className={item.shortly ? styles.noninteractive : ""}
                  >
                    <Container
                      key={item.id}
                      id={item.id}
                      title={item.name}
                      subTitle={item.description}
                      img={item.coverPhoto}
                      idCourseCategory={item.idCourseCategory}
                      idTeacher={item.idTeacher}
                      shortly={item.shortly}
                    />
                  </SwiperSlide>
                ))}
          </Swiper>
        ) : (
          <Loading></Loading>
        )}
      </div>
    </div>
  );
}
