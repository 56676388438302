import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import Api from "../../helpers/Api";
import { setShared } from "../../store/slices/sharedSlice";
import { UserProfile } from "../UserProfile";
import { Loading } from "../../components/Loading";

import styles from './styles.module.css'

export function InfoPaciente() {
  const [objShare, SetObjShared] = useState([]);
  const [loading, setLoading] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  var Newurl = window.location.href;

  var parts = Newurl.split("?");

  // if (parts.length > 1) {
  //   var NewqueryParams = parts[1];

  //   var params = NewqueryParams.split("&");
  //   for (var i = 0; i < params.length; i++) {
  //     var param = params[i].split("=");
  //     var key = param[0];
  //     var value = param[1];
  //   }
  // }

  function replaceDomain(url) {
    var regex = /\/shared.*/;
    var newDomain = "http://ser.cdt.com.br";

    var path = url.match(regex);

    if (path) {
      return newDomain + path[0];
    }

    return url;
  }

  var newUrl = replaceDomain(Newurl);

  // const url = `http://ser.cdt.com.br/shared?=${value}`;
  const url = newUrl;

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);

    const getShared = async () => {
      try {
        const paramIndex = url.indexOf('?');
        const paramsString = paramIndex !== -1 ? url.substring(paramIndex + 1) : '';

        const urlParams = new URLSearchParams(paramsString);

        const idCliente = urlParams.get('idCliente');
        const ss = urlParams.get('ss');
        const seqAtend = urlParams.get('seqAtend');

        localStorage.setItem("idClientLogin", idCliente);

        if (idCliente && ss && seqAtend) {
          const qrCodeResult = await Api.GetPatientForQrCode(idCliente, ss, seqAtend);
 
          if (qrCodeResult.isSuccess) {
            const resultImages = await Api.GetImagensPatient(qrCodeResult.data.idClient, qrCodeResult.data.idPaciente, qrCodeResult.data.seqAtend);

            if (resultImages.isSuccess) {
              const pacienteAtualizado = {
                ...qrCodeResult.data,
                imagem: `${API_URL}${qrCodeResult.data.imagem}`,
                imagensPaciente: resultImages.data
                  .filter(imagem => {
                    if (imagem.link.startsWith('https')) {
                      return (
                        (imagem.link.includes('/img') ||
                          imagem.nome.endsWith('.dfx') ||
                          imagem.nome.endsWith('.zip')) &&
                        !imagem.nome.includes('tomo_m')
                      );
                    }
                    return !imagem.nome.includes('tomo_m');
                  })
                  .map(imagem => ({
                    ...imagem,
                    link: imagem.link.startsWith('https') ? imagem.link : `${API_URL}${imagem.link}`,
                    icone: imagem.icone.startsWith('https') ? imagem.icone : `${API_URL}${imagem.icone}`
                  }))
              };

              dispatch(setShared(pacienteAtualizado));
              SetObjShared(pacienteAtualizado);
              setLoading(false);
              return;
            }
          }
        } else {
          const result = await Api.GetPatientByUniqueLink(url);

          if (result.isSuccess === true) {
            const resultImages = await Api.GetImagensPatient(result.data.idClient, result.data.idPaciente, result.data.seqAtend);

            if (resultImages.isSuccess === true) {
              const pacienteAtualizado = {
                ...result.data,
                imagem: `${API_URL}${result.data.imagem}`,
                imagensPaciente: resultImages.data
                  .filter(imagem => {
                    if (imagem.link.startsWith('https')) {
                      return (
                        (imagem.link.includes('/img') ||
                          imagem.nome.endsWith('.dfx') ||
                          imagem.nome.endsWith('.zip')) &&
                        !imagem.nome.includes('tomo_m')
                      );
                    }
                    return !imagem.nome.includes('tomo_m');
                  })
                  .map(imagem => ({
                    ...imagem,
                    link: imagem.link.startsWith('https') ? imagem.link : `${API_URL}${imagem.link}`,
                    icone: imagem.icone.startsWith('https') ? imagem.icone : `${API_URL}${imagem.icone}`
                  }))
              };

              dispatch(setShared(pacienteAtualizado));
              SetObjShared(pacienteAtualizado);
              setLoading(false);
            }
          }
        }

      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    getShared();
  }, []);

  return (
    <>
      {
        loading ?
          <div className={styles.containerLoading}>
            <img src="/logo_serodonto.svg" />
            <div className={styles.loading_container}>
              <div className={styles.loading_spinne}></div>
              <p>Por favor, Aguarde...</p>
            </div>
          </div>
          :
          <>
            <div className={styles.containerImage}>
              <img src="/logo_serodonto.svg" />
            </div>
            <UserProfile objShare={objShare} />
          </>
      }
    </>
  );
}
