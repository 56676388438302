import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    isCefalometria: <any>false
}

export const IsCefalometriaSlice = createSlice({
    name: 'isCefalometria',
    initialState,
    reducers: {
        setIsCefalometria: (state, action: PayloadAction<any>) => {
            state.isCefalometria = action.payload
        }
    }
})

export const { setIsCefalometria } = IsCefalometriaSlice.actions;

export const selectIsCefalometria = (state: any) => state.isCefalometria || false;

export default IsCefalometriaSlice.reducer;