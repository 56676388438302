import { useState, InputHTMLAttributes, useEffect } from "react";
import { Field } from "formik";

import {
  EyeSlashIcon,
  EyeIcon,
  DocumentArrowUpIcon,
} from "@heroicons/react/24/outline";

import styles from "./styles.module.css";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  error?: any;
  mask?: any;
  name: any;
  disabled?: boolean;
  labelStyleAbsolute?: boolean;
  onBlur?: any;
  noRequired?: boolean;
  autoCapitalize?: any;
  icon?: JSX.Element;
  onButtonClick?: () => void;
}

export function Input({ labelStyleAbsolute, onButtonClick, ...props }: InputProps) {
  const [type, setType] = useState(props.type);
  const [openEye, setOpenEye] = useState<boolean>(false);

  const handleToggle = () => {
    if (type === "password") {
      setType("text");
      setOpenEye(true);
    } else {
      setType("password");
      setOpenEye(false);
    }
  };

  return (
    <div className={styles.textInputContainer}>

      {props.icon &&
        <span
          className={styles.inputIcon}
          style={{
            display: props.icon ? 'block' : 'none'
          }}
        >
          {props.icon}
        </span>
      }

      {props.type === "file" && (
        <DocumentArrowUpIcon
          width={20}
          className={styles.iconFile}
          style={{
            display: props.icon ? 'block' : 'none'
          }}
        />
      )}

      <Field
        {...props}
        onBlur={(ev) => !!props.onBlur && props.onBlur(ev)}
        type={type}
        required={props.noRequired ? null : true}
        className={`${styles.input} ${props.error && styles.inputError}`}
        autoComplete={props.autoComplete || 'off'} 
        style={
          {
            opacity: props.disabled && '.5',
          }

        }
        autoCapitalize="none"
      />

      <label
        className={
          labelStyleAbsolute
            ? `${styles.labelAbsolute}`
            : `${styles.label}`
        }
        style={
          {
            opacity: props.disabled && '.8',
            color: props.error && '#c80000'
          }
        }
      >
        {props.title ? props.title : "description"}
      </label>

      <div className={styles.container_error}>
        <span className={styles.textError}>{props.error}</span>
      </div>

      {
        props.type === 'password' &&
        <span className={styles.viewIcon} onClick={handleToggle}>
          {
            openEye ?
              <EyeIcon width={20} />
              :
              <EyeSlashIcon width={20} />
          }
        </span>
      }

      {onButtonClick && (
        <button className={styles.inputButton} onClick={onButtonClick}>
          Buscar
        </button>
      )}
    </div>
  );
}
