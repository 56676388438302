import { InputHTMLAttributes } from "react";
import { Field } from "formik";

import styles from './styles.module.css'

interface TextAreaProps extends InputHTMLAttributes<HTMLInputElement> {
    title: string;
    error?: string;
    mask?: any;
    name: any;
    disabled?: boolean;
    labelStyleAbsolute?: boolean;
}

export function TextArea({ labelStyleAbsolute, ...props }: TextAreaProps) {
    return (
        <div className={styles.textInputContainer}>
            <Field
                {...props}
                as="textarea"
                required
                className={`${styles.input} ${props.error && styles.inputError}`}
            />

            <label className={labelStyleAbsolute ? `${styles.labelAbsolute}` : `${styles.label}`}>{props.title ? props.title : 'description'}</label>

            <div className={styles.container_error}>
                <span className={styles.textError}>{props.error}</span>
            </div>
        </div>
    )
}