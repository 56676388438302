import React, { useEffect } from "react";
import Modal from "react-modal";
import { Formik, Form, Field, FormikValues } from "formik";
import { XMarkIcon } from "@heroicons/react/24/outline";
import styles from "../styles.module.css";
import { Button } from "@cdt-ui/react";
import Select from "react-select";
import { Input } from "../../../../../components/Form/Input";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
// import Tomografia from "./icons/tomografia.svg";
// import ATM from "./icons/atm.svg";
// import RAIOX from "./icons/raio-x.svg";
import * as yup from "yup";
import { ModalForm } from "../../../../Academy/VisualizarCurso/components/Filter/components/ModalEspecialista/components/Form";
import { ModalFormaddIndicators } from ".";
import Api from "../../../../../helpers/Api";
import { selectUser } from "../../../../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
interface ModalAddIndicatorsProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function ModalAddIndicators({
  isOpen,
  onRequestClose,
}: ModalAddIndicatorsProps) {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const customValueRenderer = (selectedOptions: any[]) => {
    return (
      <div className={styles.selected_options}>
        {selectedOptions.map((option) => (
          <div
            key={option.value}
            className={`${styles.examsBadge}
            ${
              option.value === "tomografia"
                ? styles.tomografiaBadge
                : option.value === "atm"
                ? styles.atmBadge
                : option.value === "raio-x"
                ? styles.raioxBadge
                : ""
            }`}
          >
            <div className="icon">
              <img src={option.iconSrc} alt={option.alt} />
            </div>
            <div>
              {option.label} {option.key}
            </div>
          </div>
        ))}
      </div>
    );
  };
  const validationSchema = yup.object().shape({
    specName: yup
      .string()
      .required("Este campo é obrigatório!")
      .min(5, "O título deve ter pelo menos 5 caracteres!"),
    days: yup.string().required("Este campo é obrigatório!"),
  });
  const { user } = useSelector(selectUser);
  const idClient = user.idClient;
  const getOccupationArea = async () => {
    try {
      const occupationAreas = await Api.GetServices(idClient);
      if (occupationAreas.isSuccess) {
        setOccupationAreas(occupationAreas.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const [occupationAreas, setOccupationAreas] = useState([]);
  const dataAtual = new Date();

  const ano = dataAtual.getFullYear();
  const mes = dataAtual.getMonth() + 1; // Os meses são base 0 (janeiro = 0, fevereiro = 1, etc.), então somamos 1.
  const dia = dataAtual.getDate();

  const dataFormatada = `${ano}-${mes}-${dia}`;
  const handleSubmit = async ({
    specName,
    areasAtuacao,
    days,
  }: FormikValues) => {
    try {
      const { data } = await Api.postIndicators({
        id: 0,
        description: specName,
        active: true,
        indicatorExams: [
          {
            id: 0,
            convenio: "Particular",
            servico: areasAtuacao,
            active: true,
          },
        ],
        days: days,
        creationDat: dataAtual,
      });
      console.log({
        id: 0,
        description: specName,
        active: true,
        indicatorExams: [
          {
            id: 0,
            convenio: "Particular",
            servico: areasAtuacao,
            active: true,
          },
        ],
        days: days,
        creationDat: dataAtual,
      });

      setIsModalOpen(false);
      toast("Indicador Cadastrado", {
        style: {
          background: "#008a00",
          color: "#fff",
        },
      });
    } catch (error) {
      toast("Error ao salvar", {
        style: {
          background: "#e71010",
          color: "#fff",
        },
      });
    }
  };

  useEffect(() => {
    getOccupationArea();
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react_modal_overlay"
      className={styles.react_modal_AddIndicator}
    >
      <div className={styles.icon_wrapper}>
        <XMarkIcon title="Fechar" onClick={onRequestClose} />
      </div>

      <header className={styles.header}>
        <div className={styles.container_content}>
          <h2>Cadastrar Indicador de oportunidade</h2>
          <p>Descrição para auxílio</p>
        </div>
      </header>

      <Formik
        initialValues={{
          specName: "",
          days: "",
          areasAtuacao: "",
        }}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={false}
      >
        {({ errors }) => (
          <ModalFormaddIndicators
            utils={{
              errors,
              getOccupationArea,
              occupationAreas,
              onRequestClose,
            }}
          />
        )}
      </Formik>
    </Modal>
  );
}
