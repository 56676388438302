import styles from "../styles.module.css";

import {
  ArrowPathIcon,
  ChevronRightIcon,
  ArrowRightOnRectangleIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { Text } from "@cdt-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { formatarData } from "../../../helpers/Funcoes";
import { useState } from "react";
import { selectUser } from "../../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../helpers/Api";
import { Newspaper, Wrench } from "@phosphor-icons/react";
import {
  AcademicCapIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
export function Card(notification, info) {
  console.log(notification, "notification")
  const navigate = useNavigate();
  const { user } = useSelector(selectUser);
  const dateCriacaoFormatada = formatarData(
    notification.notification.dateCreation
  );
  const getNotificationDetails = async () => {
    const idUser = user?.idUser;

    try {
      const values = {
        id: 0,
        idUser: idUser,
        idNotification: notification.notification.id,
        read: true,
        readDate: new Date(),
      };

      const result = await Api.updateNotificationById(
        notification.info.id,
        values
      );

      if (notification.notification.link.includes("ser.cdt")) {
        window.location.href = notification.notification.link;
      } else {
        window.open(notification.notification.link, "_blank");
      }
    } catch (error) {
      console.error("Erro ao fazer a requisição:", error);
    }
  };
  if (!notification.notification.active) {
    return null; // Retorna null se a notificação não estiver ativa
  }
  return (
    <div
      className={styles.cardContainer}
    // onClick={() => getNotificationDetails()}
    >
      <div className={styles.cardContainerflex}>
        <div className={styles.cardLeftContainer}>
          <div className={styles.iconContainer}>
            {notification.notification.idImage === 1 ? <Wrench /> : null}
            {notification.notification.idImage === 2 ? <Newspaper /> : null}
            {notification.notification.idImage === 3 ? (
              <AcademicCapIcon width={20} />
            ) : null}
            {notification.notification.idImage === 4 ? (
              <ExclamationCircleIcon width={20} />
            ) : null}
          </div>
          <div>
            <Text className={styles.cardMainText}>
              {notification.notification.title}
            </Text>
            <Text className={styles.textsubtitle}>
              {notification.notification.description}
            </Text>
          </div>
        </div>
        <div>
          {notification.info.read === false ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle cx="4" cy="4" r="4" fill="#007E69" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle
                cx="4"
                cy="4"
                r="3.25"
                fill="white"
                stroke="#E4E4E4"
                stroke-width="1.5"
              />
            </svg>
          )}
        </div>
      </div>
      <Text className={styles.textsubtitle}>{dateCriacaoFormatada}</Text>
    </div>
  );
}
