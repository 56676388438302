import Api from "../../../helpers/Api";

export const getAllModels = async (
  userId: number,
  setModelsData: React.Dispatch<React.SetStateAction<any[]>>,
  setFilteredModelsData: React.Dispatch<React.SetStateAction<any[]>>,
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const safeSetLoading = (value: boolean) => {
    if (setLoading) {
      setLoading(value);
    }
  };

  safeSetLoading(true); 

  try {
    let response = await Api.GetAllExamRequestModel(userId);
    if (response.isSuccess) {
      setModelsData(response.data);
      setFilteredModelsData(response.data);
    }

    setTimeout(() => {
      safeSetLoading(false);
    }, 1000);

  } catch (error) {
    console.error(error);
    setTimeout(() => {
      safeSetLoading(false);
    }, 1000);
  }
};

export const postModel = async (
  modelData: any,
  setModelUpdated: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    const response = await Api.PostExamRequestModel(modelData);
    if (response.isSuccess) {
      setModelUpdated(true);
    }
  } catch (error) {
    console.error(error);
  }
};

export const getModel = async (id: number) => {
  try {
    const response = await Api.GetExamRequestModel(id);
    if (response.isSuccess) {
      return response.data;
    }
    return {};
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const updateModel = async (
  id: number,
  data: any,
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    const response = await Api.PutExamRequestModel(id, data);
    if (response.isSuccess) {
      setUpdated(true);
    }
  } catch (error) {
    setUpdated(false);
    console.error(error);
  }
};

export const getAllExamsRequest = async (
  userId: number,
  userHash: string,
  apiKey: string
) => {
  try {
    const response = await Api.GetAllExamRequest(userId, apiKey, userHash);
    if (response.isSuccess) {
      return response.data;
    }
    return [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getExamRequest = async (
  id: number,
  userHash: string,
  apiKey: string
) => {
  try {
    const response = await Api.GetExamRequest(id, apiKey, userHash);
    if (response.isSuccess) {
      return response.data;
    }
    return {};
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const sendExamRequest = async (
  data: any,
  apiKey: string,
  hashUser: string,
  setModalType: React.Dispatch<React.SetStateAction<string>>,
) => {
  try {
    const response = await Api.PostExamRequestSend(data, apiKey, hashUser);
    if (response.isSuccess) {
      setModalType('successExamsRequest')
    }
  } catch (error) {
    console.error(error);
    setModalType('errorExamsRequest');
  }
};

export const createExamRequest = async (
  data: any,
  apiKey: string,
  hashUser: string,
  setModalType: React.Dispatch<React.SetStateAction<string>>,
) => {
  try {
    const response = await Api.PostExamRequestCreate(data, apiKey, hashUser);
    if (response.isSuccess) {
      setModalType('successExamsRequest')
    }
  } catch (error) {
    console.error(error);
    setModalType('errorExamsRequest');
  }
};

export const updateExamRequest = async (
  id: number,
  data: any,
  userHash: string,
  apiKey: string,
  setModalType?: React.Dispatch<React.SetStateAction<string>>,
  setExamRequestUpdate?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const safeSetExamRequestUpdate = (value: boolean) => {
    if (setExamRequestUpdate) {
      setExamRequestUpdate(value);
    }
  };

  const safeSetModalType = (value: string) => {
    if (setModalType) {
      setModalType(value);
    }
  }

  try {
    const response = await Api.UpdateExamRequest(id, data, apiKey, userHash);
    if (response.isSuccess) {
      safeSetExamRequestUpdate(true);
      safeSetModalType('successExamsRequest');
    }
  } catch (error) {
    safeSetModalType('errorExamsRequest');
  }
};
