import { useState } from 'react';
import { PhotoIcon, DocumentTextIcon, StarIcon, ShareIcon } from '@heroicons/react/24/solid';
import { Tooth } from '@phosphor-icons/react';

import { Padrao } from '../Pages/Padrao';
import { Grupos } from '../Pages/Grupos';
import { Sobreposicao } from '../Pages/Sobreposicao';
import { AnaliseFacial } from '../Pages/AnaliseFacial';
import { Panoramica } from '../Pages/Panoramica';
import { Atm } from '../Pages/Atm';
import { AnaliseSorriso } from '../Pages/AnaliseSorriso';

import styles from './styles.module.css'
import { Correcao } from '../Pages/correcao';
import { Gerados } from '../Pages/Gerados/Gerados';

export function Navigation({ getRelatoriosSelect, ocefx }: any) {
    const [activeTab, setActiveTab] = useState('padrao');

    const handleTabClick = (tabName: string) => {
        setActiveTab(tabName);
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'padrao':
                return <Padrao getRelatoriosSelect={getRelatoriosSelect} />;
            case 'correcao':
                return <Correcao getRelatoriosSelect={getRelatoriosSelect} />;
            case 'grupos':
                return <Grupos getRelatoriosSelect={getRelatoriosSelect} />;
            case 'Sobreposicao':
                return <Sobreposicao getRelatoriosSelect={getRelatoriosSelect} />;
            case 'AnaliseFacial':
                return <AnaliseFacial getRelatoriosSelect={getRelatoriosSelect} />;
            case 'panoramica':
                return <Panoramica getRelatoriosSelect={getRelatoriosSelect} />;
            case 'atm':
                return <Atm getRelatoriosSelect={getRelatoriosSelect} />;
            case 'analiseSorriso':
                return <AnaliseSorriso />;
            case 'gerados':
                return <Gerados ocefx={ocefx}/>;
            default:
                return null;
        }
    };
    return (
        <div className={styles.wrapper}>
            <nav>
                <ul>
                    <li
                        onClick={() => handleTabClick('padrao')}
                        className={activeTab === 'padrao' ? styles.active : ''}
                    >
                        <span>
                            Padrão
                        </span>
                    </li>
                    <li
                        onClick={() => handleTabClick('correcao')}
                        className={activeTab === 'correcao' ? styles.active : ''}
                    >
                        <span>
                            Correção
                        </span>
                    </li>
                    <li
                        onClick={() => handleTabClick('grupos')}
                        className={activeTab === 'grupos' ? styles.active : ''}
                    >
                        <span>
                            Grupos
                        </span>
                    </li>
                    <li
                        onClick={() => handleTabClick('Sobreposicao')}
                        className={activeTab === 'Sobreposicao' ? styles.active : ''}
                    >
                        <span>
                            Sobreposição
                        </span>
                    </li>
                    <li
                        onClick={() => handleTabClick('AnaliseFacial')}
                        className={activeTab === 'AnaliseFacial' ? styles.active : ''}
                    >
                        <span>
                            Facial
                        </span>
                    </li>
                    <li
                        onClick={() => handleTabClick('panoramica')}
                        className={activeTab === 'panoramica' ? styles.active : ''}
                    >
                        <span>
                            Panorâmica
                        </span>
                    </li>
                    <li
                        onClick={() => handleTabClick('atm')}
                        className={activeTab === 'atm' ? styles.active : ''}
                    >
                        <span>
                            ATM
                        </span>
                    </li>
                    <li
                        onClick={() => handleTabClick('gerados')}
                        className={activeTab === 'gerados' ? styles.active : ''}
                    >
                        <span>
                            PDFs 
                        </span>
                    </li>

                </ul>
            </nav>

            <div className={styles.content}>
                {renderContent()}
            </div>
        </div>
    )
}