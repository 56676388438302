import Modal from 'react-modal';
import { useLocation } from 'react-router-dom';

import { XMarkIcon } from '@heroicons/react/24/outline'

import styles from './styles.module.css'

interface ModalOpenPdfProps {
    isOpen: boolean;
    onRequestClose: () => void;
    openPfd: (dataPdf: string) => void;
    jsonData?: any;
    ocefx?: any;
}
export function ModalOpenPdf({ ocefx, isOpen, onRequestClose, openPfd, jsonData }: ModalOpenPdfProps) {
    const location = useLocation();
    const queryString = location.search.substring(1);
    const queryParams = new URLSearchParams(queryString);

    const idParams = queryParams.get("id");
    const seqAtendParams = queryParams.get("atd");

    const openPagePdf = (dataPdf: string) => {
        openPfd(dataPdf)
        onRequestClose()
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react_modal_overlayPdf"
            className="react-modal-pdf"
        >
            <div className={styles.modalOpenPdf}>

                <div className={styles.title}>
                    <p>
                        Clique no ícone de PDF para abrir a página de impressão, caso deseje imprimir o documento:
                    </p>
                </div>


                <XMarkIcon
                    title="Fechar"
                    width={16}
                    onClick={onRequestClose}
                    className={styles.iconClose}
                />


                <div
                    className={styles.containerIcon}
                    style={{
                        justifyContent: jsonData.length < 4 && 'center'
                    }}
                >
                    {

                        jsonData.length == 0 ?
                            <div className={styles.loading_container}>
                                <div className={styles.loading_spinne}></div>
                                <p>Aguarde...</p>
                            </div>
                            :
                            jsonData?.map((dataPdf, index) => (
                                <div className={styles.containerImagePdf}>
                                    <img
                                        key={index}
                                        onClick={() => openPagePdf(dataPdf)}
                                        src={`${ocefx.estado._servCefx}/api/web/arquivos/getImagecmp/${idParams}/${seqAtendParams}/${dataPdf.replace('.pdf', '_m.jpg')}`}
                                        alt="PDF"
                                        title='Abrir PDF'
                                        onError={(e: any) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                                'https://cyberbullying.org/wp-content/uploads/2019/02/pdf-download-300x300.png';
                                        }}
                                    />
                                    <div
                                        className={styles.containerImage_active}
                                        title='Abrir PDF'
                                        onClick={() => openPagePdf(dataPdf)}
                                    />
                                </div>
                            ))
                    }
                </div>
            </div>

        </Modal >
    )
}