import React from 'react'
import { inflate } from 'zlib'
import Modal from 'react-modal'
import styles from './styles.module.css';
import { Container } from '../../../Academy/CadastrarCurso/components/Content/Container';
import { ClockIcon } from "../../components/ClockIcon";
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import CheckIcon from '../../../../assets/iconCheck.png';
import { Button } from '@cdt-ui/react';
import { ArrowRight } from '@phosphor-icons/react';
import { TextField, InputLabel } from '@mui/material';
import Select, { StylesConfig, components, MultiValueRemoveProps } from 'react-select';
import { Input } from '../../../../components/Form/Input';
import { useState } from 'react';
import Avatar from "react-avatar";
import { Card } from "../../components/Card";

interface ModalSendCRMProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function ModalSendCRM({ isOpen, onRequestClose }: ModalSendCRMProps) {
  const queryOptions = [
    { label: 'Selecione o modelo de funil para adicionar pacientes ao CRM' }
  ]

  const [clicked, setClicked] = useState(false);

  const NewRender = ({ examOptions }) => (
    <div className={styles.selectedOptions}>
      <div className={styles.selectedOption}>
        OI
        <p>{examOptions.label}</p>
      </div>
    </div>
  );

  return (
    <div className={`${styles.SendMsg} ${styles.modal}`}>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react_modal_overlay"
        className={styles.react_modal_SendCRM}>

        <div className={styles.icon_wrapper}>
          <XMarkIcon
            title='Fechar'
            onClick={onRequestClose}
          />
        </div>

        <header className={styles.headerCRM}>
          <div className={styles.container_content}>
            <h2>Enviar pacientes para CRM</h2>
            <p>Lorem ipsum dolor sit amet</p>
          </div>
        </header>
        <div className={styles.cardContentWhatsHeader}>
          <h2>PACIENTES</h2>
        </div>
        <div className={styles.cardContentCRM}>
          <h2>Retorno tomografia - Jovens</h2>
          <div className={styles.clockIconContainerCRM}>
            <ClockIcon
              containerClassName="ClockIcon"
            />
            <p>Consulta há 1 ano</p>
          </div>

          <div className={styles.pacientesContainerCRM}>
            <div className={styles.avataresCRM}>
              <span className={styles.avatarsCRM}>
                <Avatar
                  className={styles.avatarCRM}
                  size="30"
                  src="https://s3-alpha-sig.figma.com/img/2e01/b2ad/c895fca7e299aceb539bf37f9a72f316?Expires=1693180800&Signature=f3tWiqHhWBe1ltwiLUk8CEfaRaxVTTCWxHzE2Dp6GFWtERsFJA8gx0~lh1xvDFlOVIB~iNU1lyXephgidM-aVECsULQeW6LqShNFggOLINEU6Be49pMoT-AlGDpfZu33EziR-IlyTWSO~f~EeUAAsk6k1tafJG21JgQbaNIyKMlZkwoR8bD-E8yqBR8HzLVVmimbG4KoSPEu~L~aXaCsdi9sehxOkRcmIHZlbYpFCUHm2CXyzuemXdPxkLMv2fF01jVqZoaczLXkWmEe3JtFH6qd-0gGhuwiJmwAAtjM1FAOf7Sfp8cl626-ymPANjT3uzM41S921fU60BLoV8JTlQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
                />
                <Avatar
                  className={styles.avatar1CRM}
                  size="30"
                  src="https://s3-alpha-sig.figma.com/img/3170/76df/a57d49314359fd7606cce752f3324d5d?Expires=1693180800&Signature=K2ypC1P4TjzX9YhxEoc8UP2bwtHEKDy5U5vQtOyx9Xjt9dWMCRp54QyAg35XN~LwjLnxM9ckeOba4r0wpWV-FQNiVv69KDDtOQMwJpfNwVUY8~Go37Ff18LWUrFMpNfJ-qiZplyXH1NHtOpPOblB3TQfV4UgFntNRo9JUbOJrUUOSyTcM0VLlmrK6kXshb3XkZIJgz3UuRtwVhDdUgHgaqTzLP-MEHUdPLXRiNtr5HjMxGrVaBUz4JQWxW4qvGargu4ROlqASR2V8hgyMbwvgWjnGOK8btGfUINW2xtmPWSkcMV-~WuhmzZPqswYXfqzYSqJfmf1PbdEQtQkXR8ayQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
                />
                <Avatar
                  className={styles.avatar2CRM}
                  size="30"
                  src="https://s3-alpha-sig.figma.com/img/f127/01bd/073e2bc1cd36ec23f693b57248587006?Expires=1693180800&Signature=PQR6rMdBj5VGRghuwsQtl31QGqt305DE4r93lObv5ZSsf8GCU5zdd5MO56a3ajAfB6NRhj1yHD186Fblq0pB4C7ooO~iDjgGI77sXE3SN7tf1wRvgMfeiHM3Gwz-tqbw-x6p4~BKESBBh7jlrZ-HDbMItsYvISYAuO9xONJWN31LT3gKcZkZkjzs3G3C0utW5EfoBosWdohWNukNLs241CtDc~UQd97Xgyrrpb7Zos74Cyn3cMJ6610lUwdKdVxDOkGJyzPo3fv64ld837Z0Qc1m-bMzPcwtTSGr8qAIyOaQtCgflCx17xKsdYlWtFXlDUyWuGrBvFiphG0slSyt0w__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
                />
              </span>
            </div>
            <p>84 pacientes selecionados</p>
            <div className={styles.numeroPacientesCRM}>
              <p>84 de 324 pacientes</p>
            </div>
          </div>
        </div>

        <br></br>
        
        <div className={styles.container_select}>
          <label className={styles.label_select}>
            Modelo de Funil
          </label>
          <Select
            name="lastQuery"
            onFocus={() => setClicked(true)}
            onBlur={() => setClicked(false)}
            className={styles.select}
            placeholder=""
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#237d69",
              },
            })}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                minHeight: "50px",
                maxHeight: "auto",
                backgroundColor: "#fff",
                border: "1px solid #afafaf",
                borderRadius: "12px",
                paddingLeft: "7px",
                outline: "none",
              }),
            }}
            id="lastQuery"
            /* onChange={(event) => setFieldValue("releases", event.map(value => value.value))} */
            defaultValue={queryOptions[0]}
            options={queryOptions}
            closeMenuOnSelect={true}
          />
        </div>
        <div className={styles.btnCRM}>
          <button>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M14.425 5.57501H10C7.56252 5.57501 5.57501 7.56251 5.57501 10C5.57501 12.4375 7.56252 14.425 10 14.425C2.02188 14.425 1 10 1 10C1 5.03126 5.03126 1 10 1H14.425V5.57501Z" fill="white" />
              <path d="M10 14.425C12.4375 14.425 14.425 12.4375 14.425 10V5.57501V1H19V10C19 14.9688 14.9688 19 10 19C5.03126 19 1 14.9688 1 10C1 10 2.02188 14.425 10 14.425Z" fill="white" />
              <path d="M10.1314 11.7907C11.2239 11.7907 12.1096 10.9051 12.1096 9.8126C12.1096 8.72011 11.2239 7.83447 10.1314 7.83447C9.03896 7.83447 8.15332 8.72011 8.15332 9.8126C8.15332 10.9051 9.03896 11.7907 10.1314 11.7907Z" fill="white" />
            </svg>
            Enviar para CRM
          </button>
        </div>
      </Modal>
    </div>
  );



}