export const menuLinks = [
  {
    id: 0,
    sectionTitle: "Pacientes",
    title: "Pacientes",
    icon: "UserGroupIcon",
    children: [
      {
        title: "Pacientes",
        href: "lista-pacientes",
      },
    ],
  },

  // comentado item "Lista de Oportunidades" do menu lateral
  // {
  //   id: 3,
  //   sectionTitle: "Indicadores de Oportunidade",
  //   title: "Lista",
  //   icon: "PresentationChartBarIcon",
  //   children: [
  //     {
  //       title: "Lista de Oportunidades",
  //       href: "indicadores-de-oportunidade",
  //     },
  //   ],
  // },



  // {
  //   id: 4,
  //   sectionTitle: "Clínicas Vinculadas",
  //   title: "Clinica",
  //   icon: "PresentationChartBarIcon",
  //   children: [
  //     {
  //       title: "Clínicas Vinculadas",
  //       href: "clinica",
  //     },
  //   ],
  // },

  {
    id: 1,
    sectionTitle: "Academy",
    title: "Academy",
    icon: "AcademicCapIcon",
    children: [
      {
        title: "Academy",
        href: "academy/curso",
      },
      // {
      //   title: "Adicionar Curso",
      //   href: "academy/cadastrar",
      // },
    ],
  },

  {
    id: 2,
    sectionTitle: "Agenda",
    title: "Agenda",
    icon: "CalendarDaysIcon",
    children: [
      {
        title: "Agenda",
        href: "agenda",
      },
    ],
  },
  // {
  //   id: 5,
  //   sectionTitle: "Rquisição de Exames",
  //   title: "Requisicao de Exames",
  //   icon: "MegaphoneIcon",
  //   children: [
  //     {
  //       title: "Requisicao de Exames",
  //       href: "requisição-exames",
  //     },
  //   ],
  // },
  {
    id: 3,
    sectionTitle: "Financeiro",
    title: "Financeiro",
    icon: "BanknotesIcon",
    children: [
      {
        title: "Financeiro",
        href: "financeiro",
      },
    ],
  },
  {
    id: 4,
    sectionTitle: "Marketing",
    title: "Marketing",
    icon: "LightBulbIcon",
    children: [
      {
        title: "Marketing",
        href: "marketing",
      },
    ],
  },
  {
    id: 5,
    sectionTitle: "Fiscal",
    title: "Fiscal",
    icon: "MagnifyingGlassIcon",
    children: [
      {
        title: "Fiscal",
        href: "fiscal",
      },
    ],
  },
  {
    id: 6,
    sectionTitle: "Relatorios",
    title: "Relatorios",

    icon: "PresentationChartLineIcon",

    children: [
      {
        title: "Relatorios",
        href: "relatorios",
      },
    ],
  },
];
