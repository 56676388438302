import $ from "jquery";

import vtkResliceCursorWidget from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget';
import vtkLineSource from '@kitware/vtk.js/Filters/Sources/LineSource';
import vtkGenericRenderWindow from '@kitware/vtk.js/Rendering/Misc/GenericRenderWindow';
import vtkWidgetManager from '@kitware/vtk.js/Widgets/Core/WidgetManager';
import vtkInteractorStyleImage from '@kitware/vtk.js/Interaction/Style/InteractorStyleImage';
import vtkInteractorStyleTrackballCamera from '@kitware/vtk.js/Interaction/Style/InteractorStyleTrackballCamera';
import vtkImageMapper from '@kitware/vtk.js/Rendering/Core/ImageMapper';
import vtkImageReslice from '@kitware/vtk.js/Imaging/Core/ImageReslice';
import vtkImageSlice from '@kitware/vtk.js/Rendering/Core/ImageSlice';
import vtkXMLImageDataReader from "@kitware/vtk.js/IO/XML/XMLImageDataReader";
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkColorTransferFunction from "@kitware/vtk.js/Rendering/Core/ColorTransferFunction";
import vtkPiecewiseFunction from "@kitware/vtk.js/Common/DataModel/PiecewiseFunction";
import vtkVolumeProperty from "@kitware/vtk.js/Rendering/Core/VolumeProperty";
import vtkVolume from "@kitware/vtk.js/Rendering/Core/Volume";
import vtkVolumeMapper from "@kitware/vtk.js/Rendering/Core/VolumeMapper";
import vtkPlaneSource from '@kitware/vtk.js/Filters/Sources/PlaneSource';
import vtkPlane from '@kitware/vtk.js/Common/DataModel/Plane';
import vtkLineWidget from '@kitware/vtk.js/Widgets/Widgets3D/LineWidget';
import { func } from "prop-types";
import { xyzToViewType } from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/Constants';

import '@kitware/vtk.js/Rendering/Profiles/All';

import { CaptureOn } from '@kitware/vtk.js/Widgets/Core/WidgetManager/Constants';

import { vec3 } from 'gl-matrix';
import { SlabMode } from '@kitware/vtk.js/Imaging/Core/ImageReslice/Constants';

// Force the loading of HttpDataAccessHelper to support gzip decompression
import '@kitware/vtk.js/IO/Core/DataAccessHelper/HttpDataAccessHelper';

const viewColors = [
    [1, 0, 0], // sagittal
    [0, 1, 0], // coronal
    [0, 0, 1], // axial
    [0.5, 0.5, 0.5], // 3D
];

const appCursorStyles = {
    translateCenter: 'move',
    rotateLine: 'alias',
    translateAxis: 'pointer',
    default: 'default',
};

let viewAttributes = [];
const widget = vtkResliceCursorWidget.newInstance();
const widgetState = widget.getWidgetState();

widgetState
    .getStatesWithLabel('sphere')
    .forEach((handle) => handle.setScale1(10));

widget
    .getWidgetState()
    .getStatesWithLabel('handles')
    .forEach((handle) => handle.setOpacity(85));

widgetState.getStatesWithLabel('line').forEach((handle) => handle.setScale3(2, 2, 2));

//todo:descomentar
//widgetState.setKeepOrthogonality(true);
//widgetState.setSphereRadius(6);
//widgetState.setLineThickness(1);

//if (mobileAndTabletCheck())
//  widgetState.setSphereRadius(30);
//else
//  widgetState.setSphereRadius(6);

//Tolerance - precisao em pixels para pegar a linha

const showDebugActors = true;

const initialPlanesState = { ...widgetState.getPlanes() };

let view3D = null;
let theNavegacao = { rends: [], jviewer: 0, widget: widget, widgetState: widgetState, atributos: viewAttributes, ln: null };

var coords = { x: 0, y: 0, bt: 0 };

const line1 = vtkLineSource.newInstance();
const line2 = vtkLineSource.newInstance();
const line3 = vtkLineSource.newInstance();

var filtroAtual = '';
var theVolume;

var blnAbriuDicom = false;
var urlAbriuDicom = '';
var atores3d = new Array();

function onMouseDown(e) {
    e.preventDefault();
    if (e.which == 3) {
        coords.x = e.x;
        coords.y = e.y;
        coords.bt = 3;
    }
}

function onMouseMove(e) {
    e.preventDefault();
    if (coords.bt == 3) {
        var dif = coords.y - e.y;
        var ij = e.currentTarget.janela;
        if (e.currentTarget.janela < 4) {
            var ps = theNavegacao.rends[ij].getActiveCamera().getParallelScale();
            theNavegacao.rends[ij].getActiveCamera().setParallelScale(Math.abs(ps - dif));
            theNavegacao.rends[ij].getRenderWindow().render()
        }
        coords.x = e.x;
        coords.y = e.y;
    }
}

function onMouseUp(e) {
    e.preventDefault();

    coords.bt = 0;
}

function createRGBStringFromRGBValues(rgb) {
    if (rgb.length !== 3) {
        return 'rgb(0, 0, 0)';
    }
    return `rgb(${(rgb[0] * 255).toString()}, ${(rgb[1] * 255).toString()}, ${(
        rgb[2] * 255
    ).toString()})`;
}


export function criarMPR() {
    if (theNavegacao.rends.length > 0) {
        theNavegacao = { rends: [], jviewer: 0, widget: widget, widgetState: widgetState, atributos: viewAttributes, ln: null };
        viewAttributes = [];
        //for (let i = 0; i < 4; i++) {
        //    var element = document.getElementById("divMPRA" + i);
        //    //if (i == 3)
        //    //  element = document.getElementById("divContainer3d");
        //    //const colElement = document.getElementById("col_mpr" + i);
        //    var v = (window.innerHeight - 120) / 2;
        //    element.style.height = v + 'px';
        //    element.style.width = v + 'px';
        //    //element.style.width= v + 'px';
        //    //colElement.style.height = v + 'px';

        //    var obj = viewAttributes[i];
        //    if(i<3)
        //        obj.renderer.setBackground([0, 0, 0]);//...viewColors[i]);//
        //}

        //return;
    }

    for (let i = 0; i < 4; i++) {
        var element = document.getElementById("divMPRA" + i);
        //if (i == 3)
        //  element = document.getElementById("divContainer3d");
        //const colElement = document.getElementById("col_mpr" + i);
        var v = (window.innerHeight - 120) / 2;
        // element.style.height = v + 'px';
        // element.style.width= v + 'px';
        //element.style.width= v + 'px';
        //colElement.style.height = v + 'px';

        const grw = vtkGenericRenderWindow.newInstance();
        grw.setContainer(element);
        grw.resize();
        //grw.getRenderer().getRenderWindow().getViews()[0].setSize(v, v);

        const obj = {
            renderWindow: grw.getRenderWindow(),
            renderer: grw.getRenderer(),
            GLWindow: grw.getOpenGLRenderWindow(),
            interactor: grw.getInteractor(),
            widgetManager: vtkWidgetManager.newInstance(),
            orientationWidget: null,
        };

        obj.renderer.getActiveCamera().setParallelProjection(true);
        obj.renderer.setBackground([0, 0, 0]);//...viewColors[i]);//
        obj.renderWindow.addRenderer(obj.renderer);
        obj.renderWindow.addView(obj.GLWindow);
        obj.renderWindow.setInteractor(obj.interactor);
        obj.interactor.setView(obj.GLWindow);
        obj.interactor.initialize();
        obj.interactor.bindEvents(element);
        //nao precisava disso
        //obj.renderer.getRenderWindow().getViews()[0].setSize(50, 50);//element.clientWidth, element.clientHeight)

        element.addEventListener('mousedown', onMouseDown);
        element.addEventListener('mousemove', onMouseMove);
        element.addEventListener('mouseup', onMouseUp);
        element.janela = i;

        obj.widgetManager.setRenderer(obj.renderer);
        theNavegacao.rends.push(obj.renderer);
        if (i < 3) {
            obj.interactor.setInteractorStyle(vtkInteractorStyleImage.newInstance());
            obj.widgetInstance = obj.widgetManager.addWidget(widget, xyzToViewType[i]);
            //TODO: corrigir as linhas comentadas abaixo (3 linhas)
            obj.widgetInstance.setScaleInPixels(true);
            //obj.widgetInstance.setRotationHandlePosition(0.75);
            //se estiver rodando e colocar false, as linhas horiz e vert se movem independentemente
            obj.widgetInstance.setKeepOrthogonality(true);
            //Rotacao - se colocar true, fica igual rastreamento - talvez tenha que habilitar para evol cloud
            obj.widgetInstance.setEnableRotation(false);

            obj.widgetInstance.setCursorStyles(appCursorStyles);
            obj.widgetManager.enablePicking();
            // Use to update all renderers buffer when actors are moved
            obj.widgetManager.setCaptureOn(CaptureOn.MOUSE_MOVE);
        }
        else {
            obj.interactor.setInteractorStyle(vtkInteractorStyleTrackballCamera.newInstance());
        }

        obj.reslice = vtkImageReslice.newInstance();
        obj.reslice.setSlabMode(SlabMode.MEAN);
        obj.reslice.setSlabNumberOfSlices(1);
        obj.reslice.setTransformInputSampling(false);
        obj.reslice.setAutoCropOutput(true);
        obj.reslice.setOutputDimensionality(2);
        obj.reslice.setBackgroundColor([-1500, -1500, -1500, -1500]);
        obj.resliceMapper = vtkImageMapper.newInstance();
        obj.resliceMapper.setInputConnection(obj.reslice.getOutputPort());
        obj.resliceActor = vtkImageSlice.newInstance();
        obj.resliceActor.setMapper(obj.resliceMapper);
        obj.sphereActors = [];
        obj.sphereSources = [];

        obj.resliceActor.getProperty().setColorWindow(5000);
        obj.resliceActor.getProperty().setColorLevel(1500);
        obj.resliceActor.getProperty().setInterpolationTypeToLinear();

        //teste - parece que deu certo


        widgetState.getPlanes()[4].normal = [-1, 0, 0];
        widgetState.getPlanes()[5].normal = [0, 1, 0];
        widgetState.getPlanes()[6].normal = [0, 0, 1];

        widgetState.getPlanes()[4].viewUp = [0, 0, -1];
        widgetState.getPlanes()[5].viewUp = [0, 0, -1];
        widgetState.getPlanes()[6].viewUp = [0, 1, 0];




        //estas linhas impediam o brilho e contraste
        /*
                // Create sphere for each 2D views which will be displayed in 3D
                // Define origin, point1 and point2 of the plane used to reslice the volume
                for (let j = 0; j < 3; j++) {
                    const sphere = vtk.Filters.Sources.vtkSphereSource.newInstance();
                    sphere.setRadius(10);
                    const mapper = vtk.Rendering.Core.vtkMapper.newInstance();
                    mapper.setInputConnection(sphere.getOutputPort());
                    const actor = vtk.Rendering.Core.vtkActor.newInstance();
                    actor.setMapper(mapper);
                    actor.getProperty().setColor(...viewColors[i]);
                    actor.setVisibility(showDebugActors);
                    obj.sphereActors.push(actor);
                    obj.sphereSources.push(sphere);
                }
        */
        if (i < 3) {
            viewAttributes.push(obj);
        } else {
            view3D = obj;
        }

        var props = viewAttributes[0].resliceActor.getProperty();
        if (i > 0 && i < 3)
            viewAttributes[i].resliceActor.setProperty(props);


    }
}


//funcao updateReslice - chamada ao carregar, angular o plano, scroll, etc - funcao local do script
// o viewtype passo é o numero 4 5 6 dos reslices
function updateReslice(interactionContext = {
    viewType: '',
    reslice: null,
    actor: null,
    renderer: null,
    resetFocalPoint: false, // Reset the focal point to the center of the display image
    keepFocalPointPosition: false, // Defines if the focal point position is kepts (same display distance from reslice cursor center)
    computeFocalPointOffset: false, // Defines if the display offset between reslice center and focal point has to be
    // computed. If so, then this offset will be used to keep the focal point position during rotation.
    spheres: null,
    slider: null,
}) {
    const obj = widget.updateReslicePlane(interactionContext.reslice, interactionContext.viewType);
    if (obj) {
        // Get returned modified from setter to know if we have to render
        interactionContext.actor.setUserMatrix(interactionContext.reslice.getResliceAxes());
        //interactionContext.sphereSources[0].setCenter(...obj.origin);
        //interactionContext.sphereSources[1].setCenter(...obj.point1);
        //interactionContext.sphereSources[2].setCenter(...obj.point2);
    }
    widget.updateCameraPoints(interactionContext.renderer, interactionContext.viewType,
        interactionContext.resetFocalPoint, interactionContext.keepFocalPointPosition,
        interactionContext.computeFocalPointOffset);
    view3D.renderWindow.render();
    return obj;
}

export default function abreMPRNova(data) {
    document.getElementById("spnLoading").style.display = 'none';
    document.getElementById("spnLoading").style.opacity = '-1'
    if (!data)
        return;

    //if (theVolume) {
    //    theVolume.getMapper().setInputData(data);
    //    widget.setImage(data);
    //    viewAttributes.forEach((obj, i) => {
    //        obj.reslice.setInputData(data);
    //    });
    //    renderAll();
    //    return;
    //}

    widget.setImage(data);

    viewAttributes.forEach((obj, i) => {
        obj.reslice.setInputData(data);
        obj.renderer.addActor(obj.resliceActor);
        //view3D.renderer.addActor(obj.resliceActor);
        obj.sphereActors.forEach((actor) => {
            obj.renderer.addActor(actor);
            view3D.renderer.addActor(actor);
        });
        const reslice = obj.reslice;
        const viewType = xyzToViewType[i];

        viewAttributes
            // No need to update plane nor refresh when interaction
            // is on current view. Plane can't be changed with interaction on current
            // view. Refreshs happen automatically with `animation`.
            // Note: Need to refresh also the current view because of adding the mouse wheel
            // to change slicer
            .forEach((v) => {
                // Interactions in other views may change current plane
                v.widgetInstance.onInteractionEvent(
                    // computeFocalPointOffset: Boolean which defines if the offset between focal point and
                    // reslice cursor display center has to be recomputed (while translation is applied)
                    // canUpdateFocalPoint: Boolean which defines if the focal point can be updated because
                    // the current interaction is a rotation
                    ({ computeFocalPointOffset, canUpdateFocalPoint }) => {
                        const activeViewType = widget
                            .getWidgetState()
                            .getActiveViewType();
                        const keepFocalPointPosition =
                            activeViewType !== viewType && canUpdateFocalPoint;
                        updateReslice({
                            viewType,
                            reslice,
                            actor: obj.resliceActor,
                            renderer: obj.renderer,
                            resetFocalPoint: false,
                            keepFocalPointPosition,
                            computeFocalPointOffset,
                            sphereSources: obj.sphereSources,
                            slider: obj.slider,
                        });
                        atualizaPosLinha(line1, 4);
                        atualizaPosLinha(line2, 5);
                        atualizaPosLinha(line3, 6);
                    }
                );
            });

        updateReslice({
            viewType,
            reslice,
            actor: obj.resliceActor,
            renderer: obj.renderer,
            resetFocalPoint: true, // At first initilization, center the focal point to the image center
            keepFocalPointPosition: false, // Don't update the focal point as we already set it to the center of the image
            computeFocalPointOffset: true, // Allow to compute the current offset between display reslice center and display focal point
            sphereSources: obj.sphereSources,
            slider: obj.slider,
        });
        obj.renderWindow.render();
    });
    //mostrarDesenhos();
    //nao precisava disso
    //view3D.renderer.getRenderWindow().getViews()[0].setSize(100, 100);//element.clientWidth, element.clientHeight)
    mostra3d(data, view3D.renderer);
    posicao('A');
    view3D.renderer.resetCamera();
    view3D.renderer.resetCameraClippingRange();
    resetZoom();
    renderAll();
    //initCam2d();
    //todo:descomentar
    //abreViewer2(url);

    //TODO: arrumar o slider
    // set max number of slices to slider.
    //const maxNumberOfSlices = vec3.length(image.getDimensions());
    //document.getElementById('slabNumber').max = maxNumberOfSlices;
    //});
}

// ----------------------------------------------------------------------------
// Define panel interactions
// ----------------------------------------------------------------------------
// chamada dos controles da tela - funcao local do script
function updateViews() {
    updateViews(true);
}

function updateViews2(resetFocalPoint) {
    viewAttributes.forEach((obj, i) => {
        updateReslice({
            viewType: xyzToViewType[i],
            reslice: obj.reslice,
            actor: obj.resliceActor,
            renderer: obj.renderer,
            resetFocalPoint: resetFocalPoint,
            keepFocalPointPosition: false,
            computeFocalPointOffset: true,
            sphereSources: obj.sphereSources,
            resetViewUp: true,
        });
        obj.renderWindow.render();
    });
    view3D.renderer.resetCamera();
    view3D.renderer.resetCameraClippingRange();
}

//todo: descomentar
////controles e eventos
//const checkboxOrthogonality = document.getElementById('checkboxOrthogality');
//checkboxOrthogonality.addEventListener('change', (ev) => {
//    widgetState.setKeepOrthogonality(checkboxOrthogonality.checked);
//});

//const checkboxRotation = document.getElementById('checkboxRotation');
//checkboxRotation.addEventListener('change', (ev) => {
//    widgetState.setEnableRotation(checkboxRotation.checked);
//});

//const checkboxTranslation = document.getElementById('checkboxTranslation');
//checkboxTranslation.addEventListener('change', (ev) => {
//    widgetState.setEnableTranslation(checkboxTranslation.checked);
//});

//const checkboxScaleInPixels = document.getElementById('checkboxScaleInPixels');
//checkboxScaleInPixels.addEventListener('change', (ev) => {
//    viewAttributes.forEach((obj) => {
//        obj.widgetInstance.setScaleInPixels(checkboxScaleInPixels.checked);
//        obj.interactor.render();
//    });
//});

//const checkboxLines = document.getElementById('checkboxLines');
//checkboxLines.addEventListener('change', (ev) => {
//    widgetState.setLineThickness(checkboxLines.checked ? 1 : 0);
//    widgetState.setSphereRadius(checkboxLines.checked ? 6 : 0);
//    updateViews();

//});

//const optionSlabModeMin = document.getElementById('slabModeMin');
//optionSlabModeMin.value = SlabMode.MIN;
//const optionSlabModeMax = document.getElementById('slabModeMax');
//optionSlabModeMax.value = SlabMode.MAX;
//const optionSlabModeMean = document.getElementById('slabModeMean');
//optionSlabModeMean.value = SlabMode.MEAN;
//const optionSlabModeSum = document.getElementById('slabModeSum');
//optionSlabModeSum.value = SlabMode.SUM;
//const selectSlabMode = document.getElementById('slabMode');
//selectSlabMode.addEventListener('change', (ev) => {
//    viewAttributes.forEach((obj) => {
//        obj.reslice.setSlabMode(Number(ev.target.value));
//    });
//    updateViews();
//});

//const sliderSlabNumberofSlices = document.getElementById('slabNumber');
//sliderSlabNumberofSlices.addEventListener('change', (ev) => {
//    const divSlabNumberValue = document.getElementById('slabNumberValue');
//    divSlabNumberValue.innerHTML = ev.target.value;
//    viewAttributes.forEach((obj) => {
//        obj.reslice.setSlabNumberOfSlices(ev.target.value);
//    });
//    updateViews();
//});

//a ultima nao descomenta


//const buttonReset = document.getElementById('buttonReset');
//buttonReset.addEventListener('click', () => {
//    widgetState.setPlanes(initialPlanesState);
//    widget.setCenter(widget.getWidgetState().getImage().getCenter());
//    updateViews();
//});
//fim : controles e eventos




//Nossa funções daqui para baixo
function initCam2d() {

    var widefov = 3000; //
    for (let k = 0; k < 3; k++) {
        theNavegacao.rends[k].resetCamera();
        var cam = theNavegacao.rends[k].getActiveCamera();
        var centro = cam.getFocalPoint();
        // cam.setFocalPoint(centro[0], centro[1], centro[2]);
        if (k == 0) {
            cam.setPosition(centro[0] + widefov, centro[1], centro[2]);
            cam.setViewUp(0, 0, -1);
        }
        if (k == 1) {
            cam.setPosition(centro[0], centro[1] - widefov, centro[2]);
            cam.setViewUp(0, 0, -1);
        }
        if (k == 2) {
            cam.setPosition(centro[0], centro[1], centro[2] - widefov);
            cam.setViewUp(0, 1, 0);
            //cam.roll(180);
        }
        //cam.zoom(1.8);
    }
}

function abreViewer2(urlImagem) {
    if (theNavegacao.jviewer == 0) {

        var dados = document.getElementById("dadosListaViewer");
        var url = urlImagem;
        var psi = url.lastIndexOf('/');
        url = url.substring(0, psi) + '/projeto.json';
        $.getJSON(url,
            function (json) {
                theNavegacao.jviewer = json;
                var listaf = theNavegacao.jviewer.fases;

                listaf.sort(function (a, b) {
                    return a.ordem > b.ordem;
                });


                for (let i = 0; i < listaf.length; i++) {

                    var accordItem;
                    if (listaf[i].pai == 8999 && !listaf[i].descricao.includes('INFORMAÇÕES: PACIENTE')) {
                        accordItem = `
                            <li>
                                <a href="javascript:;" class="li-custom has-arrow" onclick='return selecionarPosicao(` + i + `)'>
                                <i class='bx bxs-circle menu-icon-circle' ></i>`;
                        if (listaf[i].descricao != '')
                            accordItem += listaf[i].descricao + ' ' + listaf[i].regiao;
                        else accordItem += `N/I` + ' ' + listaf[i].regiao;
                        accordItem += `</a>
                                <ul class="mm-collapse mm-collapsing-custom">`;
                        for (let j = 0; j < listaf.length; j++) {
                            if (listaf[j].pai == listaf[i].numero) {
                                accordItem += `
                                            <li>
                                                <a href="javascript:;" class="li-custom" onclick='return selecionarPosicao(` + j + `)'>
                                                <i class='bx bxs-circle menu-icon-circle' ></i>`;
                                if (listaf[j].anotacao != '')
                                    accordItem += listaf[j].anotacao + ' ' + listaf[i].regiao;
                                else accordItem += `N/I` + ' ' + listaf[i].regiao;;
                                accordItem += `
                                                </a>

                                                 <ul class="mm-collapse d-none">
                                                    <li style="padding:8px 15px 8px 30px!important">
                                                         <span> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five cent</span>
                                                    </li>
                                                </ul>
                                            </li>`;

                                /*<li>
                                        <a href="javascript:;" class="li-custom has-arrow" onclick='return selecionarPosicao(` + j + `)'>
                                        <i class='bx bxs-circle menu-icon-circle' ></i>`;
                                                if (listaf[j].anotacao != '')
                                                    accordItem += listaf[j].anotacao;
                                                else accordItem += `N/I`;
                                accordItem += `
                                        </a>

                                         <ul class="mm-collapse">
                                            <li style="padding:8px 15px 8px 30px!important">
                                                 <span> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five cent</span>
                                            </li>
                                        </ul>
                                    </li>`;*/
                            }
                        };
                        accordItem += `
                                </ul>
                            </li>`;

                        dados.innerHTML += accordItem;
                    }

                    if (accordItem != "") {
                        $("#menuDicom").addClass("has-arrow");
                    }

                }
                //var fase = theNavegacao.jviewer.fases[38];
                //ativaPosicao(fase.posicao);
                //mostrarDesenhos();
            });


    }

    if ($("#dadosEscaneamento").children().length > 0)
        $("#menuEscaneamento").css("display", "block");
}

function mostrarDesenhos() {
    theNavegacao.jviewer.desenhos.forEach((des) => {
        const linha = vtkLineSource.newInstance();
        linha.setPoint1(des.x, des.y, des.z);
        linha.setPoint2(des.x2, des.y2, des.z2);

        //const seta = vtk.Filters.Sources.vtkArrowSource.newInstance();

        //seta.setTipResolution(8);
        //seta.setTipLength(0.3);
        //seta.setTipRadius(0.1);
        //seta.update();

        const actor = vtkActor.newInstance();
        const mapper = vtkMapper.newInstance();

        //actor.getProperty().setPointSize(10);
        //actor.getProperty().setRepresentation(Representation.POINTS);

        actor.setMapper(mapper);
        mapper.setInputConnection(linha.getOutputPort());

        for (var i = 0; i < 4; i++) {
            theNavegacao.rends[i].addActor(actor);
        }

    });

}

function carregaFiltro(nome, actor, shift) {
    // create color and opacity transfer functions
    const ctfun = vtkColorTransferFunction.newInstance();
    const ofun = vtkPiecewiseFunction.newInstance();
    const gdfun = vtkPiecewiseFunction.newInstance();
    const aProp = vtkVolumeProperty.newInstance();

    if (nome == "RX") {

        ctfun.addRGBPoint(shift + -1000.000, 0.000, 0.000, 0.000, 0.500, 0.000);
        ctfun.addRGBPoint(shift + -975.989, 1.000, 1.000, 1.000, 0.500, 0.000);
        ctfun.addRGBPoint(shift + -428.672, 0.486, 0.486, 0.486, 0.500, 0.000);
        ctfun.addRGBPoint(shift + 418.785, 0.495, 0.495, 0.495, 0.500, 0.000);
        ctfun.addRGBPoint(shift + 754.237, 0.502, 0.502, 0.502, 0.500, 0.000);
        ctfun.addRGBPoint(shift + 1213.277, 1.000, 1.000, 1.000, 0.500, 0.000);
        ctfun.addRGBPoint(shift + 1848.870, 1.000, 1.000, 1.000, 0.500, 0.000);
        ctfun.addRGBPoint(shift + 2431.497, 1.000, 1.000, 1.000, 0.500, 1.000);
        ctfun.addRGBPoint(shift + 4302.966, 1.000, 1.000, 1.000, 0.500, 0.000);
        ctfun.addRGBPoint(shift + 4302.966, 1.000, 1.000, 1.000, 0.500, 0.000);
        ctfun.addRGBPoint(shift + 5521.187, 1.000, 1.000, 1.000, 0.500, 0.000);

        ofun.addPoint(shift + -1000.000, 0.000);
        ofun.addPoint(shift + -975.989, 0.000);
        ofun.addPoint(shift + -428.672, 0.000);
        ofun.addPoint(shift + 418.785, 0.006);
        ofun.addPoint(shift + 754.237, 0.027);
        ofun.addPoint(shift + 1213.277, 0.056);
        ofun.addPoint(shift + 1848.870, 0.041);
        ofun.addPoint(shift + 2431.497, 0.140);
        ofun.addPoint(shift + 4302.966, 0.196);
        ofun.addPoint(shift + 4302.966, 0.295);
        ofun.addPoint(shift + 5521.187, 1.000);

        gdfun.addPoint(0.000, 1.000);
        gdfun.addPoint(255.000, 1.000);


        aProp.setInterpolationType(1);
        aProp.setShade(0);
        aProp.setAmbient(0.180);
        aProp.setDiffuse(0.460);
        aProp.setSpecular(0.250);
        aProp.setSpecularPower(38.000);
        aProp.setScalarOpacityUnitDistance(0, 0.250);


        aProp.setRGBTransferFunction(0, ctfun);
        aProp.setScalarOpacity(0, ofun);
        //erro - nao existe funcao
        //aProp.setGradientOpacity(0, gdfun);
    }
    if (nome == "claro") {
        ctfun.addRGBPoint(0 + shift, 0.0, 0.0, 0.0, 0.0);
        ctfun.addRGBPoint(500 + shift, 1.0, 0.5, 0.3, 0.0);
        ctfun.addRGBPoint(1000 + shift, 1.0, 0.5, 0.3, 0.0);
        ctfun.addRGBPoint(1150 + shift, 1.0, 1.0, 0.9, 0.0);

        ofun.addPoint(0 + shift, 0.00);
        ofun.addPoint(500 + shift, 0.15);
        ofun.addPoint(1000 + shift, 0.15);
        ofun.addPoint(1150 + shift, 0.85);

        gdfun.addPoint(0, 0.00);
        gdfun.addPoint(90, 0.5);
        gdfun.addPoint(100, 1.0);

        aProp.setScalarOpacityUnitDistance(0, 4.5);

        aProp.setShade(true);
        aProp.setAmbient(0.4);
        aProp.setDiffuse(0.6);
        aProp.setSpecular(0.3);
        //aProp.setSpecularPower(8.0);

    }
    if (nome == "default" || nome == "")//osso
    {

        ctfun.addRGBPoint(shift + -3024.000, 0.000, 0.000, 0.000);
        ctfun.addRGBPoint(shift + -1041.547, 0.000, 0.000, 0.000);
        ctfun.addRGBPoint(shift + -984.814, 0.502, 0.502, 0.502);
        ctfun.addRGBPoint(shift + -919.206, 0.753, 0.753, 0.753);
        ctfun.addRGBPoint(shift + 61.604, 0.720, 0.629, 0.497);
        ctfun.addRGBPoint(shift + 439.828, 0.812, 0.718, 0.528);
        ctfun.addRGBPoint(shift + 641.385, 0.906, 0.816, 0.553);
        ctfun.addRGBPoint(shift + 3071.000, 1.000, 1.000, 1.000);
        //8
        ofun.addPoint(shift + -3024.000, 0.000);
        ofun.addPoint(shift + -1041.547, 0.000);
        ofun.addPoint(shift + -984.814, 0.002);
        ofun.addPoint(shift + -919.206, 0.000);
        ofun.addPoint(shift + 61.604, 0.000);
        ofun.addPoint(shift + 439.828, 0.000);
        ofun.addPoint(shift + 641.385, 0.766);
        ofun.addPoint(shift + 3071.000, 0.706);
        //2
        gdfun.addPoint(0.000, 1.000, 0.500, 0.000);
        gdfun.addPoint(255.000, 1.000, 0.500, 0.000);

        aProp.setShade(true);
        aProp.setAmbient(0.1);
        aProp.setDiffuse(0.9);
        aProp.setSpecular(0.2);
        aProp.setSpecularPower(10.0);
        aProp.setScalarOpacityUnitDistance(0, .518);

    }
    if (nome == "laranja")//laranja
    {
        ctfun.addRGBPoint(shift + -1.000, 0.000, 0.000, 0.000, 0.500, 0.000);
        ctfun.addRGBPoint(shift + 74.170, 0.000, 0.000, 0.000, 0.500, 0.000);
        ctfun.addRGBPoint(shift + 173.801, 1.000, 0.502, 0.251, 0.500, 0.000);
        ctfun.addRGBPoint(shift + 406.273, 1.000, 0.502, 0.251, 0.500, 0.000);
        ctfun.addRGBPoint(shift + 1289.668, 1.000, 0.502, 0.000, 0.500, 0.000);
        ctfun.addRGBPoint(shift + 2465.314, 0.953, 0.847, 0.078, 0.500, 0.000);

        ofun.addPoint(shift + -1.000, 0.000, 0.500, 0.000);
        ofun.addPoint(shift + 74.170, 0.000, 0.500, 0.000);
        ofun.addPoint(shift + 173.801, 0.000, 0.500, 0.000);
        ofun.addPoint(shift + 406.273, 0.037, 0.500, 0.000);
        ofun.addPoint(shift + 1289.668, 0.054, 0.500, 0.000);
        ofun.addPoint(shift + 2465.314, 0.328, 0.500, 0.000);
        //2
        gdfun.addPoint(0.000, 1.000, 0.500, 0.000);
        gdfun.addPoint(255.000, 1.000, 0.500, 0.000);

        aProp.setShade(true);
        aProp.setAmbient(0.1);
        aProp.setDiffuse(0.9);
        aProp.setSpecular(0.2);
        aProp.setSpecularPower(10.0);
        aProp.setScalarOpacityUnitDistance(0, .518);

    }
    if (nome == "novo") {

        ctfun.addRGBPoint(200.0, 0.4, 0.2, 0.0);
        ctfun.addRGBPoint(2000.0, 1.0, 1.0, 1.0);

        ofun.addPoint(200.0, 0.0);
        ofun.addPoint(1200.0, 0.5);
        ofun.addPoint(3000.0, 0.8);
        aProp.setRGBTransferFunction(0, ctfun);
        aProp.setScalarOpacity(0, ofun);
        aProp.setScalarOpacityUnitDistance(0, 4.5);
        aProp.setInterpolationTypeToLinear();
        aProp.setUseGradientOpacity(0, true);
        aProp.setGradientOpacityMinimumValue(0, 15);
        aProp.setGradientOpacityMinimumOpacity(0, 0.0);
        aProp.setGradientOpacityMaximumValue(0, 100);
        aProp.setGradientOpacityMaximumOpacity(0, 1.0);
        aProp.setShade(true);
        aProp.setAmbient(0.2);
        aProp.setDiffuse(0.7);
        aProp.setSpecular(0.3);
        aProp.setSpecularPower(8.0);
    }

    aProp.setRGBTransferFunction(0, ctfun);
    aProp.setScalarOpacity(0, ofun);
    aProp.setInterpolationTypeToLinear();
    actor.setProperty(aProp);
}

function mostra3d(data, ren3d) {

    //if (theVolume)
    //{
    //    theVolume.getMapper().setInputData(data);
    //    return;
    //}
    const actor = vtkVolume.newInstance();
    const mapper = vtkVolumeMapper.newInstance();

    const sampleDistance = 0.7 * Math.sqrt(data.getSpacing().map((v) => v * v).reduce((a, b) => a + b, 0));
    mapper.setSampleDistance(sampleDistance);

    //mapper.setSampleDistance(1.1);
    actor.setMapper(mapper);

    carregaFiltro("", actor, 0);

    mapper.setInputData(data);
    ren3d.addVolume(actor);

    if (theVolume)
        theVolume.setVisibility(false);

    theVolume = actor;

    addLinha(line1, ren3d);
    addLinha(line2, ren3d);
    addLinha(line3, ren3d);
    atualizaPosLinha(line1, 4);
    atualizaPosLinha(line2, 5);
    atualizaPosLinha(line3, 6);
    //addLine(10, 10, 10, 20, 20, 20, "hi", ren3d);
}

function atualizaPosLinha(line1, lado) {
    var dimensao = theNavegacao.widgetState.getImage().getBounds();
    var med = dimensao[1] / 2;
    var medH = dimensao[5] / 2;
    var nm = widgetState.getPlanes()[lado].normal;//  theNavegacao.widget[lado].getNormal();
    var ct = widgetState.getCenter();
    line1.setPoint1(ct[0] - med * nm[0], ct[1] - med * nm[1], ct[2] - med * nm[2]);
    line1.setPoint2(ct[0] + med * nm[0], ct[1] + med * nm[1], ct[2] + med * nm[2]);
}

function addLinha(line1, ren3d) {
    line1.setPoint1(0, 0, 0);
    line1.setPoint2(0, 0, 0);
    const actor = vtkActor.newInstance();
    const mapper = vtkMapper.newInstance();
    actor.setMapper(mapper);
    mapper.setInputConnection(line1.getOutputPort());
    ren3d.addActor(actor);

}

export function selecionarPosicao(idx) {
    var fase = theNavegacao.jviewer.fases[idx];

    if (fase.tipoElemento == 1)//teAvaliacao
        selecionaAvaliacao(fase.elemento);
    else {
        ativaPosicao(fase.posicao);
        //TODO: Corrigir - precisava clicar duas vezes para atualizar
        ativaPosicao(fase.posicao);
    }
}

function selecionaAvaliacao(elemento) {
}

export function ativaPosicao(posi) {
    if (!posi) {
        return;
    }
    if (posi.filtro3d != "") {
        if (posi.filtro3d.startsWith("fltPac")) {
            CarregaFiltroUsado(posi.filtro3d);
        }
        else {
            CarregaFiltroUsado(posi.filtro3d);
        }
    }

    var ren = view3D.renderer;
    var rw = view3D.renderWindow;

    widget.setCenter([posi.corteSagital, posi.corteCoronal, posi.corteAxial]);

    aplicarFiltro2d(posi.mpr2d, false);

    //setRegiaoClip(posi.Cropx1, posi.Cropx2, posi.Cropy1, posi.Cropy2, posi.Cropz1, posi.Cropz2);
    //rw.render();


    if (posi.modo == 0 || posi.modo == 2) {
        if (ren) {
            var cam3d = ren.getActiveCamera();
            if (posi.Ver1 == "2")
                cam3d.setFocalPoint(posi.fp3d.x, posi.fp3d.y, posi.fp3d.z);
            cam3d.setPosition(posi.pos3d.x, posi.pos3d.y, posi.pos3d.z);
            cam3d.setViewUp(posi.vu3d.x, posi.vu3d.y, posi.vu3d.z);
            cam3d.setParallelScale(posi.zoom3d * 1.8);
        }
    }

    if (posi.modo == 0) {
        //todo: corrigir
        //angular = false;
        //tipoAngular = 0;

        widgetState.getPlanes()[4].normal = [-1, 0, 0];
        widgetState.getPlanes()[5].normal = [0, 1, 0];
        widgetState.getPlanes()[6].normal = [0, 0, 1];

        var camSagi = theNavegacao.rends[0].getActiveCamera();
        if (posi.Ver1 == "2")
            camSagi.setFocalPoint(posi.fpSagi.x, posi.fpSagi.y, posi.fpSagi.z)
        camSagi.setPosition(posi.posSagi.x, posi.posSagi.y, posi.posSagi.z)
        camSagi.setParallelScale(posi.zoomSagi);

        var camCoro = theNavegacao.rends[1].getActiveCamera();
        if (posi.Ver1 == "2")
            camCoro.setFocalPoint(posi.fpCoro.x, posi.fpCoro.y, posi.fpCoro.z)
        camCoro.setPosition(posi.posCoro.x, posi.posCoro.y, posi.posCoro.z)
        camCoro.setParallelScale(posi.zoomCoro);

        var camAxi = theNavegacao.rends[2].getActiveCamera();
        if (posi.Ver1 == "2")
            camAxi.setFocalPoint(posi.fpAxi.x, posi.fpAxi.y, posi.fpAxi.z)
        camAxi.setPosition(posi.posAxi.x, posi.posAxi.y, posi.posAxi.z)
        camAxi.setParallelScale(posi.zoomAxi);
    }
    else if (posi.modo == 2) //rastreamento
    {
        var axiPt1 = { x: posi.planoAxi.x1, y: posi.planoAxi.y1, z: posi.planoAxi.z1 };
        var axiPt2 = { x: posi.planoAxi.x2, y: posi.planoAxi.y2, z: posi.planoAxi.z2 };
        var axiPt3 = { x: posi.planoAxi.x3, y: posi.planoAxi.y3, z: posi.planoAxi.z3 };

        axiPt3 = { x: posi.planoSagi.x3, y: posi.planoSagi.y3, z: posi.planoSagi.z3 };
        calcPlanoVert(axiPt1, axiPt2, axiPt3);

        var sagPt1 = { x: posi.planoSagi.x1, y: posi.planoSagi.y1, z: posi.planoSagi.z1 };
        var sagPt2 = { x: posi.planoSagi.x2, y: posi.planoSagi.y2, z: posi.planoSagi.z2 };
        var sagPt3 = { x: posi.planoCoro.x1, y: posi.planoCoro.y1, z: posi.planoCoro.z1 };
        calcPlanoVert(sagPt1, sagPt2, sagPt3);

        var coroPt1 = { x: posi.planoCoro.x1, y: posi.planoCoro.y1, z: posi.planoCoro.z1 };
        var coroPt2 = { x: posi.planoCoro.x2, y: posi.planoCoro.y2, z: posi.planoCoro.z2 };
        var coroPt3 = { x: posi.planoSagi.x1, y: posi.planoSagi.y1, z: posi.planoSagi.z1 };
        calcPlanoVert(coroPt1, coroPt2, coroPt3);

        aplicaPlano(coroPt1, sagPt2, sagPt1, 6, posi.planoAxi.espelhado);
        aplicaPlano(sagPt3, sagPt2, axiPt1, 5, posi.planoCoro.espelhado);
        aplicaPlano(axiPt3, coroPt2, coroPt3, 4, posi.planoSagi.espelhado);//sag

        var camSagi = theNavegacao.rends[0].getActiveCamera();
        if (posi.Ver1 == "2")
            camSagi.setFocalPoint(posi.fpSagi.x, posi.fpSagi.y, posi.fpSagi.z)
        camSagi.setPosition(posi.posSagi.x, posi.posSagi.y, posi.posSagi.z)
        camSagi.setParallelScale(posi.zoomSagi);

        var camCoro = theNavegacao.rends[1].getActiveCamera();
        if (posi.Ver1 == "2")
            camCoro.setFocalPoint(posi.fpCoro.x, posi.fpCoro.y, posi.fpCoro.z)
        camCoro.setPosition(posi.posCoro.x, posi.posCoro.y, posi.posCoro.z)
        camCoro.setParallelScale(posi.zoomCoro);

        var camAxi = theNavegacao.rends[2].getActiveCamera();
        if (posi.Ver1 == "2")
            camAxi.setFocalPoint(posi.fpAxi.x, posi.fpAxi.y, posi.fpAxi.z)
        camAxi.setPosition(posi.posAxi.x, posi.posAxi.y, posi.posAxi.z)
        camAxi.setParallelScale(posi.zoomAxi);

        theNavegacao.rends[0].getActiveCamera().setParallelScale(posi.zoomSagi);
        theNavegacao.rends[1].getActiveCamera().setParallelScale(posi.zoomCoro);
        theNavegacao.rends[2].getActiveCamera().setParallelScale(posi.zoomAxi);

    }
    atualizaPosLinha(line1, 4);
    atualizaPosLinha(line2, 5);
    atualizaPosLinha(line3, 6);
    if (ren)
        ren.updateLightsGeometryToFollowCamera();
    if (rw)
        rw.render();


    //
    //viewAttributes.forEach((obj, i) => {
    //    updateReslice({
    //        viewType: xyzToViewType[i],
    //        reslice: obj.reslice,
    //        actor: obj.resliceActor,
    //        renderer: obj.renderer,
    //        resetFocalPoint: false,
    //        keepFocalPointPosition: false,
    //        computeFocalPointOffset: true,
    //        sphereSources: obj.sphereSources,
    //        resetViewUp: false,
    //    });
    //    obj.renderWindow.render();
    //});
    //

    renderAll();
    updateViews();
    //mostrarDesenhos();
}

function getPosicaoJS() {
    var posi = {};
    var janelas = ['Sagi', 'Coro', 'Axi', '3d'];

    posi.filtro3d = filtroAtual;

    posi.center = widgetState.getCenter();

    posi['plan4'] = widgetState.getPlanes()[4].normal;
    posi['plan5'] = widgetState.getPlanes()[5].normal;
    posi['plan6'] = widgetState.getPlanes()[6].normal;

    for (var i = 0; i < 4; i++) {
        var jan = janelas[i];
        var cam = theNavegacao.rends[i].getActiveCamera();

        posi['fp' + jan] = cam.getFocalPoint();
        posi['pos' + jan] = cam.getPosition();
        posi['vu' + jan] = cam.getViewUp();
        posi['zoom' + jan] = cam.getParallelScale();
    }

    return posi;
}

function setPosicaoJS(posi) {
    var janelas = ['Sagi', 'Coro', 'Axi', '3d'];

    if (filtroAtual != posi.filtro3d) {
        if (posi.filtro3d.startsWith("fltPac")) {
            CarregaFiltroUsado(posi.filtro3d);
        }
        else {
            CarregaFiltroUsado(posi.filtro3d);
        }
    }

    widgetState.getPlanes()[4].normal = posi['plan4'];
    widgetState.getPlanes()[5].normal = posi['plan5'];
    widgetState.getPlanes()[6].normal = posi['plan6'];

    widget.setCenter(posi.center);

    var ren = view3D.renderer;
    var rw = view3D.renderWindow;

    for (var i = 0; i < 4; i++) {
        var jan = janelas[i];
        var cam = theNavegacao.rends[i].getActiveCamera();

        var pt = posi['fp' + jan];
        cam.setFocalPoint(pt[0], pt[1], pt[2]);

        pt = posi['pos' + jan];
        cam.setPosition(pt[0], pt[1], pt[2]);

        pt = posi['vu' + jan];
        cam.setViewUp(pt[0], pt[1], pt[2]);

        cam.setParallelScale(posi['zoom' + jan]);
    }

    atualizaPosLinha(line1, 4);
    atualizaPosLinha(line2, 5);
    atualizaPosLinha(line3, 6);

    if (ren)
        ren.updateLightsGeometryToFollowCamera();
    if (rw)
        rw.render();

    renderAll();
    updateViews(false);
}

function calcPlanoVert(pt1, pt2, pt3) {
    var PQ = { x: 0, y: 0, z: 0 };//= new CPonto3D();
    PQ.x = pt1.x - pt2.x;
    PQ.y = pt1.y - pt2.y;
    PQ.z = pt1.z - pt2.z;

    var PR = { x: 0, y: 0, z: 0 };// = new CPonto3D();
    PR.x = pt3.x - pt2.x;
    PR.y = pt3.y - pt2.y;
    PR.z = pt3.z - pt2.z;

    var PF = { x: 0, y: 0, z: 0 };// = new CPonto3D();
    PF.x = (PQ.y * PR.z - PQ.z * PR.y);
    PF.y = -(PQ.x * PR.z - PQ.z * PR.x);
    PF.z = (PQ.x * PR.y - PQ.y * PR.x);

    pt3.x = pt2.x + (PF.x);
    pt3.y = pt2.y + (PF.y);
    pt3.z = pt2.z + (PF.z);
}

function aplicaPlano(pt1, pt2, pt3, lado, espelhado) {
    var pls = vtkPlaneSource.newInstance();
    pls.setOrigin(pt2.x, pt2.y, pt2.z);
    if (espelhado) {
        pls.setPoint1(pt3.x, pt3.y, pt3.z);
        pls.setPoint2(pt1.x, pt1.y, pt1.z);
    }
    else {
        pls.setPoint1(pt1.x, pt1.y, pt1.z);
        pls.setPoint2(pt3.x, pt3.y, pt3.z);
    }
    pls.update();

    widgetState.getPlanes()[lado].normal = pls.getNormal();

    //theRastreamento.resliceCursorRepresentations[0].getResliceCursor().getPlane(lado).setOrigin(pls.getOrigin());
    //theRastreamento.resliceCursorRepresentations[0].getResliceCursor().getPlane(lado).setNormal(pls.getNormal());
}

function CarregaFiltroUsado(arq) {
    var shift = 0;
    filtroAtual = arq;

    const ctfun = vtkColorTransferFunction.newInstance();
    const ofun = vtkPiecewiseFunction.newInstance();
    const gdfun = vtkPiecewiseFunction.newInstance();
    const aProp = vtkVolumeProperty.newInstance();

    var listaf = theNavegacao.jviewer.filtrosUsados;
    for (var i = 0; i < listaf.length; i++) {
        var ft = listaf[i];

        if (ft.nome.indexOf(arq) >= 0) {
            for (var k = 0; k < ft.color.length; k++) {
                var novo = ft.color[k];
                ctfun.addRGBPoint(shift + (novo.x), (novo.r), (novo.g), (novo.b), (novo.midpoint), (novo.sharpness));
            }
            for (var k = 0; k < ft.scalarOpacity.length; k++) {
                var novo = ft.scalarOpacity[k];
                ofun.addPoint(shift + (novo.x), (novo.y));
            }
            for (var k = 0; k < ft.gradientOpacity.length; k++) {
                var novo = ft.gradientOpacity[k];
                gdfun.addPoint(shift + (novo.x), (novo.y));
            }
            aProp.setInterpolationType((ft.interpolationType));
            aProp.setShade((ft.shade));
            aProp.setAmbient((ft.ambient));
            aProp.setDiffuse((ft.diffuse));
            aProp.setSpecular((ft.specular));
            aProp.setSpecularPower((ft.specularPower));
            aProp.setScalarOpacityUnitDistance(0, (ft.scalarOpacityUnitDistance));

            aProp.setRGBTransferFunction(0, ctfun);
            aProp.setScalarOpacity(0, ofun);

            if (theVolume)
                theVolume.setProperty(aProp);

            if (view3D.renderWindow)
                view3D.renderWindow.render();
            return;
        }
    }
}

function aplicarFiltro2d(prop, aplicarSharpen) {
    var prop2d = viewAttributes[0].resliceActor.getProperty();// theRastreamento.resliceCursorRepresentations[0].getImageActor().getProperty();

    prop2d.setColorWindow(prop.window);
    prop2d.setColorLevel(prop.level);
    prop2d.setAmbient(prop.ambiente);
    prop2d.setDiffuse(prop.difuso);
    prop2d.setOpacity(prop.opacidade);
}

function setRegiaoClip(x1, x2, y1, y2, z1, z2) {
    var p1 = vtkPlane.newInstance();
    p1.setOrigin(x1, y1, z1);
    p1.setNormal(0, 0, 1);

    var p2 = vtkPlane.newInstance();
    p2.setOrigin(x2, y2, z2);
    p2.setNormal(0, 0, -1);

    var p3 = vtkPlane.newInstance();
    p3.setOrigin(x1, y1, z1);
    p3.setNormal(0, 1, 0);

    var p4 = vtkPlane.newInstance();
    p4.setOrigin(x2, y2, z2);
    p4.setNormal(0, -1, 0);


    var p5 = vtkPlane.newInstance();
    p5.setOrigin(x1, y1, z1);
    p5.setNormal(1, 0, 0);

    var p6 = vtkPlane.newInstance();
    p6.setOrigin(x2, y2, z2);
    p6.setNormal(-1, 0, 0);

    theVolume.getMapper().removeAllClippingPlanes();
    theVolume.getMapper().addClippingPlane(p1);
    theVolume.getMapper().addClippingPlane(p2);
    theVolume.getMapper().addClippingPlane(p3);
    theVolume.getMapper().addClippingPlane(p4);
    theVolume.getMapper().addClippingPlane(p5);
    theVolume.getMapper().addClippingPlane(p6);
}


export function posicao(dir) {
    var widefov = 3000; // Tomei 640 como a maior resolucao dos volumes I-Cat

    var cam = view3D?.renderer?.getActiveCamera();
    var centro = theVolume?.getCenter();

    if(cam && centro) {
        cam.setFocalPoint(centro[0], centro[1], centro[2]);
        if (dir == 'R') {
            cam.setPosition(centro[0] - widefov, centro[1], centro[2]);
            cam.setViewUp(0, 0, -1);
        }
        else if (dir == 'L') {
            cam.setPosition(centro[0] + widefov, centro[1], centro[2]);
            cam.setViewUp(0, 0, -1);
        }
        else if (dir == 'S') {
            cam.setPosition(centro[0], centro[1], centro[2] - widefov);
            cam.setViewUp(0, -1, 0);
        }
        else if (dir == 'I') {
            cam.setPosition(centro[0], centro[1], centro[2] + widefov);
            cam.setViewUp(0, -1, 0);
            cam.roll(180);
        }
        else if (dir == 'A') {
            cam.setPosition(centro[0], centro[1] + widefov, centro[2]);
            cam.setViewUp(0, 0, -1);
        }
        else if (dir == 'P') {
            cam.setPosition(centro[0], centro[1] - widefov, centro[2]);
            cam.setViewUp(0, 0, -1);
        }
    
        view3D.renderer.updateLightsGeometryToFollowCamera();
        view3D.renderer.resetCamera();
        view3D.renderWindow.render()
        
        return false;
    } else {
        console.log(`erro ao tentar mudar a posicao: ${dir}`)
    }
}

function resetZoom() {
    theNavegacao.rends[0].getActiveCamera().zoom(1.8);
    theNavegacao.rends[1].getActiveCamera().zoom(1.8);
    theNavegacao.rends[2].getActiveCamera().zoom(1.8);
    theNavegacao.rends[3].getActiveCamera().zoom(1.8);
}

function renderAll() {
    if (!theNavegacao.rends[0])
        return;
    theNavegacao.rends[0].getRenderWindow().render();
    theNavegacao.rends[1].getRenderWindow().render();
    theNavegacao.rends[2].getRenderWindow().render();
    theNavegacao.rends[3].getRenderWindow().render();
}

function flip_camera(cam) {
    var pos = cam.getPosition();
    var vup = cam.getViewUp();
    for (var i = 0; i < 3; ++i) {
        pos[i] = -pos[i];
        vup[i] = -vup[i];
    }
    cam.setPosition(pos);
    cam.setViewUp(vup);
}

function addLine(x, y, z, x2, y2, z2, texto, ren) {
    var ln = vtkLineWidget.newInstance();
    var widgetManager = vtkWidgetManager.newInstance()
    widgetManager.setRenderer(ren);
    var currentHandle = widgetManager.addWidget(ln);
    var lineWidget = currentHandle;

    lineWidget.setText("hi");
    lineWidget.getHandle(0).setOrigin([0, 0, 0]);
    lineWidget.getHandle(1).setOrigin([10, 10, 10]);
    lineWidget.getHandle(0).setShape("cone");
    lineWidget.updateHandleVisibility(0)

    theNavegacao.ln = lineWidget;

}

//final rastreamento

//inicio atores3d

function getAtor(url) {
    for (var i = 0; i < atores3d.length; i++) {
        if (atores3d[i].nome == url)
            return atores3d[i];
    }

    return null;
}

function setOpacidade1(e) {
    setOpacidade(e.id, e.value / 100.0);
}

function setOpacidade(i, valor) {
    //todo:corrigir
    // getAtor(lista[i].Link).obj.getProperty().setOpacity(valor);
    view3D.renderWindow.render();
}


//final atores3d


function identity(out) {
    out[0] = 1;
    out[1] = 0;
    out[2] = 0;
    out[3] = 0;
    out[4] = 0;
    out[5] = 1;
    out[6] = 0;
    out[7] = 0;
    out[8] = 0;
    out[9] = 0;
    out[10] = 1;
    out[11] = 0;
    out[12] = 0;
    out[13] = 0;
    out[14] = 0;
    out[15] = 1;
    return out;
}

export function navegacaojs() { }