import Modal from "react-modal";
import { Formik, Form, FormikValues } from "formik";
import { XMarkIcon, CheckCircleIcon, } from "@heroicons/react/24/outline";
import styles from "./styles.module.css";

import Logoevol from '../../../../assets/logo-evol.jpeg';

import { Input } from "../../../../components/Form/Input";
import { useContext, useState } from "react";
import { ArrowRight } from "@phosphor-icons/react";
import Api from "../../../../helpers/Api";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { selectUser, setUser } from "../../../../store/slices/userSlice";
import { LoadingClinics } from "../../components/LoadingClinics";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "@cdt-ui/react";
import { setClinic } from "../../../../store/slices/clinicSlice";
import Cookies from "js-cookie";
import { PatientContext } from "../../../PatientList/PatientProvider";
import { setIsClinicConnectionsComplete } from "../../../../store/slices/isClinicConnectionsCompleteSlice";

interface ModalAddIndicatorsProps {
  isOpen: boolean;
  isModalEvol?: boolean;
  setUpdateLinkedClinics?: any;
  onRequestClose: () => void;
  setUpdateClinicsData: (
    value: ((prevState: number) => number) | number
  ) => void;
}

export function ModalAddClinics({
  isOpen,
  isModalEvol,
  onRequestClose,
  setUpdateClinicsData,
  setUpdateLinkedClinics,
}: ModalAddIndicatorsProps) {
  const { user } = useSelector(selectUser);

  const { resetPatientLoading } = useContext(PatientContext);

  const [clicked, setClicked] = useState(false);
  const [clickedClinic, setClickedClinic] = useState(false);
  const [selected, setSelected] = useState(isModalEvol ? false : true);
  const [searchText, setSearchText] = useState("");
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [clinics, setClinics] = useState([]);
  const [login, setLogin] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingVinculated, setLoadingVinculated] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const selectedClinicDetails = clinics.find(
    (clinic) => clinic.id === selectedClinic
  );

  const nextPage = () => {
    setSelected(false);
  };

  const submitSeachClinic = async () => {
    setLoading(true);
    setClickedClinic(true);
    try {
      const results = await Api.searchClinics(searchText);
      setClinics(results);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (formikValues: FormikValues) => {
    setIsLoadingSubmit(true);

    try {
      const trimmedFormValues = {
        logon: formikValues.username,
        password: formikValues.confirmPassword,
        idClient: selectedClinicDetails !== undefined ? selectedClinicDetails.id.toString() : '',
      };

      const signInResult = await Api.SignIn(trimmedFormValues);
      const hashValue = signInResult.data[0].hash;
      const storedLogon = sessionStorage.getItem("logon");
      const storedPassword = sessionStorage.getItem("password");
      const userResponse = await Api.byemail(storedLogon);

      if (hashValue !== '') {

        let clinicData = {};

        if (isModalEvol) {
          clinicData = {
            id: 0,
            logon: formikValues.username,
            senha: formikValues.confirmPassword,
            idUser: user.length ? user[0].idUser : userResponse.data.id.toString(),
            idClient: '14466'
          };
        } else {
          clinicData = {
            id: 0,
            idClient: selectedClinicDetails !== undefined ? selectedClinicDetails.id.toString() : '14446',
            clinicalName: selectedClinicDetails.apelido,
            logon: formikValues.username,
            senha: formikValues.confirmPassword,
            address: selectedClinicDetails.endereco,
            city: selectedClinicDetails.cidade,
            neighborhood: selectedClinicDetails.bairro,
            roles: "AUTENTICADO",
            hash: hashValue,
            idUser: user.length ? user[0].idUser : userResponse.data.id.toString(),
          };
        }

        const postClinicResponse = await Api.postClinic(clinicData);

        if (postClinicResponse.IsSuccess === 'true' || postClinicResponse.IsSuccess === true) {
          toast("Clínica vinculada!", {
            position: "top-right",
            style: {
              background: "#007e69",
              color: "#fff",
            },
          });
        }
        const clinicsResponse = await Api.getClinics(user.length ? user[0].idUser : userResponse.data.id.toString());

        for (const item of clinicsResponse.data) {
          const clienteId = item.idClient;
          const getIP = await Api.GetIP(clienteId);
          item.ip = getIP;
        }

        dispatch(setUser(clinicsResponse.data));

        setUpdateClinicsData((prevValue: number) => prevValue + 1);

        if (!user.length) {
          const trimmedFormValues = {
            logon: storedLogon,
            password: storedPassword,
            idClient: selectedClinicDetails.id.toString(),
          };

          dispatch(setUser(false));

          const signInResult = await Api.SignIn(trimmedFormValues);

          if (signInResult.isSuccess === true) {
            for (const item of signInResult.data) {
              const clienteId = item.idClient;
              const getIP = await Api.GetIP(clienteId);
              item.ip = getIP;
            }

            dispatch(setUser(signInResult.data));
            localStorage.removeItem("logon");
            localStorage.removeItem("password");

            toast("Login realizado com sucesso!", {
              position: "top-right",
              style: {
                background: "#007e69",
                color: "#fff",
              },
            });

            onRequestClose();
            setIsClinicConnectionsComplete(false);
            navigate("/dashboard/lista-pacientes");
            return;
          }
        }
      }
      setLoadingVinculated(true);
      setUpdateLinkedClinics(true);

    } catch (error) {
      toast("Desculpe, não foi possível vincular a clínica. Verifique se o usuário ou a senha estão corretos.", {
        position: "top-right",
        style: {
          background: "#c80000",
          color: "#fff",
          textAlign: 'center'
        },
      });
      setIsLoadingSubmit(false)
    } finally {
      setIsLoadingSubmit(false)
    }
  };

  const handleLogout = () => {
    setLoadingVinculated(false)
    setUpdateLinkedClinics(false)
    localStorage.setItem("scrollValue", "0");
    localStorage.setItem("totalPages", "0");
    localStorage.setItem("page", "0");
    localStorage.setItem("atd", "null");
    localStorage.setItem("toConclude", "0");
    dispatch(setUser(false));
    dispatch(setClinic(false));

    Cookies.remove("userMain");
  };

  const handleStayConnected = () => {
    resetPatientLoading();
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleStayConnected}
      overlayClassName="react_modal_overlay"
      className={styles.react_modal_AddIndicator}
    >
      {!loadingVinculated ?
        <div>
          <XMarkIcon
            title="Fechar"
            className={styles.icon_wrapper}
            onClick={onRequestClose}
          />

          <header className={styles.header}>
            <div className={styles.container_content}>
              <h2>
                {isModalEvol ? "Vincular à clínica eVolCloud" : "Vincular a uma clínica"}
              </h2>

              {
                selected ?
                  <p>
                    No campo abaixo, digite o nome da clinica de interesse e clique no
                    botão buscar, após isso seleciona a clinica e digite suas
                    credenciais de acesso.
                  </p>
                  :
                  <p>
                    No campo abaixo, digite seu usuário e senha, e clique no botão "Vincular".
                  </p>
              }
            </div>
          </header>

          <Formik
            initialValues={{
              confirmPassword: "",
              username: "",
            }}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ errors, isSubmitting, values }: any) =>
              selected ? (
                <div>
                  <div className={styles.fields_wrapper}>
                    <div className={styles.input_wrapper}>
                      <Input
                        name="Buscar Clínica"
                        type="text"
                        title="Buscar Clínica"
                        className={styles.input_test}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder="Nome da clínica"
                        onButtonClick={() => {
                          if (searchText && searchText.length >= 3) {
                            submitSeachClinic();
                            setError("");
                          } else {
                            setError(
                              "Por favor, insira pelo menos 3 caracteres para buscar."
                            );
                          }
                        }}
                      />
                      {error && <p className={styles.error_message}>{error}</p>}
                    </div>
                  </div>
                  <div
                    className={
                      clickedClinic ? styles.selectClinic : styles.notSelectClinic
                    }
                  >
                    <p className={styles.selectClinicText}>
                      SELECIONE A CLÍNICA
                    </p>

                    <div className={styles.switchField}>
                      {loading ? (
                        <LoadingClinics />
                      ) : clinics.length === 0 ? (
                        <div className={styles.containerTextUserFalse}>
                          <p> Clínica não encontrada</p>
                        </div>
                      ) : (
                        clinics.map((clinic) => (
                          <div key={clinic.id}>
                            <input
                              type="radio"
                              id={`radio-${clinic.id}`}
                              name="clinicSelection"
                              value={clinic.id}
                              checked={selectedClinic === clinic.id}
                              onChange={() => {
                                setSelectedClinic(clinic.id);
                                setClicked(true);
                              }}
                            />
                            <label htmlFor={`radio-${clinic.id}`}>
                              {clinic?.apelido}
                            </label>
                          </div>
                        ))
                      )}
                    </div>
                  </div>

                  <div
                    className={
                      clicked
                        ? styles.submitSendMessages
                        : styles.notsubmitSendMessage
                    }
                  >
                    <button
                      /* variant="greenDark" */
                      disabled={!clicked}
                      onClick={nextPage}
                      className={`${styles.submitSendMessage} ${styles.button}`}
                      style={
                        !clicked
                          ? { background: "#afafaf" }
                          : { background: "#007E69" }
                      }
                    >
                      Continuar
                      <ArrowRight />
                    </button>
                  </div>
                </div>
              ) : (
                <Form>
                  <div className={styles.selectUser}>
                    <p className={styles.selectClinicText}>
                      INSERIR DADOS PARA VINCULAR CLÍNICA
                    </p>
                    {
                      isModalEvol ?
                        <img src={Logoevol} alt="" />
                        :
                        <div className={styles.cardselected}>
                          <p className={styles.selected}>
                            {

                              selectedClinicDetails?.apelido
                            }
                          </p>
                        </div>
                    }
                  </div>
                  <div className={styles.inputForm}>
                    <Input
                      title="Usuário"
                      name="username"
                      error={errors.username}
                      type="text"
                      className={styles.input_test}
                      placeholder="Digite o nome de usuário cadastrado na clínica"
                    />

                    <Input
                      className={styles.input_test}
                      name="confirmPassword"
                      type="password"
                      title="Senha"
                      placeholder="Digite sue senha"
                      error={errors.confirmPassword}
                    // error={errors.confirmPassword}
                    />
                  </div>

                  <div className={styles.submitSendMessageNext}>
                    <Button
                      type="submit"
                      className={`${!isModalEvol && styles.submitSendMessage2} ${styles.button}`}
                      disabled={isLoadingSubmit}
                    >
                      {
                        !isLoadingSubmit &&
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M13.125 7.5V4.375C13.125 3.33947 12.2855 2.5 11.25 2.5L6.25 2.5C5.21447 2.5 4.375 3.33947 4.375 4.375L4.375 15.625C4.375 16.6605 5.21447 17.5 6.25 17.5H11.25C12.2855 17.5 13.125 16.6605 13.125 15.625V12.5M10 7.5L7.5 10M7.5 10L10 12.5M7.5 10L18.125 10"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      }
                      {
                        isLoadingSubmit ?
                          <div className={styles.container_buttons_loading}>
                            <div className={styles.loading_spinne}></div>
                          </div>
                          :
                          isModalEvol ? "Vincular eVolCloud" : "Vincular clínica"
                      }
                    </Button>
                  </div>
                </Form>
              )
            }
          </Formik>
        </div >
        :
        <>
          <div className={styles.icon_wrapper}>
            <XMarkIcon title="Fechar" onClick={onRequestClose} />
          </div>

          <header className={styles.headerModalSucesss}>
            <div className={styles.success}>
              <CheckCircleIcon width={22}/>
            </div>
            <div className={styles.container_contentSucesss}>
              <h2> Clínica vinculada com sucesso! </h2>

              <p>
                Os pacientes da <b>clínica</b> recentemente adicionada à sua conta já estão disponíveis.
              </p>

              <div className={styles.containerButtons}>
                <button
                  type="button"
                  onClick={handleStayConnected}
                >
                  OK, entendi
                </button>
              </div>
            </div>
          </header>
        </>
      }

    </Modal >
  );
}
