import { Doughnut } from "react-chartjs-2";

import { Circle } from "../Circle";

import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
ChartJS.register(ArcElement, Tooltip);

export function Chart({ styles }) {
  const data = {
    labels: ["Whatsapp", "E-mail", "CRM"],
    datasets: [
      {
        data: [224, 107, 116],
        borderWidth: 1,
        backgroundColor: ["#00523D", "#009C86", "#ADE1DA"],
        hoverOffset: 4,
      },
    ],
  };
  return (
    <div className={styles.graphContainer}>
      <div className={styles.graphWrapper}>
        <Doughnut data={data} options={{ responsive: true }} />
      </div>
      <div className={styles.meioDeCaptacaoContainer}>
        <h4 className={styles.meioDeCaptacao}>Meio de captação</h4>
        <p className={styles.chartLabel}>
          <Circle containerClassName={styles.whatsappCircle} color="#00523D" />
          <span className={styles.chartType}>Whatsapp</span>
          <span className={styles.chartAmount}>224</span>
          <span className={styles.chartLabelPercentage}>(50%)</span>
        </p>
        <p className={styles.chartLabel}>
          <Circle containerClassName={styles.emailCircle} color="#009C86" />
          <span className={styles.chartType}>E-mail</span>
          <span className={styles.chartAmount}>107 </span>
          <span className={styles.chartLabelPercentage}>(24%)</span>
        </p>
        <p className={styles.chartLabel}>
          <Circle containerClassName={styles.CRMCircle} color="#ADE1DA" />
          <span className={styles.chartType}>CRM</span>
          <span className={styles.chartAmount}>116</span>
          <span className={styles.chartLabelPercentage}>(26%)</span>
        </p>
      </div>
    </div>
  );
}
