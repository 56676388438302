import ImagemRelatórios from "../Images/Relatorios.png";
import { Paywall } from "../Components";

export function Reports() {
  const paywall = [
    {
      id: 1,
      videourl: "https://player-vz-fb154304-6eb.tv.pandavideo.com.br/embed/?v=9e3c36ab-8ae4-4ac8-9970-42adaede1d7e",
      title:
        "Módulo de relatórios Serodonto: a chave para decisões estratégicas na sua clínica odontológica.",
      link: "https://api.whatsapp.com/send?phone=5512983206773&text=Estou%20interessado%20nas%20ferramentas%20de%20relatório%20do%20Serodonto!",
      subtitles: [
        {
          subtitle: "Tenha uma clínica rentável e com boa reputação.",
        },
        {
          subtitle:
            "Enfrente com tranquilidade os desafios relacionados à qualidade, pressão financeira e riscos administrativos.",
        },
        {
          subtitle: "Tenha clareza sobre o desempenho da sua clínica.",
        },
        {
          subtitle:
            "Acompanhe em tempo real: volume médio de consultas, faturamento e despesas.",
        },
        {
          subtitle:
            "Estabeleça metas e configure alertas para indicadores relevantes.",
        },
        {
          subtitle:
            "Proteção de dados e assistência especializada garantidos pela Serodonto.",
        },
      ],
    },
  ];

  return (
    <>
      {paywall.map((item, index) => {
        return (
          <Paywall
            key={index}
            title={item.title}
            subtitles={item.subtitles}
            videourl={item.videourl}
            link={item.link}
            backgroundImage={ImagemRelatórios}
          />
        );
      })}
      <></>
    </>
  );
}
