import { Text } from "@cdt-ui/react";
import styles from "./styles.module.css";

export function Video({ teacher }) {
  return teacher.presentationVideo ? (
    <div className={styles.vidContainer}>
      <iframe
        id="panda-5e2e4932-33fa-4d06-8ce1-4b906c35b5db"
        src={teacher.presentationVideo}
        style={{ border: "none" }}
        allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
        allowFullScreen={true}
        /* width="720"
        height="360" */
        className={styles.vid}
      />

      <Text className={styles.title}>
        Aprenda comigo como alavancar sua carreira como dentista
      </Text>
      <Text className={styles.subtitle}>{teacher.catchPhrase} </Text>
    </div>
  ) : (
    <></>
  );
}
