import { useEffect, useState } from 'react';

import { Cog6ToothIcon } from '@heroicons/react/24/outline'

import { Pontos } from '../Pages/Pontos';
import { Estruturas } from '../Pages/Estruturas';
import { Imagens } from '../Pages/Imagens';
import { Analise } from '../Pages/Analise';
import { Opcoes } from '../Pages/Opcoes';
import { Calibrar } from '../Pages/Calibrar';

import styles from './styles.module.css'
import { Headset } from '@phosphor-icons/react';
import { useOpcoes } from '../OpcoesContext';

interface NavigationSidebarCefxProps {
    textPont: string;
    ocefx: any;
    handleGetNameEndCodAnalyze: any;
    handlePontName: (ponto: any) => void;
}

export function NavigationSidebarCefx({
    textPont,
    ocefx,
    handleGetNameEndCodAnalyze,
    handlePontName
}: NavigationSidebarCefxProps) {
    const [activeTab, setActiveTab] = useState('analise');

    const { loadingPontIa, setLoadingPontIa } = useOpcoes();

    const handleTabClick = (tabName: string) => {
        setActiveTab(tabName);
    };

    useEffect(() => {
        if (textPont.includes('desenho')) {
            setActiveTab('estruturas');
        } else if (textPont !== '') {
            setActiveTab('pontos');
        }
    }, [textPont]);

    useEffect(() => {
        const htmlBody = document.querySelector('html, body') as HTMLElement | null;

        if (htmlBody) {
            if (activeTab) {
                htmlBody.style.overflow = 'hidden';
            } else {
                htmlBody.style.overflow = 'visible';
            }
        }

        return () => {
            if (htmlBody) {
                htmlBody.style.overflow = 'visible';
            }
        };
    }, [activeTab]);

    useEffect(() => {
        if (loadingPontIa) {
            setActiveTab('analise');
        }
    }, [loadingPontIa]);

    const renderContent = () => {
        switch (activeTab) {
            case "analise":
                return (
                    <Analise
                        ocefx={ocefx}
                        handleGetNameEndCodAnalyze={handleGetNameEndCodAnalyze}
                    />
                );
            case "pontos":
                return (
                    <Pontos
                        textPont={textPont}
                        ocefx={ocefx}
                        handlePontName={handlePontName}
                    />
                );
            case "estruturas":
                return (
                    <Estruturas
                        ocefx={ocefx}
                    />
                );
            case "imagens":
                return (
                    <Imagens
                        ocefx={ocefx}
                    />
                );
            case "opcoes":
                return (
                    <Opcoes
                        ocefx={ocefx}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className={styles.wrapper}>
            <nav>
                <ul>
                    <li
                        title='Análise'
                        className={activeTab === "analise" ? `${styles.linkActive}` : ""}
                        onClick={() => handleTabClick("analise")}
                    >
                        <img src="/iconCefx/analiseIcon.svg" alt="Ícone de Análise" />
                        {
                            activeTab === "analise" &&
                            <span>Análises</span>
                        }
                    </li>
                    <li
                        title='Pontos'
                        className={activeTab === "pontos" ? `${styles.linkActive}` : ""}
                        onClick={() => handleTabClick("pontos")}
                    >
                        <img src="/iconCefx/pontosIcon.svg" alt="Ícone de pontos" />
                        {
                            activeTab === "pontos" &&
                            <span>Pontos</span>
                        }
                    </li>
                    <li
                        title='Estruturas'
                        className={activeTab === "estruturas" ? `${styles.linkActive}` : ""}
                        onClick={() => handleTabClick("estruturas")}
                    >
                        <img src="/iconCefx/estruturaIcon.svg" alt="Ícone de estruturas" />
                        {
                            activeTab === "estruturas" &&
                            <span>Estruturas</span>
                        }
                    </li>
                    <li
                        title='Imagens'
                        className={activeTab === "imagens" ? `${styles.linkActive}` : ""}
                        onClick={() => handleTabClick("imagens")}
                    >
                        <img src="/iconCefx/imagensIcon.svg" alt="Ícone de imagens" />
                        {
                            activeTab === "imagens" &&
                            <span>Imagens</span>
                        }
                    </li>
                    {/* <li
                        title='Opções'
                        className={activeTab === "opcoes" ? `${styles.linkActive}` : ""}
                        onClick={() => handleTabClick("opcoes")}
                    >
                        <Cog6ToothIcon width={14} />
                        {
                            activeTab === "opcoes" &&
                            <span>Opções</span>
                        }
                    </li> */}
                    {/* <li
                        title='Calibrar'
                        className={activeTab === "calibrar" ? `${styles.linkActive}` : ""}
                        onClick={() => handleTabClick("calibrar")}>
                        <MagnifyingGlassCircleIcon />
                    </li> */}
                </ul>
            </nav>

            <div
                className={styles.wrapper_renderContent}
                style={{
                    borderTopLeftRadius: activeTab === "analise" && '0',
                    borderTopRightRadius: activeTab === "imagens" && '0',
                }}
            >
                <div
                    className={styles.renderContent}
                    style={{
                        borderTopLeftRadius: activeTab === "analise" && '0',
                        borderTopRightRadius: activeTab === "imagens" && '0',
                    }}
                >
                    {renderContent()}
                </div>

                <footer className={styles.footerSidebar}>
                    <p>
                        <Headset size={22} />
                        Precisa de ajuda? Fale conosco
                    </p>

                    <div>
                        <img src="/logo_cdt.svg" alt="logo" />

                        <div className={styles.border} />

                        <img src="/logo_serodonto.svg" />
                    </div>
                </footer>
            </div>
        </div>
    )
}