import { Button, Text } from "@cdt-ui/react";

import styles from "./styles.module.css";
import {
  Checks
} from "@phosphor-icons/react";

import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import useCollapseState from "../../../components/Sidebar/collapse";

export function Paywall({ videourl, title, subtitles, link, backgroundImage }: any) {
  const handleButtonClick = () => {
    window.open(link, '_blank');
  };
  const { collapse, setCollapse } = useCollapseState();

  return (
    <div className={styles.parentContainer}
      style={{ background: `url(${backgroundImage}) no-repeat`, backgroundSize: "cover" }}
    >
      <section
        className={collapse ? `${styles.wrapperClose}` : `${styles.wrapper}`}
      >
        <div className={styles.containerVideo}>
          <iframe
            className={styles.video}
            src={videourl}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
        <div className={styles.container}>
          <Text className={styles.title}>{title}</Text>
          <div className={styles.subTitle}>
            <div className={styles.subTitle}>
              {subtitles.map((item, index) => (
                <Text className={styles.subTitles} key={index}>
                  <Checks className={styles.subTitlesCheck} color="#2EBBAA" />
                  {item.subtitle}
                </Text>
              ))}
            </div>
          </div>
          <div className={styles.container_buttons}>
            <Button
              variant="greenDark"
              className={styles.button}
              onClick={handleButtonClick}
            >
              Saiba mais{" "}
              <ArrowTopRightOnSquareIcon
                className="hero-icons-heroicons"
                color="white"
              />
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
}
