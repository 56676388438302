import { ReactNode } from 'react'

import styles from './styles.module.css'

interface LayoutProps {
    children: ReactNode
}

export function Layout(props: LayoutProps) {
    return (
        <div className={styles.container}>
            {props.children}
        </div>
    );
}
