import styles from "./styles.module.css";
import { ErrorInfo, useEffect, useRef, useState, useCallback } from "react";
import { Button, Text } from "@cdt-ui/react";

import { Plus } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { ModalAddClinic } from "../components/Modals/ModalAddClinic";
import { ModalAddClinics } from "./Modals/ModalAddClinic";

import { setUser } from "../../../store/slices/userSlice";

export function NewClinic() {
  const navigate = useNavigate();
  const navigateToCadastro = () => {
    setModalAddIndicatorsOpen(true);
  };
  const [updateClinicsData, setUpdateClinicsData] = useState<number>(0);
  const [isModalAddIndicatorsOpen, setModalAddIndicatorsOpen] = useState(false);

  const handleCloseModalAddIndicators = () => {
    setModalAddIndicatorsOpen(false);
  };
  
  return (
    <section className={styles.wrapper}>
      <div className={styles.containerImage}>
        <img src="/logo_serodonto_verde.png" width={"200px"} />
      </div>
      <div className="dashboard-layout">
        <main className="dashboard-content">
          <div className={styles.containerContent}>
            <div>
              <Text className={styles.containerText}>
                Clínica não cadastrada
              </Text>
              <Text className={styles.containerSubText}>
                Clique em <b> Criar Clínica </b> para cadastrar uma nova clínica.
              </Text>
            </div>
            <div className={styles.containerImg}>
              <img
                src="/emptystate.png"
                loading="lazy"
                onError={(e: any) => { e.target.src = "/placeholder.png"; }}
              />
            </div>
            <div className={styles.containerButton}>
              <Button
                variant="tertiary"
                className={styles.buttonMore}
                onClick={navigateToCadastro}
              >
                <Plus />
                Criar clínica
              </Button>
            </div>
          </div>
        </main>
      </div>
      {isModalAddIndicatorsOpen && (
        <ModalAddClinics
          isOpen={isModalAddIndicatorsOpen}
          onRequestClose={handleCloseModalAddIndicators}
          setUpdateClinicsData={setUpdateClinicsData}
        />
      )}
    </section>
  );
}
