import React, { useEffect, useState, useMemo } from 'react';

import { is3dTypeUtils } from '../../../../../utils/fileTypes/is3dTypeUtils';
import icone3D from '../../../../../assets/icone3d.png';
import { isJpnAndPngUtils } from '../../../../../utils/fileTypes/isJpgAndPngUtils';

import styles from './styles.module.css';

export const ImageComponent = React.memo(function ImageComponent({
    theSelectImage,
    imageParams,
    oViewer,
    handleImageSelect,
    image,
    handleChanceTheSelectedImage,
    checkedSwitchDesenho,
    addedMultipleImages,
}: any) {
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const imgElement = new Image();
        imgElement.src = image.link;
        imgElement.onload = () => {
            const width = imgElement.width;
            const height = imgElement.height;
            setImageDimensions({ width, height });
            setIsLoading(false);
        };
    }, [image.link]);

    const handleOnClickImage = (imageLink) => {
        if (checkedSwitchDesenho) {
            handleImageSelect(imageLink, checkedSwitchDesenho);
        } else {
            handleChanceTheSelectedImage(imageLink);
            oViewer.abrir(imageLink);
        }
    };

    const is3DFile = (complemento) => is3dTypeUtils(complemento);

    const imgSrc = is3DFile(image.complemento)
        ? icone3D
        : isJpnAndPngUtils(image.complemento)
            ? `${image.link}?tw=259&th=229`
            : image.icone;

    // const imgStyles = {
    //     width: imageDimensions.width > imageDimensions.height ? '288px' : '138px',
    //     height: '146px',
    //     border: '1px solid #353535',
    // };

    const imgStyles = useMemo(() => {
        return {
            width: imageDimensions.width > imageDimensions.height ? '288px' : '138px',
            height: '146px',
            border: '1px solid #353535',
        };
    }, [imageDimensions]);

    return (
        <div
            className={`${styles.containerImage} ${(checkedSwitchDesenho && addedMultipleImages.includes(image.link)) ||
                (!checkedSwitchDesenho && (theSelectImage === image.link || (theSelectImage === '' && imageParams === image.link)))
                ? styles.containerImage_active
                : ''
                }`}
            title={image.nome}
            onClick={() => handleOnClickImage(image.link)}
        >
            {isLoading && (
                <div className={styles.loader}>
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            )}
            <img
                src={imgSrc}
                onLoad={() => setIsLoading(false)}
                style={imgStyles}
                className={styles.img}
            />
            <div className={styles.linearGradient}>
                <span>{image.nome}</span>
            </div>
        </div>
    );
});
