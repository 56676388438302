import { Text } from "@cdt-ui/react";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
import styles from "../../../styles.module.css";
import { Link } from "react-router-dom";
import { ClockIcon } from "../../../../../IndicadoresOportunidade/components/ClockIcon";
import { Suspense, useEffect, useState } from "react";
import Api from "../../../../../../helpers/Api";
import { Avatar } from "@cdt-ui/react";

import { Loading } from "../../Filter/components/LoadingModal";
interface TeacherShape {
  name: string;
  photo?: string;
}
export function Container({
  title,
  subTitle,
  img,
  id,
  idCourseCategory,
  idTeacher,
  shortly,
}: any) {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<any>({});
  const [teacher, setTeacher] = useState<TeacherShape>({ name: "" });
  const [isSeeMoreActive, setSeeMoreActive] = useState<Boolean>(false);

  const renderFirst70Characters = (text) => {
    if (String(text).length <= 65) return text;

    return String(text).slice(0, 65);
  };

  const toggleSeeMore = () => {
    setSeeMoreActive((current) => !current);
  };

  const Getcategory = async () => {
    setLoading(true);

    try {
      const results = await Api.getCategoryId(idCourseCategory);

      setCategory(results.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const t = await Api.getTeacherById(idTeacher);
        if (t.isSuccess) {
          setTeacher(t.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    Getcategory();
  }, []);

  return (
    <Link
      to={shortly ? null : `/dashboard/academy/area-estudo/${id}`}
      className={styles.containerAula}
    >
      <div className={styles.containercontentImg}>
        <span className={styles.timeBadge}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <path
              d="M12.0625 6.5625L5.1875 13.4375L1.75 10"
              stroke="#008A00"
              stroke-width="1.8"
              strokeLinecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.25 6.5625L12.375 13.4375L10.5469 11.6094"
              stroke="#008A00"
              stroke-width="1.8"
              strokeLinecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {category.name}
        </span>
        <img src={img} alt="" className={styles.containerImg} />
      </div>
      <div className={styles.containerContentInfo}>
        <div>
          <div className={styles.containerTitles}>
            <Text className={styles.containerTitle}>{title}</Text>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10 3C10.8284 3 11.5 3.67157 11.5 4.5C11.5 5.32843 10.8284 6 10 6C9.17157 6 8.5 5.32843 8.5 4.5C8.5 3.67157 9.17157 3 10 3Z"
                fill="#666666"
              />
              <path
                d="M10 8.5C10.8284 8.5 11.5 9.17157 11.5 10C11.5 10.8284 10.8284 11.5 10 11.5C9.17157 11.5 8.5 10.8284 8.5 10C8.5 9.17157 9.17157 8.5 10 8.5Z"
                fill="#666666"
              />
              <path
                d="M11.5 15.5C11.5 14.6716 10.8284 14 10 14C9.17157 14 8.5 14.6716 8.5 15.5C8.5 16.3284 9.17157 17 10 17C10.8284 17 11.5 16.3284 11.5 15.5Z"
                fill="#666666"
              />
            </svg>
          </div>
          <Text
            className={styles.containerSubTitle}
            onClick={(e) => e.preventDefault()}
          >
            {!isSeeMoreActive && renderFirst70Characters(subTitle)}
            {String(subTitle).length >= 65 && !isSeeMoreActive ? (
              <span
                onClick={toggleSeeMore}
                style={{
                  color: "#007E69",
                  fontWeight: 700,
                  fontSize: 16,
                  cursor: "pointer",
                }}
              >
                {" "}
                ... ver mais
              </span>
            ) : (
              ""
            )}
            {isSeeMoreActive && subTitle}
            {String(subTitle).length >= 65 && isSeeMoreActive && (
              <span
                onClick={toggleSeeMore}
                style={{
                  color: "#007E69",
                  fontWeight: 700,
                  fontSize: 16,
                  cursor: "pointer",
                }}
              >
                {" "}
                ... ver menos
              </span>
            )}
          </Text>
        </div>
        <div className={styles.containerAuther}>
          <Suspense fallback={<Loading />}>
            <Link to={`/dashboard/academy/especialista/${idTeacher}`}>
              <div className={styles.profileContainer}>
                <Avatar className={styles.profilePic} src={teacher.photo} />

                <Text className={styles.containerSubTitleTeacher}>
                  {teacher.name}
                </Text>
              </div>
            </Link>
          </Suspense>
        </div>
        {/* <div>
          <Text className={styles.TitleProgress}>Progresso do curso </Text>
          <div className={styles.ContentProgress}>
            <Text className={styles.TextGreen}> 0% </Text>
          </div>
        </div> */}
      </div>
    </Link>
  );
}
