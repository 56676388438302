import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    paginationAConcluir: {},
};

export const ToConcludePaginationAConcluirSlice = createSlice({
    name: "paginationAConcluir",
    initialState,
    reducers: {
        setToConcludePaginationAConcluir: (state, action: PayloadAction<{ idClient: any, currentPage: number, totalPages: number, toConcludeCefx: any }>) => {
            const { idClient, currentPage, totalPages, toConcludeCefx } = action.payload;
            state.paginationAConcluir[idClient] = { currentPage, totalPages, toConcludeCefx };
        },
        clearPaginationAConcluir: (state) => {
            state.paginationAConcluir = {};
        },
    }
})

export const { setToConcludePaginationAConcluir, clearPaginationAConcluir } = ToConcludePaginationAConcluirSlice.actions;

export const selectPaginationCefxAConcluir = (state: any) => state.paginationAConcluir || {};

export default ToConcludePaginationAConcluirSlice.reducer;
