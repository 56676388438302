import { useState } from "react";

import {
  ChevronUpDownIcon,
  StarIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import { NoSymbolIcon } from "@heroicons/react/24/solid";
import { useMediaQuery } from "react-responsive";
import DentistIcon from "./icons/dentistIcon.svg";
import { ModalSheetDentistDetails } from "./ModalSheet/ModalSheetDentistDetails";

import styles from "./styles.module.css";
import { table } from "console";
import Api from "../../../../helpers/Api";
import toast from "react-hot-toast";
import { Button } from "@cdt-ui/react";

import {
  PlusIcon,
  ShareIcon,
  ArrowDownTrayIcon,
  TrashIcon,
  PrinterIcon,
} from "@heroicons/react/24/solid";
import { Document, Page, pdfjs } from "react-pdf";
import { ModalShareProfile } from "../../../../components/ModalShareProfile";
import { ModalShareProfileMobile } from "../../../../components/ModalShareProfileMobile";

export function Share({ newObjectDataShare, infoPacient, getProfileSharedSuccessfully }: any) {
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const [isModalSheetShareOpen, setModalSheetShareOpen] = useState(false);
  const [isModalSheetDentistDetails, setIsModalSheetDentistDetails] =
    useState(false);
  const isMobileScreen = useMediaQuery({ query: "(max-width: 684px)" });
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const handleOpenModalSheetShare = () => {
    setModalSheetShareOpen(true);
  };
  const selectedDataShare = newObjectDataShare.find(
    (item) => item.id === selectedId
  );

  const handleOpenModalSheetDentistDetails = (id) => {
    setSelectedId(id);
    setIsModalSheetDentistDetails(true);
  };

  const handleCloseModalSheetShare = () => {
    setModalSheetShareOpen(false);
  };
  const copyURL = () => {
    setIsModalSheetDentistDetails(true);
  };
  const DeleteShared = async (id) => {
    try {
      const { data } = await Api.UpdateShared(id, { id: 0, active: false });

      toast("Compartilhamento Atualizado", {
        style: {
          background: "#008a00",
          color: "#fff",
        },
      });
      getShared();
    } catch (error) {
      toast("Error ao salvar", {
        style: {
          background: "#e71010",
          color: "#fff",
        },
      });
    }
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOPenModal = () => {
    setModalOpen(true);
  };
  const newObjectWithPatientNameAndId = {
    seqAtend: infoPacient.seqAtend,
    idClient: infoPacient.idClient,
    Paciente: infoPacient.paciente,
    Id: infoPacient.id,
    ss: infoPacient.ss,
    imagem: infoPacient.imagem
  };


  const getShared = async () => {
    try {
      const shared = await Api.GetShared(infoPacient.seqAtend);
      if (shared.isSuccess) {
        //SetObjShared(shared.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={styles.wrapper}>
      {isMobileScreen ? (
        <div className={styles.containerButton_end}>
          <div
            className={`${styles.buttonShare} ${styles.iconButton}`}
            onClick={handleOPenModal}
          >
            <ShareIcon width={20} height={20} />{" "}
            {!isMobileScreen && "Compartilhar"}
          </div>
        </div>
      ) : (
        <div className={styles.allButton}>
          <div className={styles.containerButton_share}>
            <Button
              className={styles.buttonShare}
              onClick={handleOPenModal}
              size="full"
              style={{ height: '40px' }}
            >
              <ShareIcon width={14} height={14} />{" "}
              {!isMobileScreen && "Compartilhar"}
            </Button>
          </div>
        </div>
      )}

      {newObjectDataShare == undefined ? (
        <div
          style={{
            textAlign: "center",
            paddingTop: "40px",
            color: "#afafaf",
            fontWeight: 500
          }}
        >
          <p>O perfil ainda não foi compartilhado.</p>
        </div>
      ) : (
        <>
          {!isMobileScreen ? (
            <div>
              {
                newObjectDataShare.length == 0 ?
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: "40px",
                      color: "#afafaf",
                      fontWeight: 500
                    }}
                  >
                    <p>O perfil ainda não foi compartilhado.</p>
                  </div>
                  :
                  <table className={styles.tableShare}>
                    <thead className={styles.tableShare_thead}>
                      <tr>
                        <th>
                          Dentista
                          <ChevronUpDownIcon width={12} />
                        </th>
                        <th>
                          E-mail
                          <ChevronUpDownIcon width={12} />
                        </th>
                        {/* <th>
                      O que compartilhou
                      <ChevronUpDownIcon width={12} />
                    </th> */}
                        <th>
                          Link
                          <ChevronUpDownIcon width={12} />
                        </th>
                        <th>
                          Cancelar Compartilhamento
                          <ChevronUpDownIcon width={12} />
                        </th>
                      </tr>
                    </thead>
                    <tbody className={styles.tableShare_tbody}>
                      {newObjectDataShare.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {item.name}
                          </td>
                          <td>
                            {item.email || "-------"}
                          </td>
                          {/* <td>
                        {item.share || "-------"}
                      </td> */}
                          <td>
                            <a
                              href={`${item.uniqueLink}`}
                              target="_blank"
                              style={{ color: '#0096FF' }}
                              title="Abra o perfil compartilhado"
                            >
                              {item.uniqueLink || "-------"}
                            </a>
                          </td>
                          <td>
                            <NoSymbolIcon
                              width={16}
                              onClick={() => DeleteShared(item.id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
              }
            </div>
          ) : (
            <table className={styles.tableShare}>
              <thead className={styles.tableShare_thead}>
                <tr>
                  <th>
                    Dentista
                    <ChevronUpDownIcon width={12} />
                  </th>
                  <th>Detalhes</th>
                  <th>Deletar</th>
                </tr>
              </thead>
              <tbody className={styles.tableShare_tbody}>

                {
                  newObjectDataShare.length === 0 ?
                    <p className={styles.text}>
                      O perfil ainda não foi compartilhado.
                    </p>
                    :
                    newObjectDataShare.map((item, index) => (
                      <tr key={index}>
                        <td
                          onClick={() =>
                            handleOpenModalSheetDentistDetails(item.id)
                          }
                        >
                          {" "}
                          {item.name}
                        </td>
                        <td
                          onClick={() =>
                            handleOpenModalSheetDentistDetails(item.id)
                          }
                        >
                          <EyeIcon width={18} height={18} />
                        </td>

                        <td>
                          <NoSymbolIcon
                            width={16}
                            onClick={() => DeleteShared(item.id)}
                          />
                        </td>
                      </tr>
                    ))
                }

              </tbody>
            </table>
          )}
        </>
      )}
      {isMobileScreen ? (
        <ModalShareProfileMobile
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          PatientNameAndId={newObjectWithPatientNameAndId}
        />
      ) : (
        isModalOpen && (
          <ModalShareProfile
            isOpen={isModalOpen}
            onRequestClose={handleCloseModal}
            PatientNameAndId={newObjectWithPatientNameAndId}
            getProfileSharedSuccessfully={getProfileSharedSuccessfully}
          />
        )
      )}
      {isModalSheetDentistDetails && (
        <ModalSheetDentistDetails
          isOpen={isModalSheetDentistDetails}
          onRequestClose={() => {
            setIsModalSheetDentistDetails(false);
          }}
          newObjectDataShare={selectedDataShare}
        />
      )}
    </div>
  );
}
