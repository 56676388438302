import { ChevronUpDownIcon } from '@heroicons/react/24/outline'

import { dateFormatter } from '../../../../utils/formatter';
import { patientData } from '../../../../Data/patientData';
import { useMediaQuery } from 'react-responsive';

import styles from './styles.module.css'

export function Exams({ examesPaciente }: any) {
    const isMobileScreen = useMediaQuery({ query: '(max-width: 684px)' });

    return (
        <div className={styles.wrapper}>
            {
                examesPaciente?.length == 0 ?
                    <div style={{
                        textAlign: "center",
                        paddingTop: "40px",
                        color: "#afafaf",
                        fontWeight: 500
                    }}>
                        <p>
                            Sem exames ou serviços no momento.
                        </p>
                    </div>
                    :
                    <table className={styles.tableExams}>
                        <thead className={styles.tableExams_thead}>
                            <tr>
                                <th>
                                    {isMobileScreen ? 'Qtd.' : 'Quantidade'}
                                    <ChevronUpDownIcon width={12} />
                                </th>
                                <th>
                                    Serviço
                                    <ChevronUpDownIcon width={12} />
                                </th>
                                <th>
                                    Convênio
                                    <ChevronUpDownIcon width={12} />
                                </th>
                            </tr>
                        </thead>

                        <>
                            {
                                examesPaciente === undefined ?
                                    <div style={{
                                        textAlign: "center",
                                        paddingTop: "40px",
                                        color: "#298e79",
                                        fontWeight: 600
                                    }}>
                                        <p>
                                            Sem exames e serviços no momento.
                                        </p>
                                    </div>
                                    :
                                    examesPaciente?.map((exams: any, index: number) => (
                                        <tbody
                                            key={index}
                                            className={styles.tableExams_tbody}
                                        >
                                            <tr>
                                                <td>
                                                    1
                                                </td>
                                                <td>
                                                    {exams.servico}
                                                </td>
                                                <td>
                                                    {exams.convenio}
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))
                            }
                        </>
                    </table>
            }
        </div>
    )
}