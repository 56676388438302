export const menuLinksPatient = [
  {
    id: 1,
    sectionTitle: "Paciente",
    title: "Paciente",
    icon: "UserGroupIcon",
    children: [
      {
        title: "Paciente",
        href: "lista-pacientes",
      },
    ],
  },
  
];
