import styles from "./styles.module.css";

import { PaperClipIcon } from "@heroicons/react/24/solid";

import { Field } from "formik";

export function Input({ errors, name, title, placeholder, isTextArea, label }) {
  return (
    <div className={styles.selectionContainer}>
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>
      <div className={styles.inputsIconContainer}>
        {name === "extra" || name === "vidLink" ? (
          <PaperClipIcon width={20} className={styles.paperClip} />
        ) : (
          <></>
        )}
        <Field
          name={name}
          id={name}
          type="text"
          title={title}
          placeholder={placeholder}
          className={`${styles.input}  ${
            isTextArea ? styles.textarea : styles.categories
          } ${name === "extra" || name === "vidLink" ? styles.iconInput : ""}`}
          as={isTextArea ? "textarea" : ""}
          errors={errors}
        />
        {name === "vidLink" || name === "extra" ? (
          <></>
        ) : (
          <p className={styles.errorMsg}>{errors}</p>
        )}
      </div>
      {name === "vidLink" ? <p className={styles.errorMsg}>{errors}</p> : <></>}
    </div>
  );
}
