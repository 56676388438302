import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

import styles from "./styles.module.css";

export function BoxVideo() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container_video}>
        <iframe
          width="560"
          height="315"
          className={styles.video}
          src="https://player-vz-fb154304-6eb.tv.pandavideo.com.br/embed/?v=98856a72-a853-476d-ab04-2ea7513f10be"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>

      <div className={styles.content}>
        <h2>
          Como cadastrar uma clínica que utiliza o sistema <strong>ServerOdonto</strong>
        </h2>
        <p>
          Caso você ja tenha indicado pacientes para realizar exames em clínicas que utilizam ServerOdonto, você pode vícular essas clínicas à sua conta atual e ver todos os seus pacientes na mesma interface, sem precisar ficar alternando entre contas.
        </p>

        <p>
          Para isso, clique no botão "Víncular Clínica", digite o nome da clínica que você possua pacientes, entre com suas credenciais e pronto, você já tem sua clinica vínculada!
        </p>

        {/* <div className={styles.containerbutton}>
          <a href="https://www.youtube.com/embed/bjNYWc2O1SM" target="_blank" className={styles.button}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path d="M6.3 2.841A1.5 1.5 0 004 4.11V15.89a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z" />
            </svg>
            Assistir
          </a>
        </div> */}
      </div>
    </div>
  );
}
