import Modal from "react-modal";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import styles from './styles.module.css'

interface ModalLoadingProcessIaProps {
    isOpen: boolean;
    onRequestClose: () => void;
    overlayClassName?: any;
}

export function ModalLoadingProcessIa({ isOpen, onRequestClose, overlayClassName }: ModalLoadingProcessIaProps) {

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 14,
            borderRadius: 12,
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: '#fff',
        },
    }));

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName='no-overlay'
            className="react-modal-processIa"
        >
            <div className={styles.wrapper}>
                <LightTooltip
                    title={
                        <div className={styles.containerTitleTooltip}>
                            <p>
                                <strong>Processando... </strong> <br />
                                Pode levar algum tempo, dependendo do arquivo e da conexão
                            </p>
                        </div>
                    }
                    arrow
                    color="#fff"
                    placement="right"
                >

                    <QuestionMarkCircleIcon />
                </LightTooltip>

                <div className={styles.loading_container}>
                    <div className={styles.loading_spinne}></div>
                    <h2>Carregando...</h2>
                </div>
                <p>
                    Aguarde, o CEF-X está marcando os pontos.
                </p>
            </div>
        </Modal >
    )
}