import { useEffect, useState } from 'react';

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { CheckIcon, CursorArrowRippleIcon, TrashIcon, ArrowPathIcon, ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
import { toast } from "react-hot-toast";
import 'react-toastify/dist/ReactToastify.css';

import { styled } from '@mui/material/styles';
import Switch from "react-switch";

import styles from './styles.module.css'
import { useOpcoes } from '../../OpcoesContext';

interface PontosProps {
    textPont: string;
    ocefx: any;
    handlePontName: (ponto: any) => void;
}

export function Pontos({ textPont, ocefx, handlePontName }: PontosProps) {
    const { pontos, cefxSelectedTool, brightness, handleBrightnessChange, contrast, handleContrastChange, switchPontos, pontosOnOrOff, handleClickPont, setHandleClickPont } = useOpcoes();

    const [checkedListNumber, setCheckedListNumber] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [showPoints, setShowPoints] = useState(false);
    const [disableScroll, setDisableScroll] = useState(false);
    const [textPonto, setTextPonto] = useState('');

    const { nome: selectedPointName } = handleClickPont;

    const Tipospontos = ["Pontos a marcar", "Ponto atual", "Ponto marcado", "Mouse em cima - detalhes"];

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 14,
            borderRadius: 16,
            scrollX: 'hidden',
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: '#fff',
        },
    }));

    const handleChange = () => {
        setCheckedListNumber(!checkedListNumber);
    };

    const handleChangePontosSwitch = () => {
        if (cefxSelectedTool !== 'marcarPonto') {
            pontosOnOrOff(!switchPontos);
            ocefx.ligaPontos(!switchPontos);
        } else {
            toast(
                () => (
                    <span>
                        Para desativar os pontos, ative a ferramenta <strong>"Mover Imagem"</strong>.
                    </span>
                ),
                {
                    position: "top-right",
                    style: {
                        background: "#f09500",
                        color: "#fff",
                        textAlign: 'center'
                    },
                }
            );
        }
    };

    const handleProximoPonto = (pontoAtual: number) => {
        const nextPont = pontoAtual + 1
        ocefx.setPontoAtual(nextPont);
        pontos.map((point) => point.id.id === nextPont && setHandleClickPont({ ponto: point.id.id, nome: point.id.nome, tipo: ocefx.estado.paciente.tipo }))
    }

    const handleVoltarPonto = (pontoAtual: number) => {
        if (pontoAtual > 1) {
            const nextPont = pontoAtual - 1;
            ocefx.setPontoAtual(nextPont);
            pontos.map((point) => point.id.id === nextPont && setHandleClickPont({ ponto: point.id.id, nome: point.id.nome, tipo: ocefx.estado.paciente.tipo }))
        }
    }

    useEffect(() => {
        handlePontName(handleClickPont)
    }, [handleClickPont]);

    useEffect(() => {
        let openTimer;
        let closeTimer;

        if (textPont) {
            pontos.map((point) => point.id.nome === textPont && setHandleClickPont({ ponto: point.id.id, nome: point.id.nome, tipo: ocefx.estado.paciente.tipo }))
            openTimer = setTimeout(() => {
                setTooltipOpen(true);
                setShowPoints(true)

                closeTimer = setTimeout(() => {
                    setTooltipOpen(false);
                }, 1700);
            }, 0);
        }

        return () => {
            clearTimeout(openTimer);
            clearTimeout(closeTimer);
        };
    }, [textPont]);

    useEffect(() => {
        if (showPoints) {
            setDisableScroll(true);
        } else {
            setDisableScroll(false);
        }
    }, [showPoints]);

    useEffect(() => {
        const htmlBody = document.querySelector('html, body') as HTMLElement | null;

        if (htmlBody) {
            if (disableScroll) {
                htmlBody.style.overflow = 'hidden';
            } else {
                htmlBody.style.overflow = 'visible';
            }
        }

        return () => {
            if (htmlBody) {
                htmlBody.style.overflow = 'visible';
            }
        };
    }, [disableScroll]);

    return (
        <div>
            <div className={styles.switch}>
                <p>Pontos - Números</p>

                <div>
                    <p>Lista</p>

                    <div>
                        <Switch
                            onChange={handleChange}
                            checked={checkedListNumber}
                            className="react-switch"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#007E69"
                            height={20}
                            width={40}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.wrapper_pontoSelecionado}>
                {
                    !selectedPointName ?
                        <>
                            <span className={styles.semPontoText}>
                                Nenhum ponto selecionado.
                            </span>
                        </>
                        :
                        <>
                            <span>Nª</span>
                            <p>{handleClickPont.nome}</p>
                        </>
                }
            </div>

            <div className={styles.containerIconsArrows}>
                <button
                    type='button'
                    onClick={() => handleVoltarPonto(handleClickPont.ponto)}
                    disabled={handleClickPont.ponto == 1 && true}
                >
                    <ChevronDoubleLeftIcon width={16} />
                    Voltar Ponto
                </button>

                <button
                    type='button'
                    onClick={() => handleProximoPonto(handleClickPont.ponto)}
                >
                    Próximo Ponto
                    <ChevronDoubleRightIcon width={16} />
                </button>
            </div>

            <div className={styles.wrapper}>
                {
                    checkedListNumber ?
                        <div className={styles.tableContainer}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>
                                            <strong>Ponto</strong>
                                        </th>
                                        <th>
                                            <strong>Sigla</strong>
                                        </th>
                                        <th>
                                            <strong>Descrição</strong>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        {
                                            pontos.map((point, index) => (
                                                <>
                                                    <td
                                                        key={index}
                                                        className={styles.tdID}
                                                        onClick={() => {
                                                            ocefx.setPontoAtual(point.id.id)
                                                            setHandleClickPont({ ponto: point.id.id, nome: point.id.nome, tipo: ocefx.estado.paciente.tipo })
                                                        }}
                                                    >
                                                        <div
                                                            className={styles.point}
                                                            style={{
                                                                background: (point.id.selecionado === true && point.id.nome === selectedPointName) ? '#FFC107' : (point.id.marcado ? '#A44700' : (point.id.selecionado === true ? '#535353' : '#535353')),
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            {
                                                                point.id.marcado &&
                                                                <CheckIcon width={12} />

                                                            }
                                                        </div>
                                                        {point.id.id}
                                                    </td>
                                                    <td>
                                                        {point.id.sigla}
                                                    </td>
                                                    <td>
                                                        {point.id.nome}
                                                    </td>

                                                </>
                                            ))
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className={styles.points}>
                            {
                                pontos.map((point, index) => {
                                    return (
                                        <div key={index}>
                                            {
                                                point.id.nome === textPont && point.id.nome === textPonto ?
                                                    <LightTooltip
                                                        key={index}
                                                        title={
                                                            <div
                                                                className={styles.pointSelected}
                                                            >
                                                                <header>
                                                                    <div>
                                                                        <span>Pontos</span>
                                                                        <div className={styles.pointTooltip}
                                                                            style={{
                                                                                background: (point.id.selecionado === true && point.id.nome === selectedPointName) ? '#FFC107' : (point.id.marcado ? '#A44700' : (point.id.selecionado === true ? '#535353' : 'none')),
                                                                                color: '#000'
                                                                            }}
                                                                        >
                                                                            {point.id.id}

                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <span>Sigla</span>
                                                                        <div>
                                                                            Nª {''}
                                                                            {point.id.sigla}
                                                                        </div>
                                                                    </div>

                                                                    <div className={styles.description}>
                                                                        <strong> Descrição </strong>
                                                                        <p>
                                                                            {
                                                                                point.id.nome
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </header>
                                                            </div>
                                                        }
                                                        arrow
                                                        color="#fff"
                                                    >
                                                        <div
                                                            key={index}
                                                            className={styles.point}
                                                            style={{
                                                                background: (point.id.selecionado === true && point.id.nome === selectedPointName) ? '#FFC107' : (point.id.marcado ? '#A44700' : (point.id.selecionado === true ? '#535353' : 'none')),
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                ocefx.setPontoAtual(point.id.id)
                                                                setHandleClickPont({ ponto: point.id.id, nome: point.id.nome, tipo: ocefx.estado.paciente.tipo })
                                                                setTextPonto(point.id.nome)
                                                            }}
                                                        >
                                                            <span
                                                                id={`hpt${point.id.id}`}
                                                                style={
                                                                    {
                                                                        color: point.id.nome === selectedPointName ? "black" : "white",
                                                                        cursor: 'pointer',

                                                                    }
                                                                }
                                                                onClick={() => {
                                                                    // setPontoAtual(codigo);
                                                                    return false;
                                                                }}
                                                            >
                                                                {
                                                                    point.id.id
                                                                }
                                                            </span>
                                                        </div>
                                                    </LightTooltip>
                                                    :
                                                    <LightTooltip
                                                        key={index}
                                                        title={
                                                            <div
                                                                className={styles.pointSelected}
                                                            >
                                                                <header>
                                                                    <div>
                                                                        <span>Pontos</span>
                                                                        <div
                                                                            className={styles.pointTooltip}
                                                                            style={{
                                                                                background: (point.id.selecionado === true && point.id.nome === selectedPointName) ? '#FFC107' : (point.id.marcado ? '#A44700' : (point.id.selecionado === true ? '#535353' : '#535353')),
                                                                                color: '#fff'
                                                                            }}
                                                                        >
                                                                            {point.id.id}
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <span>Sigla</span>
                                                                        <div>
                                                                            Nª {''}
                                                                            {point.id.sigla}
                                                                        </div>
                                                                    </div>

                                                                    <div className={styles.description}>
                                                                        <strong> Descrição </strong>
                                                                        <p>
                                                                            {
                                                                                point.id.nome
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </header>

                                                            </div>
                                                        }
                                                        arrow
                                                        color="#fff"
                                                    >
                                                        <div
                                                            key={index}
                                                            className={styles.point}
                                                            style={{
                                                                background: (point.id.id == handleClickPont.ponto) ? '#FFC107' : (point.id.marcado ? '#A44700' : (point.id.selecionado === true ? '#535353' : 'none')),
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                ocefx.setPontoAtual(point.id.id)
                                                                setHandleClickPont({ ponto: point.id.id, nome: point.id.nome, tipo: ocefx.estado.paciente.tipo })
                                                                setTextPonto(point.id.nome)
                                                            }}
                                                        >
                                                            <span
                                                                id={`hpt${point.id.id}`}
                                                                style={
                                                                    {
                                                                        color: point.id.nome === selectedPointName ? "black" : "white",
                                                                        cursor: 'pointer',

                                                                    }
                                                                }
                                                                onClick={() => {
                                                                    // setPontoAtual(codigo);
                                                                    return false;
                                                                }}
                                                            >
                                                                {
                                                                    point.id.id
                                                                }
                                                            </span>
                                                        </div>
                                                    </LightTooltip>
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div >
                }

                {
                    !checkedListNumber && (
                        <>
                            <div className={styles.containerExampleOfPoints}>
                                {
                                    Tipospontos.map((point, index) => (
                                        <div className={styles.wrapper_tiposDePontos} key={index}>
                                            <div className={styles.pointExample} style={{
                                                background: point === 'Pontos a marcar' ? '#535353' : point === 'Ponto atual' ? '#FFC107' : point === 'Ponto marcado' ? '#A44700' : '#D37730',
                                            }}>
                                            </div>
                                            <span>
                                                {point}
                                            </span>
                                        </div>
                                    ))
                                }
                            </div>

                            <div className={styles.containerSettings}>
                                <p>Configurações de visualização</p>

                                <div className={styles.containerOnOff}>
                                    <div>
                                        <Switch
                                            onChange={handleChangePontosSwitch}
                                            checked={switchPontos}
                                            className="react-switch"
                                            onColor="#007E69"
                                            height={22}
                                            width={44}
                                        />
                                        <strong>Mostrar Pontos</strong>
                                    </div>
                                </div>

                                <div className={styles.containerButtonsPoints}>
                                    <button
                                        type='button'
                                        onClick={() => ocefx.excluirPontoAtual(true)}
                                    >
                                        <CursorArrowRippleIcon width={20} />
                                        Excluir Ponto Atual
                                    </button>

                                    <button
                                        type='button'
                                        onClick={() => ocefx.limparPontos(true)}
                                    >
                                        <TrashIcon width={20} />
                                        Limpar Pontos
                                    </button>
                                </div>

                                <div className={styles.containerInputsRange}>
                                    <div className={styles.containerInputOpcoesBasicas}>
                                        <span>
                                            Brilho
                                        </span>
                                        <input
                                            type="range"
                                            min={0}
                                            max={200}
                                            value={brightness}
                                            onChange={handleBrightnessChange}
                                        />
                                        <div>
                                            <span>
                                                0
                                            </span>
                                            <span>
                                                {brightness}
                                            </span>
                                        </div>
                                    </div>

                                    <div className={styles.containerInputOpcoesBasicas}>
                                        <span>
                                            Contraste
                                        </span>
                                        <input
                                            type="range"
                                            min={0}
                                            max={100}
                                            value={contrast}
                                            onChange={handleContrastChange}
                                        />
                                        <div>
                                            <span>
                                                0
                                            </span>
                                            <span>
                                                {contrast}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )
}