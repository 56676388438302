import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState = {
  user: <any>false,
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    signOut: (state) => {
      state.user = false;
    },
  },
});

export const { setUser, signOut } = UserSlice.actions;

export const selectUser = (state: any) => state.user || false;

export default UserSlice.reducer;
