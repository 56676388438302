
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom';

export function ErrorFallback({ resetErrorBoundary }: any) {
    const navigate = useNavigate();

    const closeViwer = () => {
        resetErrorBoundary();
        navigate(-1);
    }

    return (
        <div className={styles.containerError}>
            <div className={styles.containerImage}>
                <img src="/logo_serodonto_verde.png" width={"200px"} />
            </div>

            <h1>
                Oops! Algo deu errado.
            </h1>
            <p>
                Por favor, recarregue a página ou tente novamente mais tarde.
            </p>

            <button
                onClick={closeViwer}
            >
                Voltar
            </button>
        </div>
    )
}