import { Link, useNavigate } from "react-router-dom";
import Avatar from "react-avatar";

import { BadgeIcon } from "../BadgeIcon";
import { ClockIcon } from "../ClockIcon";
import { ChartLineUp } from "../ChartLineUp";
import { useEffect, useRef, useState } from "react";
import { Chart } from "../Chart";
import Api from "../../../../helpers/Api";

export function Card({
  styles,
  indicatorExams = [],
  id,
  description,
  creationDate,
  days,
  patientsCount,
  patientsReturnedCount,
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const getCardIndicatorId = async (id) => {
    setLoading(true);
    try {
      const results = await Api.getIndicator(id);
      navigate(`detalhes-card/${id}`);
      console.log(results);
      // History.push(`/indicadores-de-oportunidade/detalhes-card/${id}`);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const date = new Date(creationDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  return (
    <div onClick={() => getCardIndicatorId(id)} className={styles.link}>
      <div className={styles.containerGrid}>
        <h3 className={styles.cardTitle}>{description}</h3>
        <div className={styles.patientsContainer}>
          <span className={styles.avatars}></span>
          <p className={styles.cardSubtitle}>
            {patientsCount} pacientes atualmente
          </p>
        </div>
        <div className={styles.examsContainer}>
          <h4 className={styles.examsText}>EXAME(S)</h4>
          {indicatorExams.map((item: any, index: any) => (
            <span
              key={index}
              className={`${styles.examsBadge} ${
                styles[`${item.servico.toLowerCase().replace(/-/g, "")}Badge`]
              }`}
            >
              <BadgeIcon
                containerClassName={styles.iconContainer}
                color="#078295"
              />
              {item.servico}
            </span>
          ))}
        </div>
        <div className={styles.chartContainer}>
          <span className={styles.timeBadge}>
            <ClockIcon containerClassName={styles.clockIconContainer} />
            Consulta há {days} dias
          </span>
          <div className={styles.chartTopContainer}>
            <span className={styles.chartLineUpContainer}>
              <ChartLineUp containerClassName={styles.chartLineUpIcon} />
            </span>
            <div className={styles.chartTopTextContainer}>
              <p className={styles.chartTitle}>
                {patientsReturnedCount} pacientes retornaram (0%)
              </p>
              <p className={styles.chartSubtitle}>0 mensagens enviadas</p>
            </div>
          </div>

          <Chart styles={styles} />
          <div className={styles.dateAdded}>
            <p>Data de criação {formattedDate}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
