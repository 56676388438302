import { useState, useEffect } from "react";
import { Avatar, Button, Heading } from "@cdt-ui/react";
import { Formik, Form, FormikValues } from "formik";
import Modal from "react-modal";
import {
  XMarkIcon,
  PhotoIcon,
  ChevronDownIcon,
  LinkIcon,
} from "@heroicons/react/24/solid";
import * as yup from "yup";
import { useSelector } from "react-redux";
import styles from "./styles.module.css";
import { Input } from "../Form/Input";
import { TextArea } from "../Form/TextArea";
import { Select } from "../Form/Select";
import { FilePdf } from "@phosphor-icons/react";
import Api from "../../helpers/Api";
import { selectUser } from "../../store/slices/userSlice";
import { toast } from "react-hot-toast";
interface ModalShareProfileProps {
  isOpen: boolean;
  onRequestClose: () => void;
  selectedImages?: string[];
  PatientNameAndId?: any;
  getProfileSharedSuccessfully?: (successfully: boolean) => void;
}

export function ModalShareProfile({
  isOpen,
  onRequestClose,
  selectedImages,
  PatientNameAndId,
  getProfileSharedSuccessfully,
}: ModalShareProfileProps) {
  const [allImagens, setAllImagens] = useState(selectedImages);
  const [errorMensagem, setErrorMensagem] = useState(false);
  const [uniqueLink, setUniqueLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [profileSharedSuccessfully, setProfileSharedSuccessfully] = useState(false);
  const endsWithPDF = allImagens?.some((url) => String(url).endsWith(".pdf"));
  const { user } = useSelector(selectUser);


  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("E-mail inválido, verifique e tente novamente")
      .max(45, ({ max }) => `máximo ${max} caracteres`)
      .required("Campo obrigatório"),
    dentistName: yup.string().required("Campo obrigatório"),
    subject: yup.string().required("Campo obrigatório"),

  });
  const [objShare, SetObjShared] = useState([]);

  const getShared = async () => {
    try {
      const shared = await Api.GetShared(PatientNameAndId.seqAtend);
      if (shared.isSuccess) {
        SetObjShared(shared.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = async (
    { email, dentistName, message, subject }: FormikValues,
    setFieldValue,
    setSubmitting
  ) => {
    setLoading(true)
    try {

      const result = await Api.createShared({
        id: 0,
        idPatient: PatientNameAndId.seqAtend,
        idClient: PatientNameAndId.idClient ? PatientNameAndId.idClient : user[0].idClient,
        safeSequentialCare: PatientNameAndId.ss,
        email: email,
        name: dentistName,
        subject: subject,

        body: message,
        active: true,
      });

      if (result.isSuccess === true) {
        setUniqueLink(result.data);
        getProfileSharedSuccessfully(true);

        setFieldValue('email', '');
        setFieldValue('dentistName', '');
        setFieldValue('subject', '');

        setFieldValue('message', '');

        toast("Compartilhamento Cadastrado", {
          style: {
            background: "#008a00",
            color: "#fff",
          },
        });
        getShared();
      } else if (result.isSuccess === false && result.message === 'Patient has already been shared in other moment.') {
        setFieldValue('email', '');
        setFieldValue('dentistName', '');
        setFieldValue('subject', '');

        setFieldValue('message', '');

        toast("Paciente já foi previamente compartilhado.", {
          style: {
            background: "#f09500",
            color: "#fff",
            textAlign: 'center'
          },
        });
      } else {
        toast("Error ao salvar", {
          style: {
            background: "#e71010",
            color: "#fff",
          },
        });
      }
      setLoading(false);
    } catch (error) {
      toast("Error ao salvar", {
        style: {
          background: "#e71010",
          color: "#fff",
        },
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleRemoveImage = (indexToRemove?: number) => {
    if (allImagens?.length <= 1) {
      setErrorMensagem(true);
      return;
    }

    const newImages = allImagens.filter((_, index) => index !== indexToRemove);
    setAllImagens(newImages);
  };
  const copyToClipboard = (text) => {
    navigator?.clipboard
      .writeText(text?.uniqueLink)
      .then(() => {
        toast("Link copiado para a área de transferência", {
          style: {
            background: "#008a00",
            color: "#fff",
          },
        });
      })
      .catch((error) => {
        console.error("Error copying link to clipboard:", error);
        toast("Erro ao copiar o link para a área de transferência", {
          style: {
            background: "#e71010",
            color: "#fff",
          },
        });
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react_modal_overlay"
      className={`${selectedImages
        ? "react-modal-shareProfile_image"
        : "react-modal-shareProfiles"
        }`}
    >
      <div className="modal-content">
        <header className={styles.header}>
          <Heading as="h2">Compartilhar Perfil do Paciente</Heading>
          <p>
            O destinatário poderá visualizar os dados e as imagens, sem fazer
            login.
          </p>
          <XMarkIcon title="Fechar" onClick={onRequestClose} />
        </header>

        <div className={styles.wrapper}>
          <div className={styles.containerProfile}>
            <p>PACIENTE</p>
            <div className={styles.profile}>
              <Avatar src={PatientNameAndId.imagem} />
              <div>
                <strong>
                  {PatientNameAndId && PatientNameAndId.Paciente
                    ? PatientNameAndId.Paciente
                    : `Nome paciente`}
                </strong>
                <span>
                  {PatientNameAndId && PatientNameAndId.Paciente
                    ? `#${PatientNameAndId.Id}`
                    : `Id paciente`}
                </span>
              </div>
            </div>
          </div>

          {selectedImages ? (
            <div className={styles.containerImages}>
              {allImagens?.map((imagem, index) => (
                <div key={index} className={styles.imagem}>
                  {String(imagem.link).endsWith(".pdf") ? (
                    <div className={styles.imagePfd}>
                      <FilePdf />
                    </div>
                  ) : (
                    <img src={String(imagem.link)} alt={`Imagem ${index}`} />
                  )}
                  <div className={styles.containerIconClose}>
                    <XMarkIcon
                      title="Excluir"
                      onClick={() => handleRemoveImage(index)}
                    />
                  </div>
                </div>
              ))}
              {errorMensagem && (
                <span className={styles.errorImage}>Necessário uma imagem</span>
              )}
            </div>
          ) : (
            <div className={styles.border}></div>
          )}
          <Formik
            initialValues={{
              email: "",
              dentistName: "",
              subject: "",
              message: "",
              viewType: "",
            }}
            // onSubmit={(values) => handleSubmit(values)}
            onSubmit={(values, { setSubmitting, setFieldValue }) => handleSubmit(values, setFieldValue, setSubmitting)}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
          >
            {({ errors, isSubmitting, values, setFieldValue }: any) => (
              <Form className={styles.form}>
                <div className={styles.containerForm}>
                  <div className={styles.container_input}>
                    <Input
                      type="email"
                      name="email"
                      title="E-mail"
                      placeholder="E-mail para:"
                      error={errors.email}
                    />

                    <Input
                      type="text"
                      name="dentistName"
                      title="Dentista"
                      placeholder="Nome do dentista"
                      error={errors.dentistName}
                    />
                  </div>

                  <div className={styles.container_inputsubject}>
                    <Input
                      type="text"
                      name="subject"
                      title="Assunto"
                      placeholder="Assunto do E-mail"
                      error={errors.subject}
                    />
                  </div>

                  <div>
                    <TextArea
                      title="Mensagem"
                      name="message"
                      placeholder="mensagem"
                    />
                  </div>
                </div>
                {/* 
                <div className={styles.containerSelect}>
                  <div>
                    <div className={styles.containerIcon}>
                      <PhotoIcon width={16} />
                    </div>
                    <div className={styles.containerContent}>
                      <strong>
                        Compartilhar somente imagens
                        <ChevronDownIcon width={16} />
                      </strong>
                      <p>
                        A pessoa não visualizará o perfil do paciente, este
                        poderá baixar as imagens compartilhadas
                      </p>
                    </div>
                  </div>
                  <Select name="viewType" />
                </div> */}

                <div className={styles.containerButton}>
                  <div className={styles.containerButton_copie}>
                    <Button
                      variant="tertiary"
                      size="full"
                      type="button"
                      onClick={() => copyToClipboard(uniqueLink)}
                      disabled={uniqueLink === ""}
                      className={styles.buttonMore}
                    >
                      <LinkIcon /> Copiar link
                    </Button>
                  </div>

                  <div className={styles.containerButton_submit}>
                    <Button
                      type="submit"
                      variant="greenDark"
                      size="full"
                      disabled={loading}
                    >
                      {
                        loading ?
                          'Enviando...'
                          :
                          'Enviar'
                      }
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}
