import { useState, useEffect } from "react";

import { SubCard } from "../Subcard";

import { ChevronUpDownIcon } from "@heroicons/react/24/outline";

import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../../../utils/StrictModeDroppable";

import styles from "../styles.module.css";

export function Card({ title, amount, value, cardData, listType = "red" }) {
  const dados = cardData.filter((el) => el[listType])[0][listType];

  const rearrangeArr = (arr, sourceIndex, destIndex) => {
    const arrCopy = [...arr];
    const [removed] = arrCopy.splice(sourceIndex, 1);
    arrCopy.splice(destIndex, 0, removed);

    return arrCopy;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardTopContainer}>
        <div className={styles.cardTitleContainer}>
          <h3>{title}</h3>

          <div className={styles.amountsContainer}>
            <p className={styles.amount}>{amount}</p>
            <ChevronUpDownIcon width={20} />
          </div>
        </div>
        <p className={styles.value}>{value}</p>
      </div>
      {/*  <DragDropContext onDragEnd={handleOnDragEnd}> */}
      <StrictModeDroppable droppableId={listType}>
        {(provided) => (
          <ul
            className={styles.draggableList}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {dados.map((c, index) => (
              <Draggable key={c.id} draggableId={c.id} index={index}>
                {(provided) => (
                  <li
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={styles.draggableList}
                  >
                    <SubCard
                      title={c.title}
                      content={c.content}
                      username={c.username}
                      userProfile={c.userProfile}
                      dataDisposal={listType}
                      badge={c.badge}
                    />
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </StrictModeDroppable>
      {/* </DragDropContext> */}
    </div>
  );
}
