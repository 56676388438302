import React from "react";
import { inflate } from "zlib";
import Modal from "react-modal";
import styles from "./styles.module.css";
import { Container } from "../../../Academy/CadastrarCurso/components/Content/Container";
import { ClockIcon } from "../../components/ClockIcon";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Avatar from "react-avatar";
import { Card } from "../../components/Card";

interface ModalSendMsgWhatsProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function ModalSMWhats({
  isOpen,
  onRequestClose,
}: ModalSendMsgWhatsProps) {
  return (
    <div className={`${styles.SendMsg} ${styles.modalwhats}`}>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react_modal_overlay"
        className={styles.react_modal_SendMessageWhats}
      >
        <div className={styles.icon_wrapper}>
          <XMarkIcon title="Fechar" onClick={onRequestClose} />
        </div>

        <header className={styles.headerWhats}>
          <div className={styles.container_contentwhats}>
            <h2>Enviar mensagem por WhatsApp</h2>
            <p>Lorem ipsum dolor sit amet</p>
          </div>
        </header>
        <div className={styles.cardContentWhatsHeader}>
          <h2>PACIENTES</h2>
        </div>
        <div className={styles.cardContentWhats}>
          <h2>Retorno tomografia - Jovens</h2>
          <div className={styles.clockIconContainerwhats}>
            <ClockIcon containerClassName="ClockIcon" />
            <p>Consulta há 1 ano</p>
          </div>

          <div className={styles.pacientesContainerwhats}>
            <div className={styles.avatareswhats}>
              <span className={styles.avatarswhats}>
                <Avatar
                  className={styles.avatarwhats}
                  size="30"
                  src="https://s3-alpha-sig.figma.com/img/2e01/b2ad/c895fca7e299aceb539bf37f9a72f316?Expires=1693180800&Signature=f3tWiqHhWBe1ltwiLUk8CEfaRaxVTTCWxHzE2Dp6GFWtERsFJA8gx0~lh1xvDFlOVIB~iNU1lyXephgidM-aVECsULQeW6LqShNFggOLINEU6Be49pMoT-AlGDpfZu33EziR-IlyTWSO~f~EeUAAsk6k1tafJG21JgQbaNIyKMlZkwoR8bD-E8yqBR8HzLVVmimbG4KoSPEu~L~aXaCsdi9sehxOkRcmIHZlbYpFCUHm2CXyzuemXdPxkLMv2fF01jVqZoaczLXkWmEe3JtFH6qd-0gGhuwiJmwAAtjM1FAOf7Sfp8cl626-ymPANjT3uzM41S921fU60BLoV8JTlQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
                />
                <Avatar
                  className={styles.avatar1whats}
                  size="30"
                  src="https://s3-alpha-sig.figma.com/img/3170/76df/a57d49314359fd7606cce752f3324d5d?Expires=1693180800&Signature=K2ypC1P4TjzX9YhxEoc8UP2bwtHEKDy5U5vQtOyx9Xjt9dWMCRp54QyAg35XN~LwjLnxM9ckeOba4r0wpWV-FQNiVv69KDDtOQMwJpfNwVUY8~Go37Ff18LWUrFMpNfJ-qiZplyXH1NHtOpPOblB3TQfV4UgFntNRo9JUbOJrUUOSyTcM0VLlmrK6kXshb3XkZIJgz3UuRtwVhDdUgHgaqTzLP-MEHUdPLXRiNtr5HjMxGrVaBUz4JQWxW4qvGargu4ROlqASR2V8hgyMbwvgWjnGOK8btGfUINW2xtmPWSkcMV-~WuhmzZPqswYXfqzYSqJfmf1PbdEQtQkXR8ayQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
                />
                <Avatar
                  className={styles.avatar2whats}
                  size="30"
                  src="https://s3-alpha-sig.figma.com/img/f127/01bd/073e2bc1cd36ec23f693b57248587006?Expires=1693180800&Signature=PQR6rMdBj5VGRghuwsQtl31QGqt305DE4r93lObv5ZSsf8GCU5zdd5MO56a3ajAfB6NRhj1yHD186Fblq0pB4C7ooO~iDjgGI77sXE3SN7tf1wRvgMfeiHM3Gwz-tqbw-x6p4~BKESBBh7jlrZ-HDbMItsYvISYAuO9xONJWN31LT3gKcZkZkjzs3G3C0utW5EfoBosWdohWNukNLs241CtDc~UQd97Xgyrrpb7Zos74Cyn3cMJ6610lUwdKdVxDOkGJyzPo3fv64ld837Z0Qc1m-bMzPcwtTSGr8qAIyOaQtCgflCx17xKsdYlWtFXlDUyWuGrBvFiphG0slSyt0w__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
                />
              </span>
            </div>
            <p>84 pacientes selecionados</p>
            <div className={styles.numeroPacienteswhats}>
              <p>84 de 324 pacientes</p>
            </div>
          </div>
        </div>

        <div className={styles.linhahats}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="752"
            height="3"
            viewBox="0 0 754 2"
            fill="none"
          >
            <path d="M1 1H753" stroke="#EBEEF3" strokeLinecap="round" />
          </svg>
        </div>

        <div className={styles.mensagemPacientewhats}>
          <p>Mensagem para paciente</p>
          <textarea
            defaultValue="Lembrete: Seu Sorriso Merece Cuidados Regulares!
Prezado(a) [Nome do paciente],
Parabéns por cuidar do seu sorriso! Já se passou um ano desde sua última visita conosco. Agende sua consulta para mantermos seu sorriso saudável e radiante.
ㅤ
Contate-nos: [Inserir telefone]
Atenciosamente,
Equipe [Nome da Clínica Odontológica]"
          ></textarea>
        </div>
        <div className={styles.whatsBtn}>
          <button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.0863 2.90644C15.2062 1.03311 12.7057 0.00111111 10.0419 0C4.55291 0 0.0857439 4.44556 0.083511 9.90978C0.0828412 11.6564 0.541259 13.3613 1.41277 14.8644L0 20L5.27906 18.6218C6.73358 19.4113 8.3712 19.8276 10.0378 19.828H10.0419C10.0416 19.828 10.0421 19.828 10.0419 19.828C15.5302 19.828 19.9978 15.382 20 9.91756C20.0011 7.26956 18.9664 4.77978 17.0863 2.90644ZM5.99783 10.2704C5.87346 10.1051 4.98186 8.92778 4.98186 7.70889C4.98186 6.49 5.62471 5.89089 5.85269 5.64311C6.08068 5.39533 6.35041 5.33333 6.51632 5.33333C6.68222 5.33333 6.84835 5.33489 6.99327 5.342C7.146 5.34978 7.3512 5.28422 7.55306 5.76711C7.7605 6.26289 8.25799 7.482 8.32029 7.60578C8.38259 7.72978 8.4239 7.87422 8.34106 8.03956C8.25821 8.20467 8.21668 8.308 8.09231 8.45267C7.96794 8.59733 7.83106 8.77556 7.71897 8.88644C7.59437 9.01 7.46464 9.144 7.60978 9.39178C7.75491 9.63978 8.2542 10.4507 8.99396 11.1073C9.94429 11.9511 10.7461 12.2124 10.9949 12.3364C11.2436 12.4604 11.3888 12.4398 11.5339 12.2744C11.679 12.1091 12.156 11.5513 12.3219 11.3036C12.4878 11.0558 12.6537 11.0971 12.8817 11.1796C13.1099 11.2622 14.3331 11.8613 14.5818 11.9851C14.8306 12.1091 14.9965 12.1711 15.0588 12.2744C15.1211 12.3778 15.1211 12.8736 14.9136 13.452C14.7062 14.0304 13.7121 14.5584 13.234 14.6293C12.8053 14.6931 12.2629 14.7198 11.6668 14.5311C11.3055 14.4171 10.8419 14.2647 10.2482 14.0096C7.75246 12.9369 6.12243 10.4358 5.99783 10.2704Z"
                fill="white"
              />
            </svg>
            Enviar por WhatsApp
          </button>
        </div>
      </Modal>
    </div>
  );
}
