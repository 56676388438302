import React, { useCallback, useState } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import './styles.css';


export const TextEditor = ({ wrapperRef }) => {
  return (
    <div id="containerTextEditor" ref={wrapperRef} className="containerTextEditor"></div>
  );
};



