import { ErrorInfo, useEffect, useState } from "react";
import { Formik, Form, FormikValues, Field } from "formik";
import { Button } from "@cdt-ui/react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import * as yup from "yup";
import Api from "../../../helpers/Api";
import { useSelector, useDispatch } from "react-redux";

import { Input } from "../../../components/Form/Input";
import { CheckboxEmail } from "../../../components/Form/Checkbox/NotificacaoPorEmail";
import { CheckboxTermosDeUso } from "../../../components/Form/Checkbox/AceitarTermos";
/* import { Select } from "../../../components/Form/Select"; */
import Select, { components } from "react-select";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { ModalErrorOrSucessMessage } from "../../../components/ModalErrorOrSucessMessage";

import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { selectUser, setUser } from "../../../store/slices/userSlice";
import { CustomSelect } from "../../../components/CustomSelect";

interface Step3Props {
  payloadStep1AndStep2: any;
  handleBackButtonStep2: () => void;
}

export function Step3({
  payloadStep1AndStep2,
  handleBackButtonStep2,
}: Step3Props) {
  const [isModalErroOrSucessOpen, setIsModalErroOrSucessOpen] = useState(false);
  const [inputValues, setInputValues] = useState([]);
  const [registroValue, setRegistroValue] = useState("");
  const [codigoValue, setCodigoValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [labelStyles, setLabelStyles] = useState({
    fontSize: "0.875rem",
    color: "#000",
  });
  const [isSelectFocused, setIsSelectFocused] = useState(false);

  const navigate = useNavigate();
  const { user } = useSelector(selectUser);
  const dispatch = useDispatch();

  const navigateToDashboardScreen = () => {
    if (user !== false) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  };

  const handleStateSelected = (value) => {
    setRegistroValue(value);
  };

  const handleCloseModalErrorOrSucess = () => {
    setIsModalErroOrSucessOpen(false);
  };

  const validationSchema = yup.object().shape({
    agreeToTerms: yup.string().required("Campo obrigatório"),
  });

  const handleSubmit = async (values: FormikValues) => {
    setLoading(true);
    const { registro, numero, agreeToTerms } = values;

    const transformedPayload = {
      pais: "Brasil",
      idClinica: user ? user.idClient : 0,
      ...payloadStep1AndStep2.step1,
      ...payloadStep1AndStep2.step2,
    };
    console.log(transformedPayload)
    if (agreeToTerms) {
      try {
        const userResult = await Api.CreateUser(transformedPayload);

        if (userResult.isSuccess === true && user !== false) {
          const trimmedValues = {
            logon: payloadStep1AndStep2.step1.logon.trim(),
            password: payloadStep1AndStep2.step1.senha.trim(),
            idClient: user[0].idClient,
          };


          for (let i = 0; i < user.length; i++) {
            const userData = user[i];
            await Api.postClinic({
              id: 0,
              idClient: userData.idClient,
              clinicalName: userData.clinicalName,
              logon: userData.logon,
              senha: userData.senha,
              address: "",
              city: "",
              neighborhood: "",
              hash: userData.hash,
              idUser: userResult.data.id,
            });
          }

          dispatch(setUser(false));

          const result = await Api.SignIn(trimmedValues);

          if (result.isSuccess === true) {
            const clienteId = result.data[0].idClient;
            const getIP = await Api.GetIP(clienteId);

            result.data[0].ip = getIP;

            dispatch(setUser(result.data[0]));
            setIsModalErroOrSucessOpen(true);
          } else {
            console.log("error");
          }
        } else if (userResult.isSuccess === true) {
          toast(userResult.message, {
            style: {
              background: "#008a00",
              color: "#fff",
            },
          });
          setIsModalErroOrSucessOpen(true);
        } else {
          toast(userResult.message, {
            style: {
              background: "#FFFF00",
              color: "#000",
            },
          });
        }
      } catch (error) {
        toast("Error ao salvar", {
          style: {
            background: "#e71010",
            color: "#fff",
          },
        });
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };



  return (
    <>
      <div className={styles.containerStep3}>


        <div className={styles.formStep3}>
          <p
            style={{
              fontSize: 14,
              color: "#7B889C",
              fontWeight: "700",
              marginBottom: 16,
            }}
          >
            DADOS DA PROFISSÂO
          </p>

          <div className={styles.inputsStep3}>
            <Formik
              initialValues={{

                enviaEmail: "",
                agreeToTerms: "",
              }}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values);
                resetForm();
              }}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={validationSchema}
            >
              {({ errors, isSubmitting, values, setFieldValue, isValid }) => (
                <Form>
                  {registroValue}

                  <div className={styles.borderStyle} />

                  <div className={styles.containerCheckbox}>
                    <CheckboxEmail
                      name="enviaEmail"
                      customStyle={{ width: "18px", height: "18px" }}
                    />

                    <CheckboxTermosDeUso
                      customStyle={{ width: "18px", height: "18px" }}
                      name="agreeToTerms"
                      error={errors.agreeToTerms}
                    />
                  </div>

                  <div className={styles.borderStyle} />

                  <div className={styles.container_buttons}>
                    <div className={styles.buttonCancelar}>
                      <Button
                        type="button"
                        size="full"
                        variant="tertiary"
                        disabled={loading && true}
                        onClick={handleBackButtonStep2}
                      >
                        <ArrowLeftIcon />
                        <span>Voltar</span>
                      </Button>
                    </div>

                    <div className={styles.buttonContinuar}>
                      <Button
                        type="submit"
                        size="full"
                        variant="greenDark"
                        disabled={loading && true}
                      >
                        {loading ? (
                          <div className={styles.container_buttons_loading}>
                            <div className={styles.loading_spinne}></div>
                          </div>
                        ) : (
                          <>
                            <span>Salvar</span>

                            <ArrowRightIcon />
                          </>
                        )}
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {isModalErroOrSucessOpen && (
        <ModalErrorOrSucessMessage
          type="success"
          isOpen={isModalErroOrSucessOpen}
          handleClose={handleCloseModalErrorOrSucess}
          onRequestClose={handleCloseModalErrorOrSucess}
          handleToNavigate={navigateToDashboardScreen}
        />
      )}
    </>
  );
}
