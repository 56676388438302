import { useEffect, useRef } from "react";

import styles from "./styles.module.css";

export function Dicom({ viwerActives, oViewer }) {
  const container3DRef = useRef(null);
  const divContainer3dRef = useRef(null);
  const divMPR0Ref = useRef(null);
  const divMPR1Ref = useRef(null);
  const divMPR2Ref = useRef(null);
  const divMPR3Ref = useRef(null);

  useEffect(() => {
    if (viwerActives[0] && viwerActives[5]) {
      if (container3DRef.current) {
        container3DRef.current.className = styles.container3dV1;
      }
      if (divContainer3dRef.current) {
        divContainer3dRef.current.style.display = "block";
        divContainer3dRef.current.className = styles.divContainer3dV1;
      }
      if (divMPR0Ref.current) divMPR0Ref.current.style.display = "none";
      if (divMPR1Ref.current) divMPR1Ref.current.style.display = "none";
      if (divMPR2Ref.current) divMPR2Ref.current.style.display = "none";
    } else if (viwerActives[1]) {
      if (container3DRef.current) {
        container3DRef.current.className = styles.container3dV2;
      }
      if (divContainer3dRef.current) {
        divContainer3dRef.current.className = styles.divContainer3dV2;
      }
      if (divContainer3dRef.current)
        divContainer3dRef.current.style.display = "block";
      if (divMPR0Ref.current) {
        divMPR0Ref.current.style.display = "block";
        divMPR0Ref.current.className = styles.divContainer3dV2;
      }
      if (divMPR1Ref.current) divMPR1Ref.current.style.display = "none";
      if (divMPR2Ref.current) divMPR2Ref.current.style.display = "none";
    } else if (viwerActives[2]) {
      if (container3DRef.current) {
        container3DRef.current.className = styles.container3dV3;
      }
      if (divContainer3dRef.current) {
        divContainer3dRef.current.className = styles.divContainer3dV3;
      }
      if (divContainer3dRef.current)
        divContainer3dRef.current.style.display = "block";
      if (divMPR0Ref.current) {
        divMPR0Ref.current.style.display = "block";
        divMPR0Ref.current.className = styles.divContainer3dV3;
      }
      if (divMPR1Ref.current) divMPR1Ref.current.style.display = "none";
      if (divMPR2Ref.current) divMPR2Ref.current.style.display = "none";
    } else if (viwerActives[3]) {
      if (container3DRef.current) {
        container3DRef.current.className = styles.container3dV4;
      }
      if (divContainer3dRef.current) {
        divContainer3dRef.current.className = styles.divContainer3dV4;
      }
      if (divContainer3dRef.current)
        divContainer3dRef.current.style.display = "block";
      if (divMPR0Ref.current) {
        divMPR0Ref.current.style.display = "block";
        divMPR0Ref.current.className = styles.divContainer3dV4;
      }
      if (divMPR1Ref.current) {
        divMPR1Ref.current.style.display = "block";
        divMPR1Ref.current.className = styles.divContainer3dV4;
      }
      if (divMPR2Ref.current) {
        divMPR2Ref.current.style.display = "block";
        divMPR2Ref.current.className = styles.divContainer3dV4;
      }
    } else if (viwerActives[4]) {
      if (container3DRef.current) {
        container3DRef.current.className = styles.container3dV5;
      }
      if (divContainer3dRef.current) {
        divContainer3dRef.current.className = styles.divContainer3dV5;
      }
      if (divContainer3dRef.current)
        divContainer3dRef.current.style.display = "block";
      if (divMPR0Ref.current) {
        divMPR0Ref.current.style.display = "block";
        divMPR0Ref.current.className = styles.divContainer3dV5;
      }
      if (divMPR1Ref.current) {
        divMPR1Ref.current.style.display = "block";
        divMPR1Ref.current.className = styles.divContainer3dV5;
      }
      if (divMPR2Ref.current) {
        divMPR2Ref.current.style.display = "block";
        divMPR2Ref.current.className = styles.divContainer3dV5;
      }
    } else if (viwerActives[0] && viwerActives[6]) {
      if (container3DRef.current) {
        container3DRef.current.className = styles.container3dV1;
      }
      if (divContainer3dRef.current) {
        if (divMPR0Ref.current) divMPR0Ref.current.style.display = "block";
        divMPR0Ref.current.className = styles.divContainer3dV1;
      }
      if (divContainer3dRef.current)
        divContainer3dRef.current.style.display = "none";
      if (divMPR1Ref.current) divMPR1Ref.current.style.display = "none";
      if (divMPR2Ref.current) divMPR2Ref.current.style.display = "none";
    } else if (viwerActives[0] && viwerActives[7]) {
      if (container3DRef.current) {
        container3DRef.current.className = styles.container3dV1;
      }
      if (divContainer3dRef.current) {
        if (divMPR1Ref.current) divMPR1Ref.current.style.display = "block";
        divMPR1Ref.current.className = styles.divContainer3dV1;
      }
      if (divContainer3dRef.current)
        divContainer3dRef.current.style.display = "none";
      if (divMPR0Ref.current) divMPR0Ref.current.style.display = "none";
      if (divMPR2Ref.current) divMPR2Ref.current.style.display = "none";
    } else if (viwerActives[0] && viwerActives[8]) {
      if (container3DRef.current) {
        container3DRef.current.className = styles.container3dV1;
      }
      if (divContainer3dRef.current) {
        if (divMPR2Ref.current) divMPR2Ref.current.style.display = "block";
        divMPR2Ref.current.className = styles.divContainer3dV1;
      }
      if (divContainer3dRef.current)
        divContainer3dRef.current.style.display = "none";
      if (divMPR0Ref.current) divMPR0Ref.current.style.display = "none";
      if (divMPR1Ref.current) divMPR1Ref.current.style.display = "none";
    } else if (viwerActives[0]) {
      if (container3DRef.current) {
        container3DRef.current.className = styles.container3dV1;
      }
      if (divContainer3dRef.current) {
        divContainer3dRef.current.style.display = "block";
        divContainer3dRef.current.className = styles.divContainer3dV1;
      }
      if (divMPR0Ref.current) divMPR0Ref.current.style.display = "none";
      if (divMPR1Ref.current) divMPR1Ref.current.style.display = "none";
      if (divMPR2Ref.current) divMPR2Ref.current.style.display = "none";
    }

    oViewer.render()

  }, [viwerActives]);

  return (
    <div className={styles.wrapperDicon}>
      <div ref={container3DRef}>

        <div id="divMPRA3" ref={divContainer3dRef} />

        <div id="divMPRA2" ref={divMPR0Ref} style={{ display: "none" }} />

        <div id="divMPRA0" ref={divMPR1Ref} style={{ display: "none" }} />

        <div id="divMPRA1" ref={divMPR2Ref} />

      </div>
    </div >
  );
}


