// Filters/selectStyles.ts
import { StylesConfig } from 'react-select';

interface OptionType {
    value: string | number;
    label: string;
}

export const customSelectStyles: StylesConfig<OptionType, boolean> = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        display: 'flex',
        alignItems: 'center',
        width: "100%",
        minWidth: '200px',
        maxWidth: '200px',
        minHeight: "40px",
        maxHeight: "auto",
        backgroundColor: "#fff",
        borderRadius: state.menuIsOpen ? "12px 12px 0 0" : "12px",
        border: "1px solid #919DAF",
        boxShadow: "none",
        "&:hover": {
            borderColor: "none",
        },
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        zIndex: "11",
        borderRadius: "0px 0px 12px 12px",
        borderLeft: "1px solid #919DAF",
        borderRight: "1px solid #919DAF",
        borderBottom: "1px solid #919DAF",
        boxShadow: "none",
        overflow: "hidden",
        marginTop: "0px"
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor:
            state.isFocused || state.isSelected ? "#E4E4E4" : "#fff",
        fontWeight:
            state.isSelected || state.isFocused ? "600" : "400",
        margin: "0px auto",
        width: "100%",
        borderRadius: "2px",
    }),
    dropdownIndicator: (baseStyles, state) => ({
        ...baseStyles,
        color: '#919DAF',
        transform: state.isFocused ? "rotate(-180deg)" : "",
        cursor: "pointer",
    }),
    indicatorSeparator: (baseStyles, state) => ({
        ...baseStyles,
        display: "none",
    }),
    multiValue: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "#E4E4E4",
        borderRadius: "12px",
    }),
    multiValueLabel: (baseStyles) => ({
        ...baseStyles,
        color: "black",
    }),
    placeholder: (baseStyles) => ({
        ...baseStyles,
        color: "#919DAF",
    }),
    menuList: (baseStyles) => ({
        ...baseStyles,
        "::-webkit-scrollbar": {
            width: "8px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1",
            borderRadius: "0px",
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "0px",
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555",
        },
    }),
};

