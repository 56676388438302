import { useEffect, useState } from "react";

import {
  RocketLaunchIcon,
  Bars3Icon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";

import styles from "./styles.module.css";

import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import { Text } from "@cdt-ui/react";
import { academy } from "../../../../../Data/academy";
import { Circle } from "@phosphor-icons/react";
import { LessonItem } from "./NavLink";

interface SidebarProps {
  links: any;
  sidebarState: any;
}

type Props = {
  title: string;
  href: string;
};

interface NavLinkProps {
  item: any;
  collapse: boolean;
}

export function Teste({
  links,
  onSelectLesson,
  sidebarState,
}: SidebarProps & { onSelectLesson: (lesson: any) => void }) {
  function handleOpenMenuLink(lesson) {
    // Ensure you're passing the lesson (index here is misleading, maybe rename it)
    setOpenedLessonId(lesson.id);
    onSelectLesson(lesson);
  }
  const [collapse, setCollapse] = useState(false);

  function handleCollapse() {
    setCollapse(!collapse);
  }
  const [openedLessonId, setOpenedLessonId] = useState(null);
  const [open, setOpen] = useState(false);
  const [showLinkCollapse, setShowLinkCollapse] = useState(false);

  const asPath = window.location.pathname;

  return (
    <aside
      className={collapse ? `${styles.aside_collapse}` : `${styles.aside}`}
    >
      {collapse ? (
        <>
          <div
            className={styles.containerHeaderCollapse}
            onClick={handleCollapse}
          >
            <div className={styles.containerHeader}>
              <div onClick={handleCollapse}>
                <p className={styles.containerAnimationOn}>
                  MOSTRAR LISTA DE CONTEÚDOS
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4.5 9L12 16.5L19.5 9"
                    stroke="#535353"
                    stroke-width="2"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.containerHeader}>
            <div onClick={handleCollapse}>
              <p className={styles.containerAnimationOn}>
                ESCONDER LISTA DE CONTEÚDOS
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.48825 16.465C4.98986 16.9244 5.78749 16.9095 6.26981 16.4318L12.5016 10.1314L18.7333 16.4318C19.2156 16.9095 20.0133 16.9244 20.5149 16.465C21.0165 16.0057 21.0321 15.246 20.5498 14.7683L13.4098 7.56832C13.1723 7.33302 12.8443 7.20005 12.5016 7.20005C12.1588 7.20005 11.8309 7.33302 11.5933 7.56832L4.45331 14.7683C3.97099 15.246 3.98663 16.0057 4.48825 16.465Z"
                  fill="#535353"
                />
              </svg>
            </div>
          </div>
          <div></div>
          {links.map((lesson, idx) => (
            <LessonItem
              key={lesson.id}
              lesson={lesson}
              open={lesson.id === openedLessonId}
              onLessonClick={handleOpenMenuLink}
            />
          ))}
        </>
      )}
    </aside>
  );
}
