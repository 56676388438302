import { createContext, useState, useEffect, useRef } from "react";
import Api from "../../helpers/Api";
import { selectUser } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPatientsList, setClinicPage
} from "../../store/slices/patientListSlice";
import { selectisClinicConnectionsComplete } from "../../store/slices/isClinicConnectionsCompleteSlice";
import { selectPaginationCefxAConcluir, setToConcludePaginationAConcluir } from "../../store/slices/toConcludePaginationAConcluirSlice";
import { selectPaginationCefxConcluidos, setToConcludePaginationConcluidos } from "../../store/slices/toConcludePaginationConcluidosSlice";
import { selectPaginationCefxEmAndamento, setToConcludePaginationEmAndamento } from "../../store/slices/toConcludePaginationEmAndamentoSlice";


import { addPatientCefxAConcluir } from "../../store/slices/patientCefxAConcluirSlice";
import { addPatientCefxConcluidos } from "../../store/slices/patientCefxConcluidosSlice";
import { addPatientCefxEmAndamento } from "../../store/slices/patientCefxEmAndamentoSlice";

import { selectPatientsCefxAConcluir } from "../../store/slices/patientCefxAConcluirSlice";
import { selectPatientCefxConcluidos } from "../../store/slices/patientCefxConcluidosSlice";
import { selectPatientsCefxEmAndamento } from "../../store/slices/patientCefxEmAndamentoSlice";
import { useReloadPatientsList } from "../../hooks/useReloadPatientsLists";

import { selectTempPatientsList } from "../../store/slices/tempPatientListSlice";
import { selectIsCefalometria } from "../../store/slices/isCefalometriaSlice";
import { selectProcesso } from "../../store/slices/processoSlice";
import { selectToConcludeCefx } from "../../store/slices/toConcludeCefxSlice";
import { markConcludeLoaded } from "../../store/slices/loadedConcludesSlices";

export const PatientContext = createContext(null);

export const PatientProvider = ({ children }) => {
  const dispatch = useDispatch();

  const API_URL = process.env.REACT_APP_API_URL;

  const handleReloadList = useReloadPatientsList();

  const { patientsCefxAConcluir } = useSelector(selectPatientsCefxAConcluir);
  const { patientsCefxConcluidos } = useSelector(selectPatientCefxConcluidos);
  const { patientsCefxEmAndamento } = useSelector(
    selectPatientsCefxEmAndamento
  );
  const { paginationAConcluir } = useSelector(selectPaginationCefxAConcluir) || {};
  const { paginationConcluidos } = useSelector(selectPaginationCefxConcluidos) || {};
  const { paginationEmAndamento } = useSelector(selectPaginationCefxEmAndamento) || {};

  const { user } = useSelector(selectUser);
  const { isCefalometria } = useSelector(selectIsCefalometria);
  const { toConcludeCefx } = useSelector(selectToConcludeCefx);
  const { processo } = useSelector(selectProcesso);

  const [patientList, setPatientList] = useState([]);
  const [filteredPatientList, setFilteredPatientList] = useState([]);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [lastPage, setLastPage] = useState(false);
  const [shouldAbort, setShouldAbort] = useState(false);
  const [noPatientsCefx, setNoPatientsCefx] = useState(false);
  const [loadingButtonChecklist, setLoadingButtonCheckList] = useState(false);
  const [loadingFromPaginationPageNextCefx, setLoadingFromPaginationPageNextCefx] = useState(false);
  const [resultProcessos, setResultProcessos] = useState([]);
  const [updateCefxList, setUpdateCefxList] = useState(false);

  const abortControllerRef = useRef(null);

  const reorderedClinics = useRef([]);

  const resetPatientLoading = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    handleReloadList();

    setPatientList([]);
    setShouldAbort(true);
    setFilteredPatientList([]);
  };

  const reactivePatientsLoading = () => {
    if (user && user.length > 0) {
      resetPatientLoading();
    }
  };

  // //Inicio Pacientes CEFX
  const toConcludeRef = useRef(Number(toConcludeCefx));
  const toProcessoRef = useRef(null);

  const cefxPatientIsLoaded = useSelector((state: any) => {
    const loaded = state?.loadedConcludes?.[toConcludeCefx] ?? false;

    let dataExists = false;

    switch (toConcludeCefx) {
      case 0:
        dataExists = patientsCefxAConcluir.length > 0;
        break;
      case 1:
        dataExists = patientsCefxConcluidos.length > 0;
        break;
      case 2:
        dataExists = patientsCefxEmAndamento.length > 0;
        break;
      default:
        break;
    }

    return loaded && dataExists;
  });

  const fetchPatientsForClinic = async (userItem, currentPage, signal) => {
    setNoPatientsCefx(false);

    try {
      const response = await Api.GetPatientListCefx(
        { idClient: userItem.idClient, hash: userItem.hash, page: currentPage },
        { process_id: processo, to_conclude: toConcludeCefx },
        { signal }
      );

      if (response.isSuccess) {
        const { patients, totalPages } = response.data;

        if (patients.length === 0) {
          setNoPatientsCefx(true);
          setUpdateCefxList(false);
        } else {
          setNoPatientsCefx(false);
        }

        const patientsWithIP = patients.map(patient => ({
          ...patient,
          clinic: userItem.clinicalName,
          imagem: `${API_URL}${patient.imagem}`,
        }));

        switch (toConcludeCefx) {
          case 0:
            dispatch(addPatientCefxAConcluir(patientsWithIP));
            dispatch(setToConcludePaginationAConcluir({
              idClient: userItem.idClient,
              currentPage,
              totalPages,
              toConcludeCefx
            }));
            break;
          case 1:
            dispatch(addPatientCefxConcluidos(patientsWithIP));
            dispatch(setToConcludePaginationConcluidos({
              idClient: userItem.idClient,
              currentPage,
              totalPages,
              toConcludeCefx
            }));
            break;
          case 2:
            dispatch(addPatientCefxEmAndamento(patientsWithIP));
            dispatch(setToConcludePaginationEmAndamento({
              idClient: userItem.idClient,
              currentPage,
              totalPages,
              toConcludeCefx
            }));
            break;
          default:
            break;
        }

        return response.data;
      } else {
        console.error("A requisição falhou:", response.errorMessage);
        return null;
      }
    } catch (error) {
      console.error('Erro ao carregar pacientes Cefx:', error);
      return null;
    } finally {
      setUpdateCefxList(false);
      setLoadingButtonCheckList(false);
    }
  };


  const fetchFirstPageForAllClinics = async () => {
    setLoadingButtonCheckList(true);

    try {
      const controller = new AbortController();
      const { signal } = controller;
      abortControllerRef.current = controller;

      const promises = user.map((userItem: any) => fetchPatientsForClinic(userItem, 0, signal));
      await Promise.all(promises);

      dispatch(markConcludeLoaded(toConcludeCefx));
    } catch (error) {
      //console.error('Erro ao carregar a primeira página dos pacientes:', error);
    } finally {
      setLoadingButtonCheckList(false);
    }
  };

  const fetchNextPage = async () => {
    setLoadingFromPaginationPageNextCefx(true);

    const controller = new AbortController();
    const { signal } = controller;
    abortControllerRef.current = controller;

    const promises = user.map(userItem => {
      let pagination;
      switch (toConcludeCefx) {
        case 0:
          pagination = paginationAConcluir[userItem.idClient];
          break;
        case 1:
          pagination = paginationConcluidos[userItem.idClient];
          break;
        case 2:
          pagination = paginationEmAndamento[userItem.idClient];
          break;
        default:
          pagination = { currentPage: 0, totalPages: 1 };
          break;
      }

      if (!pagination || pagination.currentPage >= pagination.totalPages) {
        return null;
      }

      const nextPage = pagination.currentPage + 1;

      return fetchPatientsForClinic(userItem, nextPage, signal);
    });

    await Promise.all(promises.filter(promise => promise !== null));

    setLoadingFromPaginationPageNextCefx(false);
  };

  useEffect(() => {
    if (lastPage && isCefalometria) {
      fetchNextPage();
    }
  }, [lastPage]);

  useEffect(() => {
    if (isCefalometria && !cefxPatientIsLoaded) {
      fetchFirstPageForAllClinics();
    }
  }, [isCefalometria, toConcludeCefx, processo]);

  useEffect(() => {
    if (updateCefxList) {
      fetchFirstPageForAllClinics();
    }
  }, [updateCefxList])

  useEffect(() => {
    if (isCefalometria) {
      const fetchDataProcess = async () => {
        try {
          for (const currentUser of user) {
            const { idClient } = currentUser;
            const processos = await Api.GetProcess(idClient);

            if (processos.isSuccess) {
              const processosAtualizados = [{ id: -1, name: "Todos" }, ...processos.data];

              const processosOrdenados = processosAtualizados.slice(1).sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
              });

              processosOrdenados.unshift(processosAtualizados[0]);

              setResultProcessos(processosOrdenados);
            }
          }
        } catch (error) {
          //console.error(error);
        }
      };
      fetchDataProcess();

    }
  }, []);

  return (
    <PatientContext.Provider
      value={{
        patientList,
        setPatientList,
        filteredPatientList,
        setFilteredPatientList,
        loadingNextPage,
        setLoadingNextPage,
        lastPage,
        setLastPage,
        resetPatientLoading,
        reactivePatientsLoading,
        loadingButtonChecklist,
        setLoadingButtonCheckList,
        noPatientsCefx,
        setNoPatientsCefx,
        resultProcessos,
        setUpdateCefxList,
        updateCefxList,
        reorderedClinics,
        loadingFromPaginationPageNextCefx
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};
