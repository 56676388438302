import { useState } from 'react';
import { ImageComponent } from '../Imagens2D/ImageComponent';
import styles from './styles.module.css'

interface View3DProps {
    oViewer: any;
    theSelectImage?: string;
    imageParams: string;
    pacientAllImagens3d: any[];
    isMobile?: boolean;
    handleChanceTheSelectedImage: (urlImage: string) => void;
    handleCollapse: () => void;
}

export function View3D({
    oViewer,
    theSelectImage,
    imageParams,
    pacientAllImagens3d,
    isMobile,
    handleCollapse,
    handleChanceTheSelectedImage }: View3DProps) {

    const [selectedImages, setSelectedImages] = useState<string[]>([imageParams]);

    const handleImageClick = (imageLink: string) => {
        setSelectedImages((prevSelected) =>
            prevSelected.includes(imageLink)
                ? prevSelected?.filter((link) => link !== imageLink)
                : [...prevSelected, imageLink]
        );
    };

    return (
        <div>
            <p className={styles.title}> Visualizador 3D</p>
            {
                pacientAllImagens3d?.length !== 0 ? (
                    <div className={styles.containerImages}>
                        <div className={styles.containerImagens}>
                            {pacientAllImagens3d.map((image: any, index: number) => (
                                <div
                                    key={index}
                                    onClick={() => isMobile && handleCollapse()}
                                >
                                    <ImageComponent
                                        key={index}
                                        image={image}
                                        theSelectImage={theSelectImage}
                                        handleImageClick={handleImageClick}
                                        handleChanceTheSelectedImage={
                                            handleChanceTheSelectedImage
                                        }
                                        imageParams={imageParams}
                                        oViewer={oViewer}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )
                    :
                    <div className={styles.containerNoResults}>
                        <span>Sem resultado</span>
                    </div>
            }
        </div>
    )
}