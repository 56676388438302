import { Link, useLocation } from "react-router-dom";
import { cloneElement, ReactElement, useState, useEffect } from "react";

interface ActiveLinkProps {
  children: ReactElement;
  shouldMatchExactHref?: boolean;
  href: string;
}

export function ActiveLink({
  href,
  children,
  shouldMatchExactHref = false,
  ...rest
}: ActiveLinkProps) {
  const asPath = window.location.pathname;

  const location = useLocation();
  const [ isActive, setIsActive ] = useState(false);

  useEffect(() => {
    setIsActive(location.pathname === `/dashboard/${href}`);
  }, [location.pathname, href]);

  const clonedElement = cloneElement(children, {
    style: isActive ? {} : {},
    ...children.props,
  });

  function handleScrollTop() {
    localStorage.setItem('scrollValue', '0');
  }

  return (
    <Link
      style={
        isActive
          ? {
              position: "relative",
              color: "#298e79",
              textDecoration: "none",
              fontWeight: "600",
            }
          : {
              position: "relative",
              color: "#57667A",
              textDecoration: "none",
            }
      }
      to={href}
      {...rest}
      onClick={() => handleScrollTop()}
    >
      {clonedElement}
    </Link>
  );
}
