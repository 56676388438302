import { useEffect, useState, useCallback, useContext } from "react";

import { Button } from "@mui/material";
import Logoevol from '../../assets/logo-evol.jpeg';

import styles from "./styles.module.css";

import {
  PlusIcon
} from "@heroicons/react/24/outline";

import { BoxVideo } from "./components/BoxVideo";
import { Cards } from "./components/Cards";
import Api from "../../helpers/Api";
import { LoadingClinics } from "../../components/LoadingClinics";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";
import { selectClinic, setClinic } from "../../store/slices/clinicSlice";
import { ModalAddClinics } from "./newClinic/Modals/ModalAddClinic";
import { NoClass } from "./components/NoClass";
import { PatientContext } from "../PatientList/PatientProvider";
import { Footer } from "../../components/Footer";

export function Clinic() {

  const [isModalAddIndicatorsOpen, setModalAddIndicatorsOpen] = useState(false);
  const [isModalEvol, setIsModalEvol] = useState(false)
  const [updateClinicsData, setUpdateClinicsData] = useState<number>(0);
  const [fetchClinics, setFetchClinics] = useState(true);
  const [updateLinkedClinics, setUpdateLinkedClinics] = useState(false);

  const dispatch = useDispatch();
  const { clinic } = useSelector(selectClinic);
  const { user } = useSelector(selectUser);
  const { stopPatientLoading } = useContext(PatientContext);
  const [loadingGetClinicLinked, setLoadingGetClinicLinked] = useState(true);

  const handleOpenModalAddIndicators = (evol?: string) => {
    if (evol !== undefined) {
      setIsModalEvol(true)
    } else {
      setIsModalEvol(false)
    }
    setModalAddIndicatorsOpen(true);
  };

  const handleCloseModalAddIndicators = () => {
    setModalAddIndicatorsOpen(false);
    setIsModalEvol(false)
  };

  const fetchClinicsData = useCallback(async () => {
    if (user && user.length > 0) {
      setLoadingGetClinicLinked(true);
      try {
        const clinicRequests = user.map(async (userData) => {
          const idUser = userData.idUser;
          const result = await Api.getClinics(idUser);
          return result.data;
        });

        const clinicsArray = await Promise.all(clinicRequests);
        const allClinics = clinicsArray.flat();

        const uniqueClinics = allClinics.filter((clinic, index, self) =>
          index === self.findIndex((c) => c.idUser === clinic.idUser && c.clinicalName === clinic.clinicalName && c.hash === clinic.hash)
        );

        for (const item of uniqueClinics) {
          const clienteId = item.idClient;

          const getIP = await Api.GetIP(clienteId);

          item.ip = getIP;
        }

        dispatch(setClinic(uniqueClinics));
      } catch (error) {
        console.error(error);
      } finally {
        setFetchClinics(false);
        setUpdateLinkedClinics(false);
        setTimeout(() => {
          setLoadingGetClinicLinked(false);
        }, 1000)
      }
    }
  }, [user, dispatch, setFetchClinics, updateLinkedClinics]);

  useEffect(() => {
    fetchClinicsData();
  }, [updateClinicsData]);

  const fetchClinicsDatas = () => {
    fetchClinicsData();
  };

  return (
    <div className={styles.outerContainer}>
      {user && user.length > 0 && user[0].roles === "NAOAUTENTICADO" ?
        <NoClass
          text1={"Conta não cadastrada"}
          text2={"Clique em  Criar conta para fazer o cadastro"}
          fullScreen
        />
        :
        <div className={styles.cardsContainer}>
          <div className={styles.containerSelect}>
            <div className={styles.inputsContainer}>
              <div className={styles.searchContainer}>

              </div>
              <div className={styles.createBtnContainer}>
                <Button
                  className={`${styles.createBtn} ${styles.buttonEvolCloud}`}
                  onClick={() => handleOpenModalAddIndicators('evol')}
                >
                  <img src={Logoevol} alt="e-Vol" />

                  <PlusIcon className={styles.plusIcon} width={16} height={16} />
                  <div className={styles.createBtnContainerdisplay}>
                    eVolCloud
                  </div>
                </Button>

                <Button
                  className={`${styles.createBtn} ${styles.button}`}
                  onClick={() => handleOpenModalAddIndicators()}
                >
                  <PlusIcon className={styles.plusIcon} width={16} height={16} />
                  <div className={styles.createBtnContainerdisplay}>
                    {" "}
                    Vincular clínica
                  </div>
                </Button>
              </div>
            </div>
            {
              loadingGetClinicLinked ? (
                <LoadingClinics />
              ) : (!clinic && !loadingGetClinicLinked) || clinic.length == 0 ? (
                <div className={styles.textNoClinic}>
                  <p>
                    Nenhuma clínica vinculada.
                  </p>
                </div>
              ) : clinic.length !== 0 ? (
                clinic.map((clinics: any, index: number) => (
                  <Cards
                    id={clinics.id}
                    key={clinics.id}
                    address={clinics.address}
                    logon={clinics.logon}
                    city={clinics.city}
                    clinicalName={clinics.clinicalName}
                    neighborhood={clinics.neighborhood}
                    idClient={clinics.idClient}
                    styles={styles}
                    deleteCallback={fetchClinicsDatas}
                  />
                ))
              ) : (
                null
              )
            }
          </div>

          <div className={styles.containerBox}>
            <BoxVideo />
          </div>
        </div>
      }

      <div className={styles.containerFooter}>
        <Footer />
      </div>

      {isModalAddIndicatorsOpen && (
        <ModalAddClinics
          isModalEvol={isModalEvol}
          isOpen={isModalAddIndicatorsOpen}
          onRequestClose={handleCloseModalAddIndicators}
          setUpdateClinicsData={setUpdateClinicsData}
          setUpdateLinkedClinics={setUpdateLinkedClinics}
        />
      )}
    </div>
  );
}
