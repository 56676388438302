import { useEffect, useState } from 'react';
import Switch from "react-switch";
import { toast } from 'react-hot-toast';
import { ArrowPathIcon } from "@heroicons/react/24/outline";

import styles from './styles.module.css'
import { useOpcoes } from '../../OpcoesContext';

interface ImagensProps {
    ocefx: any;
}

export function Imagens({ ocefx }: ImagensProps) {
    const { imagensCefx, setImagensCefx, brightness, handleBrightnessChange, contrast, handleContrastChange, swtichImage, setSwitchImage, isSincronizarImagens, setIsSincronizarImagens } = useOpcoes();

    const [transpFusao, setTranspFusao] = useState(50);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleTranspFusao = (event) => {
        const newTranspFusao = event.target.value;
        ocefx.setTranspFusao(newTranspFusao)
        setTranspFusao(newTranspFusao);
    };

    const handleCheckboxClick = (index) => {
        if (selectedIndex !== index) {
            setSelectedIndex(index);
        }
    };

    const handleChangeImagemSwitch = () => {
        setSwitchImage(!swtichImage);
        ocefx.ligaImagem(!swtichImage);
    };

    const handleSincronizarImagens = () => {
        setImagensCefx([]);
        ocefx.reDownload();
        var lst = ocefx.getList('imagens');
        setImagensCefx(lst);
        toast.success('Imagens sincronizado com sucesso!');
    };

    return (
        <div className={styles.containerImagensCefx}>
            <p>Imagens</p>

            <div className={styles.containerImagescefx}>
                {imagensCefx.map((imagem, index) => (
                    <div key={index}>
                        <input
                            className={styles.inputCheckbox}
                            type="checkbox"
                            checked={selectedIndex === index}
                            onChange={() => { }}
                        />
                        <img src={`${ocefx.estado._servCefx}${imagem.src}`} alt={`IMG-${imagem.alvo}-${imagem.id}`} />
                        <div
                            className={styles.linearGradient}
                            onClick={() => {
                                ocefx.selecionaAlvo(imagem.alvo, imagem.id);
                                handleCheckboxClick(index);
                            }}
                        >
                            <span>
                                {`IMG-${imagem.alvo}-${imagem.id}`}
                            </span>
                        </div>
                    </div>
                ))}
            </div>

            <div className={styles.containerSettings}>
                <p>Configurações de visualização</p>

                <div className={styles.containerButtonSicronizarImagens}>
                    <button
                        type='button'
                        onClick={() => handleSincronizarImagens()}
                    >
                        Sincronizar imagens
                    </button>
                </div>

                <div className={styles.containerOnOff}>
                    <div>
                        <Switch
                            onChange={handleChangeImagemSwitch}
                            checked={swtichImage}
                            className="react-switch"
                            onColor="#007E69"
                            height={22}
                            width={44}
                        />
                        <strong>Mostrar Imagem</strong>
                    </div>
                </div>

                <div className={styles.containerInputsRange}>
                    <div className={styles.containerInputOpcoesBasicas}>
                        <span>
                            Transparência - Fusão
                        </span>
                        <input
                            type="range"
                            min={0}
                            max={100}
                            value={transpFusao}
                            onChange={handleTranspFusao}
                        />
                        <div>
                            <span>
                                0
                            </span>
                            <span>
                                <span>{transpFusao}</span>
                            </span>
                        </div>
                    </div>

                    <div className={styles.containerInputOpcoesBasicas}>
                        <span>
                            Brilho
                        </span>
                        <input
                            type="range"
                            min={0}
                            max={200}
                            value={brightness}
                            onChange={handleBrightnessChange}
                        />
                        <div>
                            <span>
                                0
                            </span>
                            <span>
                                {brightness}
                            </span>
                        </div>
                    </div>

                    <div className={styles.containerInputOpcoesBasicas}>
                        <span>
                            Contraste
                        </span>
                        <input
                            type="range"
                            min={0}
                            max={100}
                            value={contrast}
                            onChange={handleContrastChange}
                        />
                        <div>
                            <span>
                                0
                            </span>
                            <span>
                                {contrast}
                            </span>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}