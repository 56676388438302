import ImagemAgenda from "../Images/Agenda.png";
import { Paywall } from "../Components";
export function Diary() {
  const paywall = [
    {
      id: 1,
      videourl:
        "https://player-vz-fb154304-6eb.tv.pandavideo.com.br/embed/?v=abe53165-8286-4608-b76c-9d07c8f75310",
      title:
        "Gestão eficiente de agendas: simplifique os atendimentos com a agenda inteligente da Serodonto.",
      link: "https://api.whatsapp.com/send?phone=5512981507852&text=Estou%20interessado%20nas%20ferramentas%20de%20agenda%20do%20Serodonto!",
      subtitles: [
        {
          subtitle:
            "A Serodonto oferece uma solução completa para a gestão de agendas, evitando atrasos, cancelamentos e duplicidade de horários.",
        },
        {
          subtitle:
            "O sistema gera automaticamente agendas individuais para cada dentista e otimiza a distribuição dos horários, realizando encaixes de forma automatizada.",
        },
        {
          subtitle:
            "A tecnologia identifica lacunas de horários disponíveis e dispara alertas, garantindo o máximo aproveitamento do tempo dos profissionais.",
        },
        {
          subtitle:
            "A Serodonto integra-se com o WhatsApp, enviando lembretes automáticos de agendamento e confirmação aos pacientes por mensagem.",
        },
        {
          subtitle:
            "Com a agenda inteligente, é possível prevenir atrasos, melhorar a eficiência da clínica, aumentar a satisfação dos pacientes e melhorar a reputação do consultório.",
        },
      ],
    },
  ];

  return (
    <>
      {paywall.map((item, index) => {
        return (
          <Paywall
            key={index}
            title={item.title}
            subtitles={item.subtitles}
            videourl={item.videourl}
            link={item.link}
            backgroundImage={ImagemAgenda}
          />
        );
      })}
      <></>
    </>
  );
}
