import { combineReducers, createStore, applyMiddleware } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';

import userReducer from "./slices/userSlice";
import patientsWithImagesReducer from './slices/patientsWithImagesSlice';
import patientsReducer from "./slices/patientListSlice";
import patientsCefxAConcluirReducer from "./slices/patientCefxAConcluirSlice";
import toConcludePaginationAConcluirReducer from "./slices/toConcludePaginationAConcluirSlice";
import patientsCefxConcluidosReducer from "./slices/patientCefxConcluidosSlice";
import toConcludePaginationConcluidosReducer from "./slices/toConcludePaginationConcluidosSlice";
import patientsCefxEmAndamentoReducer from "./slices/patientCefxEmAndamentoSlice"
import toConcludePaginationEmAndamentoReducer from "./slices/toConcludePaginationEmAndamentoSlice"
import tempPatientReducer from "./slices/tempPatientListSlice";
import isCefalometriaReducer from './slices/isCefalometriaSlice';
import loadedConludesReducer from './slices/loadedConcludesSlices';
import toConcludeCefxReducer from './slices/toConcludeCefxSlice';
import processoReducer from './slices/processoSlice';
import researchPatientReducer from "./slices/researchPatientSlice";
import clinicReducer from "./slices/clinicSlice";
import indicatorReducer from "./slices/indicatorListSlice";
import sharedReducer from "./slices/sharedSlice";
import isClinicConnectionsCompleteReducer from "./slices/isClinicConnectionsCompleteSlice";
import headerLogoReducer from "./slices/headerLogoSlice";

const rootReducers = combineReducers({
  isCefalometria: isCefalometriaReducer,
  loadedConcludes: loadedConludesReducer,
  toConcludeCefx: toConcludeCefxReducer,
  processo: processoReducer,
  user: userReducer,
  patientsWithImages: patientsWithImagesReducer,
  patients: patientsReducer,
  patientsCefxAConcluir: patientsCefxAConcluirReducer,
  paginationAConcluir: toConcludePaginationAConcluirReducer,
  patientsCefxConcluidos: patientsCefxConcluidosReducer,
  paginationConcluidos: toConcludePaginationConcluidosReducer,
  patientsCefxEmAndamento: patientsCefxEmAndamentoReducer,
  paginationEmAndamento: toConcludePaginationEmAndamentoReducer,
  tempPatients: tempPatientReducer,
  researchPatient: researchPatientReducer,
  clinic: clinicReducer,
  indicator: indicatorReducer,
  shared: sharedReducer,
  isClinicConnectionsComplete: isClinicConnectionsCompleteReducer,
  headerLogo: headerLogoReducer,
});

const persistConfig = {
  key: "root",
  storage,
  version: 2.1,
  stateReconciler: autoMergeLevel2,
  migrate: (state) => {
    if (state && state._persist && state._persist.version === 1) {
      return Promise.resolve(state);
    }
    return Promise.resolve(state || {});
  },

};

const persistedReducer = persistReducer(persistConfig, rootReducers);

const middlewares = [];

export const store = createStore(
  persistedReducer,
  applyMiddleware(...middlewares)
);

export const persistor = persistStore(store);
