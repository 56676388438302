import { createSlice } from "@reduxjs/toolkit";

interface LoadedConcludesState {
    [key: number]: boolean;
}

const initialState: LoadedConcludesState = {};

export const loadedConcludesSlices = createSlice({
    name: "loadedConcludes",
    initialState,
    reducers: {
        markConcludeLoaded: (state, action) => {
            state[action.payload] = true;
        },
        resetConcludeLoaded: (state, action) => {
            state[action.payload] = false;
        },
        clearAllConcludesLoaded: () => {
            return {};
        },
    },
});

export const { markConcludeLoaded, resetConcludeLoaded, clearAllConcludesLoaded } = loadedConcludesSlices.actions;

export default loadedConcludesSlices.reducer;