import { Field } from "formik";
import { InputHTMLAttributes } from "react";

import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";

import styles from "./styles.module.css";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  type?: string;
  handleDeleteItems?: () => void;
  search?: number;
}

export function InputSearch(props: InputProps) {
  return (
    <div className={styles.wrapper_input}>
      <MagnifyingGlassIcon width={16} />

      <Field {...props} type={props.type} className={styles.input} />

      <span className={styles.buttonDeleteSearch}
        style={{
          display: props.search != 0 ? 'block' : 'none'
        }}
      >
        <XMarkIcon width={16} onClick={props.handleDeleteItems} />
      </span>
    </div>
  );
}
