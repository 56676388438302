import { Field } from 'formik'
import styles from './styles.module.css'
import { CSSProperties } from 'react';

interface CheckboxTermosDeUsoProps {
  name: string;
  error?: any;
  customStyle?: CSSProperties;
}

export function CheckboxTermosDeUso({ name, error, customStyle }: CheckboxTermosDeUsoProps) {
  return (
    <div className={styles.checkbox_container}>
      <div className={styles.checkbox_variants}>
        <Field
          className="mr-2"
          name={name}
          type="checkbox"
          style={customStyle}
        />

        <label
          htmlFor="checkbox"
          id="checkbox"
          className={styles.text_checkbox}
        >
          Aceitar os Termos de Uso e Políticas de Privacidade
        </label>
      </div>
      <div className={styles.containerError}>
        <span className={styles.error}>{error}</span>
      </div>
    </div>
  )
}

