import { createContext, useContext, useState } from 'react'

const CheckListContext = createContext();

export const CheckListProvider = ({ children }) => {
    const [checkListActive, setCheckListActive] = useState('checklistAConcluir');

    const handleTabClick = (tabName) => {
        if (tabName !== checkListActive) {
            setCheckListActive(tabName);
        };
    };

    return (
        <CheckListContext.Provider value={{
            checkListActive,
            handleTabClick
        }}>
            {children}
        </CheckListContext.Provider>
    )
};

export const useCheckList = () => useContext(CheckListContext);