import { useRef } from "react";
import { DocumentArrowUpIcon } from "@heroicons/react/24/outline";
import { Form, Field } from "formik";

import { Button } from "@cdt-ui/react";

import styles from "./styles.module.css";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

interface OccupationArea {
  totalPages: number;
  currentPage: number;
  exams: Array<{
    id: number;
    convenio: string;
    servico: string;
  }>;
}

export function ModalFormaddIndicators({ utils }) {
  const { errors, getOccupationArea, occupationAreas, onRequestClose } = utils;

  // Create a set to store unique exam values
  const uniqueExams = new Set();

  const areasAtuacaoInputRef = useRef(null);
  const daysInputRef = useRef(null);

  const onFocus = (inputRef) => {
    if (inputRef) {
      inputRef.current.focus();
    }
  };

  return (
    <Form>
      <div className={styles.inputsContainer}>
        <Field
          name="specName"
          type="text"
          placeholder="Título do Indicador"
          className={styles.input}
          errors={errors.specName}
        />
        {errors.specName ? (
          <p className={styles.errorMsg}>{errors.specName}</p>
        ) : (
          <></>
        )}
        <div className={`${styles.selectionContainer} `}>
          <label htmlFor="areasAtuacao" className={styles.label}>
            Exame(s)
          </label>
          <ChevronDownIcon
            width={16}
            style={{ position: "absolute", right: "16px", top: "39%" }}
            onClick={() => onFocus(areasAtuacaoInputRef)}
          />
          <Field
            name="areasAtuacao"
            type="text"
            placeholder="Nome do Especialista"
            className={styles.input}
            errors={errors.areasAtuacao}
            onFocus={getOccupationArea}
            as="select"
            style={{
              appearance: "none",
            }}
            innerRef={areasAtuacaoInputRef}
          >
            <option value="">Selecione uma opção</option>
            {occupationAreas &&
              Array.isArray(occupationAreas.exams) &&
              occupationAreas.exams.map(({ id, servico }) => {
                if (!uniqueExams.has(servico)) {
                  uniqueExams.add(servico); // Add the value to the set
                  return (
                    <option key={id} value={servico}>
                      {servico}
                    </option>
                  );
                }
                return null; // Skip duplicates
              })}
          </Field>
        </div>
        <div className={`${styles.selectionContainer} `}>
          <label htmlFor="days" className={styles.label}>
            Última consulta
          </label>
          <ChevronDownIcon
            width={16}
            style={{ position: "absolute", right: "16px", top: "39%" }}
            onClick={() => onFocus(daysInputRef)}
          /> 
          <Field
            name="days"
            type="text"
            placeholder="Nome do Especialista"
            className={styles.input}
            errors={errors.days}
            onFocus={getOccupationArea}
            as="select"
            style={{
              appearance: "none",
            }}
            innerRef={daysInputRef}
          >
            <option value="30">1 mês</option>
            <option value="90">3 meses</option>
            <option value="180">6 meses</option>
            <option value="365">1 ano</option>
          </Field>
        </div>
      </div>
      <div className={styles.btnContainer}>
        <Button
          variant="tertiary"
          className={styles.btn}
          onClick={() => onRequestClose("close")}
          type="button"
        >
          Cancelar
        </Button>
        <Button variant="greenDark" className={styles.btn} type="submit">
          Continuar
        </Button>
      </div>
    </Form>
  );
}
