import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
/* import { TableBody } from "./TableBody"; */

import styles from "./styles.module.css";
import { useState } from "react";
import { TableBody } from "./TableBody";
import { Checkbox } from "../components/Checkbox";
import { useMediaQuery } from "react-responsive";

interface TableProps {
  amount: number;
  examsRequest: any[];
  currentData: any[];
  updateExamsRequest: (data: any[]) => void;
  isSelected: (id: number) => boolean;
  handleChangeExamsRequest: (id: number) => void;
  isAllSelected: () => boolean;
  selectAllExamsRequest: () => void;
  handleConfirmDeleteUnique: (id: number) => void;
  handleConfirmDeleteSelecteds: () => void;
}

export function Table({
  amount,
  examsRequest,
  currentData,
  updateExamsRequest,
  isSelected,
  handleChangeExamsRequest,
  isAllSelected,
  selectAllExamsRequest,
  handleConfirmDeleteUnique,
  handleConfirmDeleteSelecteds,
}: TableProps) {
  const arrowSize = 12;

  const [arrowState, setArrowState] = useState({
    name: "up",
    situation: "up",
    dentist: "up",
    createdDate: "up",
  });

  const reorder = (type, prop) => {
    const listToSort = [...examsRequest];
    const reordered = listToSort.sort((a, b) => {
      let comparison = 0;
      if (typeof a[prop] === "string") {
        comparison = a[prop].localeCompare(b[prop]);
      } else {
        comparison = a[prop] - b[prop];
      }
      return type === "up" ? comparison : -comparison;
    });
    return reordered;
  };

  const handleReorder = (type, prop) => {
    let reordered;

    if (prop === "createdDate") {
      if (type === "up") {
        reordered = reorder("up", prop);
        setArrowState({ ...arrowState, [prop]: "up" });
      } else {
        reordered = reorder("down", prop);
        setArrowState({ ...arrowState, [prop]: "down" });
      }
    } else {
      reordered = reorder(type, prop);
      setArrowState({ ...arrowState, [prop]: type });
    }

    updateExamsRequest(reordered);
  };

  return (
    <section className={styles.container}>
      <div className={styles.container_amountOfCustomers}>
        <p>{amount} encontrados</p>
      </div>

      <div className={styles.container_table}>
        <table>
          <thead>
            <tr>
              <th>
                <Checkbox
                  inputId="exam-request-all"
                  isSelected={isAllSelected}
                  handleChange={selectAllExamsRequest}
                  add={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </th>
              <th>
                <div className={styles.thContent}>
                  Título
                  <span className={styles.Default}>
                    {arrowState.name === "up" ? (
                      <ChevronUpIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("down", "name")}
                      />
                    ) : (
                      <ChevronDownIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("up", "name")}
                      />
                    )}
                  </span>
                </div>
              </th>
              {/*               <th>
                 <div>
                  Situação
                  <span className={styles.upDownIconsContainer}>
                    {arrowState.situation === "up" ? (
                      <ChevronUpIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("down", "situation")}
                      />
                    ) : (
                      <ChevronDownIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("up", "situation")}
                      />
                    )}
                  </span>
                </div>
              </th> */}
              <th>
                <div>
                  Dentista
                  <span className={styles.upDownIconsContainer}>
                    {arrowState.dentist === "up" ? (
                      <ChevronUpIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("down", "dentist")}
                      />
                    ) : (
                      <ChevronDownIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("up", "dentist")}
                      />
                    )}
                  </span>
                </div>
              </th>
              <th>
                <div>
                  Data do envio
                  <span className={styles.upDownIconsContainer}>
                    {arrowState.createdDate === "up" ? (
                      <ChevronUpIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("down", "createdDate")}
                      />
                    ) : (
                      <ChevronDownIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("up", "createdDate")}
                      />
                    )}
                  </span>
                </div>
              </th>
              {/* <th>
                {!useMediaQuery({query: '(max-width: 768px)'}) && "Editar"}
              </th> */}
              <th>
              {!useMediaQuery({query: '(max-width: 768px)'}) && "Desativar"}
              </th>
            </tr>
          </thead>
          {currentData.length > 0 ? (
            currentData.map((request) => (
              <TableBody
                bodyContents={request}
                isSelected={isSelected}
                handleChangeExamsRequest={handleChangeExamsRequest}
                handleConfirmDeleteUnique={handleConfirmDeleteUnique}
                handleConfirmDeleteSelecteds={handleConfirmDeleteSelecteds}
              />
            ))
          ) : (
            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              Não encontrado
            </p>
          )}
        </table>
      </div>
    </section>
  );
}
