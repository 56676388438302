import { useEffect, useState, useRef } from "react";
import { Input } from "../../../components/Form/Input";
import { Formik, Form, FormikValues } from "formik";
import { Button } from "@cdt-ui/react";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { ArrowRightIcon, XMarkIcon } from "@heroicons/react/24/outline";

import styles from "./styles.module.css";

import Api from "../../../helpers/Api";
import { selectUser } from "../../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Step3 } from "../Step3";

interface Step1Props {
  handleToJump: () => void;
  getPayload?: (values: any) => void;
  userDetailsStep1: any;
  payloadStep1: any;
}

export function Step1({
  handleToJump,
  getPayload,
  userDetailsStep1,
  payloadStep1
}: Step1Props) {
  const formRef = useRef<any>();
  const [cpfLoading, setCpfLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [cpfExists, setCpfExists] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const { user } = useSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    formRef.current.setFieldValue('cpf', payloadStep1.cpf);
    formRef.current.setFieldValue("nomePessoal", payloadStep1.nomePessoal);
    formRef.current.setFieldValue("dataNascimento", payloadStep1.dataNascimento);
    formRef.current.setFieldValue("user_name_field", payloadStep1.user_name_field);
    formRef.current.setFieldValue("senha", payloadStep1.senha);
  }, [payloadStep1]);

  const validationSchema = yup.object().shape({
    nomePessoal: yup.string().required("Campo obrigatório"),
    cpf: yup.string().required("Campo obrigatório"),
    dataNascimento: yup.string().required("Campo obrigatório"),
    user_name_field: yup.string().required("Campo obrigatório"),
    senha: yup
      .string()
      .min(4, ({ min }) => `A senha deve ter ${min} caracteres`)
      .max(80, ({ max }) => `A senha deve ter ${max} caracteres`)
      .required("Campo obrigatório"),
  });

  const handleSubmit = async (values: FormikValues) => {
    if (values) {
      getPayload(values);
    }
  };

  async function onBlurCpf(event?: any) {
    setCpfLoading(true);
    const { value } = event?.target;

    const cpf = value.replace("-", "").replace(".", "").replace(".", "");

    if (cpf?.length !== 11) {
      setCpfLoading(false);
      return;
    }

    await Api.VerifyIfCpfExists({ cpf })
      .then((result) => {
        if (result.message == "CPF já cadastrado.") {
          toast(result.message, {
            style: {
              background: "#f09500",
              color: "#fff",
            },
          });
          setCpfExists(true);
        } else {
          setCpfLoading(false);
          setCpfExists(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setCpfLoading(false);
      });
    setCpfLoading(false);
    return;
  }

  async function onBlurLogin(event?: any) {
    setLoginLoading(true);
    const { value } = event?.target;
    const login = value;

    if (!login) {
      setLoginLoading(false);
      return;
    } else {
      await Api.VerifyLoginExists({ login })
        .then((result) => {
          if (result.message == "Login já cadastrado.") {
            toast("Usuário já existente.", {
              style: {
                background: "#f09500",
                color: "#fff",
              },
            });
            setUserExists(true);
          } else {
            setLoginLoading(false);
            setUserExists(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoginLoading(false);
        });
    }
    setLoginLoading(false);
  }

  const handleChangecpf = (event: any, setFieldValue: any) => {
    let { value } = event.target;

    value = value.replace(/\D/g, "");

    if (value.length >= 14) {
      value = value.slice(0, 14);
    }

    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else {
      value = value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    }
    setInputValue(value);
    setFieldValue("cpf", value);
  };

  /*   useEffect(() => {
      formRef.current.setFieldValue("nomePessoal", userDetailsStep1?.nome);
      formRef.current.setFieldValue("logon", userDetailsStep1?.email);
      formRef.current.setFieldValue("cpf", userDetailsStep1?.documento);
    }, [userDetailsStep1]); */

  return (
    <div className={styles.containerStep1}>
      <div className={styles.headerStep1}>
        <div>
          <p style={{ color: "#008F79", fontWeight: "700", marginBottom: 5 }}>
            1. Dados Pessoais
          </p>
          <div
            style={{
              height: 6,
              backgroundColor: "#008F79",
              borderRadius: 20,
            }}
            className={styles.bar}
          ></div>
        </div>

        <div>
          <p style={{ color: "#D3D3D3", fontWeight: "600", marginBottom: 5 }}>
            2. Endereço e Contato
          </p>
          <div
            style={{
              height: 6,
              backgroundColor: "#E4E4E4",
              borderRadius: 20,
            }}
            className={styles.bar}
          ></div>
        </div>
      </div>

      <div className={styles.formStep1}>
        <p
          style={{
            color: "#7B889C",
            fontWeight: "700",
            marginBottom: 16,
            fontSize: 14
          }}
        >
          DADOS PESSOAIS
        </p>

        <div className={styles.inputsStep1}>
          <Formik
            initialValues={{
              nomePessoal: "",
              cpf: "",
              dataNascimento: "",
              user_name_field: "",
              senha: "",
            }}
            innerRef={formRef}
            onSubmit={(values) => handleSubmit(values)}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
          >
            {({
              errors,
              isSubmitting,
              values,
              setFieldValue,
              handleChange,
            }) => (
              <Form autoComplete="off">
                <Input
                  name="nomePessoal"
                  type="text"
                  title="Nome Profissional"
                  placeholder="Como será chamado pela clínica (Ex: Dr. Anna...)"
                  error={errors.nomePessoal}
                />

                <div
                  style={{ marginTop: "24px" }}
                  className={styles.containerInput}
                >
                  <div className={styles.containerCpf}>
                    <Input
                      disabled={cpfLoading}
                      type="text"
                      onBlur={(ev) => onBlurCpf(ev)}
                      title="CPF/CNPJ"
                      name="cpf"
                      value={values.cpf}
                      onChange={(event) =>
                        handleChangecpf(event, setFieldValue)
                      }
                      placeholder="000.000.000-00"
                      labelStyleAbsolute
                      error={errors.cpf || (cpfExists && "CPF já cadastrado.")}
                    />
                    {cpfLoading && (
                      <div className={styles.container_buttons_loading}>
                        <div className={styles.loading_spinne}></div>
                      </div>
                    )}
                  </div>

                  <Input
                    name="dataNascimento"
                    type="date"
                    title="Data de Nascimento"
                    labelStyleAbsolute
                    error={errors.dataNascimento}
                  />
                </div>

                <div className={styles.borderStyle} />

                <p
                  style={{
                    color: "#7B889C",
                    fontWeight: "700",
                    marginBottom: 10,
                    // marginTop: 16,
                    fontSize: 14
                  }}
                >
                  DADOS DE CADASTRO
                </p>

                <div className={styles.containerUserSenha}>
                  <div className={styles.containerCpf}>
                    <Input
                      disabled={loginLoading}
                      labelStyleAbsolute
                      name="user_name_field"
                      onBlur={(ev) => onBlurLogin(ev)}
                      type="text"
                      title="Nome de usuário"
                      placeholder="Meu usuário"
                      error={
                        errors.user_name_field || (userExists && "usuário já existente.")
                      }
                      autoComplete="off" 
                    />
                    {loginLoading && (
                      <div className={styles.container_buttons_loading}>
                        <div className={styles.loading_spinne}></div>
                      </div>
                    )}
                  </div>

                  <Input
                    name="senha"
                    type="password"
                    title="Senha"
                    placeholder="Digitar senha"
                    error={errors.senha}
                    autoComplete="off" 
                  />
                </div>

                <div className={styles.container_buttons}>
                  <div className={styles.buttonCancelar}>
                    <Button
                      type="button"
                      size="full"
                      variant="tertiary"
                      onClick={() =>
                        user !== false ? navigate("/dashboard") : navigate("/")
                      }
                    >
                      <XMarkIcon widths={20} height={20} />
                      {user !== false ? (
                        <span>Pular</span>
                      ) : (
                        <span>Cancelar</span>
                      )}
                    </Button>
                  </div>

                  <div className={styles.buttonContinuar}>
                    <Button
                      disabled={cpfLoading || loginLoading}
                      type="submit"
                      size="full"
                      variant="greenDark"
                    >
                      <span>Continuar</span>
                      <ArrowRightIcon width={16} />
                    </Button>
                  </div>
                </div>

                {/* {
                  user !== false && (
                    <div className={styles.buttonToJump}>
                      <Button
                        type="button"
                        size="full"
                        variant="primary"
                        onClick={handleToJump}
                      >
                        <p style={{ color: "white", marginRight: 5 }}>Pular</p>

                        <div style={{ position: "absolute", marginLeft: "18%" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 40 16"
                            stroke-width="1.5"
                            stroke="white"
                          >
                            <path
                              strokeLinecap="round"
                              stroke-linejoin="round"
                              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                            />
                          </svg>
                        </div>
                      </Button>
                    </div>
                  )
                } */}
              </Form>
            )}
          </Formik>
        </div>
      </div>

    </div>
  );
}
