import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  patientsCefxEmAndamento: <any>[],
};

export const PatientCefxEmAndamento = createSlice({
  name: "patientsCefxEmAndamento",
  initialState,
  reducers: {
    setpatientCefxEmAndamento: (state, action: PayloadAction<any>) => {
      state.patientsCefxEmAndamento = action.payload;
    },
    addPatientCefxEmAndamento: (state, action: PayloadAction<any[]>) => {
      const newPatients = action.payload.filter(newPatient =>
        !state.patientsCefxEmAndamento.some(existingPatient => existingPatient.id === newPatient.id)
      );
      state.patientsCefxEmAndamento = [...state.patientsCefxEmAndamento, ...newPatients];
    },
    clearPatientCefxEmAndamento: (state) => {
      state.patientsCefxEmAndamento = [];
    },
  },
});

export const { setpatientCefxEmAndamento, addPatientCefxEmAndamento, clearPatientCefxEmAndamento } = PatientCefxEmAndamento.actions;

export const selectPatientsCefxEmAndamento = (state: any) => state.patientsCefxEmAndamento || [];

export default PatientCefxEmAndamento.reducer;
