import { useEffect, useRef, useState } from "react";

import styles from "./styles.module.css";

import { Formik, FormikValues, Field } from "formik";
import * as yup from "yup";
import { Button } from "@cdt-ui/react";
import { useMediaQuery } from "react-responsive";

import Select from "react-select";
import {
  MagnifyingGlassIcon,
  PlusIcon,
  PlayCircleIcon,
} from "@heroicons/react/24/outline";
import { ModalAddIndicators } from "./components/Modals/ModalAddIndicators/ModalAddIndicators";
import { ModalSendEmail } from "./components/Modals/ModalSendEmail";
import { Card } from "./components/Card";
import Api from "../../helpers/Api";
import { selectUser } from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { LoadingClinics } from "../Clinic/components/LoadingClinics";
import { Loading } from "./components/Loading";
import {
  selectIndicatorList,
  setindicator,
} from "../../store/slices/indicatorListSlice";
import { useDispatch } from "react-redux";
export function IndicadoresOportunidade() {

  const formRef = useRef<any>();
  const dispatch = useDispatch();
  const { indicator } = useSelector(selectIndicatorList);

  const [isModalSendEmailOpen, setModalSendEmailOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalAddIndicatorsOpen, setModalAddIndicatorsOpen] = useState(false);

  const { user } = useSelector(selectUser);
  const idClient = user.idClient;

  const optionsLabels = [
    { value: "tomografia", label: "Tomografia" },
    { value: "raiox", label: "RAIO-X" },
    { value: "atm", label: "ATM" },
  ];

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
  });

  const Handlesearch = async (values: FormikValues): Promise<void> => {
    console.log(values);
  };


  const handleOpenModalSendEmail = () => {
    setModalSendEmailOpen(true);
  };

  const handleCloseModalSendEmail = () => {
    setModalSendEmailOpen(false);
  };

  const handleOpenModalAddIndicators = () => {
    setModalAddIndicatorsOpen(true);
  };

  const handleCloseModalAddIndicators = () => {
    setModalAddIndicatorsOpen(false);
  };


  const getCardIndicator = async () => {
    setLoading(true);

    if (user && user.length > 0) {
      try {
        const indicatorRequests = user.map(async (userData) => {
          const idClient = userData.idClient;
          const results = await Api.getAllIndicators(idClient);

          return results.data;
        });

        const indicatorsArray = await Promise.all(indicatorRequests);

        const allIndicators = indicatorsArray.flat();
        dispatch(setindicator(allIndicators));
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.log("User is undefined");
      setLoading(false);
    }
  };


  useEffect(() => {
    getCardIndicator();
  }, []);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.cardsContainer}>
        <Formik
          initialValues={{
            patient: "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
          onSubmit={Handlesearch}
          innerRef={formRef}
        >
          <div className={styles.wrapper_input}>
            <MagnifyingGlassIcon width={16} className={styles.searchIcon} />
            <Field
              name="search"
              type="text"
              placeholder="Buscar"
              className={styles.searchInput}
            />
          </div>
        </Formik>
        <div className={styles.containerSelect}>
          <Select
            name="exames"
            className={styles.selectOption}
            placeholder={
              <div className={styles.selectContainer}>
                <PlayCircleIcon width={20} className={styles.playIcon} />
                Exames
              </div>
            }
            styles={{
              menu: (baseStyles, state) => ({
                ...baseStyles,
                width: "230px",
                zIndex: "11",
                borderRadius: "0px 0px 12px 12px",
                border: "1px solid #D3D3D3",
                boxShadow: "none"
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused || state.isSelected ? "#E4E4E4" : "",
                fontWeight: state.isSelected || state.isFocused ? "600" : "400",
                margin: "0px auto",
                width: "99%",
                borderRadius: "12px",
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,
                transform: state.isFocused ? "rotate(-180deg)" : "",
                cursor: "pointer",
              }),
              indicatorSeparator: (baseStyles, state) => ({
                ...baseStyles,
                display: "none",
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                width: "230px",
                minHeight: "41px",
                maxHeight: "auto",
                backgroundColor: "#EFEFEF",
                borderRadius: "12px",
                zIndex: 10,
                paddingLeft: "7px",
                outline: "none",
                padding: "3px",
                border:
                  state.isFocused
                    ? "1px solid #EFEFEF"
                    : "1px solid #EFEFEF",
                boxShadow: "none",
                "&:hover": {
                  borderColor: "#EFEFEF",
                },
              }),
            }}
            id="situation"
            closeMenuOnSelect={true}
            isMulti
            options={optionsLabels}
          />
        </div>

        <div className={styles.createBtnContainer}>
          <Button
            variant="greenDark"
            className={styles.createBtn}
            onClick={handleOpenModalAddIndicators}
          >
            <PlusIcon className={styles.plusIcon} width={16} /> Criar indicador
          </Button>
        </div>

        {indicator && indicator.length !== 0 && user !== false ? (
          indicator.map((item: any, index: any) => (
            <Card
              key={item.id}
              id={item.id}
              description={item.description}
              days={item.days}
              creationDate={item.creationDate}
              styles={styles}
              indicatorExams={item.indicatorExams}
              patientsCount={item.patientsCount}
              patientsReturnedCount={item.patientsReturnedCount}
            />
          ))
        ) : indicator === undefined ? (
          <div className={styles.containerTextUserFalse}>
            <p>Sem Clínicas Vinculadas.</p>
          </div>
        ) : (
          <Loading />
        )}
      </div>

      {isModalAddIndicatorsOpen && (
        <ModalAddIndicators
          isOpen={isModalAddIndicatorsOpen}
          onRequestClose={handleCloseModalAddIndicators}
        />
      )}
    </div>
  );
}
