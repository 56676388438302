import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import {
    XMarkIcon
} from "@heroicons/react/24/outline";

import styles from './styles.module.css';
import { useState } from "react";
import { ModalExit } from "../../../../components/ModalExit";
import { selectIsCefalometria } from "../../../../store/slices/isCefalometriaSlice";

interface NewHeaderSidebarProps {
    infoPaciente: any;
    loadingPacientSearchCefx?: boolean;
    ocefx?: any;
}

export function NewHeaderSidebar({ infoPaciente, loadingPacientSearchCefx, ocefx }: NewHeaderSidebarProps) {
    const location = useLocation();

    const [isModalSair, setIsModalSair] = useState(false);

    const queryString = location.search.substring(1);
    const queryParams = new URLSearchParams(queryString);
    const pacienteQuery = queryParams.get("paciente")?.replace(/_/g, " ");
    const cefalometria = queryParams.get("cefalometria");

    const anos = infoPaciente?.idade?.match(/\d+(a|anos)/);
    const meses = infoPaciente?.idade?.match(/\d+m/);

    let idadeAnos = null;
    let idadeMeses = null;

    if (anos) {
        idadeAnos = anos[0].replace(/a|anos/, '');
    }

    if (meses) {
        idadeMeses = meses[0].replace('m', '');
    }

    const formatarIdade = (idadeAnos, idadeMeses) => {
        const anosFormatados = idadeAnos ? `${idadeAnos} ano${idadeAnos !== '1' ? 's' : ''}` : '';
        const mesesFormatados = idadeMeses ? `${idadeMeses} mes${idadeMeses !== '1' ? 'es' : ''}` : '';

        const idadeFormatada = [anosFormatados, mesesFormatados].filter(Boolean).join(' e ');

        return idadeFormatada;
    };

    const idadeFormatada = formatarIdade(idadeAnos, idadeMeses);

    const closeViwer = () => {
        window.history.back();
    };

    const handleOpenModalExit = () => {
        setIsModalSair(true);
    };

    const handleCloseModalExit = () => {
        setIsModalSair(false);
    };

    return (
        <div className={styles.wrapperHeader}>

            <header className={styles.header}>

                {
                    loadingPacientSearchCefx ?
                        <div className={styles.containerLoading}>
                            <span>Carregando...</span>
                        </div>
                        :
                        <>
                            {
                                !infoPaciente ?
                                    <p>{pacienteQuery}</p>
                                    :
                                    <div className={styles.containerInfoPacient}>
                                        <img src={`${infoPaciente?.imagem}`} alt="Avatar Paciente" />

                                        <div className={styles.containerInfo}>
                                            <h2>{infoPaciente?.paciente}</h2>
                                            {
                                                !anos ?
                                                    <strong></strong>
                                                    :
                                                    <strong>{idadeFormatada}</strong>
                                            }
                                        </div>

                                        <div
                                            className={styles.containerIconClose}
                                            onClick={() => cefalometria === 's' ? handleOpenModalExit() : closeViwer()}
                                            title="Fechar"
                                        >
                                            <XMarkIcon width={22} />
                                        </div>


                                    </div>
                            }
                        </>
                }
            </header>
            {isModalSair && (
                <ModalExit
                    isOpen={isModalSair}
                    onRequestClose={handleCloseModalExit}
                    ocefx={ocefx}
                />
            )}
        </div>
    )
}