import { BadgeIcon } from "../../../../components/BadgeIcon";

export function ExamesBadge({ styles, type }) {
  return (
    <div>
      {type === "tomografia" ? (
        <span className={`${styles.examsBadge} ${styles.tomografiaBadge}`}>
          <BadgeIcon
            containerClassName={styles.iconContainer}
            color="#D72C69"
          />
          Tomografia
        </span>
      ) : type === "raiox" ? (
        <span className={`${styles.examsBadge} ${styles.raioxBadge}`}>
          <BadgeIcon
            containerClassName={styles.iconContainer}
            color="#078295"
          />
          RAIO-X
        </span>
      ) : type === "atm" ? (
        <span className={`${styles.examsBadge} ${styles.atmSecondaryBadge}`}>
          <div className={styles.iconContainer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M7 12.25C9.8995 12.25 12.25 9.8995 12.25 7C12.25 4.10051 9.8995 1.75 7 1.75C4.10051 1.75 1.75 4.10051 1.75 7C1.75 9.8995 4.10051 12.25 7 12.25Z"
                stroke="#B83D9E"
                stroke-miterlimit="10"
              />
              <path
                d="M8.96875 7.875L7 5.6875L5.03125 7.875"
                stroke="#B83D9E"
                strokeLinecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          ATM
        </span>
      ) : (
        <></>
      )}
    </div>
  );
}
