import Modal from "react-modal";
import styles from "./styles.module.css";
import { useRef } from "react";
import { XMarkIcon, ShareIcon } from "@heroicons/react/24/outline";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from 'react-hot-toast';

import Api from "../../../../helpers/Api";
import { updateModel, postModel } from "../../utils/apiConnection";
import { Input } from "../../../../components/Form/Input";
import { TextArea } from "../../../../components/Form/TextArea";

interface ModalSaveModelProps {
  isOpen: boolean;
  onRequestClose: () => void;
  content: HTMLElement;
  idUser: number;
  modelData: any;
  setModelUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalSaveModel = ({
  isOpen,
  onRequestClose,
  content,
  idUser,
  modelData,
  setModelUpdated,
}: ModalSaveModelProps) => {
  const formikRef = useRef(null);

  const handleSubmit = async (values: any) => {
    const modelRequest = {
      active: true,
      name: values.modelName,
      html: content,
      idUser: idUser,
      isFavorite: modelData ? modelData.isFavorite : false,
      isHidden: modelData ? modelData.isHidden : false,
    };
    !modelData
      ? await postModel(modelRequest, setModelUpdated)
      : await updateModel(modelData.id, modelRequest, setModelUpdated);
    onRequestClose();
    toast.success('Modelo salvo com sucesso!');
  };

  const handleExternalSubmit = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  const validationSchema = Yup.object({
    modelName: Yup.string().required("O nome do modelo é obrigatório!"),
    modelDescription: Yup.string(),
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react_modal_overlay"
      className={styles.react_modal_Send_Request}
    >
      <div className={styles.xIconWrapper}>
        <XMarkIcon title="Fechar" onClick={onRequestClose} />
      </div>

      <div className={styles.textContainer}>
        <h2>Salvar requisição como modelo</h2>
        <p>Dê nome e salve essa requisição como modelo</p>
      </div>

      <Formik
        initialValues={{
          modelName: modelData ? modelData.name : "",
          modelDescription: "",
        }}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        validationSchema={validationSchema}
      >
        {({
          errors,
          isSubmitting,
          values,
          setFieldValue,
          handleChange,
        }) => (
          <Form>
            <div className={styles.wrapper_input}>
              <Input
                name="modelName"
                type="text"
                title="Nome do modelo"
                placeholder="Buscar modelo de requisição"
                error={errors.modelName}
              />
            </div>

            <div className={styles.wrapper_input}>
              <TextArea
                name="modelDescription"
                type="email"
                title="Descrição Opcional"
                placeholder="Buscar modelo de requisição"
              />
            </div>
          </Form>
        )}
      </Formik>

      <div className={styles.buttonsContainer}>
        <div className={styles.containerButtonCancel}>
          <button onClick={onRequestClose}>Cancelar</button>
        </div>

        <div className={styles.containerButtonSave}>
          <button onClick={handleExternalSubmit}>
            {!modelData ? "Salvar" : "Atualizar"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

