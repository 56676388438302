import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  clinic: [],
};

export const ClinicSlice = createSlice({
  name: "clinic",
  initialState,
  reducers: {
    setClinic: (state, action: PayloadAction<any>) => {
      state.clinic = action.payload;
    },
  },
});

export const { setClinic } = ClinicSlice.actions;

export const selectClinic = (state: any) => state.clinic;

export default ClinicSlice.reducer;
