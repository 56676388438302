import { useState } from "react";
import styles from "./styles.module.css";

export function Checkbox({ isChecked, onChange }) {
  return (
    <div className={styles.checkbox_container}>
      <div className={styles.checkbox_variants}>
        <input
          name="checkbox"
          id="checkbox"
          type="checkbox"
          className={styles.checkbox_parentcomponents}
          checked={isChecked}
          onChange={onChange}
        />

        <label
          htmlFor="checkbox"
          id="checkbox"
          className={styles.text_checkbox}
        >
          Lembrar de mim
        </label>
      </div>
    </div>
  );
}
