import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from "react-redux";
import Api from "../helpers/Api";
import { selectUser } from "../store/slices/userSlice";
import { useEffect, useRef, useState } from "react";
import { selectIsCefalometria } from "../store/slices/isCefalometriaSlice";
import { selectisClinicConnectionsComplete, setIsClinicConnectionsComplete } from "../store/slices/isClinicConnectionsCompleteSlice";
import { selectPatientsList, addPatients, setClinicPage, clearPatients } from '../store/slices/patientListSlice';

const API_URL = process.env.REACT_APP_API_URL;

type Paciente = {
    id: number;
    idClient: string;
    uid: string | null;
    seqAtend: number;
    paciente: string;
    status: string;
    dataAtend: string;
    dataNasc: string;
    idade: string;
    indicacao: string;
    idPaciente: number;
    sexo: string;
    telefone: string;
    imagem: string;
    clinic: string;
    todosExames: any | null;
    urlImagensPaciente: string;
    exames: Array<{ id: number; convenio: string; servico: string }>;
    ss: string;
    email: string;
};

export function useListPacientData() {
    const dispatch = useDispatch();

    const { user } = useSelector(selectUser);
    const { isCefalometria } = useSelector(selectIsCefalometria);
    const { isClinicConnectionsComplete } = useSelector(selectisClinicConnectionsComplete);
    const { patients } = useSelector(selectPatientsList);
    const { clinicPagination } = useSelector(selectPatientsList);

    const [getLastPage, setGetLastPage] = useState(false);
    const [loadingFromPagintionPageNext, setLoadingFromPagintionPageNext] = useState(false);

    const abortControllerRef = useRef(null);

    const fetchPatientsForClinic = async (userItem: any, currentPage: number, signal: any) => {
        if (!isCefalometria) {
            try {
                const response = await Api.GetPatientList(
                    {
                        idClient: userItem.idClient,
                        hash: userItem.hash,
                        page: currentPage,
                    },
                    '',
                    signal
                );

                if (response.isSuccess) {
                    const updatedPatients = response.data.patients?.map((patient) => ({
                        ...patient,
                        imagem: patient.imagem.includes('https://app.evolcloud.com.br')
                            ? 'https://cdt2so.blob.core.windows.net/3889/061922/93d00bdb-823c-4e3a-a0c0-8dcb3894fedf/dcm/00/inicio.png'
                            : `${API_URL}${patient.imagem}`,
                        clinic: userItem.clinicalName,
                        idClient: userItem.idClient,
                    }));

                    dispatch(addPatients(updatedPatients));
                    dispatch(setClinicPage({
                        idClient: userItem.idClient,
                        currentPage: response.data.currentPage,
                        totalPages: response.data.totalPages,
                    }));
                }
            } catch (error) {
                if (error.name !== 'AbortError') {
                    console.error('Erro ao buscar pacientes:', error);
                }
            }
        }
    };

    const fetchFirstPageForAllClinics = async () => {
        if (!isCefalometria && !isClinicConnectionsComplete) {
            const controller = new AbortController();
            const { signal } = controller;
            abortControllerRef.current = controller;

            const promises = user.map((userItem) => fetchPatientsForClinic(userItem, 0, signal));

            const results = await Promise.all(promises);

            dispatch(setIsClinicConnectionsComplete(true));

            return results;
        }

        return [];
    };

    const fetchNextPage = async () => {
        setLoadingFromPagintionPageNext(true);

        const controller = new AbortController();
        const { signal } = controller;
        abortControllerRef.current = controller;

        const promises = user.map((userItem) => {
            const clinicPage = clinicPagination[userItem.idClient] || { currentPage: 0, totalPages: 1 };

            if (clinicPage.currentPage + 1 >= clinicPage.totalPages) {
                //console.log(`Não há mais páginas para carregar para a clínica ${userItem.clinicalName}`);
                return null;
            }

            const nextPage = clinicPage.currentPage + 1;

            dispatch(setClinicPage({
                idClient: userItem.idClient,
                currentPage: nextPage,
                totalPages: clinicPage.totalPages,
            }));

            return fetchPatientsForClinic(userItem, nextPage, signal);
        });

        await Promise.all(promises.filter(promise => promise !== null));

        setLoadingFromPagintionPageNext(false);
    };

    useEffect(() => {
        if (getLastPage && !isCefalometria) {
            fetchNextPage();
        }
    }, [getLastPage]);

    useEffect(() => {
        if (!isClinicConnectionsComplete && patients.length == 0 && !isCefalometria) {
            dispatch(clearPatients());
            fetchFirstPageForAllClinics();
        }
    }, [isClinicConnectionsComplete]);

    useEffect(() => {
        return () => {
            if (abortControllerRef.current && !isCefalometria) {
                abortControllerRef.current.abort();
            }
        };
    }, []);

    const query = useQuery({
        queryFn: fetchFirstPageForAllClinics,
        queryKey: ['pacientsList-key'],
        staleTime: 10 * 60 * 1000, // 10 minutos
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: true,
    });

    return { ...query, data: patients, setGetLastPage, loadingFromPagintionPageNext };
}

