import React from "react";
import styles from "./styles.module.css";
import { CheckIcon } from "@heroicons/react/24/outline";

interface CheckboxProps {
  id?: number;
  inputId: string;
  isSelected: ((id?: number) => boolean) | (() => boolean);
  handleChange: ((id?: number) => void) | (() => void);
  add?: React.CSSProperties;
}

export const Checkbox = ({
  id,
  inputId,
  isSelected,
  handleChange,
  add,
}: CheckboxProps) => {
  const safeHandleChange = () => {
    return id ? handleChange(id) : handleChange();
  };

  return (
    <>
      <label htmlFor={id ? `checkbox-${inputId}-${id}` : `checkbox-${inputId}`}>
        <div
          className={styles.inputCheckbox}
          style={
            (id ? isSelected(id) : isSelected())
              ? { background: "#00AC98", border: "none", ...add }
              : add
          }
        >
          {(id ? isSelected(id) : isSelected()) && <CheckIcon color="white" />}
        </div>
      </label>
      <input
        id={id ? `checkbox-${inputId}-${id}` : `checkbox-${inputId}`}
        type="checkbox"
        onChange={safeHandleChange}
        style={{ display: "none" }}
      />
    </>
  );
};
