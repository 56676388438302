import { Text } from "@cdt-ui/react";
import styles from "./styles.module.css";
import { Student } from "@phosphor-icons/react";

import { Badge } from "../Badge/index";

export function AboutCard({ teacher }) {
  return (
    <div className={styles.aboutCard}>
      <div className={styles.aboutMeContainer}>
        <Student weight="bold" width={32} height={32} />
        <Text className={styles.aboutMe}>Sobre Mim</Text>
      </div>
      <div className={styles.descriptionContainer}>
        <Text className={styles.description}>{teacher.description}</Text>
        <div className={styles.others}>
          <Text className={styles.othersTitle}>Áreas de atuação</Text>
          <div className={styles.badgeContainer}>
            {teacher.occupationAreas ? (
              teacher.occupationAreas.map(({ name }, index) => (
                <Badge name={name} type={index + 1} />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
