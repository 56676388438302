import { useState, useEffect } from "react";

import { InputsTopBar } from "./InputsTopBar";
import { Card } from "./Card";
import { Swiper, SwiperSlide } from "swiper/react";
import data from "./data.json";

import { DragDropContext } from "react-beautiful-dnd";

import styles from "./styles.module.css";

export function FunilVendas() {
  const [dados, setDados] = useState(data);

  const reorder = (list, startIndex, endIndex, id) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return { [id]: result };
  };

  useEffect(() => {
    console.log(dados);
  }, [dados]);

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;
    if (source.droppableId === destination.droppableId) {
      const currentList = dados.filter((el) => el[source.droppableId])[0][
        source.droppableId
      ];
      const items = reorder(
        currentList,
        source.index,
        destination.index,
        source.droppableId
      );
      const copy = [...dados];
      const objIndex = dados.findIndex((i) => i[source.droppableId]);

      //@ts-ignore
      copy.splice(objIndex, 1, items);
      setDados(copy);
    } else {
      const sourceList = dados.find((i) => i[source.droppableId])[
        source.droppableId
      ];
      const destinationList = dados.find((i) => i[destination.droppableId])[
        destination.droppableId
      ];
      const result = move(sourceList, destinationList, source, destination);
      const copy = [...dados];
      const sourceObjIndex = dados.findIndex((i) => i[source.droppableId]);
      const destinationObjIndex = dados.findIndex(
        (i) => i[destination.droppableId]
      );
      //@ts-ignore
      copy.splice(sourceObjIndex, 1, {
        [source.droppableId]: result[source.droppableId],
      });
      //@ts-ignore
      copy.splice(destinationObjIndex, 1, {
        [destination.droppableId]: result[destination.droppableId],
      });
      setDados(copy);
    }
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  return (
    <div className={styles.outerContainer}>
      <InputsTopBar />

      <div className={styles.cardsContainer}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Swiper slidesPerView={3} spaceBetween={8}>
            <SwiperSlide>
              <Card
                title="Entrada ⏳"
                amount={13}
                value="R$ 27.261,00"
                cardData={dados}
                listType="entrada"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card
                title="Prospecção 📣"
                amount={992}
                value="R$ 17.655,00"
                cardData={dados}
                listType="prospection"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card
                title="Conexão 🔗"
                amount={2}
                value="R$ 418,00"
                cardData={dados}
                listType="connection"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card
                title="Agendados 🗓️"
                amount={1}
                value="R$ 0,00"
                cardData={dados}
                listType="scheduled"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card
                title="Qualificados ⏳"
                amount={4}
                value="R$ 2.794,00"
                cardData={dados}
                listType="qualified"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card
                title="Vendidos 💰"
                amount={20}
                value="R$ 26.342,00"
                cardData={dados}
                listType="sold"
              />
            </SwiperSlide>
          </Swiper>
        </DragDropContext>
      </div>
    </div>
  );
}
