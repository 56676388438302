import { useRef } from "react";

import { Formik, FormikValues, Field } from "formik";
import * as yup from "yup";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { SelectInput } from "../SelectInput";

import styles from "../styles.module.css";

export function InputsTopBar() {
  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
  });

  const Handlesearch = async (values: FormikValues): Promise<void> => {
    console.log(values);
  };

  const formRef = useRef<any>();
  return (
    <div className={styles.inputsContainer}>
      <Formik
        initialValues={{
          patient: "",
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={Handlesearch}
        innerRef={formRef}
      >
        <div className={styles.wrapper_input}>
          <MagnifyingGlassIcon width={16} className={styles.searchIcon} />
          <Field
            name="search"
            type="text"
            placeholder="Buscar"
            className={styles.searchInput}
          />
        </div>
      </Formik>
      <SelectInput name="negociacao" />
      <SelectInput name="clinica" />
      <SelectInput name="origem" />
      <SelectInput name="periodo" />
      <SelectInput name="vendedor" />
    </div>
  );
}
