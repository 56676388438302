import { useCheckList } from '../ChecklistContext';

import styles from './styles.module.css';

interface NavigationCheckListProps {
    toggleChecklist: (checklist: string) => void;
}

export function NavigationChecklist({ toggleChecklist }: NavigationCheckListProps) {
    const { checkListActive, handleTabClick } = useCheckList();

    const activeTab = checkListActive || "checklistAConcluir";

    const handlecheckList = (tabName: string) => {
        if (tabName !== checkListActive) {
            handleTabClick(tabName);
            toggleChecklist(tabName);
        };
    };

    return (
        <div className={styles.wraperNavigation}>
            <nav>
                <ul>
                    <li
                        className={activeTab === "checklistAConcluir" && styles.active}
                        onClick={() => handlecheckList("checklistAConcluir")}
                    >
                        <span>
                            A Concluir
                        </span>
                    </li>

                    <li
                        className={activeTab === "checklistConcluidos" && styles.active}
                        onClick={() => handlecheckList("checklistConcluidos")}
                    >
                        <span>
                            Em Andamento
                        </span>
                    </li>

                    <li
                        className={activeTab === "checklistEmAndamento" && styles.active}
                        onClick={() => handlecheckList("checklistEmAndamento")}
                    >
                        <span>
                            Finalizado
                        </span>
                    </li>
                </ul>
            </nav>
        </div>
    )
}