import { BellIcon } from "@heroicons/react/24/solid";

import { Avatar, Text } from "@cdt-ui/react";

import styles from "./styles.module.css";
import { Headphones } from "@phosphor-icons/react";

interface FooterProps {
  backgroundColorTrue?: boolean;
  isMobile?: boolean;
  widthSidebar?: string;
}

export function Footer({ backgroundColorTrue, isMobile, widthSidebar }: FooterProps) {
  return (
    <footer
      className={
        backgroundColorTrue ? `${styles.footer_background}` : `${styles.footer}`
      }
      style={
        !isMobile && widthSidebar
          ? {
            paddingLeft: `${Number(widthSidebar.match(/\d+/g)) + 24}px`,
            transition: "paddding-left ease-out 100ms",
          }
          : {}
      }
    >
      <div className={styles.logo}>
        <img src="/logo_cdt.svg" alt="logo" />
        <div className={styles.footerBar} />
        <img src="/logo_serodonto.svg" />
      </div>

      {/* <div className={styles.containerLogos}>
        <img src="/logo_cdt.svg" alt="logo" />
        <img src="/logo_serodonto.svg" />
      </div> */}

      <div className={styles.contact}>
        <Headphones />
        <Text className={styles.contactAbout}>Fale conosco</Text>
        <Text className={styles.contactNumber}>(12) 98150-7852</Text>
      </div>
    </footer>
  );
}
