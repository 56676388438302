import ImagemFinanceiro from "../Images/Financeiro.png";
import { Paywall } from "../Components";

export function Financial() {

  const paywall = [
    {
      id: 1,
      videourl: "https://player-vz-fb154304-6eb.tv.pandavideo.com.br/embed/?v=cbebc86c-20c3-42ca-9df9-c9ebefaae792",
      title: "Gestão financeira descomplicada: o segredo para a lucratividade da sua clínica odontológica.",
      link: "https://api.whatsapp.com/send?phone=5512981507852&text=Estou%20interessado%20nas%20ferramentas%20de%20gestão%20do%20Serodonto!",
      subtitles: [
        {
          subtitle: "Uma boa gestão financeira é essencial para a qualidade do atendimento, satisfação dos clientes e longevidade do negócio.",
        },
        {
          subtitle: "A Serodonto oferece tecnologias de gestão que simplificam rotinas administrativas e financeiras, otimizando processos internos.",
        },
        {
          subtitle: "O módulo Financeiro da Serodonto permite controlar o fluxo de caixa, contas a pagar, contas a receber e pagamento dos dentistas.",
        },
        {
          subtitle: "Com a ferramenta de Suprimentos, é possível controlar o estoque de materiais, fazer planejamento de compras e evitar desabastecimentos.",
        },
        {
          subtitle: "A Serodonto oferece suporte humano e rápido na implantação e configuração do sistema de gestão nas clínicas.",
        },
        
      ],
    },
  ];

  return (
    <>
      {paywall.map((item, index) => {
        return (
          <Paywall
            key={index}
            title={item.title}
            subtitles={item.subtitles}
            videourl={item.videourl}
            link={item.link}
            backgroundImage={ImagemFinanceiro}
          />
        );
      })}
      <></>
    </>
  );
}
