import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  headerLogo: "",
};

export const headerLogoSlice = createSlice({
  name: "headerLogo",
  initialState,
  reducers: {
    setHeaderLogo: (state, action: PayloadAction<string>) => {
      state.headerLogo = action.payload;
    },
  },
});

export const { setHeaderLogo } = headerLogoSlice.actions;

export const selectHeaderLogo = (state: any) => state.headerLogo;

export default headerLogoSlice.reducer;
