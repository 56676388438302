export function BadgeIcon({ containerClassName, color }) {
  return (
    <div className={containerClassName}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M4.3125 7.125C4.83027 7.125 5.25 6.70527 5.25 6.1875C5.25 5.66973 4.83027 5.25 4.3125 5.25C3.79473 5.25 3.375 5.66973 3.375 6.1875C3.375 6.70527 3.79473 7.125 4.3125 7.125Z"
          stroke={color}
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.6875 7.125C8.20527 7.125 8.625 6.70527 8.625 6.1875C8.625 5.66973 8.20527 5.25 7.6875 5.25C7.16973 5.25 6.75 5.66973 6.75 6.1875C6.75 6.70527 7.16973 7.125 7.6875 7.125Z"
          stroke={color}
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.75 9.117C2.94809 8.62657 2.32837 7.88756 1.98519 7.01245C1.642 6.13734 1.59414 5.17407 1.84889 4.26925C2.10364 3.36443 2.64705 2.56763 3.3964 2.00012C4.14575 1.43262 5.06001 1.12549 6 1.12549C6.94 1.12549 7.85425 1.43262 8.6036 2.00012C9.35295 2.56763 9.89636 3.36443 10.1511 4.26925C10.4059 5.17407 10.358 6.13734 10.0148 7.01245C9.67163 7.88756 9.05192 8.62657 8.25 9.117V10.1248C8.25 10.2243 8.21049 10.3197 8.14017 10.39C8.06984 10.4603 7.97446 10.4998 7.875 10.4998H4.125C4.02554 10.4998 3.93016 10.4603 3.85984 10.39C3.78951 10.3197 3.75 10.2243 3.75 10.1248V9.117Z"
          stroke={color}
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.25 10.5V9"
          stroke={color}
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.75 10.5V9"
          stroke={color}
          strokeLinecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}
