
import styles from '../../styles.module.css'
import { CardRelatorio } from '../components/cardRelatorio';

export function Grupos({ getRelatoriosSelect }: any) {
    const arrayDeRelatorios = [
        { nome: 'Imagens+Pts', codigo: 15, img: "../../modalRelatorio/grupos/1.png" },
        { nome: 'Fotos+Pts', codigo: 16, img: "../../modalRelatorio/grupos/2.png" },
        { nome: 'Raio x, foto, cefalograma', codigo: 17, img: "../../modalRelatorio/grupos/3.png" },
        { nome: 'Imagens', codigo: 18, img: "../../modalRelatorio/grupos/4.png" },
        { nome: 'Fotos', codigo: 19, img: "../../modalRelatorio/grupos/5.png" },
        { nome: 'Raio x, foto, cefalograma', codigo: 20, img: "../../modalRelatorio/grupos/6.png" },
        { nome: 'Painel de imagens', codigo: 21, img: "../../modalRelatorio/grupos/7.png" },
        { nome: 'Planejamento', codigo: 22, img: "../../modalRelatorio/grupos/8.png" },
        { nome: 'Caixa de modelo', codigo: 23, img: "../../modalRelatorio/grupos/9.png" },
    ];

    return (
        <div className={styles.wrapper}>
            <div className={styles.container_content}>
                {arrayDeRelatorios.map((relatorio, index) => (
                    <div key={index} className={styles.wrapperImg}>
                        <CardRelatorio
                            nome={relatorio.nome}
                            codigo={relatorio.codigo}
                            getRelatoriosSelect={getRelatoriosSelect}
                            img={relatorio.img}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}