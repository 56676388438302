import styles from '../../styles.module.css'
import { CardRelatorio } from '../components/cardRelatorio';
import img1 from "../../modalRelatorio/analiseFacial/1.png"
import img2 from "../../modalRelatorio/analiseFacial/2.png"
import img3 from "../../modalRelatorio/analiseFacial/3.png"
import img4 from "../../modalRelatorio/analiseFacial/4.png"
import img5 from "../../modalRelatorio/analiseFacial/5.png"
import img6 from "../../modalRelatorio/analiseFacial/6.png"
import img7 from "../../modalRelatorio/analiseFacial/7.png"

import img8 from "../../modalRelatorio/analiseFacial/8.png"

export function AnaliseFacial({ getRelatoriosSelect }: any) {
    const arrayDeRelatorios = [
        { nome: 'Foto + Cefalograma', codigo: 31, img: img1 },
        { nome: 'Perfis', codigo: 32, img: img2 },
        { nome: 'Fatores', codigo: 33, img: img3 },
        { nome: 'Cefalograma Paisagem', codigo: 34, img: img4 },
        { nome: 'Perfis Paisagem', codigo: 35, img: img5 },
        { nome: 'Análise facial frontal', codigo: 36, img: img6 },
        { nome: 'Análise facial frontal + sorriso', codigo: 37, img: img7 },
        { nome: 'Análise facial perfil', codigo: 38, img: img8 },
    ];

    return (
        <div className={styles.wrapper}>
            <div className={styles.container_content}>
                {arrayDeRelatorios.map((relatorio, index) => (
                    <div key={index} className={styles.wrapperImg}>
                        <CardRelatorio
                            nome={relatorio.nome}
                            codigo={relatorio.codigo}
                            getRelatoriosSelect={getRelatoriosSelect}
                            img={relatorio.img}

                        />
                    </div>
                ))}
            </div>
        </div>
    )
} 