import React, { createContext, useContext, useState } from 'react';

const SelectedReportContext = createContext();

export const SelectedReportProvider = ({ children }) => {
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    const addSelectedReport = (report) => {
        setSelectedCheckboxes([...selectedCheckboxes, report]);
    };

    const removeSelectedReport = (report) => {
        setSelectedCheckboxes(selectedCheckboxes.filter(item => item !== report));
    };

    return (
        <SelectedReportContext.Provider
            value={{
                selectedCheckboxes,
                addSelectedReport,
                removeSelectedReport,
            }}
        >
            {children}
        </SelectedReportContext.Provider>
    );
};

export const useSelectedReport = () => useContext(SelectedReportContext);
