export function ChartLineUp({ containerClassName, size = "12" }) {
  return (
    <div className={containerClassName}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M10.5 9.75H1.5V2.25"
          stroke="#009C86"
          stroke-width="0.981818"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.75 3L6 6.75L4.5 5.25L1.5 8.25"
          stroke="#009C86"
          stroke-width="0.981818"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.75 4.875V3H7.875"
          stroke="#009C86"
          stroke-width="1.22727"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}
