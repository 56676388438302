import styles from "./styles.module.css";

import { Button, Text } from "@cdt-ui/react";

import { Plus } from "@phosphor-icons/react";

export function NoClass({ contentStatus }) {
  const { classes, setClasses } = contentStatus;

  return (
    <section className={styles.wrapper}>
      <div className="dashboard-layout">
        <main className="dashboard-content">
          <div className={styles.containerContent}>
            <div>
              <Text className={styles.containerText}>
                Ainda não há aulas cadastradas
              </Text>
              <Text className={styles.containerSubText}>
                Clique em <b> Adicionar aula </b>e cadastre uma aula
              </Text>
            </div>
            <div className={styles.containerImg}>
              <img src="/emptystate.png" alt="" />
            </div>
            <div className={styles.containerButton}>
              <Button
                variant="tertiary"
                className={styles.buttonMore}
                onClick={() => setClasses([...classes, {}])}
              >
                <Plus />
                Adicionar aula
              </Button>
            </div>
          </div>
        </main>
      </div>
    </section>
  );
}
