import { ClockIcon } from "../../../../components/ClockIcon";
import { BadgeIcon } from "../../../../components/BadgeIcon";
import { ChartLineUp } from "../../../../components/ChartLineUp";
import { Chart } from "../../../../components/Chart";
import { Circle } from "../../../../components/Circle";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

import Avatar from "react-avatar";

ChartJS.register(BarElement, CategoryScale, LinearScale);

export function TopCard({ styles, filteredPatients }) {
  const data = {
    labels: [""],
    datasets: [
      {
        label: "Mensagens enviadas",
        data: [1489],
        borderWidth: 1,
        backgroundColor: ["#00523D"],
        borderRadius: { bottomLeft: 10, bottomRight: 10 },
        borderSkipped: false,
        maxBarThickness: 20,
      },
      {
        label: "Retorno Geral",
        data: [447],
        borderWidth: 1,
        backgroundColor: ["#009C86"],
        maxBarThickness: 20,
      },
      {
        label: "Mensagens não enviadas",
        data: [234],
        borderWidth: 1,
        backgroundColor: ["#ADE1DA"],
        borderRadius: { topRight: 10, topLeft: 10 },
        maxBarThickness: 20,
      },
    ],
  };

  const options = {
    responsive: true,

    scales: {
      x: { display: false, stacked: true },
      y: { display: false, stacked: true },
    },
    aspectRatio: 1,
    maintainAspectRatio: false,

    events: [],
  };
  return (
    <div>
      <div className={styles.wrapper}>
        <div>
          <span className={styles.timeBadge}>
            <ClockIcon containerClassName={styles.clockIconContainer} />
            Consulta há {filteredPatients[0].days}
          </span>
          <span className={styles.createdAtContainer}>
            <p>Data de criação {filteredPatients[0].creationDate}</p>
          </span>
          <div className={styles.containerAvatar_content}>
            <h2>{filteredPatients[0].description}</h2>

            <div className={styles.patientsContainer}>
              <span className={styles.avatars}>
                <Avatar
                  className={styles.avatar}
                  size="35"
                  src="https://s3-alpha-sig.figma.com/img/2e01/b2ad/c895fca7e299aceb539bf37f9a72f316?Expires=1693180800&Signature=f3tWiqHhWBe1ltwiLUk8CEfaRaxVTTCWxHzE2Dp6GFWtERsFJA8gx0~lh1xvDFlOVIB~iNU1lyXephgidM-aVECsULQeW6LqShNFggOLINEU6Be49pMoT-AlGDpfZu33EziR-IlyTWSO~f~EeUAAsk6k1tafJG21JgQbaNIyKMlZkwoR8bD-E8yqBR8HzLVVmimbG4KoSPEu~L~aXaCsdi9sehxOkRcmIHZlbYpFCUHm2CXyzuemXdPxkLMv2fF01jVqZoaczLXkWmEe3JtFH6qd-0gGhuwiJmwAAtjM1FAOf7Sfp8cl626-ymPANjT3uzM41S921fU60BLoV8JTlQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
                />
                <Avatar
                  className={`${styles.avatar} ${styles.avatar1}`}
                  size="35"
                  src="https://s3-alpha-sig.figma.com/img/3170/76df/a57d49314359fd7606cce752f3324d5d?Expires=1693180800&Signature=K2ypC1P4TjzX9YhxEoc8UP2bwtHEKDy5U5vQtOyx9Xjt9dWMCRp54QyAg35XN~LwjLnxM9ckeOba4r0wpWV-FQNiVv69KDDtOQMwJpfNwVUY8~Go37Ff18LWUrFMpNfJ-qiZplyXH1NHtOpPOblB3TQfV4UgFntNRo9JUbOJrUUOSyTcM0VLlmrK6kXshb3XkZIJgz3UuRtwVhDdUgHgaqTzLP-MEHUdPLXRiNtr5HjMxGrVaBUz4JQWxW4qvGargu4ROlqASR2V8hgyMbwvgWjnGOK8btGfUINW2xtmPWSkcMV-~WuhmzZPqswYXfqzYSqJfmf1PbdEQtQkXR8ayQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
                />
                <Avatar
                  className={`${styles.avatar} ${styles.avatar2}`}
                  size="35"
                  src="https://s3-alpha-sig.figma.com/img/f127/01bd/073e2bc1cd36ec23f693b57248587006?Expires=1693180800&Signature=PQR6rMdBj5VGRghuwsQtl31QGqt305DE4r93lObv5ZSsf8GCU5zdd5MO56a3ajAfB6NRhj1yHD186Fblq0pB4C7ooO~iDjgGI77sXE3SN7tf1wRvgMfeiHM3Gwz-tqbw-x6p4~BKESBBh7jlrZ-HDbMItsYvISYAuO9xONJWN31LT3gKcZkZkjzs3G3C0utW5EfoBosWdohWNukNLs241CtDc~UQd97Xgyrrpb7Zos74Cyn3cMJ6610lUwdKdVxDOkGJyzPo3fv64ld837Z0Qc1m-bMzPcwtTSGr8qAIyOaQtCgflCx17xKsdYlWtFXlDUyWuGrBvFiphG0slSyt0w__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
                />
              </span>
              <p className={styles.cardSubtitle}>
                {filteredPatients[0].patientsCount} pacientes atualmente
              </p>
            </div>
            <div className={styles.examsContainer}>
              <h4 className={styles.examsText}>EXAME(S)</h4>
              {filteredPatients[0].indicatorExams.map((patient) => (
                <span
                  className={`${styles.examsBadge}  ${styles.examsBadgeMargin} ${styles.raioxBadge}`}
                >
                  <BadgeIcon
                    containerClassName={styles.iconContainer}
                    color="#078295"
                  />
                  {patient.servico}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.containerContent}>
          <div className={styles.chartContainer}>
            <div className={styles.chartTopContainer}>
              <span className={styles.chartLineUpContainer}>
                <ChartLineUp
                  size="16"
                  containerClassName={styles.chartLineUpIcon}
                />
              </span>
              <div className={styles.chartTopTextContainer}>
                <p className={styles.chartTitle}>
                  {filteredPatients[0].patientsReturnedCount} pacientes
                  retornaram (0%)
                </p>
                <p className={styles.chartSubtitle}>0 mensagens enviadas</p>
              </div>
            </div>
            <Chart styles={styles} />
          </div>

          <div>
            <div className={styles.barChartContainer}>
              <div className={styles.barChart}>
                <Bar data={data} options={options} />
              </div>
              <div className={styles.barChartLabelsContainer}>
                <div className={styles.labelContainer}>
                  <Circle
                    containerClassName={styles.messagesNotSentCircle}
                    color="#ADE1DA"
                  />
                  <div>
                    <p className={styles.labelTitle}>Mensagens não enviadas</p>
                    <p className={styles.patientsAmount}>234 pacientes</p>
                  </div>
                </div>
                <div className={styles.labelContainer}>
                  <Circle
                    containerClassName={styles.retornoGeralCircle}
                    color="#009C86"
                  />
                  <div>
                    <p className={styles.labelTitle}>Retorno Geral</p>
                    <p className={styles.patientsAmount}>447 pacientes</p>
                  </div>
                </div>
                <div className={styles.labelContainer}>
                  <Circle
                    containerClassName={styles.mensagensEnviadasCircle}
                    color="#00523D"
                  />
                  <div>
                    <p className={styles.labelTitle}>Mensagens enviadas</p>
                    <p className={styles.patientsAmount}>1.489 pacientes</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
