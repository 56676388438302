import { useEffect, useRef, useState } from "react";
import { BellIcon, Bars3Icon, BellAlertIcon } from "@heroicons/react/24/solid";
import { ProfileMenu } from "./components/ProfileMenu";
import { useSelector } from "react-redux";

import { Avatar } from "@cdt-ui/react";
import styles from "./styles.module.css";
import { selectUser } from "../../store/slices/userSlice";

import { Link, useLocation } from "react-router-dom";
import { Notification } from "./components/Notification";
import Api from "../../helpers/Api";
import { selectHeaderLogo } from "../../store/slices/headerLogoSlice";

export function Header({ sidebarState }) {
  const [toggleNotification, setToggleNotification] = useState(false);
  const [toggleProfileMenu, setToggleProfileMenu] = useState(false);
  const [notificationDetails, setNotificationDetails] = useState<any>([]);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const avatarRef = useRef(null);
  const notificationRef = useRef(null);

  const { user } = useSelector(selectUser);
  const { headerLogo } = useSelector(selectHeaderLogo);
  const idClient = user[0]?.idClient;
  const location = useLocation();
  const currentURL = location.pathname;
  const pathname = window.location?.pathname.replace(/-/g, " ");

  const pageName = pathname.split("/").filter(Boolean).pop();

  const formattedPageName =
    pageName && pageName.charAt(0).toUpperCase() + pageName.slice(1);

  const containsOnlyNumbers = /^\d+$/.test(formattedPageName);

  const { handleCollapse, setMobileCollapse } = sidebarState;

  const message = notificationDetails
    ? notificationDetails.filter((item) => !item.read)
    : [];

  const isPatientProfilePage = currentURL?.includes("perfil-paciente");

  const logoSrc = isPatientProfilePage
    ? headerLogo
    : `https://cdt.com.br/cli/p/getImagem.aspx?cmp=${idClient == null ? 3889 : idClient
    }&nm=2`;

  const getNotificationDetails = async () => {
    // const idUser = user[0]?.idUser;

    // try {
    //   const result = await Api.getnotificationById(idUser);

    //   if (result.isSuccess === true) {
    //     setNotificationDetails(result.data);
    //   } else {
    //     console.error(
    //       "A requisição não foi bem-sucedida:",
    //       result.errorMessage
    //     );
    //   }
    // } catch (error) {
    //   console.error("Erro ao fazer a requisição:", error);
    // }
  };

  const handleCloseModal = () => {
    setToggleNotification(prevState => !prevState);
  };


  const handleProfileMenuToggle = () => {
    setIsProfileMenuOpen(prevState => !prevState);
  };

  useEffect(() => {
    getNotificationDetails();
  }, []);

  return (
    <header className={styles.header}>
      <div className={styles.mobileMenu}>
        <div onClick={handleCollapse}>
          <Bars3Icon width={22} color="#298e79" />
        </div>
      </div>

      <h1 className={styles.heading}>
        <span>
          {currentURL?.includes("academy")
            ? "Academy"
            : currentURL?.includes("perfil-paciente")
              ? "Perfil do Paciente"
              : currentURL.includes("requisi%C3%A7%C3%A3o-exames")
                ? "Requisição de Exames"
                : containsOnlyNumbers
                  ? "Página Atual"
                  : formattedPageName || "Página Atual"
          }
        </span>

        <Link
          to="/dashboard/lista-pacientes"
          title="Home"
          onClick={() => setMobileCollapse(true)}
        >
          <img src="/logo_serodonto.svg" />
        </Link>
      </h1>

      <div className={styles.content}>
        <img
          className={styles.logoImage}
          src={logoSrc}
        />

        <div
          onClick={handleCloseModal}
          style={{ cursor: 'pointer' }}
          ref={notificationRef}
          className={styles.containerNotification}
        >
          {message.length >= 1 ? (
            <BellAlertIcon width={24} />
          ) : (
            <BellIcon width={24} color="#64748B" />
          )}
        </div>

        <div
          ref={avatarRef}
          className={styles.avatarContainer}
          onClick={handleProfileMenuToggle}
        >
          <Avatar />
        </div>

        <ProfileMenu
          isActive={isProfileMenuOpen}
          setIsActive={setIsProfileMenuOpen}
          avatarRef={avatarRef}
        />

        <Notification
          onclick={() => getNotificationDetails()}
          isActive={toggleNotification}
          setIsActive={setToggleNotification}
          notificationRef={notificationRef}
          notificationDetails={notificationDetails}
        />

      </div>
    </header>
  );
}
