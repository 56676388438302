import { XMarkIcon, PrinterIcon, DocumentTextIcon, PlusIcon } from '@heroicons/react/24/outline'
import Modal from 'react-modal';
import { toast } from "react-hot-toast";
import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';

import { Navigation } from './Navigation';
import { useSelectedReport } from './SelectedReportContext';
import { ModalOpenPdf } from '../ModalOpenPdf';

import { ModalAddAnalyze } from '../ModalAddAnalyze';
import { useOpcoes } from '../../NewSideberCefx/OpcoesContext';

import styles from './styles.module.css'

interface ModalReportProps {
    isOpen: boolean;
    onRequestClose: () => void;
    ocefx?: any;
    analysisSelected?: any;
    handleGetNameEndCodAnalyze: any;
};

export function ModalReport({ ocefx, isOpen, onRequestClose, analysisSelected, handleGetNameEndCodAnalyze }: ModalReportProps) {
    const { selectedCheckboxes, addSelectedReport, removeSelectedReport } = useSelectedReport();
    const { analiseCheckboxSelected: selectedAnalyze, analises } = useOpcoes();

    const [loading, setLoading] = useState(false);
    const [getClinica, setGetClinica] = useState('');
    const [getAtend, setGetAtend] = useState('');
    const [status, setStatus] = useState(false);
    const [getJsonData, setGetJsonData] = useState('');
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [isModalOpenPdf, setisModalOpenPdf] = useState(false);
    const [isModalOpenAnalyze, setModalOpenAnalyze] = useState(false);
    const [allAnalyze, setAllAnalyze] = useState([]);
    const [sels, setSels] = useState();

    let relSele = selectedCheckboxes.map(rel => rel).join('-');
    let selsAll = selectedAnalyze.map(analyze => analyze.codigo).join('-');

    const getRelatoriosSelect = (codigo) => {
        const index = selectedCheckboxes.indexOf(codigo);
        if (index !== -1) {
            removeSelectedReport(codigo);
        } else {
            addSelectedReport(codigo);
        }
    };

    const openPfd = (dataPdf: string) => {
        window.open(ocefx.estado._servCefx + `/laudos/${getClinica}/${getAtend}/${dataPdf}/`, '_blank');
    }

    const handleCloseModalOpenPdf = () => {
        setisModalOpenPdf(false);
    };

    const handleOPenModalAddAnalyze = () => {
        setModalOpenAnalyze(true);
    };

    const handleCloseModalAddAnalyze = () => {
        setModalOpenAnalyze(false);
        ocefx.reloadAnalises(allAnalyze);
    };

    const handleAllAnalyze = (analyze: string[]) => {
        handleGetNameEndCodAnalyze(analyze)
        setAllAnalyze(analyze);
    };

    useEffect(() => {
        if (status) {
            setisModalOpenPdf(true);
            setStatus(false);
        }
    }, [status]);

    useEffect(() => {
        const initialValues = {
            selectedCheckboxe: selectedAnalyze ? selectedAnalyze.map(analyze => analyze.codigo.toString()) : []
        };
        setSels(initialValues.selectedCheckboxe.join('-'))
    }, [selectedAnalyze])

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react_modal_overlay"
            className="react-modal-report"
        >
            <header className={styles.header}>
                <div className={styles.containerSelect}>
                    <div className={styles.wrapper_text}>
                        <div className={styles.containerAnaliseIcon}>
                            <DocumentTextIcon width={16} />
                        </div>

                        <div>
                            <p>Documento</p>
                            <h2>
                                Relatórios
                            </h2>
                        </div>
                    </div>
                </div>

                <div className={styles.containerButtons}>
                    <button
                        type='button'
                        title='Imprimir PDF'
                        disabled={loading}
                        style={{ opacity: loading && '.5', cursor: loading && 'not-allowed' }}
                        onClick={() => {
                            if (relSele.length === 0) {
                                toast("Por favor, selecione pelo menos um RELATÓRIO!", {
                                    position: "top-right",
                                    style: {
                                        background: "#f09500",
                                        color: "#fff",
                                        textAlign: 'center'
                                    },
                                });
                                return;
                            } else if (selectedAnalyze.length === 0 || !sels) {
                                toast("Por favor, selecione pelo menos uma ANÁLISE!", {
                                    position: "top-right",
                                    style: {
                                        background: "#f09500",
                                        color: "#fff",
                                        textAlign: 'center'
                                    },
                                });
                                return;
                            }
                            ocefx.printCefX(sels, relSele, setLoading, setGetClinica, setGetAtend, setStatus, setGetJsonData);
                        }}
                    >
                        {loading ? (
                            <div className={styles.container_buttons_loading_imprimir}>
                                <div className={styles.loading_spinne_imprimir}></div>
                            </div>
                        ) : (
                            <>
                                Gerar PDF
                                <PrinterIcon />
                            </>
                        )}
                    </button>

                    {/* <button
                        type='button'
                        title='Imprimir image JPG'
                        disabled={loading}
                    >
                        Gerar JPG
                        <PrinterIcon />
                    </button> */}
                </div>

                <div></div>

                <div className={styles.containerIconClose}>
                    <XMarkIcon
                        title="Fechar"
                        width={16}
                        onClick={onRequestClose}
                    />
                </div>

            </header>

            {isModalOpenPdf && (
                <ModalOpenPdf
                    isOpen={isModalOpenPdf}
                    onRequestClose={handleCloseModalOpenPdf}
                    openPfd={openPfd}
                    jsonData={getJsonData}
                    ocefx={ocefx}
                />
            )}

            {isModalOpenAnalyze && (
                <ModalAddAnalyze
                    isOpen={isModalOpenAnalyze}
                    onRequestClose={handleCloseModalAddAnalyze}
                    handleAllAnalyze={handleAllAnalyze}
                    analises={analises}
                    ocefx={ocefx}
                />
            )}

            <main className={styles.wrapper}>

                <Navigation
                    getRelatoriosSelect={getRelatoriosSelect}
                    ocefx={ocefx}
                />

                <div className={styles.containerAnalyze}>
                    <p>Análises</p>

                    {
                        selsAll.length == 0 ?
                            <>
                                <span className={styles.spanNotResult}>Sem resultado</span>
                            </>
                            :
                            <div className={styles.containerCheckbox}>
                                <Formik
                                    initialValues={{
                                        selectedCheckboxe: selectedAnalyze.map(analyze => analyze.codigo.toString())
                                    }}
                                    onSubmit={(values) => console.log(values)}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    enableReinitialize
                                >
                                    {({ values, setFieldValue, getFieldProps }) => (
                                        <Form>
                                            <div className="modalAddAnalyze-content">
                                                <div>
                                                    {selectedAnalyze.map((analyze, index) => (
                                                        <label className={styles.containerSearchResult} key={index}>
                                                            <input
                                                                type="checkbox"
                                                                {...getFieldProps('selectedCheckboxe')}
                                                                value={analyze.codigo.toString()}
                                                                checked={values.selectedCheckboxe.includes(analyze.codigo.toString())}
                                                                onChange={(e) => {
                                                                    const selectedValue = e.target.value;
                                                                    const updatedSelectedCheckboxe = values.selectedCheckboxe.includes(selectedValue)
                                                                        ? values.selectedCheckboxe.filter(value => value !== selectedValue)
                                                                        : [...values.selectedCheckboxe, selectedValue];

                                                                    setFieldValue('selectedCheckboxe', updatedSelectedCheckboxe);
                                                                    const updatedSelsAll = updatedSelectedCheckboxe.join('-');
                                                                    setSels(updatedSelsAll);
                                                                }}
                                                            />
                                                            <span>{analyze.nome}</span>
                                                        </label>
                                                    ))}
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                    }

                    <div className={styles.containerButtonAnalyzes}>
                        <button
                            type="button"
                            disabled={loading}
                            style={{ opacity: loading && '.5', cursor: loading && 'not-allowed' }}
                            onClick={handleOPenModalAddAnalyze}
                            className={styles.buttonAnalyzes}
                        >
                            <PlusIcon />
                            Adicionar Análise
                        </button>
                    </div>

                </div>
            </main>
        </Modal >
    )
}