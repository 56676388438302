import { useEffect, useState } from "react";

import { ClipboardIcon, PlusIcon } from "@heroicons/react/24/outline";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { ModalAddAnalyze } from "../../../Modals/ModalAddAnalyze";

import { useOpcoes } from "../../OpcoesContext";

import styles from './styles.module.css';

interface AnaliseProps {
    ocefx: any;
    handleGetNameEndCodAnalyze: any;
}

export function Analise({ ocefx, handleGetNameEndCodAnalyze }: AnaliseProps) {
    const { analiseCheckboxSelected, analises, selectedTracado, handleSelectedTracado } = useOpcoes();

    const [loadingGetTracado, setLoadingGetTracado] = useState(false);
    const [selectedAnalyze, setSelectedAnalyze] = useState<number | null>(null);
    const [allAnalyze, setAllAnalyze] = useState([]);
    const [isModalOpenAnalyze, setModalOpenAnalyze] = useState(false);
    const [analyzes, setAnalyzes] = useState(analiseCheckboxSelected || []);

    const handleClickSelectedAnalyze = (index: number, analyze: number, analyzeNome: string) => {
        handleSelectedTracado(analyzeNome);
        setSelectedAnalyze(index === selectedAnalyze ? null : index);
        ocefx.getTracado(analyze, setLoadingGetTracado);
    };

    const handleOffTracado = () => {
        ocefx.getTracado(0, setLoadingGetTracado);
        handleSelectedTracado('');
    }

    const handleOPenModalAddAnalyze = () => {
        setModalOpenAnalyze(true);
    };

    const handleCloseModalAddAnalyze = () => {
        setModalOpenAnalyze(false);
        ocefx.reloadAnalises(allAnalyze);
    };

    const handleAllAnalyze = (analyze: string[]) => {
        handleGetNameEndCodAnalyze(analyze)
        setAllAnalyze(analyze);
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const newAnalyzes = Array.from(analyzes);
        const [removed] = newAnalyzes.splice(result.source.index, 1);
        newAnalyzes.splice(result.destination.index, 0, removed);
        setAnalyzes(newAnalyzes);
    };

    useEffect(() => {
        setAnalyzes(analiseCheckboxSelected || []);
    }, [analiseCheckboxSelected]);

    return (
        <div className={styles.containerAnalyzes}>
            <p> Análises </p>

            {
                analiseCheckboxSelected.length !== 0 &&
                <div className={styles.containerTextTracado}>
                    {
                        selectedTracado == '' ?
                            <span>
                                Para definir o traçado, clique diretamente em um Card.
                            </span>
                            :
                            <div>
                                {selectedTracado}
                                <button
                                    type="button"
                                    title="Desligar traçado"
                                    onClick={() => handleOffTracado()}
                                    disabled={loadingGetTracado}
                                    style={{
                                        background: loadingGetTracado && 'rgba(83, 83, 83, 0.32)',
                                        outline: 'none',
                                        cursor: loadingGetTracado && 'not-allowed',
                                        opacity: loadingGetTracado && '0.8',
                                    }}
                                >
                                    Desligar
                                </button>
                            </div>
                    }
                </div>
            }

            <div className={styles.analyzes}>
                {analiseCheckboxSelected.length == 0 ? (
                    <div className={styles.containerNoResults}>
                        <span>Sem resultado</span>
                    </div>
                ) : (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <div
                                    className={styles.container_analyzes_data}
                                    style={{ overflowY: analiseCheckboxSelected.length >= 6 ? 'scroll' : 'hidden' }}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {analyzes.map((analyze, index) => (
                                        <Draggable key={analyze.codigo} draggableId={`draggable-${analyze.codigo}`} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        boxShadow: snapshot.isDragging ? '0 0 0.5rem rgba(0,0,0,0.2)' : 'none',
                                                        borderRadius: '8px',
                                                        marginBottom: '8px',
                                                    }}
                                                >
                                                    <button
                                                        title="Marcar traçado"
                                                        disabled={loadingGetTracado && selectedAnalyze === index}
                                                        className={`${styles.analyzes_data}`}
                                                        onClick={() => handleClickSelectedAnalyze(index, analyze.codigo, analyze.nome)}
                                                        style={{
                                                            background: loadingGetTracado && selectedAnalyze === index ? 'rgba(83, 83, 83, 0.32)' : selectedTracado === analyze.nome && '#007E69',
                                                            outline: 'none',
                                                            cursor: loadingGetTracado && selectedAnalyze === index ? 'not-allowed' : 'pointer',
                                                            opacity: loadingGetTracado && selectedAnalyze === index ? '0.8' : '1',

                                                        }}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div className={styles.containerIconDragAndDrop} title="Mover">
                                                            <svg
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                onClick={(e) => e.stopPropagation()}
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M17.6002 7.20001C18.4839 7.20001 19.2002 7.91635 19.2002 8.8C19.2002 9.68365 18.4839 10.4 17.6002 10.4C16.7166 10.4 16.0002 9.68365 16.0002 8.8C16.0002 7.91635 16.7166 7.20001 17.6002 7.20001ZM12.0001 7.20001C12.8838 7.20001 13.6001 7.91635 13.6001 8.8C13.6001 9.68365 12.8838 10.4 12.0001 10.4C11.1165 10.4 10.4001 9.68365 10.4001 8.8C10.4001 7.91635 11.1165 7.20001 12.0001 7.20001ZM8 8.8C8 7.91635 7.28366 7.20001 6.40001 7.20001C5.51637 7.20001 4.80003 7.91635 4.80003 8.8C4.80003 9.68365 5.51637 10.4 6.40001 10.4C7.28366 10.4 8 9.68365 8 8.8ZM7.99941 15.2002C7.99941 14.3165 7.28308 13.6002 6.39943 13.6002C5.51578 13.6002 4.79944 14.3165 4.79944 15.2002C4.79944 16.0838 5.51578 16.8002 6.39943 16.8002C7.28308 16.8002 7.99941 16.0838 7.99941 15.2002ZM13.5995 15.2002C13.5995 14.3165 12.8832 13.6002 11.9995 13.6002C11.1159 13.6002 10.3995 14.3165 10.3995 15.2002C10.3995 16.0838 11.1159 16.8002 11.9995 16.8002C12.8832 16.8002 13.5995 16.0838 13.5995 15.2002ZM19.1997 15.2002C19.1997 14.3165 18.4833 13.6002 17.5997 13.6002C16.716 13.6002 15.9997 14.3165 15.9997 15.2002C15.9997 16.0838 16.716 16.8002 17.5997 16.8002C18.4833 16.8002 19.1997 16.0838 19.1997 15.2002Z"
                                                                    fill="#AFAFAF"
                                                                />
                                                            </svg>
                                                            <span>{analyze.nome}</span>
                                                        </div>

                                                        {
                                                            loadingGetTracado && selectedAnalyze === index ?
                                                                <div className={styles.container_buttons_loading}>
                                                                    <div className={styles.loading_spinne}></div>
                                                                </div>
                                                                :
                                                                <ClipboardIcon />
                                                        }
                                                    </button>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}

                <div className={styles.containerButtonAnalyzes}>
                    <button
                        type="button"
                        onClick={handleOPenModalAddAnalyze}
                        className={styles.buttonAnalyzes}
                    >
                        <PlusIcon />
                        Adicionar Análise
                    </button>
                </div>
            </div>

            {isModalOpenAnalyze && (
                <ModalAddAnalyze
                    isOpen={isModalOpenAnalyze}
                    onRequestClose={handleCloseModalAddAnalyze}
                    handleAllAnalyze={handleAllAnalyze}
                    analises={analises}
                />
            )}
        </div>
    )
}