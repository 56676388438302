import { useState } from "react";
import Select, { components, StylesConfig } from "react-select";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import styles from './styles.module.css';
import { CircularProgress } from "@mui/material";

interface CustomSelectProps {
  label: string;
  name: string;
  placeholder: string;
  values: any;
  options: any[];
  selectStyles?: StylesConfig<any, boolean>;
  isLoading?: boolean;
  onChange?: () => void;
  onBlur?: () => void;
  value?: any;
  defaultValue?: any;
}

export const CustomSelect = ({
  label,
  name,
  placeholder,
  values,
  options,
  selectStyles,
  value,
  defaultValue,
  isLoading,
  onBlur,
  onChange,
  ...restProps
}: CustomSelectProps) => {

  const [labelStyles, setLabelStyles] = useState({
    fontSize: "0.875rem",
    color: "#000",
  });
  const [isSelectFocused, setIsSelectFocused] = useState(false);

  const handleOnChange = (value) => {
    values[name] = value.value;
    setLabelStyles({
      fontSize: "1rem",
      color: "#237d69",
    });

    if (onChange) {
      onChange();
    }
  };

  const handleSelectFocus = (focused: boolean) => {
    setIsSelectFocused(focused);
    setLabelStyles((prevStyles) => ({
      ...prevStyles,
      color: focused ? "#237d69" : "#535353",
    }));
    if (!focused) {
      setLabelStyles({
        color: "#535353",
        fontSize: "0.875rem",
      });
    }
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDownIcon width={16} height={16} color="#8E8E8E" />
      </components.DropdownIndicator>
    );
  };

  const LoadingMessage = (props) => {
    return (
      <components.LoadingIndicator {...props}>
        <CircularProgress />
      </components.LoadingIndicator>
    )
  }

  return (
    <div className={styles.textInputContainer}>
      <label style={labelStyles} className={styles.label} htmlFor={name}>
        {label}
      </label>
      <Select
        name={name}
        placeholder={placeholder}
        classNamePrefix="react-select"
        onFocus={() => handleSelectFocus(true)}
        onBlur={(e) => {
          handleSelectFocus(false);
          if (onBlur) {
            onBlur();
          }
        }}
        defaultValue={defaultValue}
        value={value}
        options={options}
        onChange={handleOnChange}
        isLoading={isLoading}
        styles={{
          container: (baseStyles, state) => ({
            ...baseStyles,
            width: "100%",
            maxWidth: "100%",
            ...selectStyles?.container,
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            maxWidth: "100%",
            minHeight: "50px",
            maxHeight: "auto",
            backgroundColor: "#fff",
            borderRadius: "12px",
            border: state.isFocused ? "1px solid #237d69" : "1px solid #afafaf",
            boxShadow: "none",
            "&:hover": {
              borderColor: "none",
            },
            ...selectStyles?.control,
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            zIndex: "11",
            borderRadius: "0px 0px 12px 12px",
            border: "1px solid #D3D3D3",
            boxShadow: "none",
            ...selectStyles?.menu,
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor:
              state.isFocused || state.isSelected ? "#E4E4E4" : "",
            fontWeight: state.isSelected || state.isFocused ? "600" : "400",
            margin: "0px auto",
            width: "99%",
            borderRadius: "12px",
            ...selectStyles?.option,
          }),
          dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            transform: state.isFocused ? "rotate(-180deg)" : "",
            cursor: "pointer",
            padding: state.isFocused ? "0px 0px 0px 16px" : "0px 16px 0px 0px",
            ...selectStyles?.dropdownIndicator,
          }),
          indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            display: "none",
            ...selectStyles?.indicatorSeparator,
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            fontSize: "12px",
          }),
          /* ...selectStyles, */
        }}
        components={{ DropdownIndicator }}
        {...restProps}
      />
    </div>
  );
};
