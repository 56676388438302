import Modal from 'react-modal';
import { updateModel } from '../../utils/apiConnection';
import { XMarkIcon, TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-hot-toast';

import styles from "./styles.module.css"
import { useEffect, useState } from 'react';

interface ModalDeleteModeloProps {
    isOpen: boolean;
    onRequestClose: () => void;
    selectedModelId?: number;
    selectedModelToDeleteOrEdit?: {}
    setGetResultDeleteModel?: any;
};

export function ModalDeleteModelo({ isOpen, onRequestClose, setGetResultDeleteModel, selectedModelId, selectedModelToDeleteOrEdit }: ModalDeleteModeloProps) {
    const [isDeletedSuccessfully, setIsDeletedSuccessfully] = useState(null);
    const [loadingButtonDeleteModel, setLoadingButtonDeleteModel] = useState(false);

    console.log(isDeletedSuccessfully)

    const handleExcluirModel = () => {
        setLoadingButtonDeleteModel(true);
        const data = { ...selectedModelToDeleteOrEdit, active: false }
        updateModel(selectedModelId, data, setIsDeletedSuccessfully);
    }

    useEffect(() => {
        if (isDeletedSuccessfully) {
            setTimeout(() => {
                toast.success(
                    <div style={{ textAlign: 'center' }}>
                        Modelo excluído com sucesso!
                    </div>
                );
                setGetResultDeleteModel(true)
                setIsDeletedSuccessfully(false);
                onRequestClose();
            }, 200)
        } else if (!isDeletedSuccessfully && isDeletedSuccessfully !== null) {
            setTimeout(() => {
                toast.error(
                    <div style={{ textAlign: 'center' }}>
                        Falha ao excluir o modelo. Por favor, tente novamente.
                    </div>
                );
                onRequestClose();
            }, 200)
        }
    }, [isDeletedSuccessfully])

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react_modal_overlay"
            className={styles.react_modal_deleteModelo}
        >
            <div className={styles.xIconWrapper}>
                <XMarkIcon title="Fechar" onClick={onRequestClose} />
            </div>

            <div className={styles.trashIconWrapper}>
                <div className={styles.circle}>
                    <TrashIcon width={24} color='#C80000' />
                </div>
            </div>

            <div className={styles.textContainer}>
                <h2>Confirmar exclusão do modelo?</h2>
                <p>O modelo será excluída permanentemente</p>
            </div>

            <div className={styles.buttonsContainer}>
                <div className={styles.containerButtonCancel}>
                    <button
                        type='button'
                        onClick={onRequestClose}
                    >
                        Cancelar
                    </button>
                </div>

                <div className={styles.containerButtonDelete}>
                    <button
                        type='button'
                        onClick={() => handleExcluirModel()}
                        disabled={loadingButtonDeleteModel}
                    >
                        {
                            loadingButtonDeleteModel ? 'Excluindo...' : 'Excluir'
                        }
                    </button>
                </div>
            </div>

        </Modal>
    )
}