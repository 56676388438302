import { useState, useEffect } from 'react'
import Modal from 'react-modal';
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Formik, Form, FormikValues } from 'formik';

import { InputSearch } from './InputSearch';
import { useOpcoes } from '../../NewSideberCefx/OpcoesContext';

import styles from './styles.module.css'

interface ModalAddAnalyzeProps {
    isOpen: boolean;
    onRequestClose: () => void;
    handleAllAnalyze: ([]) => void;
    analises?: any[];
    selectedCheckboxesProp?: any;
    ocefx?: any;
}

export function ModalAddAnalyze({ ocefx, isOpen, onRequestClose, handleAllAnalyze, analises }: ModalAddAnalyzeProps) {
  const { analiseCheckboxSelected, addSelectedAnalyze, removeSelectedAnalyze } = useOpcoes();
    
    const [search, setSearch] = useState('');
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const [disableScroll, setDisableScroll] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [modalPosition, setModalPosition] = useState({
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
    });

    const handleMouseDown = (event) => {
        setIsDragging(true);
        setDragStart({ x: event.clientX, y: event.clientY });
    };

    const handleMouseMove = (event) => {
        if (isDragging) {
            const deltaX = event.clientX - dragStart.x;
            const deltaY = event.clientY - dragStart.y;
            setModalPosition({
                x: modalPosition.x + deltaX,
                y: modalPosition.y + deltaY,
            });
            setDragStart({ x: event.clientX, y: event.clientY });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleCheckboxChange = (nome: string, codigo: string, selecionada: boolean) => {
        const isCheckboxSelected = analiseCheckboxSelected.some(item => item.nome === nome);

        if (isCheckboxSelected) {
            removeSelectedAnalyze({ nome, codigo });
        } else {
            addSelectedAnalyze({ nome, codigo });
        }
    };

    const handleSearch = () => {
        if (search.length > 0) {
            setLoading(false);
            setItems(
                analises.filter((analise: any) =>
                    analise?.nome.toLowerCase().includes(search.toLowerCase()))
            );

            setTimeout(() => {
                setLoading(true);
            }, 2000);

            return;
        }
        setItems(analises);
    };

    const customStyles = {
        content: {
            top: `${modalPosition.y}px`,
            left: `${modalPosition.x}px`,
        },
    };

    const handleDeleteItems = () => {
        setSearch('');
        setItems([]);
    };

    useEffect(() => {
        handleAllAnalyze(analiseCheckboxSelected);
    }, [analiseCheckboxSelected]);

    useEffect(() => {
        handleSearch();
    }, [search]);

    useEffect(() => {
        setItems(analises);
    }, [analises]);

    useEffect(() => {
        if (isOpen) {
            setDisableScroll(true);
        } else {
            setDisableScroll(false);
        }
    }, [isOpen]);

    useEffect(() => {
        const htmlBody = document.querySelector('html, body') as HTMLElement | null;

        if (htmlBody) {
            if (disableScroll) {
                htmlBody.style.overflow = 'hidden';
            } else {
                htmlBody.style.overflow = 'visible';
            }
        }

        return () => {
            if (htmlBody) {
                htmlBody.style.overflow = 'visible';
            }
        };
    }, [disableScroll]);

    return (
        <div
            className="modal-draggable-container"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            style={{ position: "absolute", zIndex: '999' }}
        >
            <Modal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                overlayClassName="react_modal_overlay_2"
                className="react-modal-Analyze"
                style={customStyles}
            >
                <header className={styles.header}>
                    <div className={styles.containerIconDragAndDrop}>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M17.6002 7.20001C18.4839 7.20001 19.2002 7.91635 19.2002 8.8C19.2002 9.68365 18.4839 10.4 17.6002 10.4C16.7166 10.4 16.0002 9.68365 16.0002 8.8C16.0002 7.91635 16.7166 7.20001 17.6002 7.20001ZM12.0001 7.20001C12.8838 7.20001 13.6001 7.91635 13.6001 8.8C13.6001 9.68365 12.8838 10.4 12.0001 10.4C11.1165 10.4 10.4001 9.68365 10.4001 8.8C10.4001 7.91635 11.1165 7.20001 12.0001 7.20001ZM8 8.8C8 7.91635 7.28366 7.20001 6.40001 7.20001C5.51637 7.20001 4.80003 7.91635 4.80003 8.8C4.80003 9.68365 5.51637 10.4 6.40001 10.4C7.28366 10.4 8 9.68365 8 8.8ZM7.99941 15.2002C7.99941 14.3165 7.28308 13.6002 6.39943 13.6002C5.51578 13.6002 4.79944 14.3165 4.79944 15.2002C4.79944 16.0838 5.51578 16.8002 6.39943 16.8002C7.28308 16.8002 7.99941 16.0838 7.99941 15.2002ZM13.5995 15.2002C13.5995 14.3165 12.8832 13.6002 11.9995 13.6002C11.1159 13.6002 10.3995 14.3165 10.3995 15.2002C10.3995 16.0838 11.1159 16.8002 11.9995 16.8002C12.8832 16.8002 13.5995 16.0838 13.5995 15.2002ZM19.1997 15.2002C19.1997 14.3165 18.4833 13.6002 17.5997 13.6002C16.716 13.6002 15.9997 14.3165 15.9997 15.2002C15.9997 16.0838 16.716 16.8002 17.5997 16.8002C18.4833 16.8002 19.1997 16.0838 19.1997 15.2002Z"
                                fill="#AFAFAF"
                            />
                        </svg>
                    </div>

                    <div className={styles.containerHelpIcon}>
                        <img src="/iconCefx/analiseIcon.svg" alt="Ícone de Análise" />
                    </div>

                    <div className={styles.containerSelect}>
                        <p>
                            ANÁLISES
                        </p>
                        <h2>
                            Busque e Selecione
                        </h2>
                    </div>

                    <div className={styles.containerIconClose}>
                        <XMarkIcon title="Fechar" width={24} onClick={onRequestClose} />
                    </div>

                </header>

                <div className={styles.wrapper}>
                    <Formik
                        initialValues={{
                            searchA: '',
                            selectedCheckboxes: []
                        }}
                        onSubmit={(values) => { }}
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {({ errors, isSubmitting, values, setFieldValue, getFieldProps }: any) => (
                            <Form>
                                <div>
                                    <InputSearch
                                        name="searchA"
                                        placeholder='Buscar'
                                        value={search}
                                        handleDeleteItems={handleDeleteItems}
                                        onChange={(e) => setSearch(e.target.value)}
                                        search={search.length}
                                    />

                                    <div className={styles.wrapperAnalyze}>
                                        {
                                            items.length == 0 ?
                                                <p className={styles.textNoResult}>
                                                    Sem resultado
                                                </p>
                                                :
                                                items.map((analyze: any, index: number) => (
                                                    <label
                                                        className={styles.containerSearchResult}
                                                        key={index}
                                                    >
                                                        <input
                                                            className={styles.inputCheckbox}
                                                            type="checkbox"
                                                            {...getFieldProps('selectedCheckboxes')}
                                                            onChange={() => handleCheckboxChange(analyze.nome, analyze.codigo, analyze.selecionada)}
                                                            checked={analiseCheckboxSelected.some(item => item.nome === analyze.nome)}
                                                        />
                                                        <span>{analyze.nome}</span>
                                                    </label>
                                                ))
                                        }
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </div>
    )
}