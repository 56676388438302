import styles from "./styles.module.css";

import { Button, Text } from "@cdt-ui/react";

import { Plus } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

interface NoClassProps {
  text1: string;
  text2: string;
  fullScreen?: boolean;
  ModoNotication?: boolean;
}

export function NoClass({ text1, text2, fullScreen, ModoNotication }: NoClassProps) {

  const navigate = useNavigate();
  const navigateToCadastro = () => {
    navigate("/Cadastro");
  };

  return (
    <section className={fullScreen ? styles.wrapperFullScreen : ModoNotication ? styles.wrapperNotication : styles.wrapper}>
      <div className={styles.containerContent}>
        <div>
          <Text className={styles.containerText}>{text1}</Text>
          <Text className={styles.containerSubText}>
            {text2}
          </Text>
        </div>
        <div className={styles.containerImg}>
          <img src="/emptystate.png" alt="" />
        </div>
        <div className={styles.containerButton}>
          <Button
            variant="tertiary"
            className={styles.buttonMore}
            onClick={navigateToCadastro}
          >
            <Plus />
            Criar conta
          </Button>
        </div>
      </div>
    </section>
  );
}
