import React from 'react';
import Modal from 'react-modal';
import { Formik, Form, Field } from 'formik';
import { XMarkIcon } from '@heroicons/react/24/outline';
import styles from './styles.module.css';
import { Button } from '@cdt-ui/react';
import { ArrowRight } from '@phosphor-icons/react';


interface ModalSendMessageProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSelectOption: (option: string) => void;
}
export function ModalSendMessage({ isOpen, onRequestClose, onSelectOption }: ModalSendMessageProps) {

  return (
    <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react_modal_overlay"
        className={styles.react_modal_SendMessage}>
      
      <div className={styles.icon_wrapper}>
        <XMarkIcon
          title='Fechar'
          onClick={onRequestClose}
          />
      </div>

        <header className={styles.header}>
          <div className={styles.container_content}>
            <h2>Escolha a plataforma de envio</h2>
            <p>Escolha a plataforma que deseja enviar a mensagem</p>
          </div>
        </header>

        <Formik
          initialValues={{
          typeMessage: '',
        }}
          onSubmit={async (values) => {
            onSelectOption(values.typeMessage)
          }}  
          >
          
          <Form>
            <div role="group" className={styles.radioGroup} aria-labelledby="my-radio-group">
              <label className={styles.label}>
                <Field type="radio" name="typeMessage" value="whatsapp" required />
                  WhatsApp
              </label>
              <label className={styles.label}>
                <Field type="radio" name="typeMessage" value="e-mail" />
                  E-mail
              </label>
              <label className={styles.label}>
                <Field type="radio" name="typeMessage" value="crm" />
                  CRM
              </label>
            </div>

            <Button
              type="submit"
              variant="greenDark"
              size="full"
              className={styles.submitSendMessage}
              >
              Continuar
              <ArrowRight />
            </Button>
        </Form>
      </Formik>
    </Modal>
  );
}