import { useState, useEffect, CSSProperties } from "react";
import { Avatar } from "@cdt-ui/react";
import styles from "./styles.module.css";
import { useMediaQuery } from "react-responsive";

type Props = {
  id: number;
  imagem: string;
  paciente: string;
};

export const PatientImage = ({ id, imagem, paciente }: Props) => {
  const [style, setStyle] = useState<CSSProperties>(null);
  const isMobileScreen = useMediaQuery({ query: "max-width(600px)" });

  useEffect(() => {
    const img = document.createElement("img");
    img.src = imagem;

    img.onload = () => {
      if (img.naturalWidth > img.naturalHeight) {
        setStyle({
          height: "100%",
          objectFit: "cover",
        });
      } else {
        setStyle({
          height: "117%",
          objectFit: "contain",
        });
      }
    };
  }, [imagem]);

  return (
    <div>
      <div className={styles.containerAvatar}>
        <Avatar
          id={String(id)}
          src={`${imagem}`}
          style={style}
        />
      </div>
      {paciente}
    </div>
  );
};
