import { Button } from "@mui/material";

import {
  ShareIcon,
  ArrowDownTrayIcon,
  TrashIcon
} from "@heroicons/react/24/solid";
import { toast } from "react-hot-toast";

import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ModalShareProfile } from "../../../../components/ModalShareProfile";
import { ModalSheetChoice } from "./ModalShet/ModalSheetChoice";
import { ModalSheetShareProfile } from "./ModalSheetShareProfile/ModalSheetShareProfile";

import RarLogo from "../../../../assets/rar.png";
import ZipLogo from "../../../../assets/zip.png";
import PdfLogo from "../../../../assets/pdf.png";
import Api from "../../../../helpers/Api";

import styles from "./styles.module.css";

export function Documents({
  documentsPaciente,
  PatientNameAndId,
  imagemPerfil,
}: any) {
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [downloadingIndex, setDownloadingIndex] = useState(-1);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalSheetChoiceOpen, setModalSheetChoiceOpen] = useState(false);
  const showAddButton = selectedImages.length > 0;
  const [pdfUrl, setPdfUrl] = useState("");
  const isMobileScreen = useMediaQuery({ query: "(max-width: 684px)" });
  const [isModalSheetShareProfileOpen, setModalSheetShareProfileOpen] =
    useState(false);
  let touchTimer = null;

  const handleImageClick = (imageLink: string) => {
    setSelectedImages((prevSelected) =>
      prevSelected.includes(imageLink)
        ? prevSelected.filter((link) => link !== imageLink)
        : [...prevSelected, imageLink]
    );
    setPdfUrl(imageLink);
  };

  const openPDF = (pdfUrl: string) => {
    console.log(pdfUrl)
    const googleDocsUrl = `${pdfUrl}`;
    window.open(googleDocsUrl, "_blank");
  };

  const downloadPDF = () => {
    if (pdfUrl && !isMobileScreen) {
      window.open(pdfUrl);
    } else {
      selectedImages?.map((link) => {
        if (link) window.open(link);
      });
    }
  };

  const downloadDicomZip = async (link: string, imageIndex: number, patientNameAndId: { Paciente: string; Id: number }) => {
    if (downloadingIndex === -1) {
      try {
        setDownloadingIndex(imageIndex);

        const [, idCliente, seqAtend, complemento] = link.match(/RenderFilePatient\/(\d+)\/(\d+)\/(.+)$/);

        const data = await Api.DownloadFilePatient(idCliente, Number(seqAtend), complemento);

        const blob = new Blob([data], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        const patientNameForFile = patientNameAndId.Paciente.replace(/[^a-zA-Z0-9]/g, '_');
        a.download = `${patientNameForFile}_${patientNameAndId.Id}`;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        toast.success("Download concluído com sucesso!", {
          position: "bottom-right",
        });

      } catch (error) {
        console.error('Desculpe', error);
        toast.error('Desculpe, houve um erro ao tentar realizar o download. Por favor, tente novamente mais tarde.');
      } finally {
        setDownloadingIndex(-1);
      }
    }
  };

  const handleOPenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModalSheetChoice = () => {
    if (isMobileScreen && selectedImages.length >= 1) {
      setModalSheetChoiceOpen(true);
    }
  };

  const handleCloseModalSheetChoice = () => {
    setModalSheetChoiceOpen(false);
  };

  const handleOpenModalSheetShareProfile = () => {
    setModalSheetShareProfileOpen(true);
  };

  const handleCloseModalSheetShareProfile = () => {
    setModalSheetShareProfileOpen(false);
  };

  const handleImageTouchStart = () => {
    touchTimer = setTimeout(() => {
      handleOpenModalSheetChoice();
    }, 1000);
  };

  const handleImageTouchEnd = () => {
    clearTimeout(touchTimer);
  };

  const modifiedDocumentsPaciente = documentsPaciente?.map((image: any) => ({
    ...image,
    link: image.link.replace("PdfView", "getfile.aspx"),
  }));

  return (
    <div className={styles.wrapper}>
      {documentsPaciente?.length === 0 ? (
        <div
          style={{
            textAlign: "center",
            paddingTop: "40px",
            color: "#afafaf",
            fontWeight: 500
          }}
        >
          <p>Sem documentos no momento.</p>
        </div>
      ) : (
        <>
          <div className={styles.containerButtons}>
            {showAddButton && (
              <div className={`${styles.containerButtons_select_show}`}>
                <div className={styles.containerButton_delete}>
                  <Button
                    className={`${styles.buttonDelete} 
                                            ${isMobileScreen
                        ? styles.buttonMobile
                        : ""
                      }`}
                  >
                    <TrashIcon width={20} height={20} />{" "}
                    {!isMobileScreen && "Excluir"}
                  </Button>
                </div>

                <div className={styles.containerButton_download}>
                  <Button
                    className={`${styles.buttonDownload} 
                                            ${isMobileScreen
                        ? styles.buttonMobile
                        : ""
                      }`}
                    onClick={() => downloadPDF()}
                  >
                    <ArrowDownTrayIcon width={20} height={20} />{" "}
                    {!isMobileScreen && "Baixar"}
                  </Button>
                </div>

                <div className={styles.containerButton_share}>
                  <Button
                    className={`${styles.buttonShare} 
                                            ${isMobileScreen
                        ? styles.buttonMobile
                        : ""
                      }`}
                    onClick={
                      !isMobileScreen
                        ? handleOPenModal
                        : handleOpenModalSheetShareProfile
                    }
                  >
                    <ShareIcon width={20} height={20} />{" "}
                    {!isMobileScreen && "Compartilhar"}
                  </Button>
                </div>
              </div>
            )}

            <div>
            </div>
          </div>

          <div className={styles.wrapperImg}>
            {
              <div className={styles.wrapper_imagens}>
                {modifiedDocumentsPaciente?.map((image: any, imageIndex: number) => (
                  <div
                    key={imageIndex}
                    className={`${styles.wrapperImage}`}
                    style={{
                      cursor: downloadingIndex === imageIndex && 'not-allowed',
                      border: downloadingIndex === imageIndex && '1px solid #e4e4e4'
                    }}
                    onClick={() =>
                      image.complemento.endsWith('.rar') ||
                        image.complemento.endsWith('.zip')
                        ? downloadDicomZip(image.link, imageIndex, PatientNameAndId)
                        : openPDF(image.link)
                    }
                  >
                    <div
                      className={`${styles.containerImage}`}
                      onTouchStart={handleImageTouchStart}
                      onTouchEnd={handleImageTouchEnd}
                    >
                      <div
                        className={
                          selectedImages.includes(image.link) && styles.containerImage_active
                        }
                      ></div>
                      {downloadingIndex === imageIndex && (
                        <div className={styles.container_buttons_loading}>
                          <div className={styles.loading_spinne}></div>
                          <p>Download em andamento...</p>
                        </div>
                      )}
                      {downloadingIndex !== imageIndex && (
                        <img
                          src={
                            image.complemento.endsWith('.rar') || image.complemento.endsWith('.zip')
                              ? `${image.complemento.endsWith('.zip') ? ZipLogo : RarLogo}`
                              : image.complemento.endsWith('.pdf') ? PdfLogo
                                :
                                `${image.icone}?tw=430&th=429`
                          }
                          title={
                            image.complemento.endsWith('.rar') || image.complemento.endsWith('.zip')
                              ? 'Download'
                              : 'Visualizar PFD'
                          }
                          style={{ cursor: 'pointer' }}
                          onError={(e: any) => {
                            e.target.onerror = null;
                            e.target.src =
                              'https://cyberbullying.org/wp-content/uploads/2019/02/pdf-download-300x300.png';
                          }}
                        />
                      )}
                    </div>

                    <div className={styles.containerContentImage}>
                      <strong>{image.nome}</strong>
                    </div>
                  </div>
                ))}
              </div>
            }
          </div>
        </>
      )}

      {isModalOpen && (
        <ModalShareProfile
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          selectedImages={selectedImages}
          PatientNameAndId={PatientNameAndId}
        />
      )}
      {isModalSheetChoiceOpen && (
        <ModalSheetChoice
          isOpen={isModalSheetChoiceOpen}
          onRequestClose={handleCloseModalSheetChoice}
          handleOpenModalSheetShareProfile={handleOpenModalSheetShareProfile}
          selectedImages={selectedImages}
        />
      )}
      {isModalSheetShareProfileOpen && (
        <ModalSheetShareProfile
          isOpen={isModalSheetShareProfileOpen}
          onRequestClose={handleCloseModalSheetShareProfile}
          selectedImages={selectedImages}
          PatientImageSource={imagemPerfil}
          PatientNameAndId={PatientNameAndId}
        />
      )}
    </div>
  );
}
