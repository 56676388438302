import { ChevronUpDownIcon, StarIcon } from '@heroicons/react/24/outline'

import styles from './styles.module.css'

const items: number[] = [...new Array(5).keys() as any]

export function Assessments({ assessmentsPaciente }: any) {
    return (
        <div className={styles.wrapper}>
            {
                assessmentsPaciente == undefined
                    ?
                    <div style={{
                        textAlign: "center",
                        paddingTop: "40px",
                        color: "#afafaf",
                        fontWeight: 500
                    }}>
                        <p className={styles.text}>
                            Sem Avaliações no momento.
                        </p>
                    </div>
                    :
                    <table className={styles.tableAssessment}>
                        <thead className={styles.tableAssessment_thead}>
                            <tr>
                                <th>
                                    Tipo de Avaliação
                                    <ChevronUpDownIcon width={12} />
                                </th>
                                <th>
                                    Classificação
                                    <ChevronUpDownIcon width={12} />
                                </th>
                            </tr>
                        </thead>

                        <tbody className={styles.tableAssessment_tbody}>
                            <tr>
                                <td>
                                    Avaliação de atendimento
                                </td>
                                <td>
                                    {
                                        items.map((index) => (
                                            <StarIcon
                                                key={index}
                                                width={20}
                                            />
                                        ))
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Avaliação de atendimento
                                </td>
                                <td>
                                    {
                                        items.map((index) => (
                                            <StarIcon
                                                key={index}
                                                width={20}
                                            />
                                        ))
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
            }
        </div>
    )
}