import React, { useState } from 'react';
import Sheet from 'react-modal-sheet';
import styles from './styles.module.css';
import { Avatar, Button } from '@cdt-ui/react';
import { ChevronDownIcon, GlobeAmericasIcon, PencilSquareIcon, PaperAirplaneIcon, LinkIcon, XMarkIcon, ShareIcon, PhotoIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import { Input } from '../../../../../components/Form/Input';
import { TextArea } from '../../../../../components/Form/TextArea';
import { Formik, Form, FormikValues } from 'formik';
import { FilePdf } from '@phosphor-icons/react';
import { color } from 'framer-motion';
import Select from 'react-select';
import * as yup from 'yup'

interface ModalSheetShareProps {
    isOpen: boolean;
    onRequestClose: () => void;
    selectedImages: string[];
    PatientNameAndId: any;
    PatientImageSource: any;
}

export const ModalSheetShareProfile = ({isOpen, onRequestClose, selectedImages, PatientNameAndId, PatientImageSource} : ModalSheetShareProps) => {
    const shareOptions = [
/*         {value: 'editar perfil', label: 'Editar o perfil'}, */
        {value: 'somente visualizar', label: 'Somente visualizar'},
        /* {value: 'visulizar e baixar', label: 'Visualzar e baixar'} */
    ]
    
    const [selected, setSelected] = useState([]);
    const [allImagens, setAllImagens] = useState(selectedImages);
    const [errorMensagem, setErrorMensagem] = useState(false);

    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .email('E-mail inválido, verifique e tente novamente')
            .max(45, ({ max }) => `máximo ${max} caracteres`)
            .required('Campo obrigatório'),
        dentistName: yup.string().required('Campo obrigatório'),
        message: yup.string().required('Campo obrigatório'),
        viewType: yup.string().required('Campo obrigatório')
    })

    const handleSubmit = async (values: FormikValues) => {
        console.log(values)
    }

    const handleRemoveImage = (indexToRemove?: number) => {
        if (allImagens.length <= 1) {
            setErrorMensagem(true)
            return;
        }

        const newImages = allImagens.filter((_, index) => index !== indexToRemove);
        setAllImagens(newImages);
    };

    return (
        <Sheet isOpen={isOpen} onClose={onRequestClose} detent="content-height">
            <Sheet.Container style={{borderRadius: '30px 30px 0px 0px', overflowY: 'scroll'}}>
                <Sheet.Header />
                    <Sheet.Content>
                        <div className={styles.container} >
                            <div className={styles.textButtons}>
                                <div className={styles.textButtonHeader}>
                                    <h2>Compartilhar perfil do paciente</h2>
                                    <small>O destinatário poderá visualizar os dados e as imagens, sem login.</small>
                                </div>
                                <div className={styles.textButton}>
                                    <h3>Paciente</h3>
                                    <div className={styles.profile}>
                                    <img
                                        src={`${PatientImageSource}`} alt="Foto do perfil do paciente"
                                        className={styles.imagePerfilPaciente}
                                    />
                                        <div>
                                            <strong>
                                                {
                                                    PatientNameAndId && PatientNameAndId.Paciente ?
                                                        PatientNameAndId.Paciente
                                                        :
                                                        `Nome paciente`
                                                }
                                            </strong>
                                            <span>
                                                {
                                                    PatientNameAndId && PatientNameAndId.Paciente ?
                                                        `#${PatientNameAndId.Id}`
                                                        :
                                                        `Id paciente`
                                                }
                                            </span>
                                        </div>
                                </div>
                                </div>
                                <div className={styles.textButton}>
                                {
                                    selectedImages ?
                                        <div className={styles.containerImages}>
                                            {
                                                allImagens?.map((imagem, index) => (
                                                    <div key={index} className={styles.imagem}>
                                                        {
                                                            imagem.endsWith('.pdf') ? (
                                                                <div className={styles.imagePfd}>
                                                                    <FilePdf />
                                                                </div>
                                                            ) : (
                                                                <img src={imagem} alt={`Imagem ${index}`} />
                                                            )
                                                        }
                                                        <div className={styles.containerIconClose}>
                                                            <XMarkIcon
                                                                title='Excluir'
                                                                onClick={() => handleRemoveImage(index)}
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <button className={styles.addImagesButton} onClick={onRequestClose}>
                                                <PlusSmallIcon width={32} height={32} color='#007E69'/>
                                            </button>
                                            {
                                                errorMensagem && (
                                                    <span className={styles.errorImage}>
                                                        Necessário uma imagem
                                                    </span>
                                                )
                                            }
                                        </div>
                                        :
                                        <div className={styles.border}></div>
                                }
                                </div>
                                <Formik
                                    initialValues={{
                                        typeMessage: '',
                                    }}
                                    onSubmit={(values) => handleSubmit(values)}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    validationSchema={validationSchema}
                                >
                                    {({ errors, isSubmitting, values, setFieldValue }: any) => (
                                        <Form>
                                            <div className={styles.input_group}>
                                                <div className={styles.input_wrapper}>
                                                    <Input
                                                        name="emailToShare"
                                                        type='text'
                                                        title='Para'
                                                        labelStyleAbsolute
                                                        placeholder='exemplo@exemplo.com'
                                                        error={errors.emailToShare}                                                    />
                                                </div>

                                                <div className={styles.input_wrapper}>
                                                    <Input
                                                        name="dentist"
                                                        type='text'
                                                        title='Dentista'
                                                        labelStyleAbsolute
                                                        placeholder='Nome do dentista'
                                                        error={errors.dentist}
                                                    />
                                                </div>

                                                <div className={styles.input_wrapper}>
                                                    <TextArea
                                                        name="messageToShare"
                                                        title='Mensagem'
                                                        labelStyleAbsolute
                                                        placeholder='Lorem ipsum dolor sit amet'
                                                        error={errors.messageToShare}
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.textButton}>
                                                <h3>Selecione o tipo de compartilhamento</h3>
                                                <div className={styles.shareCard}>
                                                    <div className={styles.shareCardInfo}>
                                                        <div className={styles.circle}>
                                                            <PhotoIcon color='#919DAF' width={32} height={32} />
                                                        </div>
                                                        <div className={styles.shareCardText}>
                                                            <h4>Somente Documento</h4>
                                                            <p>A pessoa não visualizará o perfil do paciente, este poderá visualizar apenas os documentos compartilhados</p>
                                                        </div>
                                                        <ChevronDownIcon color='#57667A' width={32} height={32} />
                                                    </div>
                                                    <Select
                                                        className={`${styles.select}`}
                                                        options={shareOptions}
                                                        value={selected}
                                                        onChange={setSelected}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                            ...theme.colors,
                                                            primary: "#DCDCDC",
                                                            },
                                                        })}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            minHeight: "50px",
                                                            maxHeight: "auto",
                                                            backgroundColor: "#fff" ,
                                                            border: state.isFocused ? '1px solid #007E69' : '1px solid #afafaf',
                                                            borderRadius: "12px",
                                                            paddingLeft: "7px",
                                                            outline: "none",
                                                            }),
                                                            option: (baseStyles) => ({
                                                                ...baseStyles,
                                                                color: "black"
                                                            }),
                                                    }}
                                                        defaultValue={shareOptions[0]}
                                                        /* valueRenderer={customValueRenderer} */
                                                    />
                                                    </div>
                                            </div>

                                            <div className={styles.container_buttons}>
                                                <Button
                                                    type="submit"
                                                    variant="greenDark"
                                                    size="full"
                                                    >
                                                    <PaperAirplaneIcon />
                                                    Enviar
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="tertiary"
                                                    size="full"
                                                    >
                                                    <LinkIcon />
                                                    Copiar Link
                                                </Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </Sheet.Content>
                    <footer></footer>
            </Sheet.Container>
            <Sheet.Backdrop />
        </Sheet>
    )
}