import { getAllModels } from "../../utils/apiConnection";
import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {
    EllipsisVerticalIcon,
    PencilIcon,
    TrashIcon,
    PlusIcon
} from "@heroicons/react/24/outline";

import { SearchInput } from "../../components/SearchInput";
import { Footer } from "../../../../components/Footer";
import { Loading } from "../../../../components/Loading";
import { ModalExit } from "../../../../components/ModalExit";

import styles from './styles.module.css'
import { ModalDeleteModelo } from "../../Modals/ModalDeleteModelo/ModalDeleteModelo";

export function ListOfModels() {
    const location = useLocation();
    const navigate = useNavigate();

    const iconRef = useRef(null);
    const optionCardRef = useRef(null);

    const [searchTerm, setSearchTerm] = useState("");
    const [modelsData, setModelsData] = useState([]);
    const [filteredModelsData, setFilteredModelsData] = useState(modelsData);
    const [activeOptionIndex, setActiveOptionIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isModalExcluir, setIsModalExcluir] = useState(false);
    const [selectedModelToDeleteOrEdit, setSelectedModelToDeleteOrEdit] = useState<{}>({})
    const [getResultDeleteModel, setGetResultDeleteModel] = useState(false);


    const getUserFromPathname = () => {
        const pathParts = location.pathname.split('/');
        const userParam = pathParts.find(part => part.startsWith('user='));
        return userParam ? userParam.split('=')[1] : null;
    };

    const idUser = Number(getUserFromPathname());

    const handleSearchChange = (event) => {
        const searchValue = event.target.value;
        setSearchTerm(searchValue);

        const filteredData = modelsData.filter((model) =>
            model.name.toLowerCase().includes(searchValue.toLowerCase())
        );

        setFilteredModelsData(filteredData);
    };

    const handleOptionActive = (idModel: number) => {
        if (idModel === activeOptionIndex) {
            setActiveOptionIndex(null);
        } else {
            setActiveOptionIndex(idModel);
            const ModelSelected = filteredModelsData.find((model) => model.id == idModel)
            setSelectedModelToDeleteOrEdit(ModelSelected)
        }
    };

    const handleEditModel = (modelId) => {
        navigate(`/dashboard/requisição-exames/editar-modelo/${modelId}`);
    };

    const redirectToEditor = () => {
        navigate("/dashboard/requisição-exames/criar");
    };

    const processHtml = (htmlContent) => {
        return htmlContent
            .replace(/height:\s*\d+px;/g, 'height: 30px;')
            .replace(/width:\s*\d+px;/g, 'width: 100%;')
            .replace(/padding:\s*\d+px;/g, 'padding: 0;')
            .replace(/margin:\s*\d+px;/g, 'margin: 0;')
            .replace(/(<p class="header"[^>]*style=")([^"]*)"/g, '$1$2; border-radius: 16px 16px 0 0;"')
            .replace(/(<p class="footer"[^>]*style=")([^"]*)"/g, '$1$2; color: rgb(3, 27, 78);"')
            .replace(/(<p class="footer"(?![^>]*style=)[^>]*>)/g, '$1 style="color: rgb(3, 27, 78);"')
            .replace(/img/g, 'img style="max-width:100%; max-height:100%; object-fit: contain; display: block; margin: 0 auto;"');
    };

    const handleOpenAndCloseModalExluir = () => {
        // setNamePdfSelect(namePdf)
        setIsModalExcluir(!isModalExcluir);
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (
                optionCardRef.current &&
                !optionCardRef.current.contains(e.target) &&
                !iconRef.current.contains(e.target)
            ) {
                handleOptionActive(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [activeOptionIndex]);

    useEffect(() => {
        getAllModels(idUser, setModelsData, setFilteredModelsData, setLoading);
    }, [getResultDeleteModel]);

    return (
        <div className={styles.wrapperListOfModels}>
            <div className={styles.containerHeader}>
                <div className={styles.input}>
                    <SearchInput
                        placeholder="Buscar"
                        searchTerm={searchTerm}
                        handleSearchChange={handleSearchChange}
                        maxWidth="356px"
                    />
                </div>

                <div className={styles.containerButton}>
                    <button
                        type="button"
                        className={styles.button}
                        onClick={redirectToEditor}
                    >
                        <PlusIcon width={20} />
                        Nova Requisição
                    </button>
                </div>
            </div>

            {loading ? (
                <div className={styles.loadingMessage}>
                    <Loading text="Aguarde, carregando lista de modelos..." loadingPacientList />
                </div>
            ) : (
                filteredModelsData.length === 0 ? (
                    <div className={styles.containerTextUserFalse}>
                        <p>Atualmente, não foi feita nenhuma requisição de exames.</p>
                    </div>
                ) : (
                    <div className={styles.modelsContainer}>
                        {filteredModelsData.map((model, index) => (
                            <div key={index} className={styles.modelCard}>
                                <div
                                    className={styles.modelContent}
                                    dangerouslySetInnerHTML={{ __html: processHtml(model.html) }}
                                />
                                <div className={styles.modelName}>
                                    <span>{model.name}</span>

                                    <EllipsisVerticalIcon
                                        width={20}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleOptionActive(model.id);
                                        }}
                                    />

                                    {activeOptionIndex === model.id && (
                                        <>
                                            <div
                                                className={styles.overlay}
                                                onClick={() => handleOptionActive(null)}
                                            />
                                            <div className={styles.optionCard}>
                                                <div
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleEditModel(model.id);
                                                    }}
                                                >
                                                    <PencilIcon width={20} />
                                                    Editar Modelo
                                                </div>
                                                <div
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleOpenAndCloseModalExluir();
                                                    }}
                                                >
                                                    <TrashIcon color="white" width={20} />
                                                    Excluir Modelo
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )
            )}

            <div className={styles.containerFooter}>
                <Footer />
            </div>

            {
                isModalExcluir && (
                    <ModalDeleteModelo
                        isOpen={isModalExcluir}
                        onRequestClose={handleOpenAndCloseModalExluir}
                        selectedModelId={activeOptionIndex}
                        selectedModelToDeleteOrEdit={selectedModelToDeleteOrEdit}
                        setGetResultDeleteModel={setGetResultDeleteModel}
                    />
                )
            }
        </div>
    );
}