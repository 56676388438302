export function EnviosIcon({ type, filled }) {
  return (
    <div>
      {type === "whatsapp" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.0863 2.90644C15.2062 1.03311 12.7057 0.00111111 10.0419 0C4.55291 0 0.0857439 4.44556 0.083511 9.90978C0.0828412 11.6564 0.541259 13.3613 1.41277 14.8644L0 20L5.27906 18.6218C6.73358 19.4113 8.3712 19.8276 10.0378 19.828H10.0419C10.0416 19.828 10.0421 19.828 10.0419 19.828C15.5302 19.828 19.9978 15.382 20 9.91756C20.0011 7.26956 18.9664 4.77978 17.0863 2.90644ZM5.99783 10.2704C5.87346 10.1051 4.98186 8.92778 4.98186 7.70889C4.98186 6.49 5.62471 5.89089 5.85269 5.64311C6.08068 5.39533 6.35041 5.33333 6.51632 5.33333C6.68222 5.33333 6.84835 5.33489 6.99327 5.342C7.146 5.34978 7.3512 5.28422 7.55306 5.76711C7.7605 6.26289 8.25799 7.482 8.32029 7.60578C8.38259 7.72978 8.4239 7.87422 8.34106 8.03956C8.25821 8.20467 8.21668 8.308 8.09231 8.45267C7.96794 8.59733 7.83106 8.77556 7.71897 8.88644C7.59437 9.01 7.46464 9.144 7.60978 9.39178C7.75491 9.63978 8.2542 10.4507 8.99396 11.1073C9.94429 11.9511 10.7461 12.2124 10.9949 12.3364C11.2436 12.4604 11.3888 12.4398 11.5339 12.2744C11.679 12.1091 12.156 11.5513 12.3219 11.3036C12.4878 11.0558 12.6537 11.0971 12.8817 11.1796C13.1099 11.2622 14.3331 11.8613 14.5818 11.9851C14.8306 12.1091 14.9965 12.1711 15.0588 12.2744C15.1211 12.3778 15.1211 12.8736 14.9136 13.452C14.7062 14.0304 13.7121 14.5584 13.234 14.6293C12.8053 14.6931 12.2629 14.7198 11.6668 14.5311C11.3055 14.4171 10.8419 14.2647 10.2482 14.0096C7.75246 12.9369 6.12243 10.4358 5.99783 10.2704Z"
            fill={filled ? "#00AC98" : "#D3D3D3"}
          />
        </svg>
      ) : type === "email" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M1.25 7.22425V14.375C1.25 15.7557 2.36929 16.875 3.75 16.875H16.25C17.6307 16.875 18.75 15.7557 18.75 14.375V7.22425L11.3102 11.8026C10.5067 12.297 9.49327 12.297 8.68976 11.8026L1.25 7.22425Z"
            fill={filled ? "#00AC98" : "#D3D3D3"}
          />
          <path
            d="M18.75 5.75652V5.625C18.75 4.24429 17.6307 3.125 16.25 3.125H3.75C2.36929 3.125 1.25 4.24429 1.25 5.625V5.75652L9.34488 10.738C9.74664 10.9852 10.2534 10.9852 10.6551 10.738L18.75 5.75652Z"
            fill={filled ? "#00AC98" : "#D3D3D3"}
          />
        </svg>
      ) : type === "cdt" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M14.425 5.57501H10C7.56252 5.57501 5.57501 7.56251 5.57501 10C5.57501 12.4375 7.56252 14.425 10 14.425C2.02188 14.425 1 10 1 10C1 5.03126 5.03126 1 10 1H14.425V5.57501Z"
            fill={filled ? "#00AC98" : "#D3D3D3"}
          />
          <path
            d="M10 14.425C12.4375 14.425 14.425 12.4375 14.425 10V5.57501V1H19V10C19 14.9688 14.9688 19 10 19C5.03126 19 1 14.9688 1 10C1 10 2.02188 14.425 10 14.425Z"
            fill={filled ? "#00AC98" : "#D3D3D3"}
          />
          <path
            d="M10.1314 11.7907C11.2239 11.7907 12.1096 10.9051 12.1096 9.8126C12.1096 8.72011 11.2239 7.83447 10.1314 7.83447C9.03896 7.83447 8.15332 8.72011 8.15332 9.8126C8.15332 10.9051 9.03896 11.7907 10.1314 11.7907Z"
            fill={filled ? "#00AC98" : "#D3D3D3"}
          />
        </svg>
      ) : (
        <></>
      )}
    </div>
  );
}
