export function WarningBadge({ styles, tempo }) {
  return (
    <div>
      <span className={styles.warningBadge}>
        <div className={styles.warningBadgeContainer}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M7 12.25C9.8995 12.25 12.25 9.8995 12.25 7C12.25 4.10051 9.8995 1.75 7 1.75C4.10051 1.75 1.75 4.10051 1.75 7C1.75 9.8995 4.10051 12.25 7 12.25Z"
              stroke="#C80000"
              stroke-miterlimit="10"
            />
            <path
              d="M7 4.375V7.4375"
              stroke="#C80000"
              strokeLinecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7 10.0625C7.36244 10.0625 7.65625 9.76869 7.65625 9.40625C7.65625 9.04381 7.36244 8.75 7 8.75C6.63756 8.75 6.34375 9.04381 6.34375 9.40625C6.34375 9.76869 6.63756 10.0625 7 10.0625Z"
              fill="#C80000"
            />
          </svg>
        </div>
        <div>1 ano</div>
      </span>
    </div>
  );
}
