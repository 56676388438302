import styles from './styles.module.css';
import { MinusSmallIcon } from '@heroicons/react/24/outline';

const DoubleEllipsisHorizontalIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6 7.69995C18.4836 7.69995 19.2 8.41629 19.2 9.29994C19.2 10.1836 18.4836 10.8999 17.6 10.8999C16.7163 10.8999 16 10.1836 16 9.29994C16 8.41629 16.7163 7.69995 17.6 7.69995ZM11.9999 7.69995C12.8835 7.69995 13.5999 8.41629 13.5999 9.29994C13.5999 10.1836 12.8835 10.8999 11.9999 10.8999C11.1162 10.8999 10.3999 10.1836 10.3999 9.29994C10.3999 8.41629 11.1162 7.69995 11.9999 7.69995ZM7.99976 9.29994C7.99976 8.41629 7.28342 7.69995 6.39977 7.69995C5.51612 7.69995 4.79978 8.41629 4.79978 9.29994C4.79978 10.1836 5.51612 10.8999 6.39977 10.8999C7.28342 10.8999 7.99976 10.1836 7.99976 9.29994ZM7.99917 15.7001C7.99917 14.8165 7.28283 14.1001 6.39918 14.1001C5.51554 14.1001 4.7992 14.8165 4.7992 15.7001C4.7992 16.5838 5.51554 17.3001 6.39918 17.3001C7.28283 17.3001 7.99917 16.5838 7.99917 15.7001ZM13.5993 15.7001C13.5993 14.8165 12.8829 14.1001 11.9993 14.1001C11.1156 14.1001 10.3993 14.8165 10.3993 15.7001C10.3993 16.5838 11.1156 17.3001 11.9993 17.3001C12.8829 17.3001 13.5993 16.5838 13.5993 15.7001ZM19.1994 15.7001C19.1994 14.8165 18.4831 14.1001 17.5994 14.1001C16.7158 14.1001 15.9995 14.8165 15.9995 15.7001C15.9995 16.5838 16.7158 17.3001 17.5994 17.3001C18.4831 17.3001 19.1994 16.5838 19.1994 15.7001Z" fill="#AFAFAF"/>
    </svg>
  );
};

const HeaderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
      <path d="M26 19.5V16C26 13.5147 23.9853 11.5 21.5 11.5H19.5C18.6716 11.5 18 10.8284 18 10V8C18 5.51472 15.9853 3.5 13.5 3.5H11M14 3.5H7.5C6.67157 3.5 6 4.17157 6 5V28C6 28.8285 6.67157 29.5 7.5 29.5H24.5C25.3285 29.5 26 28.8285 26 28V15.5C26 8.87258 20.6274 3.5 14 3.5Z" stroke="#7B889C" stroke-width="2" strokeLinecap="round" stroke-linejoin="round"/>
      <path d="M11.2 16.5H17.6" stroke="#7B889C" stroke-width="2" strokeLinecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

const FooterIcon = () => {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
    <path d="M26 19.5V16C26 13.5147 23.9853 11.5 21.5 11.5H19.5C18.6716 11.5 18 10.8284 18 10V8C18 5.51472 15.9853 3.5 13.5 3.5H11M14 3.5H7.5C6.67157 3.5 6 4.17157 6 5V28C6 28.8285 6.67157 29.5 7.5 29.5H24.5C25.3285 29.5 26 28.8285 26 28V15.5C26 8.87258 20.6274 3.5 14 3.5Z" stroke="#7B889C" stroke-width="2" strokeLinecap="round" stroke-linejoin="round"/>
    <path d="M11.2 24.5H17.6" stroke="#7B889C" stroke-width="2" strokeLinecap="round" stroke-linejoin="round"/>
  </svg>
  );
};

interface AddItensToScreenProps {
  isHeaderActive: boolean;
  setHeaderActive: React.Dispatch<React.SetStateAction<boolean>>;
  isFooterActive: boolean;
  setFooterActive: React.Dispatch<React.SetStateAction<boolean>>;
  insertDivision: () => void;
}

export const AddItensToScreen = ({
  isHeaderActive,
  setHeaderActive,
  isFooterActive,
  setFooterActive,
  insertDivision
}: AddItensToScreenProps) => {

  const toggleHeaderActive = () => {
    setHeaderActive(current => !current);
  }

  const toggleFooterActive = () => {
    setFooterActive(current => !current)
  }

  return (
    <div className={styles.addItensToScreenWrapper}>
      <p className={styles.title}>Estilos</p>
      <div className={styles.addItensToScreenCards}>
        <div
          onClick={toggleHeaderActive}
          className={`${styles.addItensToScreenCard} ${isHeaderActive ? styles.selected : ''}`}
          >
            <DoubleEllipsisHorizontalIcon />
            <HeaderIcon />
            <p>Cabeçalho</p>
          </div>

          <div
            onClick={toggleFooterActive}
            className={`${styles.addItensToScreenCard} ${isFooterActive ? styles.selected : ''}`}
            >
            <DoubleEllipsisHorizontalIcon />
            <FooterIcon />
            <p>Rodapé</p>
          </div>

          <div 
            onClick={insertDivision}
            className={styles.addItensToScreenCard}
            >
            <DoubleEllipsisHorizontalIcon />
            <MinusSmallIcon width={32} color='#7B889C' />
            <p>Divisória</p>
          </div>
        </div>
      </div>
  );
};