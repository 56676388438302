import { Formik, Form, Field } from "formik";
import styles from './styles.module.css';
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

interface SearchInputProps {
  placeholder: string;
  searchTerm: string;
  handleSearchChange: (event: any) => void;
  maxWidth ?: number | string;
}

export const SearchInput = ({
  placeholder,
  handleSearchChange,
  searchTerm,
  maxWidth
}: SearchInputProps) => {
  return (
    <Formik
    initialValues={{}}
    onSubmit={() => {}}
    >
    {({  }) => (
    <Form>
      <div className={styles.wrapper_input} style={maxWidth && {maxWidth: maxWidth, width: '100%'}}>
        <MagnifyingGlassIcon width={16} className={styles.searchIcon} />
        <Field
          name="search"
          type="text"
          placeholder={placeholder}
          className={styles.searchInput}
          onChange={handleSearchChange}
          value={searchTerm}
        />
      </div>
    </Form>
    )}
  </Formik>
  );
};