import React, { useMemo } from 'react';
import Modal from "react-modal";
import { Line } from 'rc-progress';
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import styles from './styles.module.css'
import zIndex from '@mui/material/styles/zIndex';

interface ModalProcessingProps {
    isOpen: boolean;
    onRequestClose: () => void;
    progressValue: number;
    label: string;
}

export function ModalProcessing({ isOpen, progressValue, label, onRequestClose }: ModalProcessingProps) {
    
    const LightTooltip = useMemo(() => styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 14,
            borderRadius: 12,
            zIndex: 1001,
            position: 'relative',
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: '#fff',
            zIndex: 1001
        },
    })), []);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react_modal_overlay"
            className="react-modal-processIa"
        >
            <div className={styles.wrapper}>
                <LightTooltip
                    title={
                        <div className={styles.containerTitleTooltip}>
                            <p>
                                <strong>Por favor, aguarde...</strong> <br />
                                Pode levar algum tempo, dependendo do arquivo e da conexão.
                            </p>
                        </div>
                    }
                    arrow
                    color="#fff"
                    placement="right"
                >
                    <QuestionMarkCircleIcon className={styles.svgTooltip} />
                </LightTooltip>

                <div className={styles.containerTitle}>
                    <h2>Processando...</h2>
                    <p>Isso pode levar alguns minutos. Por favor, aguarde.</p>
                </div>

                <div className={styles.containerLine}>
                    <Line
                        percent={progressValue}
                        strokeWidth={4}
                        strokeColor="#2EBBAA"
                        trailWidth={4}
                        trailColor="#666666"
                    />
                    <p>
                        {progressValue}%
                        <span>{label}</span>
                    </p>
                </div>
            </div>
        </Modal>
    )
}