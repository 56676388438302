import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    paginationConcluidos: {},
};

export const ToConcludePaginationConcluidosSlice = createSlice({
    name: "paginationConcluidos",
    initialState,
    reducers: {
        setToConcludePaginationConcluidos: (state, action: PayloadAction<{ idClient: any, currentPage: number, totalPages: number, toConcludeCefx: any }>) => {
            const { idClient, currentPage, totalPages, toConcludeCefx } = action.payload;
            state.paginationConcluidos[idClient] = { currentPage, totalPages, toConcludeCefx };
        },
        clearPaginationConcluidos: (state) => {
            state.paginationConcluidos = {};
        },
    }
})

export const { setToConcludePaginationConcluidos, clearPaginationConcluidos } = ToConcludePaginationConcluidosSlice.actions;

export const selectPaginationCefxConcluidos = (state: any) => state.paginationConcluidos;

export default ToConcludePaginationConcluidosSlice.reducer;
