// Filters/selectStyles.ts
import { StylesConfig } from 'react-select';

interface OptionType {
    value: string | number;
    label: string;
}

export const customSelectStylesExames: StylesConfig<OptionType, boolean> = {
    container: () => ({
        width: "100%",
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        backgroundColor: "#fff",
        borderRadius: state.menuIsOpen ? "12px 12px 0 0" : "12px",
        border: "1px solid #919DAF",
        boxShadow: "none",
        cursor: state.isDisabled ? 'not-allowed' : 'default',
         height: "50px",
        "&:hover": {
            borderColor: "none",
        },
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        zIndex: "11",
        width: "100%",
        marginTop: '-33px',
        borderRadius: "0px 0px 12px 12px",
        borderLeft: "1px solid  #919DAF",
        borderRight: "1px solid  #919DAF",
        borderBottom: "1px solid  #919DAF",
        boxShadow: "none",
        overflow: "hidden",
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: state.isSelected ? "#def3f1" : "#fff",
        fontWeight:
            state.isSelected || state.isFocused ? "500" : "400",
        margin: "0px auto",
        width: "100%",
        borderRadius: "2px",
    }),
    dropdownIndicator: (baseStyles, state) => ({
        ...baseStyles,
        color: '#919DAF',
        transform: state.isFocused ? "rotate(-180deg)" : "",
        cursor: "pointer",
    }),
    indicatorSeparator: (baseStyles, state) => ({
        ...baseStyles,
        display: "none",
    }),
    multiValue: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "#E4E4E4",
        borderRadius: "12px",
    }),
    multiValueLabel: (baseStyles) => ({
        ...baseStyles,
        color: "black",
    }),
    placeholder: (baseStyles) => ({
        ...baseStyles,
        color: "#919DAF",
    }),
    menuList: (baseStyles) => ({
        ...baseStyles,
        "::-webkit-scrollbar": {
            width: "8px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1",
            borderRadius: "0px",
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "0px",
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555",
        },
    }),
};

