import styles from "./styles.module.css";

export function LoadingClinics() {
  return (
    <div className={styles.loading_container}>
      <div className={styles.loading_spinne}></div>
      <p>Aguarde, carregando clínicas...</p>
    </div>
  );
}
