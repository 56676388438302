import { Text } from "@cdt-ui/react";
import styles from "./styles.module.css";

import { StarIcon } from "@heroicons/react/24/solid";
import { BookOpenIcon } from "@heroicons/react/24/outline";
import {
  Star,
  Student,
  YoutubeLogo,
  InstagramLogo,
  LinkedinLogo,
  FacebookLogo,
  Link,
} from "@phosphor-icons/react";
import Avatar from "react-avatar";

export function UserCard({ teacher }) {
  return (
    <div className={styles.userCard}>
      <div className={styles.cardRow}>
        <div className={styles.profileDetailsContainer}>
          <Avatar className={styles.profilePic} src={teacher.photo} />
          <div>
            <h3 className={styles.name}>{teacher.name}</h3>
            <Text className={styles.subTitle}>
              Especialização em{" "}
              {typeof teacher.occupationAreas !== "undefined" ? (
                teacher.occupationAreas.map(({ name }, index) => {
                  if (teacher.occupationAreas.length === index + 1) {
                    return <span>{name}</span>;
                  } else {
                    return <span>{name}, </span>;
                  }
                })
              ) : (
                <></>
              )}
            </Text>
          </div>
        </div>
      </div>

      <p className={styles.text}>{teacher.description}</p>

      <div className={styles.bubbles}>
        {teacher.linkProfileYoutube === null ||
        teacher.linkProfileYoutube === "" ? (
          <></>
        ) : (
          <a target="_blank" rel="noreferrer" href={teacher.linkProfileYoutube}>
            <div className={styles.bubbleContainer}>
              <YoutubeLogo color="#007E69" width={16} weight="bold" />
            </div>
          </a>
        )}

        {teacher.linkProfileInstagram === null ||
        teacher.linkProfileInstagram === "" ? (
          <></>
        ) : (
          <a
            target="_blank"
            rel="noreferrer"
            href={teacher.linkProfileInstagram}
          >
            <div className={styles.bubbleContainer}>
              <InstagramLogo color="#007E69" width={16} weight="bold" />
            </div>
          </a>
        )}

        {teacher.linkProfileLinkedin === null ||
        teacher.linkProfileLinkedin === "" ? (
          <></>
        ) : (
          <a
            target="_blank"
            rel="noreferrer"
            href={teacher.linkProfileLinkedin}
          >
            <div className={styles.bubbleContainer}>
              <LinkedinLogo color="#007E69" width={16} weight="bold" />
            </div>
          </a>
        )}

        {teacher.linkProfileFacebook === null ||
        teacher.linkProfileFacebook === "" ? (
          <></>
        ) : (
          <a
            target="_blank"
            rel="noreferrer"
            href={teacher.linkProfileFacebook}
          >
            <div className={styles.bubbleContainer}>
              <FacebookLogo color="#007E69" width={16} weight="bold" />
            </div>
          </a>
        )}
      </div>
    </div>
  );
}
