import { Field } from "formik";
import { InputHTMLAttributes, KeyboardEvent } from "react";

import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";

import styles from "./styles.module.css";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  type?: string;
  loadingicon?: boolean;
  handleDeleteSearch?: () => void;
  onEnterPress?: () => void;
}

export function Input({ onEnterPress, handleDeleteSearch, loadingicon, ...props }: InputProps) {

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onEnterPress) {
      onEnterPress();
    }
  }

  return (
    <div className={styles.wrapper_input}>
      {

        loadingicon ?
          <div className={styles.container_buttons_loading}>
            <div className={styles.loading_spinne}></div>
          </div>
          :
          <MagnifyingGlassIcon width={16} color='#64748B' />
      }

      <Field
        {...props}
        type={props.type}
        className={styles.input}
        onKeyDown={handleKeyDown}
      />

      {
        props.value &&
        <div
          className={styles.container_iconX}
          onClick={() => handleDeleteSearch()}
        >
          <XMarkIcon width={22} className={styles.iconX} />
        </div>
      }
    </div>
  );
}
