import { useState } from 'react';

import { NewHeaderSidebar } from './NewHeaderSidebar'
import { NavigationSidebarCefx } from './NavigationSidebarCefx'

import styles from './styles.module.css'

interface NewSidebarCefxProps {
    infoPaciente: any;
    pontos: any[]
    textPont: string;
    ocefx: any;
    handleGetNameEndCodAnalyze: any;
    handlePontName: (ponto: any) => void;
    loadingPacientSearchCefx?: boolean;
}

export function NewSidebarCefx({
    infoPaciente,
    textPont,
    ocefx,
    handleGetNameEndCodAnalyze,
    loadingPacientSearchCefx,
    handlePontName }: NewSidebarCefxProps) {

    return (
        <aside className={styles.asideCefX}>
            <NewHeaderSidebar
                infoPaciente={infoPaciente}
                loadingPacientSearchCefx={loadingPacientSearchCefx}
                ocefx={ocefx}
            />

            <NavigationSidebarCefx
                textPont={textPont}
                ocefx={ocefx}
                handleGetNameEndCodAnalyze={handleGetNameEndCodAnalyze}
                handlePontName={handlePontName}
            />
        </aside>
    )
}