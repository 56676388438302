import { Button, Text } from "@cdt-ui/react";

import styles from "./styles.module.css";
import { ArrowRight } from "@phosphor-icons/react";

import { useEffect, useState } from "react";

import { NoClass } from "./components/NoClass";
import { Class } from "./components/Class";

import { ImageSVG } from "./components/Image/index";

import { PencilIcon } from "@heroicons/react/24/solid";

import { Badge } from "../Especialista/components/Badge";
import { Link, useParams, useNavigate } from "react-router-dom";

import Api from "../../../helpers/Api";

import { StrictModeDroppable } from "../../../utils/StrictModeDroppable";

import { DragDropContext, Draggable } from "react-beautiful-dnd";

import { Loading } from "../../../components/Loading/index";

export function EmptyState() {
  const [classes, setClasses] = useState([]);
  const [courseData, setCourseData] = useState<any>({});
  const [teacherData, setTeacherData] = useState<any>({});

  const navigate = useNavigate();

  const { id } = useParams();

  const getLessons = async () => {
    try {
      const lesson = await Api.getLessonFromCourse(id);
      if (lesson.isSuccess && lesson.data.length > 0) {
        let copyArr = [...classes];
        lesson.data.map(
          ({ title, description, video, linkExtraContent, id }) => {
            copyArr.push({
              createNext: false,
              id: id - 1,
              extra: linkExtraContent,
              vidLink: video,
              desc: description,
              title,
              collapse: true,
              isFromAPI: true,
            });
          }
        );
        setClasses(copyArr);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getCourseData = async () => {
    try {
      const course = await Api.getCourseById(parseInt(id));
      if (course.isSuccess) {
        const teacher = await Api.getTeacherById(course.data.idTeacher);
        if (teacher.isSuccess) {
          setTeacherData(teacher.data);
          setCourseData({ ...course.data, teacher: teacher.data });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCourseData();
    getLessons();
  }, []);

  const handleSubmitCourse = async () => {
    classes.map(({ title, desc, vidLink, extra, isFromAPI }) => {
      (async () => {
        try {
          if (isFromAPI === false) {
            const { data } = await Api.postLesson({
              title,
              description: desc,
              video: vidLink,
              archiveExtraContent: "",
              linkExtraContent: extra,
              watched: false,
              active: true,
              idCourse: parseInt(id),
            });
          }
        } catch (err) {
          console.error(err);
        }
      })();
    });
    navigate("/dashboard/academy/curso");
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(classes);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const newArr = items.map((e, index) => {
      if (e.id !== index && e.title !== undefined) {
        return {
          ...e,
          id: index,
        };
      } else {
        return e;
      }
    });

    setClasses(newArr);
  };
  return (
    <>
      {Object.keys(courseData).length > 0 ? (
        <div className={styles.outerContainer}>
          <div className={styles.headerContainer}>
            <h2 className={styles.courseTitle}>{courseData.name}</h2>
            <div className={styles.categoriesContainer}>
              <h4>Categorias do Curso</h4>
              <div className={styles.badgeContainer}>
                <Badge name={courseData.courseCategory.name} type={1} />
              </div>
            </div>
            <div className={styles.descContainer}>
              <div className={styles.imageContainer}>
                <img
                  className={styles.coverPhoto}
                  src={courseData.coverPhoto}
                />
                <div className={styles.editContainer}>
                  <PencilIcon width={20} color="#fff" />
                </div>
              </div>

              <div className={styles.courseDescContainer}>
                <div>
                  <h4>Descrição do Curso</h4>
                  <Text>{courseData.description}</Text>
                </div>
                <div>
                  <h4>Nome do Especialista</h4>
                  <Text>{courseData.teacher.name}</Text>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.classScrollView}>
            {classes.length > 0 ? (
              <Button
                className={`${styles.submitBtn} ${styles.addClassBtn}`}
                variant="greenDark"
                size="full"
                onClick={() => setClasses([...classes, {}])}
              >
                Adicionar Aula
              </Button>
            ) : (
              <></>
            )}
            {classes.length > 0 ? (
              <div>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <StrictModeDroppable droppableId="droppable">
                    {(provided) => {
                      return (
                        <ul
                          className={styles.draggableList}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {classes.map(({ title, collapse }, index) => (
                            <Class
                              key={index}
                              classesData={{ classes, setClasses }}
                              title={title}
                              id={index}
                              provided={provided}
                              collapse={collapse}
                            />
                          ))}
                        </ul>
                      );
                    }}
                  </StrictModeDroppable>
                </DragDropContext>
              </div>
            ) : (
              <NoClass contentStatus={{ classes, setClasses }} />
            )}
          </div>
          <div>
            <div className={styles.horLine}></div>
            <div className={styles.submitContainer}>
              <Link className={styles.submitBtn} to="/dashboard/academy/curso">
                <Button
                  className={styles.submitBtn}
                  variant="tertiary"
                  size="full"
                >
                  Cancelar
                </Button>
              </Link>
              <Button
                className={styles.submitBtn}
                type="submit"
                variant="greenDark"
                size="full"
                onClick={handleSubmitCourse}
              >
                Salvar curso
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}
    </>
  );
}
