export function Circle({ containerClassName, color }) {
  return (
    <div className={containerClassName}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
      >
        <circle cx="4.70646" cy="4.70646" r="4.70646" fill={color} />
      </svg>
    </div>
  );
}
