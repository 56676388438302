import styles from '../../styles.module.css'
import { CardRelatorio } from '../components/cardRelatorio';
import img1 from "../../modalRelatorio/padrao/1.png"
import img2 from "../../modalRelatorio/padrao/2.png"
import img4 from "../../modalRelatorio/padrao/4.png"
import img6 from "../../modalRelatorio/Panoramica/6.png"
export function Correcao({ getRelatoriosSelect }: any) {
    const arrayDeRelatorios = [
        { nome: 'Fatores', codigo: 11, img: img1 },
        { nome: 'Fatores com gráficos', codigo: 12, img: img2 },
        { nome: 'Com imagens', codigo: 13, img: img4 },
        { nome: 'Somente desenho', codigo: 14, img: img6 },
    ];
    return (
        <div className={styles.wrapper}>
            <div className={styles.container_content}>
                {arrayDeRelatorios.map((relatorio, index) => (
                    <div key={index} className={styles.wrapperImg}>
                        <CardRelatorio
                            nome={relatorio.nome}
                            codigo={relatorio.codigo}
                            getRelatoriosSelect={getRelatoriosSelect}
                            img={relatorio.img}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}