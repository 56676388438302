import { useEffect, useLayoutEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, createBrowserRouter, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from 'react-hot-toast';

import isCefalometriaSlice, { selectIsCefalometria, setIsCefalometria } from "./store/slices/isCefalometriaSlice";
import { selectUser, setUser } from "./store/slices/userSlice";
import Api from "./helpers/Api";
import { Loading } from "./components/Loading";
import { Login } from "./pages/Login";
import { Cadastro } from "./pages/Cadastro";
import { ViewImages } from "./pages/ViewImages";
import { Sidebar } from "./components/Sidebar";
import { menuLinksPatient } from "./Data/menuLinkPatient";
import { menuLinksPatients } from "./Data/menuLinkPatients";

import { menuLinks } from "./Data/menuLinks";
import { Header } from "./components/Header";
import { store } from "./store/store";
import { PatientProvider } from "./pages/PatientList/PatientProvider";
import { Footer } from "./components/Footer";
import { PatientList } from "./pages/PatientList";
import { UserProfile } from "./pages/UserProfile";
import { NotFound } from "./pages/NotFound";
import { EditarPerfil } from "./pages/EditarPerfil";
import { Notifications } from "./pages/Notifications";
import { IndicadoresOportunidade } from "./pages/IndicadoresOportunidade";
import { SelectedCard } from "./pages/IndicadoresOportunidade/pages/SelectedCard/selectedCard";
import { Academy } from "./pages/Academy/AreaEstudo";
import { EmptyState } from "./pages/Academy/CadastrarCurso";
import { ViewCourses } from "./pages/Academy/VisualizarCurso";
import { Especialista } from "./pages/Academy/Especialista";
import { Financial } from "./pages/Paywall/Financial";
import { Diary } from "./pages/Paywall/Diary";
import { Marketing } from "./pages/Paywall/Marketing";
import { Supervisor } from "./pages/Paywall/Supervisor";
import { Reports } from "./pages/Paywall/Reports";
import { Clinic } from "./pages/Clinic";
import { FunilVendas } from "./pages/FunilVendas";
import { NoClass } from "./pages/Clinic/components/NoClass";
import { InfoPaciente } from "./pages/InfoPaciente";
import { NewClinic } from "./pages/Clinic/newClinic";
import Viewer3d from "./pages/ViewImages/Viewer3d/Viewer3d";
import { setIsClinicConnectionsComplete } from "./store/slices/isClinicConnectionsCompleteSlice";
import { ExamsRequest } from "./pages/ExamsRequest";
import { Editor } from "./pages/ExamsRequest/pages/Editor";
import { RecoverPassword } from "./pages/RecoverPassword";
import { ListOfModels } from "./pages/ExamsRequest/pages/ListOfModels";
import ErrorBoundary from "./ErrorBoundary";
import AppLayout from "./AppLayout";

function PrivateRoute({ element }) {
    const idClient = localStorage.getItem('idClientLogin');
    const dispatch = useDispatch();
    const { user } = useSelector(selectUser);
    const { isCefalometria } = useSelector(selectIsCefalometria);
    const userValue = Cookies.get("userMain");

    useLayoutEffect(() => {
        if (userValue && user.length == 0) {
            const parsedUser = JSON.parse(userValue);
            dispatch(setUser(parsedUser));
        }
    }, [dispatch, userValue]);

    const isAuthenticated = useSelector(
        (state: { user }) => state.user.user !== false
    );

    if (!isAuthenticated) {
        return <Navigate to={isCefalometria ? `/?cli=${idClient}&cefalometria=s` : `/?cli=${idClient}`} />
    }
    return element;
}


function LoginAutomatic() {
    const params = new URLSearchParams(window.location.search);

    const { user } = useSelector(selectUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isCefalometria } = useSelector(selectIsCefalometria);
    const [isLoading, setIsLoading] = useState(true);

    const idClient = params.get("cli") || params.get("idClient") || (params.get("q") ? parseInt(params.get("q"), 16).toString() : null);

    const idClientLocalStorage = localStorage.getItem('idClientLogin');

    useEffect(() => {
        const signInAndRedirect = async () => {
            const token = params.get('t');
            const seqAtend = params.get('seqAtend');
            const cefalometria = params.get("cefalometria") === "s"

            sessionStorage.setItem("token", token);

            if (params.get("cefalometria") === "s" || cefalometria) {
                dispatch(setIsCefalometria(true));
            } else {
                dispatch(setIsCefalometria(false));
            }

            const isPerfilPaciente = window.location.href.includes('perfil-paciente');
            const ss = params.get('ss');
            const paciente = params.get('paciente');
            const idPacient = params.get('idPacient');

            if (!token) {
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000)
                return
            }

            try {
                const result = await Api.SignInAutomatic(token);
                if (result.isSuccess) {
                    localStorage.setItem("idClientLogin", idClient);

                    dispatch(setUser(result.data));

                    if (isPerfilPaciente && ss && paciente && seqAtend && idClient && idPacient && !cefalometria) {
                        const redirectPath = `/dashboard/perfil-paciente/${ss}/?paciente=${encodeURIComponent(paciente)}&seqAtend=${seqAtend}&idClient=${idClient}&idPacient=${idPacient}`;
                        navigate(redirectPath);
                    } else if (seqAtend && cefalometria) {
                        const redirectPath = `/dashboard/visualizar-imagens/?cefalometria=s&id=${result.data[0].idClient}&atd=${seqAtend}`;
                        navigate(redirectPath);
                    } else {
                        navigate("/dashboard/lista-pacientes");
                    }

                } else {
                    if (Array.isArray(user) || idClientLocalStorage == idClient) {
                        const userMatch = user.find(user => user.idClient === idClient);
                        if (userMatch) {
                            if (isPerfilPaciente && ss && paciente && seqAtend && idClient && idPacient && !cefalometria) {
                                const redirectPath = `/dashboard/perfil-paciente/${ss}/?paciente=${encodeURIComponent(paciente)}&seqAtend=${seqAtend}&idClient=${idClient}&idPacient=${idPacient}`;
                                navigate(redirectPath);
                            } else if (seqAtend && cefalometria) {
                                const redirectPath = `/dashboard/visualizar-imagens/?cefalometria=s&id=${userMatch.idClient}&atd=${seqAtend}`;
                                navigate(redirectPath);
                            } else {
                                navigate("/dashboard/lista-pacientes");
                            }
                        }
                    }
                }
            } catch (error) {
                if (error.message.includes('401') || error.message.includes('400')) {
                    if (Array.isArray(user)) {
                        const userMatch = user.find(user => user.idClient === idClient);

                        if (userMatch) {
                            if (isPerfilPaciente && ss && paciente && seqAtend && idClient && idPacient && !cefalometria) {
                                const redirectPath = `/dashboard/perfil-paciente/${ss}/?paciente=${encodeURIComponent(paciente)}&seqAtend=${seqAtend}&idClient=${idClient}&idPacient=${idPacient}`;
                                navigate(redirectPath);
                            } else if (seqAtend && cefalometria) {
                                const redirectPath = `/dashboard/visualizar-imagens/?cefalometria=s&id=${userMatch.idClient}&atd=${seqAtend}`;
                                navigate(redirectPath);
                            } else {
                                navigate("/dashboard/lista-pacientes");
                            }
                        } else {
                            navigate(cefalometria ? `/?cli=${idClient}&cefalometria=s` : `/?cli=${idClient}`);
                            setTimeout(() => {
                                toast.error(
                                    <div style={{ textAlign: 'center' }}>
                                        Já existe uma conta logada e os IDs da clínica não correspondem.
                                    </div>
                                );
                            }, 200)
                        }
                    } else {
                        navigate(cefalometria ? `/?cli=${idClient}&cefalometria=s` : `/?cli=${idClient}`);
                        setTimeout(() => {
                            toast.error(
                                <div style={{ textAlign: 'center' }}>
                                    Token inválido.
                                    <br />
                                    Por favor, tente novamente ou faça o login manualmente.
                                </div>
                            );
                        }, 200)
                    }
                } else {
                    console.error("Erro na autenticação", error);
                }
            } finally {
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000)
            }
        };

        signInAndRedirect();
    }, [dispatch, navigate]);

    if (isLoading) {
        return <Loading text="Por favor, Aguarde..." fullScreen />;
    }

    return <Login />;
}

const DashboardLayout = () => {
    const location = useLocation();
    const currentURL = location?.pathname || "";
    const dispatch = useDispatch();

    const [isChecked, setChecked] = useState(false);
    const [lastVisit, setLastVisit] = useState(localStorage.getItem("lastVisit"));
    const [collapse, setCollapse] = useState(true);
    const [mobileCollapse, setMobileCollapse] = useState(false);
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [widthSidebar, setWidthSidebar] = useState<string>("72px");

    const isMobile = width <= 880;
    const { user } = useSelector(selectUser);
    const hash = user[0]?.hash;

    const isNotebook = width <= 1700;

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    function handleCollapse() {
        if (isMobile) {
            setMobileCollapse(!mobileCollapse);
        }
        else {
            setCollapse(!collapse);
        }
    }

    useEffect(() => {
        const aside = document.querySelector("aside");
        setTimeout(() => {
            setWidthSidebar(getComputedStyle(aside).width);
        }, 200);
    }, [collapse]);

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);
    const userValue = Cookies.get("userMain");

    useEffect(() => {
        localStorage.setItem("lastVisit", new Date().getTime().toString());
        setLastVisit(localStorage.getItem("lastVisit"));
    }, [location.pathname]);

    useEffect(() => {
        const INACTIVITY_TIMEOUT = 20 * 60 * 1000; // 20 minutos em milissegundos

        const logoutIfInactive = () => {
            const currentTime = new Date().getTime();
            const timeSinceLastVisit = currentTime - parseInt(lastVisit);
            if (!userValue && timeSinceLastVisit >= INACTIVITY_TIMEOUT) {
                localStorage.removeItem("persist:root");
                localStorage.removeItem("currentClinicConnection");
                setIsClinicConnectionsComplete(false);

            }
        };

        const timer = setTimeout(logoutIfInactive, INACTIVITY_TIMEOUT);

        return () => clearTimeout(timer);
    }, [userValue, lastVisit, setIsClinicConnectionsComplete]);

    return (
        <>
            {currentURL && currentURL.includes("visualizar-imagens") ? (
                <ViewImages />
            ) : (
                <div className="dashboard-layout">
                    {hash &&
                        (
                            hash.includes("PC") ||
                            hash.includes("CV") ||
                            hash.includes("FN")


                        ) ? (
                        <Sidebar
                            links={
                                hash.includes("FN") ? menuLinksPatients : menuLinksPatient
                            }
                            sidebarState={{
                                collapse,
                                setCollapse,
                                mobileCollapse,
                                setMobileCollapse,
                                isMobile,
                                isNotebook,
                                handleCollapse,
                            }}
                        />
                    ) : (
                        <Sidebar
                            links={menuLinks}
                            sidebarState={{
                                collapse,
                                setCollapse,
                                mobileCollapse,
                                setMobileCollapse,
                                isMobile,
                                isNotebook,
                                handleCollapse,
                            }}
                        />
                    )}

                    <main
                        className="dashboard-content"
                        style={
                            !isMobile
                                ? {
                                    paddingLeft: widthSidebar,
                                    transition: "paddding-left ease-out 100ms",
                                }
                                : {}
                        }
                    >
                        <Header
                            sidebarState={{
                                collapse,
                                setCollapse,
                                mobileCollapse,
                                setMobileCollapse,
                                isMobile,
                                handleCollapse,
                            }}
                        />

                        <Provider store={store}>
                            {hash &&
                                hash.includes("PC") &&
                                hash.includes("CV") &&
                                hash.includes("FN") ? (
                                <PatientProvider>
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={
                                                <PrivateRoute
                                                    element={<Navigate to="lista-pacientes" />}
                                                />
                                            }
                                        />

                                        <Route
                                            path="lista-pacientes"
                                            element={<PrivateRoute element={<PatientList sidebarCollapse={collapse} />} />}
                                        />

                                        <Route
                                            path="perfil-paciente/:id/*"
                                            element={<PrivateRoute element={<UserProfile />} />}
                                        />
                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                </PatientProvider>
                            ) : (
                                <PatientProvider>
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={
                                                <PrivateRoute
                                                    element={<Navigate to="lista-pacientes" />}
                                                />
                                            }
                                        />
                                        <Route
                                            path="editar-perfil"
                                            element={<PrivateRoute element={<EditarPerfil />} />}
                                        />
                                        <Route
                                            path="notificacoes"
                                            element={<PrivateRoute element={<Notifications />} />}
                                        />
                                        <Route
                                            path="lista-pacientes"
                                            element={<PrivateRoute element={<PatientList sidebarCollapse={collapse} />} />}
                                        />
                                        <Route
                                            path="indicadores-de-oportunidade"
                                            element={
                                                <PrivateRoute element={<IndicadoresOportunidade />} />
                                            }
                                        />
                                        <Route
                                            path="indicadores-de-oportunidade/detalhes-card/:id"
                                            element={<PrivateRoute element={<SelectedCard />} />}
                                        />

                                        <Route
                                            path="perfil-paciente/:id/*"
                                            element={<PrivateRoute element={<UserProfile />} />}
                                        />
                                        <Route
                                            path="criar-cadastro-clinica"
                                            element={<PrivateRoute element={<NoClass text1={"Conta não cadastrada"} text2={"Clique em  Criar conta para fazer o cadastro"}></NoClass>} />}
                                        />
                                        <Route
                                            path="academy/area-estudo"
                                            element={
                                                <PrivateRoute
                                                    element={
                                                        <Academy
                                                            sidebarState={{
                                                                collapse,
                                                                setCollapse,
                                                                mobileCollapse,
                                                                setMobileCollapse,
                                                                isMobile,
                                                                handleCollapse,
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                        />
                                        <Route
                                            path="academy/cadastrar/:id"
                                            element={<PrivateRoute element={<EmptyState />} />}
                                        />
                                        <Route
                                            path="academy/area-estudo/:id"
                                            element={
                                                <PrivateRoute
                                                    element={
                                                        <Academy
                                                            sidebarState={{
                                                                collapse,
                                                                setCollapse,
                                                                mobileCollapse,
                                                                setMobileCollapse,
                                                                isMobile,
                                                                handleCollapse,
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                        />
                                        <Route
                                            path="academy/curso"
                                            element={<PrivateRoute element={<ViewCourses />} />}
                                        />
                                        <Route
                                            path="academy/especialista/:id"
                                            element={<PrivateRoute element={<Especialista />} />}
                                        />
                                        <Route
                                            path="requisição-exames"
                                            element={<PrivateRoute element={<ExamsRequest />} />}
                                        />
                                        <Route
                                            path="requisição-exames/criar"
                                            element={<PrivateRoute element={<Editor />} />}
                                        />
                                        <Route
                                            path="requisição-exames/editar-modelo/:id"
                                            element={<PrivateRoute element={<Editor />} />}
                                        />
                                        <Route
                                            path="requisição-exames/lista-de-modelos/:user"
                                            element={<PrivateRoute element={<ListOfModels />} />}
                                        />
                                        <Route
                                            path=""
                                            element={<PrivateRoute element={<Editor />} />}
                                        />
                                        <Route
                                            path="financeiro"
                                            element={<PrivateRoute element={<Financial />} />}
                                        />
                                        <Route
                                            path="agenda"
                                            element={<PrivateRoute element={<Diary />} />}
                                        />
                                        <Route
                                            path="marketing"
                                            element={<PrivateRoute element={<Marketing />} />}
                                        />
                                        <Route
                                            path="fiscal"
                                            element={<PrivateRoute element={<Supervisor />} />}
                                        />
                                        <Route
                                            path="relatorios"
                                            element={<PrivateRoute element={<Reports />} />}
                                        />
                                        <Route
                                            path="/clinica"
                                            element={<PrivateRoute element={<Clinic />} />}
                                        />
                                        <Route
                                            path="/funil-de-vendas"
                                            element={<PrivateRoute element={<FunilVendas />} />}
                                        />
                                        <Route
                                            path="/instalar-aplicativo"
                                            element={<PrivateRoute element={
                                                <NoClass
                                                    text1={"Conta não cadastrada"}
                                                    text2={"É necessário cadastrar-se para instalar o aplicativo."}
                                                    fullScreen
                                                />
                                            }
                                            />}
                                        />
                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                </PatientProvider>
                            )}
                        </Provider>
                    </main>
                </div>
            )}
            {currentURL && currentURL.includes("academy") && (
                <Footer widthSidebar={widthSidebar} isMobile={isMobile} />
            )}
        </>
    );
};

export const router = createBrowserRouter([
    {
        path: '/',
        element: <AppLayout />,
        children: [
            {
                path: '',
                element: <LoginAutomatic />
            },
            {
                path: 'Cadastro',
                element: <Cadastro />
            },
            {
                path: 'recuperar-senha',
                element: <RecoverPassword />
            },
            {
                path: 'dashboard/*',
                element: <DashboardLayout />
            },
            {
                path: '*',
                element: <NotFound />
            },
            {
                path: 'shared/*',
                element: <InfoPaciente />
            },
            {
                path: 'dashboard/visualizar-imagens/*',
                element: <ViewImages />
            },
            {
                path: 'NewClinic',
                element: <NewClinic />
            },
        ]
    }
]);