import { Button, Text, Topbar } from "@cdt-ui/react";
import { Sidebar } from "./components/Sidebar";

import { Navigate, Route, Routes, useParams } from "react-router";
import { useEffect, useRef } from "react";
import styles from "./styles.module.css";
import {
  ArrowLeft,
  ArrowRight,
  Bookmark,
  TagChevron,
} from "@phosphor-icons/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import { Footer } from "../../../components/Footer";
import { aulas } from "../../../Data/aulas";
import { academy } from "../../../Data/academy";
import { toast } from "react-hot-toast";
import { Teste } from "./components/teste";
import Api from "../../../helpers/Api";
import { UserCard } from "./components/UserCard";
interface Teacher {
  name: string;
}
export function AcademyMobile({ sidebarState }) {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [lesson, setLesson] = useState([]);
  const savedIndex = localStorage.getItem("currentLessonIndex");
  const initialIndex = savedIndex !== null ? parseInt(savedIndex) : 0;
  const [currentLessonIndex, setCurrentLessonIndex] = useState(initialIndex);
  const [collapse, setCollapse] = useState(true);

  const sortedLesson = lesson.slice().sort((a, b) => a.id - b.id);

  const [teacher, setTeacher] = useState<Teacher | undefined>(undefined);
  const videoRef = useRef(null);

  const currentLesson =
    sortedLesson.length > 0 ? sortedLesson[currentLessonIndex] : null;
  function handleCollapse() {
    setCollapse(!collapse);
  }

  const submitSeachClinic = async (id) => {
    try {
      const results = await Api.getLessons(id);
      setLesson(results.data);
      setLoading(true);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    submitSeachClinic(id);
  }, []);

  useEffect(() => {
    localStorage.setItem("currentLessonIndex", currentLessonIndex.toString());
  }, [currentLessonIndex]);

  function nextLesson() {
    if (currentLessonIndex < sortedLesson.length - 1) {
      setCurrentLessonIndex(currentLessonIndex + 1);
    }
  }
  function previusLesson() {
    if (currentLessonIndex > 0) {
      setCurrentLessonIndex(currentLessonIndex - 1);
    }
  }
  const handleButtonClick = () => {
    window.open(currentLesson ? currentLesson.linkExtraContent : "");
  };

  const handleSelectLesson = (selectedLesson: any) => {
    const index = sortedLesson.findIndex(
      (sortedLesson) => sortedLesson.id === selectedLesson.id
    );
    if (index !== -1) {
      setCurrentLessonIndex(index);
    }
  };

  useEffect(() => {
    if (currentLesson) {
      (async () => {
        try {
          const t = await Api.getTeacherById(currentLesson.course.idTeacher);
          if (t.isSuccess) {
            setTeacher(t.data);
          }
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [currentLesson]);

  return (
    <section>
      <div className="dashboard-layout">
        {/*<Sidebar links={aulas} /> */}

        <main className="dashboard-content">
          <div
            className={
              collapse ? `${styles.aside_collapse}` : `${styles.aside}`
            }
          >
            {collapse ? (
              <>
                <div className={styles.containerHeaderCollapse}>
                  <div className={styles.containertext}>
                    <div className={styles.containerSelect}>
                      <div
                        className={styles.buttonHamburguer}
                        onClick={handleCollapse}
                      >
                        <p className={styles.containerAnimationOn}>Aula</p>
                        <div className={styles.containerLine}></div>
                      </div>
                      <div
                        className={styles.buttonHamburguer}
                        onClick={handleCollapse}
                      >
                        <p className={styles.containerAnimationOff}>
                          Material{" "}
                        </p>
                      </div>
                    </div>
                    <div className={styles.container}>
                      <div className={styles.containerIMG}>
                        <iframe
                          ref={videoRef}
                          id="myYouTubeVideo"
                          className={styles.video}
                          src={
                            currentLesson
                              ? currentLesson.video
                              : "Carregando..."
                          }
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        ></iframe>
                      </div>

                      <div className={styles.containerButton}>
                        <Button
                          className={styles.buttons}
                          onClick={previusLesson}
                        >
                          <ArrowLeft /> Voltar
                        </Button>
                        <Button className={styles.buttons} onClick={nextLesson}>
                          Avançar
                          <ArrowRight />
                        </Button>
                      </div>
                      <div className={styles.containerHeaderTitle}>
                        <div className={styles.containerButton}>
                          <Text className={styles.containerTitle}>
                            {currentLesson
                              ? currentLesson.title
                              : "Carregando..."}
                          </Text>
                        </div>
                        <div>
                          <Text className={styles.containerSubtitle}>
                            {currentLesson
                              ? ` ${currentLesson.description}`
                              : "Carregando..."}
                          </Text>
                        </div>
                      </div>
                      <Teste
                        links={sortedLesson}
                        sidebarState={sidebarState}
                        onSelectLesson={handleSelectLesson}
                      />
                      {teacher && <UserCard teacher={teacher} />}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.containerHeaderCollapse}>
                  <div className={styles.containertext}>
                    <div className={styles.containerSelect}>
                      <div
                        className={styles.buttonHamburguer}
                        onClick={handleCollapse}
                      >
                        <p className={styles.containerAnimationOff}>Aula</p>
                      </div>
                      <div
                        className={styles.buttonHamburguer}
                        onClick={handleCollapse}
                      >
                        <p className={styles.containerAnimationOn}>Material</p>
                        <div className={styles.containerLine}></div>
                      </div>
                    </div>
                    {(currentLesson &&
                      currentLesson.linkExtraContent === null) ||
                    (currentLesson && currentLesson.linkExtraContent === "") ? (
                      <div></div>
                    ) : (
                      <div
                        className={styles.containerInfoExtra}
                        onClick={handleButtonClick}
                      >
                        <div className={styles.containerInfoExtraContent}>
                          <div>
                            <p>Conteúdo Extra</p>
                            <p>Link para o PDF da aula</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </main>
      </div>
    </section>
  );
}
