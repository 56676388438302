import { useState } from 'react';

import { CardRelatorio } from '../components/cardRelatorio';

import styles from '../../styles.module.css';

export function Padrao({ getRelatoriosSelect }: any) {
    const [selectedImages, setSelectedImages] = useState<string[]>([]);

    const arrayDeRelatorios = [
        { nome: 'Fatores', codigo: 1, img: "../../modalRelatorio/padrao/1.png" },
        { nome: 'Fatores com gráficos', codigo: 2, img: "../../modalRelatorio/padrao/2.png" },
        { nome: 'Carpal', codigo: 3, img: "../../modalRelatorio/padrao/3.png" },
        { nome: 'Com imagens', codigo: 4, img: "../../modalRelatorio/padrao/4.png" },
        { nome: 'Com foto', codigo: 5, img: "../../modalRelatorio/padrao/5.png" },
        { nome: 'Somente desenho', codigo: 6, img: "../../modalRelatorio/padrao/6.png" },
        //{ nome: 'Painel', codigo: 7, img: "../../modalRelatorio/padrao/7.png" },
        //{ nome: 'Capa da pasta', codigo: 8, img: "../../modalRelatorio/padrao/8.png" },
        { nome: 'Fatores com gráficos', codigo: 9, img: "../../modalRelatorio/padrao/9.png" },
        { nome: 'Transparência', codigo: 10, img: "../../modalRelatorio/padrao/10.png" },
    ];

    return (
        <div className={styles.wrapper}>
            <div className={styles.container_content}>
                {arrayDeRelatorios.map((relatorio, index) => (
                    <div key={index} className={styles.wrapperImg}>
                        <CardRelatorio
                            getRelatoriosSelect={getRelatoriosSelect}
                            nome={relatorio.nome}
                            codigo={relatorio.codigo}
                            img={relatorio.img}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}