import React from 'react'
import { inflate } from 'zlib'
import Modal from 'react-modal'
import styles from './styles.module.css';
import { Container } from '../../../Academy/CadastrarCurso/components/Content/Container';
import { ClockIcon } from "../../components/ClockIcon";
import { XMarkIcon } from '@heroicons/react/24/outline';
import Avatar from "react-avatar";
import { Card } from "../../components/Card";


interface ModalSendEmail {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function ModalSendEmail({ isOpen, onRequestClose }: ModalSendEmail) {
  return (
    <div className={`${styles.SendMsg} ${styles.modalEmail}`}>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react_modal_overlay"
        className={styles.react_modal_SendEmail}>

        <div className={styles.icon_wrapper}>
          <XMarkIcon
            title='Fechar'
            onClick={onRequestClose}
          />
        </div>

        <header className={styles.headerEmail}>
          <div className={styles.container_contentEmail}>
            <h2>Enviar mensagem por E-mail</h2>
            <p>Lorem ipsum dolor sit amet</p>
          </div>
        </header>
        <div className={styles.cardContentWhatsHeader}>
          <h2>PACIENTES</h2>
        </div>
        <div className={styles.cardContentEmail}>
          <h2>Retorno tomografia - Jovens</h2>
          <div className={styles.clockIconContainerEmail}>
            <ClockIcon
              containerClassName="ClockIcon"
            />
            <p>Consulta há 1 ano</p>
          </div>

          <div className={styles.pacientesContainerEmail}>
            <div className={styles.avataresEmail}>
              <span className={styles.avatarsEmail}>
                <Avatar
                  className={styles.avatarEmail}
                  size="30"
                  src="https://s3-alpha-sig.figma.com/img/2e01/b2ad/c895fca7e299aceb539bf37f9a72f316?Expires=1693180800&Signature=f3tWiqHhWBe1ltwiLUk8CEfaRaxVTTCWxHzE2Dp6GFWtERsFJA8gx0~lh1xvDFlOVIB~iNU1lyXephgidM-aVECsULQeW6LqShNFggOLINEU6Be49pMoT-AlGDpfZu33EziR-IlyTWSO~f~EeUAAsk6k1tafJG21JgQbaNIyKMlZkwoR8bD-E8yqBR8HzLVVmimbG4KoSPEu~L~aXaCsdi9sehxOkRcmIHZlbYpFCUHm2CXyzuemXdPxkLMv2fF01jVqZoaczLXkWmEe3JtFH6qd-0gGhuwiJmwAAtjM1FAOf7Sfp8cl626-ymPANjT3uzM41S921fU60BLoV8JTlQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
                />
                <Avatar
                  className={styles.avatar1Email}
                  size="30"
                  src="https://s3-alpha-sig.figma.com/img/3170/76df/a57d49314359fd7606cce752f3324d5d?Expires=1693180800&Signature=K2ypC1P4TjzX9YhxEoc8UP2bwtHEKDy5U5vQtOyx9Xjt9dWMCRp54QyAg35XN~LwjLnxM9ckeOba4r0wpWV-FQNiVv69KDDtOQMwJpfNwVUY8~Go37Ff18LWUrFMpNfJ-qiZplyXH1NHtOpPOblB3TQfV4UgFntNRo9JUbOJrUUOSyTcM0VLlmrK6kXshb3XkZIJgz3UuRtwVhDdUgHgaqTzLP-MEHUdPLXRiNtr5HjMxGrVaBUz4JQWxW4qvGargu4ROlqASR2V8hgyMbwvgWjnGOK8btGfUINW2xtmPWSkcMV-~WuhmzZPqswYXfqzYSqJfmf1PbdEQtQkXR8ayQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
                />
                <Avatar
                  className={styles.avatar2Email}
                  size="30"
                  src="https://s3-alpha-sig.figma.com/img/f127/01bd/073e2bc1cd36ec23f693b57248587006?Expires=1693180800&Signature=PQR6rMdBj5VGRghuwsQtl31QGqt305DE4r93lObv5ZSsf8GCU5zdd5MO56a3ajAfB6NRhj1yHD186Fblq0pB4C7ooO~iDjgGI77sXE3SN7tf1wRvgMfeiHM3Gwz-tqbw-x6p4~BKESBBh7jlrZ-HDbMItsYvISYAuO9xONJWN31LT3gKcZkZkjzs3G3C0utW5EfoBosWdohWNukNLs241CtDc~UQd97Xgyrrpb7Zos74Cyn3cMJ6610lUwdKdVxDOkGJyzPo3fv64ld837Z0Qc1m-bMzPcwtTSGr8qAIyOaQtCgflCx17xKsdYlWtFXlDUyWuGrBvFiphG0slSyt0w__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
                />
              </span>
            </div>
            <p>84 pacientes selecionados</p>
            <div className={styles.numeroPacientesEmail}>
              <p>84 de 324 pacientes</p>
            </div>
          </div>
        </div>

        <div className={styles.linhaEmail}>
          <svg xmlns="http://www.w3.org/2000/svg" width="752" height="3" viewBox="0 0 754 2" fill="none">
            <path d="M1 1H753" stroke="#EBEEF3" strokeLinecap="round" />
          </svg>
        </div>


        {/* <hr className={styles.hrEmail}/> */}
        <div>

          <div className={styles.AssuntoEmail}>
            <p>Assunto</p>
            <textarea placeholder="Insira o título da sua mensagem">
            </textarea>
          </div>


        </div>
        <div className={styles.mensagemPacienteEmail}>
          <p>Mensagem para paciente</p>
          <textarea placeholder="Lembrete: Seu Sorriso Merece Cuidados Regulares!
            Prezado(a) [Nome do paciente],
            Parabéns por cuidar do seu sorriso! Já se passou um ano desde sua última visita conosco. Agende sua consulta para mantermos seu sorriso saudável e radiante.
            ㅤ 
            Contate-nos: [Inserir telefone]
            Atenciosamente,
            Equipe [Nome da Clínica Odontológica]">
          </textarea>
        </div>
        <div className={styles.btnEmail}>
          <button>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M1.25 7.22425V14.375C1.25 15.7557 2.36929 16.875 3.75 16.875H16.25C17.6307 16.875 18.75 15.7557 18.75 14.375V7.22425L11.3102 11.8026C10.5067 12.297 9.49327 12.297 8.68976 11.8026L1.25 7.22425Z" fill="white" />
              <path d="M18.75 5.75652V5.625C18.75 4.24429 17.6307 3.125 16.25 3.125H3.75C2.36929 3.125 1.25 4.24429 1.25 5.625V5.75652L9.34488 10.738C9.74664 10.9852 10.2534 10.9852 10.6551 10.738L18.75 5.75652Z" fill="white" />
            </svg>
            Enviar por E-mail
          </button>
        </div>
      </Modal>
    </div>
  );



}