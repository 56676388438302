import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  shared: [],
};

export const SharedSlice = createSlice({
  name: "shared",
  initialState,
  reducers: {
    setShared: (state, action: PayloadAction<any>) => {
      state.shared = action.payload;
    },
  },
});

export const { setShared } = SharedSlice.actions;

export const selectShared = (state: any) => state.shared;

export default SharedSlice.reducer;
